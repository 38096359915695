/**
 * CalendarPage - styles
 */

import styled, { css } from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { Cell } from 'components/Grid';
import { H3, P, Preamble } from 'components/Text';
import Button from 'components/Button';

export interface ThemeProps {
	isActive?: boolean;
}

export const baseTextStyles = css`
	font-weight: ${typography.fontWeight.semiBold};
`;

export const FragmentsContainer = styled.div`
	font-family: ${typography.fontFamily.alt};
	line-height: ${px2rem(32)};
`;

export const LeftCell = styled(Cell)`
	${mediaQueries.phoneOrTablet} {
		order: 1;
	}
`;

export const RightCell = styled(Cell)`
	width: auto;
	margin: 0 ${px2rem(30)};
	${mediaQueries.phoneOrTablet} {
		margin: ${px2rem(25)} 0;
		order: 0;
	}
`;

export const LocationsArea = styled.div`
	${mediaQueries.tabletOrLarger} {
		position: sticky;
		top: ${px2rem(115)};
	}
`;

export const Location = styled(P)`
	${baseTextStyles};
	color: ${colors.grey};
	font-size: ${px2rem(16)};
	letter-spacing: 0;
	line-height: ${px2rem(25)};
	margin-bottom: 0;
`;

export const DateTime = styled(P)`
	${baseTextStyles};
	color: ${colors.black};
	font-size: ${px2rem(24)};
	letter-spacing: 0;
	line-height: ${px2rem(32)};
	margin-bottom: 0;
`;

export const LastModifiedDate = styled.p`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(24)};
	letter-spacing: normal;
	color: ${colors.greyDark};
	margin-top: ${px2rem(60)};
	margin-bottom: 0;
`;

export const StyledButton = styled(Button)`
	height: ${px2rem(60)};
	padding: ${px2rem(22)} ${px2rem(40)};
	margin-top: ${px2rem(20)};
`;

export const FormContainer = styled.div`
	max-width: ${px2rem(790)};
	margin: 0 auto;
	padding: 5.5rem 0;
	font-family: ${typography.fontFamily.alt};

	@media (max-width: 1040px) {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	${mediaQueries.phone} {
		max-width: 100%;
		padding: 3rem 0;
	}
`;

export const FormHeading = styled(H3)`
	font-size: ${px2rem(28)};
	font-weight: ${typography.fontWeight.semiBold};
	letter-spacing: 0;
	line-height: ${px2rem(55)};
	margin: 0;
	text-align: center;
`;

export const FormSubText = styled(P)`
	font-size: ${px2rem(16)};
	font-weight: ${typography.fontWeight.normal};
	margin: 0;
	line-height: ${px2rem(24)};
	letter-spacing: ${px2rem(0.35)};
	text-align: center;
`;

export const StyledPreamble = styled(Preamble)`
	margin: 0 0 3.5rem;
`;
