/**
 * StartPage
 */

import React from 'react';
import { StartPageModel } from 'types/pages';
import EpiContentArea from 'components/EpiContentArea';

/** Page template for startpage. */
const StartPage: React.FC<StartPageModel> = ({
	topContentArea,
	contentArea,
	_properties = {},
}) => (
	<main id="main-content">
		<EpiContentArea
			{..._properties?.topContentArea}
			content={topContentArea}
			isPageHeader={true}
		/>
		<EpiContentArea {..._properties?.contentArea} content={contentArea} />
	</main>
);

export default StartPage;
