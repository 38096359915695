/**
 * ErrorPage404
 */

import React from 'react';
import { ErrorPageModel } from 'types/pages';
import ErrorImage from './404.png';

import { Container, Image, ErrorHeading } from './ErrorPage404.styles';
import Button from 'components/Button';
import EpiFragments from 'components/EpiFragments';

/** Error page for handling 404 erros sent by server */
const ErrorPage404: React.FC<ErrorPageModel> = ({
	heading,
	content,
	buttonLink,
}) => {
	return (
		<main id="main-content">
			<Container>
				<Image src={ErrorImage} alt="" />

				{heading && <ErrorHeading>{heading}</ErrorHeading>}

				{content && <EpiFragments fragments={content.fragments} />}

				{buttonLink?.link && (
					<Button variant="secondary" type="link" href={buttonLink.link}>
						{buttonLink.text}
					</Button>
				)}
			</Container>
		</main>
	);
};

export default ErrorPage404;
