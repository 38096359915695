/**
 * SearchResultsList
 */

import React from 'react';
import { Accordion } from 'components/Accordion';

interface Props {
	children: any;
}

/** Calendar search results. */
const SearchResultsList: React.FC<Props> = ({ children }) => {
	return <Accordion>{children}</Accordion>;
};

export default SearchResultsList;
