/**
 * UrlFragment
 */

import React, { useEffect, useRef } from 'react';
import { LinkText } from 'components/Link/Link.styles';
import Link from 'components/Link';

import {
	Container,
	WrittenByList,
	Text,
	StyledIcon,
} from './UrlFragment.styles';

interface Props {
	/** Link text */
	text?: string;

	/** Target: New tab or not */
	target?: string;

	/** Link url */
	href?: string;

	/** Icon type */
	iconType?: 'external' | 'pdf' | 'word' | 'excel';

	/** Child items */
	writtenBy?: string[];
}

/** Url fragment. */
const UrlFragment: React.FC<Props> = ({
	href,
	iconType,
	text,
	target,
	writtenBy,
}) => {
	const nodeRef = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		if (nodeRef.current) {
			const a = nodeRef.current;

			if (nodeRef.current.classList.contains('pdf')) {
				a.setAttribute('aria-label', 'PDF-fil, ' + a.innerText);
			} else if (a.classList.contains('word')) {
				a.setAttribute('aria-label', 'Word-fil, ' + a.innerText);
			} else if (a.classList.contains('excel')) {
				a.setAttribute('aria-label', 'Excel-fil, ' + a.innerText);
			}
		}

		return () => {};
	}, []);

	return (
		<Container>
			<Link href={href} target={target} ref={nodeRef}>
				<LinkText>
					<span
						dangerouslySetInnerHTML={{
							__html: text as string,
						}}
					/>
					{iconType && (
						<StyledIcon icon={iconType} size={0} aria-hidden="true" />
					)}
				</LinkText>
			</Link>

			{writtenBy && (
				<WrittenByList>
					{writtenBy.map((text: string, index: number) => (
						<li key={index}>
							<Text>{text}</Text>
						</li>
					))}
				</WrittenByList>
			)}
		</Container>
	);
};

export default UrlFragment;
