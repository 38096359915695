/**
 * Multiselect
 */

import React, { useState, useRef } from 'react';
import { MultiselectProps } from 'types/form';
import { Fieldset } from '../index';
import Icon from 'components/Icon';
import { useOutsideClick } from 'hooks';
import {
	FormWrapper,
	MultiselectHeader,
	MultiselectExpandButton,
	Legend,
	MultiselectDropDown,
} from '../Form.styles';

const Multiselect: React.FC<MultiselectProps> = ({
	id,
	description,
	required,
	label,
	isInvalid,
	validationMessage,
	children,
}) => {
	const fieldRef = useRef<any>();
	const [visibleOptions, setVisibleOptions] = useState(false);
	useOutsideClick(fieldRef, () => {
		setVisibleOptions(false);
	});
	return (
		<FormWrapper ref={fieldRef}>
			<Fieldset
				id={id}
				description={description}
				required={required}
				label={label}
				isInvalid={isInvalid}
				hiddenLabel
				validationMessage={validationMessage}
			>
				<MultiselectHeader>
					<Legend as="p" aria-hidden="true">
						{label}
					</Legend>

					<MultiselectExpandButton
						onClick={() => setVisibleOptions((visible) => !visible)}
						aria-label={`${
							visibleOptions ? 'Stäng' : 'Öppna'
						} alternativ för ${label}`}
						aria-expanded={visibleOptions}
						aria-controls={`${id}-dropdown`}
						type="button"
					>
						<Icon
							icon="chevron"
							aria-hidden="true"
							direction={visibleOptions ? 'left' : 'right'}
						/>
					</MultiselectExpandButton>
				</MultiselectHeader>

				<MultiselectDropDown hidden={!visibleOptions} id={`${id}-dropdown`}>
					{children}
				</MultiselectDropDown>
			</Fieldset>
		</FormWrapper>
	);
};

export default Multiselect;
