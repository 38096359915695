/**
 * PopularLinks
 */

import React from 'react';
import {
	LinksHeading,
	List,
	LinksContainerInner,
} from './StartBannerBlock.styles';

interface Props {
	/** Link list heading */
	heading: string;

	/** Links */
	children: any;
}

/** Container component for popular links. */
const PopularLinks: React.FC<Props> = ({ heading, children }) => (
	<LinksContainerInner>
		<LinksHeading as="h2">{heading}</LinksHeading>
		<List>{children}</List>
	</LinksContainerInner>
);

export default PopularLinks;
