/**
 * Button - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, typography } from 'theme';
import { darken } from 'polished';

import Icon from 'components/Icon';

export interface StylePropsInterface {
	/** Sets the size of the button */
	$size?: 'large' | 'medium' | 'small';

	$hasIcon?: boolean;

	/** Sets the variant of the button */
	$variant?:
		| 'primary'
		| 'secondary'
		| 'outlined'
		| 'white'
		| 'transparent'
		| 'link';

	/** Sets the width of the button */
	$displayWidth?: 'dynamic' | 'fixed' | 'full';
}

const getSize = (size: string) => {
	switch (size) {
		case 'small':
			return `
				height: 2rem;
				padding: 0 1rem;
			`;
		case 'large':
			return `
				height: 4rem;
				padding: 0 2rem;
			`;
		default:
			return `
				height: ${px2rem(46)};
				padding: 0 1.5rem;
			`;
	}
};

const getVariant = (variant: string) => {
	switch (variant) {
		case 'primary':
			return css`
				background-color: ${colors.blueDark};
				border: none;
				color: ${colors.white};

				&:hover {
					background-color: ${darken(0.05, colors.blueDark)};
				}
			`;
		case 'outlined':
			return css`
				background-color: ${colors.white};
				border: ${px2rem(1)} solid ${colors.linkColorDark};
				color: ${colors.linkColorDark};

				&:hover {
					background-color: ${darken(0.05, colors.blueLightest)};
				}

				&:disabled {
					&:hover {
						background-color: ${colors.white};
					}
				}
			`;
		case 'white':
			return css`
				background-color: ${colors.white};
				border: none;
				color: ${colors.linkColorDark};

				&:hover {
					background-color: ${darken(0.05, colors.blueLightest)};
				}
			`;
		case 'transparent':
			return css`
				background-color: transparent;
				border: ${px2rem(1)} solid ${colors.blueDark};
				color: ${colors.blueDark};

				&:hover {
					background-color: ${darken(0.05, colors.blueLightest)};
				}
			`;
		case 'link':
			return css`
				justify-content: unset;
				background-color: transparent;
				border: ${px2rem(1)} solid transparent;
				color: ${colors.blueDark};

				padding: 0;
				border-radius: 0;
				height: 100%;

				&:hover {
					text-decoration: underline;
				}

				&:focus {
					border: ${px2rem(1)} solid ${colors.blueDark};
					box-shadow: none;
				}
			`;
		default:
			return css`
				background-color: ${colors.blueLightest};
				border: none;
				color: ${colors.linkColorDark};

				&:hover {
					background-color: ${darken(0.05, colors.blueLightest)};
				}
			`;
	}
};

export const StandardButton = styled.button<StylePropsInterface>`
	display: inline-flex;
	justify-content: ${(props) => (props.$hasIcon ? 'space-between' : 'center')};
	align-items: center;
	outline: none;
	border: none;
	border-radius: ${px2rem(50)};
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.medium};
	font-size: ${px2rem(16)};
	transition: all 0.2s;
	text-decoration: none;
	cursor: pointer;

	${({ $size }) => getSize($size || 'medium')};
	${({ $variant }) => getVariant($variant || 'primary')};

	${({ $displayWidth }) => $displayWidth === 'fixed' && 'width: 18.75rem;'};
	${({ $displayWidth }) => $displayWidth === 'full' && 'width: 100%;'};
`;

export const UnstyledButton = styled.button`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	border: 0;
	background-color: transparent;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	text-align: inherit;
	padding: 0;
	outline: none;

	&:hover {
		color: ${colors.blue};
	}
`;

export const ButtonIcon = styled(Icon)`
	height: ${px2rem(14)};
	width: ${px2rem(14)};
	margin-left: ${px2rem(18)};
`;
