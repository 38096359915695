import { css } from 'styled-components';

interface Animations {
	fadeIn: any;
	fadeOut: any;
	moveInRight: any;
	moveOutRight: any;
	moveInLeft: any;
	moveOutLeft: any;
}

export const animations: Animations = {
	fadeIn: css`
		@keyframes fadeIn {
			0% {
				display: none;
				opacity: 0;
			}
			1% {
				display: block;
			}
			100% {
				display: block;
				opacity: 1;
			}
		}
	`,
	fadeOut: css`
		@keyframes fadeOut {
			0% {
				display: block;
				opacity: 1;
			}
			99% {
				display: block;
			}
			100% {
				display: none;
				opacity: 0;
			}
		}
	`,
	moveInRight: css`
		@keyframes moveInRight {
			0% {
				display: none;
				transform: translate3d(100%, 0, 0);
			}
			1% {
				display: block;
			}
			100% {
				display: block;
				transform: translate3d(0, 0, 0);
			}
		}
	`,
	moveOutRight: css`
		@keyframes moveOutRight {
			0% {
				display: block;
				transform: translate3d(0, 0, 0);
			}
			99% {
				display: block;
			}
			100% {
				display: none;
				transform: translate3d(100%, 0, 0);
			}
		}
	`,
	moveInLeft: css`
		@keyframes moveInLeft {
			0% {
				display: none;
				opacity: 0;
				transform: translate3d(-30%, 0, 0);
			}
			1% {
				display: block;
			}
			100% {
				display: block;
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
		}
	`,
	moveOutLeft: css`
		@keyframes moveOutLeft {
			0% {
				display: block;
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
			99% {
				display: block;
			}
			100% {
				display: none;
				opacity: 0;
				transform: translate3d(-30%, 0, 0);
			}
		}
	`,
};
