/**
 * MobileMenuLanguage
 */

import React from 'react';
import { LinkType } from 'types/global';
import { FlagIcon, Link, MobileLanguageLi } from './MobileMenu.styles';

interface MobileMenuLanguageProps {
	/** Languages link */
	languages: LinkType;
}

/** A nav item (li) used by NavMobile */
const MobileMenuLanguage: React.FC<MobileMenuLanguageProps> = ({
	languages,
}) => {
	return (
		<MobileLanguageLi selected={languages.selected}>
			<Link
				lang={languages.link.includes('en') ? 'en' : 'sv'}
				href={languages.link}
			>
				<span>{languages.text}</span>
				<FlagIcon icon="flagEn" aria-hidden={true} />
			</Link>
		</MobileLanguageLi>
	);
};

export default MobileMenuLanguage;
