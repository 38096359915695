/**
 * Carousel
 */

import React, { useState, ReactElement, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useMediaQuery } from 'react-responsive';
import Icon from 'components/Icon';
import { breakpointsNumber } from 'theme/media-queries';
import { ScreenReaderText } from 'components/Text';

import {
	OuterWrapper,
	Container,
	Slide,
	CarouselWrapper,
	ButtonsContainer,
	StyledButton,
	PaginationsList,
	PaginationItem,
	Pagination,
} from './Carousel.styles';

interface Props {
	/** Screenreader text (hidden) */
	screenReaderText?: string;

	/** Display pagination */
	displayPagination?: boolean;

	/** Hide pagination in desktop */
	hidePaginationDesktop?: boolean;

	/** Display buttons */
	displayButtons?: boolean;

	/** Direction of the carousel */
	direction?: 'horizontal' | 'vertical';

	/** Number to group children by per slide */
	groupBy?: number;

	/** Slides to display */
	children?: any;
}

const splitIntoChunks = (array: ReactElement[], chunkSize: number) => {
	let chunks = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		chunks.push(array.slice(i, i + chunkSize));
	}
	return chunks;
};

/** Carousel wrapper component */
const Carousel: React.FC<Props> = ({
	screenReaderText = 'Gå till objekt',
	direction = 'horizontal',
	groupBy: groupByProp = 1,
	displayButtons,
	displayPagination,
	hidePaginationDesktop,
	children,
}) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [groupBy, setGroupBy] = useState(groupByProp);
	const [slideWidth, setSlideWidth] = useState(100 / groupBy);
	const [chunks, setChunks] = useState(splitIntoChunks(children, groupBy));
	const handlers = useSwipeable({
		onSwipedLeft: () => {
			nextSlide();
		},
		onSwipedRight: () => {
			prevSlide();
		},
		preventDefaultTouchmoveEvent: true,
	});
	const isXsmall = useMediaQuery({
		maxWidth: breakpointsNumber.small - 1,
	});

	useEffect(() => {
		if (isXsmall) {
			setGroupBy(2);
		} else {
			setGroupBy(groupByProp);
		}
	}, [groupByProp, isXsmall]);

	useEffect(() => {
		setActiveIndex(0);
		setSlideWidth(100 / groupBy);
		setChunks(splitIntoChunks(children, groupBy));
	}, [groupBy, children]);

	const nextSlide = () => {
		if (activeIndex !== chunks.length - 1 && activeIndex % 1 === 0) {
			const itemsInNextChunk = chunks[activeIndex + 1].length;
			const increaseIndex = itemsInNextChunk / groupBy;
			setActiveIndex((index) => index + increaseIndex);
		}
	};
	const prevSlide = () => {
		if (activeIndex !== 0) {
			setActiveIndex((index) => (index < 1 ? 0 : index - 1));
		}
	};

	return (
		<OuterWrapper>
			<Container {...handlers}>
				<CarouselWrapper index={isXsmall ? activeIndex * 0.9 : activeIndex}>
					{chunks.map((group: ReactElement[], chunkIndex: number) =>
						group.map((child: ReactElement, i: number) => {
							let startNr =
								i !== 0 || chunkIndex === 0 ? chunkIndex : `${chunkIndex}0`;
							const position = `${startNr}${slideWidth * i}`;

							return (
								<Slide
									key={i}
									index={children.indexOf(child)}
									slideWidth={slideWidth}
									position={position}
								>
									{child}
								</Slide>
							);
						})
					)}
				</CarouselWrapper>
			</Container>

			{displayButtons && (
				<ButtonsContainer>
					<StyledButton
						aria-label="Välj nästa i listan"
						icon="none"
						onClick={nextSlide}
						disabled={
							activeIndex === chunks.length - 1 || activeIndex % 1 !== 0
						}
					>
						<Icon icon="arrowRight" size={0} aria-hidden="true" />
					</StyledButton>
					<StyledButton
						aria-label="Välj föregående i listan"
						icon="none"
						direction="left"
						onClick={prevSlide}
						disabled={activeIndex === 0}
					>
						<Icon
							icon="arrowRight"
							direction="left"
							size={0}
							aria-hidden="true"
						/>
					</StyledButton>
				</ButtonsContainer>
			)}

			{displayPagination && (
				<PaginationsList hidePaginationDesktop={hidePaginationDesktop}>
					{chunks.map((group: ReactElement[], chunkIndex: number) => (
						<PaginationItem>
							<Pagination
								key={chunkIndex}
								isActive={activeIndex === chunkIndex}
								onClick={() => setActiveIndex(chunkIndex)}
							>
								<ScreenReaderText>{`${screenReaderText} ${chunkIndex}`}</ScreenReaderText>
							</Pagination>
						</PaginationItem>
					))}
				</PaginationsList>
			)}
		</OuterWrapper>
	);
};

export default Carousel;
