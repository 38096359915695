/**
 * ErrorPage
 */

import React from 'react';
import { Div } from './ErrorPage.styles';
import Text from 'components/Text';
import Button from 'components/Button';
import Space from 'components/Space';

interface Props {
	/** Error message/object */
	error?: string;
}

/** Error page for errors caused in general app rendering. */
const ErrorPage: React.FC<Props> = ({ error }) => {
	return (
		<Div>
			<Text as="h1">Oops!</Text>
			<Text as="p">Något gick fel när vi försökte behandla din begäran.</Text>

			<Space top={1}>
				<Button variant="secondary" type="link" href="/">
					Till startsidan
				</Button>
			</Space>
		</Div>
	);
};

export default ErrorPage;
