/**
 * LatestNewsItemBlock
 */

import React from 'react';
import { Cell } from 'components/Grid';
import { Card } from 'components/Cards';
import { LatestNewsPageCardModel } from 'types/cards';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';

type Props = {
	/** Background color */
	backgroundColor?: 'lightBlue' | 'blue' | 'white';

	/** Card index */
	index: number;

	/** Card model */
	cardModel: LatestNewsPageCardModel;

	hideImages?: boolean;
};

/** LatestNewsItemBlock */
const LatestNewsItemBlock: React.FC<Props> = ({
	backgroundColor,
	index,
	cardModel,
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	return (
		<Cell span={isPhone ? 12 : 4}>
			<Card
				backgroundColor={backgroundColor}
				cardModel={cardModel}
				index={index}
				usePreambleFallback={false}
			/>
		</Cell>
	);
};

export default LatestNewsItemBlock;
