import { colors } from 'theme';
import { FilterOption, Facet } from 'types/listings';

export const getActiveFilters = (filters: any) => {
	const initialValues: any = {};
	let activeSort = [];

	if (filters.sorting) {
		activeSort = filters.sorting.filter((item: FilterOption) => item.active);
	}

	if (activeSort.length > 0) {
		initialValues.sorting = activeSort[0].value;
	}

	filters.facets.forEach((facet: Facet) => {
		const activeFilters = facet.options.filter(
			(item: FilterOption) => item.active
		);

		if (activeFilters.length > 1) {
			const newFilters = activeFilters.map((item: FilterOption) => item.value);
			initialValues[facet.name] = newFilters;
		} else if (activeFilters.length > 0) {
			initialValues[facet.name] = [activeFilters[0].value];
		}
	});

	return initialValues;
};

export const getFiltersToShow = (values: any, facets: Facet[]) => {
	const filters: any[] = [];
	Object.keys(values).forEach((facetKey: string) => {
		const facet = facets.filter((facet: Facet) => facet.name === facetKey)[0];

		if (facet) {
			facet.options.forEach((item: FilterOption) => {
				if (
					values[facetKey] === item.value ||
					(Array.isArray(values[facetKey]) &&
						values[facetKey].includes(item.value))
				) {
					filters.push({ ...item, facet: facetKey });
				}
			});
		}
	});

	return filters;
};

export const getTextWithComma = (text: string[], allText?: string[]) => {
	let newText = text.join(', ');

	if (allText) {
		if (allText.length > text.length) {
			newText = `${newText}...`;
		}
	}

	return newText;
};

export const getChartColor = (color?: string | string[]) => {
	const getChartColorInternal = (color?: string) => {
		switch (color) {
			case 'blue':
				return colors.chartColors.blue;
			case 'blueDark':
				return colors.chartColors.blueDark;
			case 'blueDarker':
				return colors.chartColors.blueDarker;
			case 'blueLight':
				return colors.chartColors.blueLight;
			case 'blueLightest':
				return colors.chartColors.blueLightest;
			case 'yellow':
				return colors.chartColors.yellow;
			case 'yellowDark':
				return colors.chartColors.yellowDark;
			case 'orange':
				return colors.chartColors.orange;
			case 'purple':
				return colors.chartColors.purple;
			case 'green':
				return colors.chartColors.green;
			default:
				return colors.blueDarker;
		}
	};

	if (Array.isArray(color)) {
		return color.map((c) => getChartColorInternal(c));
	}
	return getChartColorInternal(color);
};

/**
 * Converts a date object into a string in the format YYYY-MM-DD
 * @param {Date} fullDate - A date object
 * @returns {String} - The formatted date string (YYYY-MM-DD)
 */
export const getDate = (fullDate: Date) => {
	const year = fullDate.getFullYear();
	let date = fullDate.getDate();
	let month = fullDate.getMonth() + 1;

	let dateString = date < 10 ? `0${date}` : date;
	let monthString = month < 10 ? `0${month}` : month;

	return `${year}-${monthString}-${dateString}`;
};
