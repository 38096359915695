/**
 * LinkTreeLi
 */

import React from 'react';
import { LinkType } from 'types/global';

import {
	LinkContainer,
	StyledLink,
	WiwLevel1,
	LiLevel1,
	LiLevel2,
	LiLevel3,
} from './LinkTreeBlock.styles';
import LinkTreeUl from './LinkTreeUl';

interface Props {
	/** A menu link */
	link: LinkType;

	/** Determine if childelement or not for color change */
	isChildLink?: boolean;

	/** The link level (1, 2) */
	level: number;
}

/** Returns the correct styled li for each level */
const getLiLevel = (level: number, isCampaignPage: boolean) => {
	switch (level) {
		case 1:
			return isCampaignPage ? WiwLevel1 : LiLevel1;
		case 2:
			return LiLevel2;
		case 3:
		default:
			return LiLevel3;
	}
};

/** LinkTree ListItem. */
const LinkTreeLi: React.FC<Props> = ({ link, isChildLink = false, level }) => {
	const LiItem = getLiLevel(level, link.isCampaignPage);
	return (
		<LiItem>
			<LinkContainer isChildLink={isChildLink}>
				<StyledLink href={link.link}>{link.text}</StyledLink>
			</LinkContainer>

			{link.children && (
				<LinkTreeUl linkList={link.children} level={level + 1} isChildLink />
			)}
		</LiItem>
	);
};

export default LinkTreeLi;
