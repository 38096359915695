/**
 * React
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelReact, RenderingStates } from 'types/epi';
import { RootState } from 'store';

export const initialState: ModelReact = {
	containerId: 'root',
	clientOnly: true,
	renderingState: RenderingStates.ClientSide,
	siteBaseUrl: process?.env?.PUBLIC_URL,
	apiUrl: `${process?.env?.PUBLIC_URL}/api/`,
	apiEditUrl: '/api/content',
};

const slice = createSlice({
	name: 'react',
	initialState,
	reducers: {
		updateRenderingState: (state, action: PayloadAction<RenderingStates>) => {
			state.renderingState = action.payload;
		},
	},
});

export const selectReact = (state: RootState) => state.react;
export const { updateRenderingState } = slice.actions;

export default slice.reducer;
