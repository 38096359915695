/**
 * AccordionHtmlFragment - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';

export const ListContainer = styled.ul`
	list-style: none;
	padding: 0;
`;

export const ListItem = styled.li`
	margin-bottom: ${px2rem(10)};
`;
