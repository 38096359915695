/**
 * Carousel - styles
 */

import styled, { css } from 'styled-components';
import { colors } from 'theme/colors';
import { px2rem } from 'theme/utils';
import { mediaQueries } from 'theme/media-queries';
import Button from 'components/Button';

interface CarouselProps {
	index: number;
}
interface SlideProps extends CarouselProps {
	slideWidth: number;
	position: string;
}
interface ButtonProps {
	direction: 'left' | 'right';
}
interface PaginationProps {
	isActive?: boolean;
	hidePaginationDesktop?: boolean;
}

export const OuterWrapper = styled.div`
	position: relative;
`;

export const Container = styled.div`
	overflow: hidden;
	position: relative;
`;

export const CarouselWrapper = styled.ul<CarouselProps>`
	padding: 0;
	margin: 0;
	display: flex;

	transition: transform 0.4s ease-in-out;
	transform: translate3d(
		${({ index }) => (index !== 0 ? `-${index * 100}` : index)}%,
		0px,
		0px
	);
`;

export const Slide = styled.li<SlideProps>`
	display: inline-block;
	flex-shrink: 0;
	width: ${({ slideWidth }) => `${slideWidth}%`};
	list-style: none;
`;

export const ButtonsContainer = styled.div`
	${mediaQueries.phone} {
		display: none;
	}

	> button:first-child {
		margin-bottom: 1rem;
	}

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	margin-right: -${px2rem(35)};
`;

export const PaginationsList = styled.ul<PaginationProps>`
	margin-top: ${px2rem(37)};
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	padding: 0;

	${mediaQueries.tabletOrLarger} {
		${({ hidePaginationDesktop }) => {
			if (hidePaginationDesktop) {
				return css`
					display: none;
				`;
			}
		}}
	}
`;

export const PaginationItem = styled.li`
	&:not(:last-child) {
		margin-right: ${px2rem(18)};
	}
`;

export const Pagination = styled.button<PaginationProps>`
	border: none;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	padding: 0;
	background-color: ${(props) =>
		props.isActive ? colors.blueDark : colors.offBlueLight};
`;

export const StyledButton = styled(Button)<ButtonProps>`
	padding: 0;
	width: ${px2rem(70)};
	height: ${px2rem(70)};
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 100%;
	border: none;
	cursor: pointer;
	pointer-events: all;

	svg {
		color: ${colors.white};
	}

	&:disabled {
		background-color: ${colors.greyLighter};
		svg {
			color: ${colors.greyLight};
		}

		&:hover {
			cursor: default;
		}
	}
`;
