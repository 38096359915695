/**
 * AccordionItem
 */

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTransitionToggle, useUuid } from 'hooks';
import {
	AccordionItemWrapper,
	Header,
	HeaderIcon,
	HeaderNoIcon,
	HeaderButton,
	ContentArea,
	InnerContent,
	CountIcon,
	Counter,
	TitleArea,
	Title,
	CheckIcon,
	HeaderIconArea,
	ReadMoreLabel,
	RightArea,
	SubHeading,
	TitleLink,
} from './Accordion.styles';
import { colors } from 'theme';
import Icon from 'components/Icon';

export interface AccordionItemProps {
	/** The title/heading text for the accordion item. */
	title: string;
	titleLink?: string;
	titleTight?: boolean; // If big or small margin

	/** The variant of the accordion item */
	variant?:
		| 'white'
		| 'lightBlue'
		| 'blueLightest'
		| 'greyLightest'
		| 'html'
		| 'list'
		| 'transparent';

	/** Should the item be opened by default? */
	open?: boolean;

	/** Disables the background-color hover */
	noHover?: boolean;

	/** Disables the border of the accordion */
	linkStyled?: boolean;

	/** Tag type to set the header to */
	as?: any;

	/** Callback when the item is toggled */
	onToggle?: any;

	/** Accordion id (if not set, a unique id is generated) */
	id?: string;

	/** Accordion index count */
	count?: number;

	/** Toggle translations */
	translations?: {
		readMoreLabel: string;
		readLessLabel: string;
	};

	/** Display check icon */
	displayCheckIcon?: boolean;

	/** KickerHeading (above heading) */
	kickerHeading?: string;
}

/** A single item inside an accordion. */
const AccordionItem: React.FC<AccordionItemProps> = ({
	title,
	titleLink,
	titleTight = false,
	variant,
	children,
	onToggle,
	open = false,
	noHover = false,
	linkStyled = false,
	as = 'h2',
	id,
	count,
	translations,
	kickerHeading,
	displayCheckIcon,
}) => {
	const { hash } = useLocation();
	const [expanded, toggle, ref, Content] = useTransitionToggle(open);
	const [getUuid] = useUuid();
	const uniqueId = id || getUuid();
	const anchorId = id || `accordion-item-anchor-${uniqueId}`;
	const contentId = `accordion-item-content-${uniqueId}`;
	const buttonId = `accordion-item-button-${uniqueId}`;
	const timeout = variant === 'html' ? 0 : 600;
	const timeoutSec = variant === 'html' ? 0 : '.6s';
	const [bgColor, setBgColor] = useState('#ffffff');

	useEffect(() => {
		if (onToggle) {
			setTimeout(() => {
				onToggle(expanded);
			}, 600);
		}
		// eslint-disable-next-line
	}, [expanded]);

	useEffect(() => {
		if (open && !expanded) {
			toggle();
		}
		// eslint-disable-next-line
	}, [open]);

	useEffect(() => {
		if (hash === `#${anchorId}` && !expanded) {
			toggle();
		}
	}, [hash]);

	return (
		<AccordionItemWrapper>
			<Header as={as} linkStyled={linkStyled}>
				<HeaderButton
					aria-controls={contentId}
					id={buttonId}
					noHover={noHover}
					linkStyled={linkStyled}
					$variant={variant}
					expanded={expanded}
					onClick={toggle}
					onMouseEnter={() => setBgColor(colors.greyLightest)}
					onMouseLeave={() => setBgColor(colors.white)}
				>
					<TitleArea>
						{count && (
							<CountIcon $variant={variant}>
								<Icon icon={'diamond'} size={0} aria-hidden="true" />
								<Counter $variant={variant}>{count}</Counter>
							</CountIcon>
						)}
						<Title titleTight={titleTight}>
							{kickerHeading && <SubHeading as="p">{kickerHeading}</SubHeading>}

							{!titleLink && title && (
								<span dangerouslySetInnerHTML={{ __html: title }} />
							)}
							{titleLink && title && (
								<TitleLink
									href={titleLink}
									dangerouslySetInnerHTML={{ __html: title }}
								></TitleLink>
							)}
							{displayCheckIcon && (
								<CheckIcon icon={'circlecheck'} size={0} aria-hidden="true" />
							)}
						</Title>
					</TitleArea>

					<RightArea>
						{translations && (
							<ReadMoreLabel>
								{expanded
									? translations.readLessLabel
									: translations.readMoreLabel}
							</ReadMoreLabel>
						)}
						{children && (
							<HeaderIconArea
								expanded={expanded}
								$variant={variant}
								aria-label="Öppna"
								aria-expanded={expanded}
							>
								<HeaderIcon
									icon={expanded ? 'minus' : 'plus'}
									color={colors.blueDark}
									$variant={variant}
									expanded={expanded}
								/>
							</HeaderIconArea>
						)}
						{!children && <HeaderNoIcon />}
					</RightArea>
				</HeaderButton>
			</Header>

			<ContentArea
				tabIndex={-1}
				id={anchorId}
				$variant={variant}
				expanded={expanded}
			>
				<Content
					id={contentId}
					role="region"
					aria-labelledby={buttonId}
					expanded={expanded}
					containerRef={ref}
					timeout={timeout}
					timeoutSec={timeoutSec}
				>
					<InnerContent>{children}</InnerContent>
				</Content>
			</ContentArea>
		</AccordionItemWrapper>
	);
};

export default AccordionItem;
