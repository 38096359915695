/**
 * Breadcrumb
 */

import BlockContainer from 'layouts/BlockLayout';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { LinkType } from 'types/global';
import {
	Container,
	BreadcrumbList,
	BreadcrumbListItem,
	BreadcrumbLink,
	BreadcrumbText,
	BreadcrumbButton,
	IconDots,
	ArrowRight,
} from './Breadcrumb.styles';

interface Props {
	/** A list of links */
	links?: LinkType[];

	/** Aria label text */
	label?: string;

	/** Whether or not to include a border bottom */
	withBorder?: boolean;
}

/** Breadcrumb */
const Breadcrumb: React.FC<Props> = ({
	links,
	label = 'Brödsmulor',
	withBorder,
}) => {
	const [expanded, setExpanded] = useState(false);
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	if (!links) {
		return null;
	}

	const renderBreadcrumbItem = (link: LinkType, index: number) => {
		let type = index < links.length - 1 ? 'link' : 'text';

		if (isPhone && !expanded && index >= 2 && index < links.length - 1) {
			if (index === links.length - 1) {
				type = 'text';
			} else if (index === 3) {
				type = 'button';
			} else {
				type = '';
			}
		}

		switch (type) {
			case 'link':
				return (
					<>
						<BreadcrumbLink href={link.link}>{link.text}</BreadcrumbLink>
						{index < links.length - 1 && <ArrowRight />}
					</>
				);
			case 'text':
				return (
					<>
						<BreadcrumbText>{link.text}</BreadcrumbText>

						{index < links.length - 1 && <ArrowRight />}
					</>
				);
			case 'button':
				return (
					<>
						<BreadcrumbButton onClick={() => setExpanded(true)}>
							<IconDots icon={'dots'} size={2} aria-hidden="true" />
						</BreadcrumbButton>

						{index < links.length - 1 && <ArrowRight />}
					</>
				);
			default:
				return null;
		}
	};

	return (
		<BlockContainer withBorder={withBorder} lessPadding>
			<Container aria-label={label}>
				<BreadcrumbList>
					{links.map((link: LinkType, index: number) => (
						<BreadcrumbListItem key={index}>
							{renderBreadcrumbItem(link, index)}
						</BreadcrumbListItem>
					))}
				</BreadcrumbList>
			</Container>
		</BlockContainer>
	);
};

export default Breadcrumb;
