export interface Typography {
	baseFontSize: number;
	fontFamily: FontFamily;
	fontWeight: FontWeight;
}

// Font families
const fontFamilyValues = {
	standard: '"Spectral", serif',
	alt: '"Euclid Circular B", sans-serif',
} as const;
export type FontFamily = typeof fontFamilyValues;
const fontFamily: FontFamily = fontFamilyValues;

// Font weights
const fontWeightValues = {
	thin: 100,
	extraLight: 200,
	light: 300,
	normal: 400,
	medium: 500,
	semiBold: 600,
	bold: 700,
	extraBold: 800,
	thick: 900,
} as const;
export type FontWeight = typeof fontWeightValues;
const fontWeight: FontWeight = fontWeightValues;

// Base font size
export const baseFontSize: number = 18;

export const typography: Typography = {
	baseFontSize,
	fontFamily,
	fontWeight,
};
