import { breakpoints } from 'theme/media-queries';

export interface Grid {
	breakpoints: Breakpoints;
	columns: Columns;
	margin: Breakpoints;
	gutter: Breakpoints;
	defaultColumnSpan: number;
	maxWidth: string;
}

interface Breakpoints {
	[device: string]: string;
}

export interface Columns {
	[device: string]: number;
}

const columns: Columns = {
	desktop: 12,
	tablet: 8,
	phone: 4,
};

const margin: Breakpoints = {
	desktop: '24px',
	tablet: '16px',
	phone: '16px',
};

const gutter: Breakpoints = {
	desktop: '24px',
	tablet: '16px',
	phone: '16px',
};

const defaultColumnSpan = 4;

const maxWidth = '1420px';

export const grid: Grid = {
	breakpoints,
	columns,
	margin,
	gutter,
	defaultColumnSpan,
	maxWidth,
};
