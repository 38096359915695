/**
 * RichText
 */

import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectEpi } from 'store/modules/epi';
import { RichText as RichTextStyle } from 'components/Text/Text.styles';

interface Props {
	textSize?: 'small' | 'medium';
	backgroundColor?: 'blue' | 'white';
}

/** Richtext components which handles links. */
const RichText: React.FC<Props> = ({
	children,
	backgroundColor,
	textSize = 'medium',
}) => {
	const history = useHistory();
	const epi = useSelector(selectEpi);
	const nodeRef = useRef<HTMLDivElement>(null);

	function handleLinkClick(this: HTMLAnchorElement, event: MouseEvent): void {
		const href = this.getAttribute('href');
		const target = this.getAttribute('target');
		const isFile = (href?.indexOf('globalassets') ?? -1) !== -1;
		if (href && href.charAt(0) === '/' && target !== '_blank' && !isFile) {
			event.preventDefault();
			history.push(href);
		}
	}

	useEffect(() => {
		const nodeRefRef = nodeRef;
		if (nodeRef.current && !epi?.beta?.inEditMode) {
			nodeRef.current.querySelectorAll('a').forEach((a) => {
				a.addEventListener('click', handleLinkClick);

				if (a.classList.contains('pdf')) {
					a.setAttribute('aria-label', 'PDF-fil, ' + a.innerText);
				} else if (a.classList.contains('word')) {
					a.setAttribute('aria-label', 'Word-fil, ' + a.innerText);
				} else if (a.classList.contains('excel')) {
					a.setAttribute('aria-label', 'Excel-fil, ' + a.innerText);
				}
			});
		}

		return () => {
			if (nodeRefRef.current) {
				nodeRefRef.current.querySelectorAll('a').forEach((a) => {
					a.removeEventListener('click', handleLinkClick);
				});
			}
		};
		// eslint-disable-next-line
	}, []);

	return (
		<RichTextStyle
			ref={nodeRef}
			textSize={textSize}
			backgroundColor={backgroundColor}
		>
			{children}
		</RichTextStyle>
	);
};

export default RichText;
