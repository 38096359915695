/**
 * FaqBlock - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { H2, P } from 'components/Text/Text.styles';
import Link from 'components/Link';

export interface HeadingProps {
	headingAlignment: 'left' | 'center';
}

export interface TextProps {
	isAnswer?: boolean;
}

export const FaqContainer = styled.div`
	padding: 5.5rem 0;

	${mediaQueries.phone} {
		padding: 3rem 0;
	}
`;

export const FaqItemContainer = styled.div``;

export const Heading = styled(H2)<HeadingProps>`
	color: ${colors.greyDark};
	text-align: ${(props) => props.headingAlignment};
	margin: 0;
	padding-bottom: ${px2rem(50)};
	font-size: ${px2rem(28)};

	${mediaQueries.phone} {
		font-size: ${px2rem(24)};
	}
`;

export const Text = styled(P)<TextProps>`
	font-size: ${px2rem(16)};
	letter-spacing: 0;
	line-height: ${px2rem(26)};
	font-weight: ${(props) =>
		!props.isAnswer
			? typography.fontWeight.medium
			: typography.fontWeight.normal};
	margin-bottom: ${px2rem(40)};
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	display: inline-flex;
	align-items: center;

	svg {
		margin-left: ${px2rem(15)};
		color: ${colors.blueDark};
		color: ${colors.blueDark};
	}
`;

export const LinkText = styled(P)`
	margin-bottom: 0;
	color: ${colors.blueDark};
	text-decoration: underline;
	font-size: ${px2rem(18)};
	letter-spacing: 0;
	line-height: ${px2rem(23)};
`;
