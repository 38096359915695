/**
 * Video - styles
 */

import styled from 'styled-components';
import { colors } from 'theme';
import Button from 'components/Button';

export const Wrapper = styled.div`
	margin: 2rem 0 3rem !important;
`;

export const TopContent = styled.div`
	padding: 2rem;
	background-color: ${colors.blueLightest};

	h2 {
		margin-top: 0.5rem;
	}
`;

export const ButtonContainer = styled.ul`
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 1rem 0 0;

	button {
		margin-top: 0.5rem;
	}
`;

export const TabButton = styled(Button)`
	margin-right: 0.5rem;

	svg {
		height: 1.5rem;
		width: 1.5rem;
		margin-right: 0.5rem;
		margin-left: 0;
	}
`;
