/**
 * ActiveFilters
 */

import React from 'react';
import { ActiveFiltersList } from './ActiveFilters.styles';
import { ScreenReaderText } from 'components/Text';
import FilterChip from 'components/FilterChip';

interface Props {
	/** Description of this property */
	filters: any[];

	/** Screenreader text for active filter chips */
	clearText: string;

	/** Text for form reset button */
	clearAllText: string;

	/** Function to call when clearing form */
	onClear?: any;
}

/** Component for active filters in listings */
const ActiveFilters: React.FC<Props> = ({
	filters,
	clearText,
	clearAllText,
	onClear,
	...props
}) => (
	<ActiveFiltersList>
		{filters.map((filter: any, i: number) => (
			<li key={i}>
				<FilterChip
					color="blue"
					type="button"
					onClick={() => {
						const checkbox = document.getElementById(
							`${filter.facet}-${filter.value}`
						);
						checkbox?.click();
						checkbox?.classList.remove('usability--tab-focus');
					}}
				>
					<ScreenReaderText>{clearText}</ScreenReaderText>
					{filter.text}
				</FilterChip>
			</li>
		))}

		<li>
			<FilterChip
				color="grey"
				type="reset"
				onClick={() => {
					if (onClear) {
						onClear();
					}
				}}
				{...props}
			>
				{clearAllText}
			</FilterChip>
		</li>
	</ActiveFiltersList>
);

export default ActiveFilters;
