/**
 * UnsubscribePage
 */

import React from 'react';
import { UnsubscribePageModel } from 'types/pages';
import Text from 'components/Text';
import EpiFragments from 'components/EpiFragments';
import Breadcrumb from 'components/Breadcrumb';
import { FragmentsContainer, StyledPreamble } from './UnsubscribePage.styles';
import PageLayout from 'layouts/PageLayout';

/** UnsubscribePage. */
const UnsubscribePage: React.FC<UnsubscribePageModel> = ({
	heading,
	preamble,
	text,
	breadcrumbs,
	translations,
	_properties = {},
}) => (
	<main id="main-content">
		{breadcrumbs && (
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>
		)}

		<PageLayout labelledBy="article-heading">
			<Text as="h1" {..._properties?.heading} id="article-heading">
				{heading}
			</Text>

			{(_properties?.preamble || preamble) && (
				<StyledPreamble {..._properties?.preamble}>{preamble}</StyledPreamble>
			)}

			<FragmentsContainer>
				<EpiFragments {..._properties?.text} fragments={text?.fragments} />
			</FragmentsContainer>
		</PageLayout>
	</main>
);

export default UnsubscribePage;
