/**
 * FaqItemBlock
 */

import React from 'react';
import {
	FaqItemContainer,
	Text,
	StyledLink,
	LinkText,
} from './FaqBlock.styles';
import Icon from 'components/Icon';

type Props = {
	/** Item heading */
	heading: string;

	/** Item text */
	text: string;

	/** Item answer */
	answer: string;

	/** Item linktext */
	linkText: string;

	/** Item link */
	url: string;
};

/** FaqItemBlock */
const FaqItemBlock: React.FC<Props> = ({ text, answer, linkText, url }) => {
	return (
		<FaqItemContainer>
			<Text
				dangerouslySetInnerHTML={{
					__html: text,
				}}
			></Text>
			<Text
				isAnswer
				dangerouslySetInnerHTML={{
					__html: answer,
				}}
			/>

			<StyledLink href={url} target="_blank">
				<LinkText>{linkText}</LinkText>

				<Icon icon="external" size={0} aria-hidden="true" />
			</StyledLink>
		</FaqItemContainer>
	);
};

export default FaqItemBlock;
