/**
 * StandardPage
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { StandardPageModel } from 'types/pages';
import { LinkType } from 'types/global';
import Text from 'components/Text';
import { Grid } from 'components/Grid';
import EpiFragments from 'components/EpiFragments';
import EpiContentArea from 'components/EpiContentArea';
import Breadcrumb from 'components/Breadcrumb';
import TableOfContents from 'components/TableOfContents';
import { Accordion, AccordionItem } from 'components/Accordion';
import {
	FragmentsContainer,
	LeftCell,
	RightCell,
	AccordionList,
	AccordionListItem,
	AccordionCell,
	StyledPreamble,
} from './StandardPage.styles';
import PageLayout from 'layouts/PageLayout';
import LinkListColumnBlock from 'components/LinkListColumnBlock';
import { LinkItemBlockModel } from 'types/blocks';
import LinkItemBlock from 'components/LinkItemBlock';

/** StandardPage. */
const StandardPage: React.FC<StandardPageModel> = ({
	heading,
	preamble,
	breadcrumbs,
	text,
	toc,
	siblings,
	translations,
	hideToC,
	contentArea,
	_properties = {},
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	return (
		<main id="main-content">
			{breadcrumbs && (
				<Breadcrumb
					links={breadcrumbs}
					label={translations.breadcrumbsLabel}
					withBorder
				/>
			)}

			<PageLayout labelledBy="article-heading">
				<Grid inner={true}>
					<LeftCell span={7} phone={12}>
						<Text as="h1" {..._properties?.heading} id="article-heading">
							{heading}
						</Text>

						{(_properties?.preamble || preamble) && (
							<StyledPreamble {..._properties?.preamble}>
								{preamble}
							</StyledPreamble>
						)}

						<FragmentsContainer>
							<EpiFragments
								{..._properties?.text}
								fragments={text?.fragments}
							/>
						</FragmentsContainer>
					</LeftCell>
					<RightCell span={4}>
						{toc &&
							!hideToC &&
							(isPhone ? (
								<Accordion>
									<AccordionItem
										title={translations.accordionHeading || ''}
										variant={'lightBlue'}
									>
										<AccordionList>
											{toc.map((link: LinkType, index: number) => (
												<AccordionListItem key={index}>
													<AccordionCell href={link.link}>
														{link.text}
													</AccordionCell>
												</AccordionListItem>
											))}
										</AccordionList>
									</AccordionItem>
								</Accordion>
							) : (
								<TableOfContents label={translations.tocHeading} items={toc} />
							))}
					</RightCell>
				</Grid>
			</PageLayout>
			<EpiContentArea {..._properties?.contentArea} content={contentArea} />
			{siblings && (
				<LinkListColumnBlock
					backgroundColor={'white'}
					headingAlignment={'left'}
				>
					{siblings.map((item: LinkItemBlockModel, i: number) => (
						<LinkItemBlock key={i} {...item} backgroundColor={'white'} />
					))}
				</LinkListColumnBlock>
			)}
		</main>
	);
};

export default StandardPage;
