/**
 * Link
 */

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StyledLink } from './Link.styles';
import Icon from 'components/Icon';
import { colors } from 'theme';

interface Props {
	/** Sets the size of the text */
	size?: 'large' | 'medium' | 'small';

	/** The url the link should navigate to */
	to?: string;

	/** The url the link should navigate to */
	href?: string;

	/** Pass optional classnames for the html */
	className?: string;

	scrollTop?: boolean;

	[htmlAttributes: string]: any;
}

const Link: React.FC<Props> = ({
	to,
	href,
	children,
	className = '',
	target,
	size,
	scrolltop = false,
	...htmlAttributes
}) => {
	const url = to || href || '#';
	const external =
		htmlAttributes !== undefined &&
		htmlAttributes.rel !== undefined &&
		(htmlAttributes.rel === 'external' || htmlAttributes.rel === 'noopener');
	const isFile = url.indexOf('globalassets') !== -1;

	if (
		(url && (url.charAt(0) !== '/' || isFile)) ||
		(target && target === '_blank')
	) {
		return (
			<>
				<StyledLink
					$size={size}
					href={url}
					onClick={() => {
						if (scrolltop) {
							window.scrollTo(0, 0);
						}
					}}
					className={className}
					target={target}
					{...htmlAttributes}
				>
					{children}
				</StyledLink>
				{external && (
					<Icon
						icon="external"
						color={colors.linkColorDark}
						size={0}
						aria-hidden="true"
					/>
				)}
			</>
		);
	} else {
		return (
			<>
				<RouterLink
					to={url}
					className={className}
					target={target}
					onClick={(e) => {
						e.currentTarget?.blur();
					}}
					{...htmlAttributes}
				>
					{children}
				</RouterLink>
				{external && (
					<Icon
						icon="external"
						color={colors.linkColorDark}
						size={0}
						aria-hidden="true"
					/>
				)}
			</>
		);
	}
};

export default Link;
