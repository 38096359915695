/**
 * LinkTreeUl
 */

import React from 'react';
import { LinkType } from 'types/global';

import { Ul } from './LinkTreeBlock.styles';
import LinkTreeLi from './LinkTreeLi';

interface Props {
	/** Link tree list */
	linkList: LinkType[];

	/** Determine if childelement or not for color change */
	isChildLink?: boolean;

	/** The link level (1, 2) */
	level: number;

	/** Is first list */
	isFirst?: boolean;
}

/** LinkTree List */
const LinkTreeUl: React.FC<Props> = ({
	linkList,
	level,
	isChildLink,
	isFirst,
}) => {
	return (
		<Ul level={level} isFirst={isFirst} isChildLink={isChildLink}>
			{linkList.map((link: LinkType, i: number) => (
				<LinkTreeLi
					key={i}
					link={link}
					level={level}
					isChildLink={isChildLink}
				/>
			))}
		</Ul>
	);
};

export default LinkTreeUl;
