/**
 * TableOfContents - styles
 */

import styled, { css } from 'styled-components';
import Scrollspy from 'react-scrollspy';
import { px2rem } from 'theme/utils';
import { colors, typography, mediaQueries } from 'theme';
import { H2 } from 'components/Text/Text.styles';

export interface ThemeProps {
	isActive?: boolean;
	isOpen?: boolean;
}

export const Container = styled.nav`
	${mediaQueries.tabletOrLarger} {
		position: sticky;
		top: ${px2rem(115)};
	}
`;

export const Heading = styled(H2)`
	margin-bottom: ${px2rem(24)};
`;

export const TocList = styled(Scrollspy)`
	margin: 0;
	list-style: none;
	padding: 0;

	li {
		display: flex;
		align-items: center;
		padding-left: ${px2rem(19)};
		padding-top: ${px2rem(16)};
		padding-bottom: ${px2rem(16)};
		border-left: ${px2rem(2)} solid ${colors.greyLighter};

		a {
			font-size: ${px2rem(15)};
			line-height: ${px2rem(25)};
			letter-spacing: ${px2rem(0.4)};
			color: ${colors.greyDark};
			text-decoration: none;
			font-family: ${typography.fontFamily.alt};

			&:hover {
				text-decoration: underline;
			}
		}

		&.is-current {
			border-left: ${px2rem(4)} solid ${colors.blueDark};
			font-weight: ${typography.fontWeight.bold};
		}
	}
`;
