/**
 * ActiveFilters - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

export const ActiveFiltersList = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: ${px2rem(970)};
	margin: 0.5rem auto 0;
	list-style: none;
	padding: 0;

	li {
		margin-right: 0.5rem;
		margin-top: 0.5rem;
	}

	${mediaQueries.phone} {
		margin-left: 1.5rem;
		margin-right: 1.5rem;
	}
`;
