/**
 * Line
 */

import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import { ChartDataSet } from 'types/blocks';
import { getChartColor } from 'common/helpers';
interface Props {
	/** Label unit */
	unit?: string;

	/** Chart color */
	gridColor?: string;

	/** Labels */
	labels: string[];

	/** Chart data */
	datasets: ChartDataSet[];
}

/** Line chart component. */
const Line: React.FC<Props> = ({ unit, labels, datasets, gridColor }) => {
	const chartRef = useRef<any>();
	useEffect(() => {
		let chartData = JSON.parse(
			JSON.stringify({
				labels: [...labels],
				datasets: [...datasets],
			})
		);

		chartData.datasets.map((data: ChartDataSet) => {
			data.pointRadius = 5;
			data.fill = false;

			if (data.backgroundColor) {
				data.backgroundColor = getChartColor(data.backgroundColor);
			}
			if (data.borderColor) {
				data.borderColor = getChartColor(data.borderColor) as string;
			}
		});

		new Chart(chartRef.current, {
			type: 'line',
			data: chartData,
			options: {
				responsive: true,
				legend: {
					position: 'bottom',
					align: 'start',
					labels: {
						fontSize: 20,
					},
				},
				tooltips: {
					displayColors: false,
					bodyFontSize: 20,
					callbacks: {
						title: () => '', // Remove title from tooltip (design)
					},
				},
				maintainAspectRatio: false,
				scales: {
					xAxes: [
						{
							gridLines: {
								color: getChartColor(gridColor),
								zeroLineWidth: 3,
							},
							ticks: {
								fontSize: 20,
							},
						},
					],
					yAxes: [
						{
							gridLines: {
								color: getChartColor(gridColor),
							},
							ticks: {
								beginAtZero: true,
								fontSize: 20,
								callback: (value) => `${value} ${unit ? unit : ''}`,
							},
						},
					],
				},
			},
		});
	}, [chartRef]);

	return <canvas ref={chartRef} />;
};

export default Line;
