/**
 * PageHeader
 */

import React, { useState, useEffect, useRef } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { LinkType } from 'types/global';
import { ModelTranslations, ModelApi } from 'types/epi';
import { colors } from 'theme';
import logoImg from './logo-kkv.svg';

import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { useOutsideClick, useFirstRender } from 'hooks';

import {
	Header,
	Logo,
	LogoLink,
	InnerWrapper,
	SuggestionButton,
	SuggestionIcon,
	LinkList,
	ListItem,
	LinkItem,
	LinkIcon,
	MenuIcon,
	IconWrapper,
	HiddenMobile,
} from './PageHeader.styles';

import Link from 'components/Link';
import SearchBox from 'components/SearchBox';
import { PageOverlay } from 'components/PageOverlay';
import MobileMenu from 'components/MobileMenu';
import DesktopMenu from 'components/DesktopMenu';

interface Props {
	/** Url to link logotype to */
	logoLink: LinkType;

	/** Button link with icon */
	buttonLink: LinkType;

	/** Language links */
	languages?: LinkType;

	/** Current Language links */
	currentLang?: 'sv' | 'en';

	/** Global site search settings */
	search: any;

	/** Main menu links */
	menu: any;

	/** Translations */
	translations: ModelTranslations;

	/** API urls */
	api: ModelApi;
}

/** The page header component. */
const PageHeader: React.FC<Props> = ({
	logoLink,
	currentLang,
	buttonLink,
	languages,
	translations,
	menu,
	search,
	api,
}) => {
	const headerRef = useRef<any>();
	const [paneMaxHeight, setPaneMaxHeight] = useState(0);
	const [searchOpen, setSearchOpen] = useState(false);
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const isFirstRender = useFirstRender();

	useEffect(() => {
		if (!menuIsOpen) {
			return clearAllBodyScrollLocks();
		}
	}, [menuIsOpen]);

	const handleFocusChange = () => {
		const focusedMenuButton = document.getElementById('inner-menu-button');

		if (menuIsOpen) {
			focusedMenuButton?.focus();
		} else {
			focusedMenuButton?.blur();
		}
	};

	const handleOpenMenu = (e: any) => {
		e.stopPropagation();
		setSearchOpen(false);
		setMenuIsOpen(true);
	};

	const handleCloseMenuSearch = (e: any) => {
		e.stopPropagation();
		setSearchOpen(false);
		setMenuIsOpen(false);
		setPaneMaxHeight(0);

		if (e.originalTarget) e.originalTarget.blur();
	};

	const handleOpenSearch = (e: any) => {
		e.stopPropagation();
		setSearchOpen(true);
		setMenuIsOpen(false);
		setTimeout(() => {
			document.getElementById('pageheader-searchbar')?.focus();
		}, 100);
	};

	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const isTablet = useMediaQuery({
		maxWidth: breakpointsNumber.desktop - 1,
	});

	useOutsideClick(headerRef, () => {
		setSearchOpen(false);
		setMenuIsOpen(false);
	});

	useEffect(() => {
		if (searchOpen && isPhone) {
			document.body.classList.add('search-overlay');
		}

		return () => {
			document.body.classList.remove('search-overlay');
		};
	}, [searchOpen, isPhone]);

	useEffect(() => {
		if (menuIsOpen && isPhone) {
			document.getElementsByTagName('html')[0].style.overflow = 'hidden';
		}

		return () => {
			document.getElementsByTagName('html')[0].style.overflow = '';
		};
	}, [menuIsOpen, isPhone]);

	useEffect(() => {
		// Not relevant on phone.
		// DOM too fast therefore timeout.
		if (!isPhone && !isFirstRender) {
			setTimeout(() => {
				handleFocusChange();
			}, 100);
		}
	}, [menuIsOpen]);

	return (
		<>
			<Header ref={headerRef} id="pageheader">
				<InnerWrapper id="pageheaderinnerwrapper">
					<LogoLink as={Link} href={logoLink?.link}>
						<Logo src={logoImg} alt={logoLink.text ? logoLink.text : ''} />
					</LogoLink>
					<LinkList>
						{!isPhone && !isTablet && buttonLink && buttonLink.link && (
							<li>
								<SuggestionButton as={Link} href={buttonLink.link}>
									{buttonLink.text}
									<IconWrapper>
										<SuggestionIcon
											icon="comment"
											color={colors.greyDark}
											aria-hidden={true}
										/>
									</IconWrapper>
								</SuggestionButton>
							</li>
						)}

						{search && (
							<ListItem>
								<LinkItem
									as="button"
									aria-expanded={searchOpen}
									aria-controls="header-search"
									onClick={
										searchOpen ? handleCloseMenuSearch : handleOpenSearch
									}
									active={searchOpen}
								>
									<HiddenMobile>
										{searchOpen
											? translations.searchCloseText
											: translations.searchOpenText}
									</HiddenMobile>
									<LinkIcon
										icon={searchOpen ? 'close' : 'search'}
										color={colors.greyDark}
										aria-hidden={true}
									/>
								</LinkItem>

								<div hidden={!search && !searchOpen}>
									<SearchBox
										action={search.searchLink}
										autocompleteSearchUrl={api.autocompleteSearchUrl}
										id="header-search"
										placeholder={search.searchPlaceholder}
										open={searchOpen}
										toggle={setSearchOpen}
										searchFieldText={translations.searchFieldText}
										closeSearchBoxText={translations.closeSearchBoxText}
										searchButtonText={translations.searchButtonText}
									/>
								</div>
							</ListItem>
						)}

						<li>
							<LinkItem
								active={menuIsOpen}
								onClick={menuIsOpen ? handleCloseMenuSearch : handleOpenMenu}
								tabIndex={menuIsOpen ? -1 : 0}
								id="outer-menu-button"
								as="button"
								aria-expanded={menuIsOpen}
							>
								<HiddenMobile>{translations.menuText}</HiddenMobile>
								<MenuIcon
									icon={menuIsOpen && isPhone ? 'close' : 'menu'}
									color={colors.greyDark}
									aria-hidden={true}
								/>
							</LinkItem>
						</li>
					</LinkList>
				</InnerWrapper>
				{isPhone ? (
					<MobileMenu
						items={menu}
						open={menuIsOpen}
						languages={languages}
						paneMaxHeight={paneMaxHeight}
						handleSetPaneMaxHeight={setPaneMaxHeight}
						buttonLink={buttonLink}
						translations={translations}
						currentLang={currentLang}
					/>
				) : (
					<DesktopMenu
						items={menu}
						languages={languages}
						open={menuIsOpen}
						onClose={handleCloseMenuSearch}
						translations={{
							menuCloseText: translations.menuCloseText,
							menuText: translations.menuText,
							menuLabel: translations.menuLabel,
							screenReaderText: translations.screenReaderShowAllText,
						}}
					/>
				)}
			</Header>
			<PageOverlay />
		</>
	);
};

export default PageHeader;
