/**
 * ThemeBannerBlock - styles
 */

import styled from 'styled-components';
import { H1, H6, P } from 'components/Text';
import { colors, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

interface StyledProps {
	$color: 'white' | 'greyDark';
}

export const Heading = styled(H1)<StyledProps>`
	color: ${({ $color }) => colors[$color]};
	margin-top: 0;

	${mediaQueries.phone} {
		font-size: ${px2rem(34)};
	}
`;

export const SubHeading = styled(H6)<StyledProps>`
	width: 100%;
	color: ${({ $color }) =>
		$color === 'white' ? colors.blueLighter : colors[$color]};
	text-transform: uppercase;
	margin-bottom: ${px2rem(38)};
	${mediaQueries.phone} {
		font-size: ${px2rem(12)};
	}
`;

export const Text = styled(P)<StyledProps>`
	color: ${({ $color }) => colors[$color]};
	line-height: ${px2rem(30)};

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(16)};
		line-height: ${px2rem(24)};
	}
`;

export const FlexWrapper = styled.div`
	display: flex;

	${mediaQueries.phone} {
		flex-wrap: wrap;
		text-align: center;
	}
`;

export const ContentWrapper = styled.div`
	padding: ${px2rem(114)} 0 ${px2rem(58)};

	${mediaQueries.phone} {
		padding: ${px2rem(76)} 0 ${px2rem(56)};
		text-align: center;
	}
`;

export const LeftSide = styled.div`
	width: 45%;

	${mediaQueries.phone} {
		width: 100%;
	}
`;

export const RightSide = styled.div`
	width: 55%;
	padding-left: 2rem;
	display: flex;
	margin-top: 1.5rem;

	${mediaQueries.tablet} {
		margin-top: 0.85rem;
	}

	${mediaQueries.phone} {
		width: 100%;
		padding-right: 2rem;
		margin-top: 0;
	}
`;

export const ImageContainer = styled.div`
	img {
		object-fit: cover;
	}
`;
