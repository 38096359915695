import React from 'react';
import { CampaignStartPageModel } from '../../types/pages';
import {
	InformationPageHeading,
	InformationPageSubheading,
} from '../InformationPage/InformationPage';
import styled from 'styled-components';
import CampaignStartPageIcon from '../../components/CampaignStartPageIcon/CampaignStartPageIcon';
import { mediaQueries, colors } from '../../theme';
import EpiFragments from '../../components/EpiFragments';
import Icon from '../../components/Icon';
import Breadcrumb from '../../components/Breadcrumb';
import Image from '../../components/Image';
import CampaignNavigation from '../../components/CampaignNavigation/CampaignNavigation';

const CampaignStartPageContent = styled.div<{
	$textAlign?: 'left' | 'right' | 'center';
	$paddingTop: number;
}>`
	text-align: ${(props) => props.$textAlign};
	max-width: 950px;
	margin-inline: auto;
	padding-top: ${(props) => props.$paddingTop + 'rem'};
	${mediaQueries.phone} {
		padding-inline: 1rem;
	}
`;

const CampaignStartPageFooter = styled.div`
	background: #f1f1f1;
	${mediaQueries.phone} {
		margin-top: 3.5rem;
	}
`;

const CampaignStartPageFooterContent = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2rem;
	position: relative;
	width: 1184px;
	min-height: 298px;
	max-width: 100%;
	margin-inline: auto;
	${mediaQueries.phone} {
		grid-template-columns: repeat(1, 1fr);
	}
`;

const ImageContainer = styled.div`
	${mediaQueries.phone} {
		display: none;
	}
	padding-bottom: 2rem;
`;

const ImageWrapper = styled.div`
	& img {
		position: absolute;
		bottom: 0;
		height: 400px;
		right: 0;
		width: auto;
		max-width: 450px;
		${mediaQueries.tablet} {
			max-width: 250px;
		}
	}
`;

const CampaignStartPageIconBlock = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 25px;
	row-gap: 40px;
	margin-top: 4rem;
	width: 1291px;
	margin-inline: auto;
	margin-bottom: calc(132px + 3rem);
	${mediaQueries.phone} {
		grid-template-columns: repeat(1, 1fr);
		padding: 1rem;
		width: 100%;
		margin-top: 40px;
		margin-bottom: 1rem;
	}
	${mediaQueries.tablet} {
		grid-template-columns: repeat(2, 1fr);
		padding: 2rem;
		width: 100%;
		margin-bottom: 5.5rem;
	}

	${mediaQueries.tabletLarger} {
		grid-template-columns: repeat(3, 1fr);
		padding: 2rem;
		width: 100%;
		margin-bottom: 5.5rem;
	}

	${mediaQueries.mediumDesktop} {
		grid-template-columns: repeat(3, 1fr);
		padding: 2rem;
		width: 100%;
		margin-bottom: 5.5rem;
	}
`;

const CampaignStartPageText = styled.div`
	font-family: 'Euclid Circular B', sans-serif;
	padding-inline: 1rem;
	padding-block: 2rem;
	& p {
		margin: 0;
	}
`;

const CampaignStartPageLink = styled.a`
	font-family: 'Euclid Circular B', sans-serif;
	font-size: 18px;
	color: ${colors.linkColorDark};
	text-decoration: none;
	margin-top: 1rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	&:hover {
		text-decoration: underline;
	}
`;

const CampaignStartPagePreamble = styled.div`
	max-width: 635px;
	margin-inline: auto;
	font-size: 1.375rem;
	line-height: 1.556;
	font-family: 'Euclid Circular B', sans-serif;
	${mediaQueries.phone} {
		font-size: 1.125rem;
	}
`;

const CampaignStartPage: React.FC<CampaignStartPageModel> = ({
	heading,
	subHeading,
	iconsContentArea,
	contentLinkContentArea,
	breadcrumbs,
	campaignNavigation,
	preamble,
}) => {
	return (
		<>
			<CampaignNavigation
				links={campaignNavigation.children}
				{...campaignNavigation}
			>
				<Breadcrumb links={breadcrumbs} />
			</CampaignNavigation>
			<CampaignStartPageContent $textAlign={'center'} $paddingTop={4}>
				<InformationPageSubheading>{subHeading}</InformationPageSubheading>
				<InformationPageHeading>{heading}</InformationPageHeading>
				<CampaignStartPagePreamble>{preamble}</CampaignStartPagePreamble>
			</CampaignStartPageContent>
			<CampaignStartPageIconBlock>
				{iconsContentArea?.map((element, index) => (
					<CampaignStartPageIcon {...element} key={index} />
				))}
			</CampaignStartPageIconBlock>
			<CampaignStartPageFooter>
				<CampaignStartPageContent $textAlign={'left'} $paddingTop={0}>
					<CampaignStartPageFooterContent>
						<CampaignStartPageText>
							<h2>{contentLinkContentArea[0]?.heading}</h2>
							<EpiFragments
								fragments={contentLinkContentArea[0]?.text.fragments}
							/>
							<CampaignStartPageLink
								href={contentLinkContentArea[0]?.link.link}
							>
								{contentLinkContentArea[0]?.link.link && (
									<Icon icon={'arrowRight'} color={'#DE752E'} />
								)}
								{contentLinkContentArea[0]?.link.text}
							</CampaignStartPageLink>
						</CampaignStartPageText>
						<ImageContainer>
							{contentLinkContentArea[0]?.image && (
								<ImageWrapper>
									<Image src={contentLinkContentArea[0]?.image.src} />
								</ImageWrapper>
							)}
						</ImageContainer>
					</CampaignStartPageFooterContent>
				</CampaignStartPageContent>
			</CampaignStartPageFooter>
		</>
	);
};

export default CampaignStartPage;
