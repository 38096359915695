/**
 * TextWiw - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { mediaQueries, colors, typography } from 'theme';
import pdficon from '../../theme/icons/icon-pdf.svg';
import wordicon from '../../theme/icons/icon-word.svg';
import excelicon from '../../theme/icons/icon-excel.svg';
import externalicon from '../../theme/icons/icon-external.svg';

const baseScrollToTopMargin = css``;
// scroll-margin-top: ${px2rem(110)};
// scroll-snap-margin-top: ${px2rem(110)};

// ${mediaQueries.phone} {
// 	scroll-margin-top: ${px2rem(210)};
// 	scroll-snap-margin-top: ${px2rem(210)};
// }

export const baseTextStyles = css`
	font-family: ${typography.fontFamily.alt};
`;

export const headingTextStyles = css`
	font-weight: ${typography.fontWeight.semiBold};
	font-family: ${typography.fontFamily.alt};
`;

export const basePreambleStyles = css`
	${baseTextStyles};
	font-weight: ${typography.fontWeight.medium};
`;

export const Span = styled.span`
	${baseTextStyles};
	margin-top: 1rem;
	margin-bottom: 1rem;
`;

export const AnchorSpace = styled.span`
	position: relative;
	top: -100px;
	display: block;
`;

export const Div = styled.div`
	${baseTextStyles};
`;

export const styleForH1 = css`
	${headingTextStyles};
	${baseScrollToTopMargin};
	font-size: ${px2rem(46)};
	line-height: ${px2rem(56)};
	margin-bottom: 4rem;

	${mediaQueries.tablet} {
		font-size: ${px2rem(32)};
		line-height: ${px2rem(38)};
		margin-bottom: 3rem;
	}

	${mediaQueries.phone} {
		font-size: ${px2rem(28)};
		line-height: ${px2rem(34)};
		margin-bottom: 2rem;
	}
`;
export const H1 = styled.h1`
	${styleForH1};
	word-break: break-word;
`;

export const styleForH2 = css`
	${headingTextStyles};
	${baseScrollToTopMargin};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${px2rem(28)};
	line-height: ${px2rem(36)};
	margin-top: 2.5rem;
	margin-bottom: 1rem;

	${mediaQueries.tablet} {
		line-height: ${px2rem(36)};
	}
`;
export const H2 = styled.h2`
	${styleForH2}
`;

export const styleForH3 = css`
	${headingTextStyles};
	${baseScrollToTopMargin};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${px2rem(22)};
	line-height: ${px2rem(32)};
	margin-top: 1.5rem;
	margin-bottom: 1rem;
`;
export const H3 = styled.h3`
	${styleForH3}
`;

export const styleForH4 = css`
	${headingTextStyles};
	${baseScrollToTopMargin};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${px2rem(18)};
	line-height: ${px2rem(32)};
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
`;
export const H4 = styled.h4`
	${styleForH4}
`;

export const styleForH5 = css`
	${headingTextStyles};
	${baseScrollToTopMargin};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${px2rem(14)};
	line-height: ${px2rem(20)};
	margin-top: 1.5rem;
`;
export const H5 = styled.h5`
	${styleForH5}
`;

export const styleForH6 = css`
	${headingTextStyles};
	${baseScrollToTopMargin};
	font-size: ${px2rem(12)};
	line-height: ${px2rem(15)};
	margin-top: 1rem;
	margin-bottom: 1rem;

	${mediaQueries.tablet} {
		font-size: ${px2rem(12)};
	}
`;
export const H6 = styled.h6`
	${styleForH6}
`;

export const styleForP = css`
	${baseTextStyles};
	font-size: ${px2rem(18)};
	line-height: ${px2rem(32)};
	margin: 0 0 1rem;

	${mediaQueries.phone} {
		font-size: ${px2rem(18)};
	}
`;
export const P = styled.p`
	${styleForP}
`;

export const styleForSmallP = css`
	${baseTextStyles};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(26)};
	margin: 0 0 1rem;

	${mediaQueries.phone} {
		font-size: ${px2rem(16)};
		line-height: ${px2rem(26)};
	}
`;
export const SmallP = styled.p`
	${styleForSmallP}
`;

export const styleForLink = css`
	${styleForP};
	color: ${colors.linkColorDark};
	transition: all 0.2s;

	&:hover {
		text-decoration: none;
	}
`;

export const Link = styled.a`
	${styleForLink}
`;

export const Preamble = styled.p`
	${basePreambleStyles};
	font-size: ${px2rem(22)};
	line-height: ${px2rem(32)};
	margin: 0 0 1rem;

	${mediaQueries.phone} {
		font-size: ${px2rem(18)};
		line-height: ${px2rem(28)};
	}
`;

export const ScreenReaderText = styled.span`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;

export const styleForTable = css`
	border: ${px2rem(1)} solid ${colors.greyLighter};
	border-spacing: 0;
	border-radius: 0.25rem;
	font-size: 1rem;
	line-height: 1.5;
	width: 100%;
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.normal};

	caption {
		padding: 0 0 0.5rem;

		${mediaQueries.phone} {
			text-align: left;
		}
	}

	& tbody tr:last-child {
		th,
		td {
			border-bottom: 0;
		}
	}

	& thead th {
		border-bottom: ${px2rem(1)} solid ${colors.greyLighter};
	}

	th,
	td {
		padding: 1rem 1.5rem;
		border: 0;
		border-bottom: ${px2rem(1)} solid ${colors.greyLighter};

		& p {
			font-family: inherit;
			font-size: inherit;
			font-weight: inherit;
			padding: 0;
			margin: 0;
		}
	}
	th {
		font-weight: ${typography.fontWeight.bold};
		textwiw-align: left;
	}
`;

export const Table = styled.table`
	${styleForTable}
`;

export const styleForScrollableTableWrapper = css`
	${mediaQueries.phone} {
		width: 100%;
		overflow-y: auto;
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
		position: relative;

		> table {
			margin-top: 0;
			margin-bottom: 0;
			width: auto;
			max-width: none;
		}

		&::-webkit-scrollbar {
			-webkit-appearance: none;
			width: ${px2rem(14)};
			height: ${px2rem(14)};
		}

		&::-webkit-scrollbar-thumb {
			border-radius: ${px2rem(8)};
			border: ${px2rem(3)} solid #fff;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
`;

export const ScrollableTableWrapper = styled.div`
	${styleForScrollableTableWrapper}

	table {
		${styleForTable}
	}
`;

// prettier-ignore
export const styleForRichTextWiw = css`
	h1 {
		${styleForH1}
	}
	h2 {
		${styleForH2}
	}
	h3 {
		${styleForH3}
	}
	h4 {
		${styleForH4}
	}
	h5 {
		${styleForH5}
	}
	h6 {
		${styleForH6}
	}
	ul,
	ol {
		margin: 0 0 2rem;
	}
	ul {
		list-style: none;
		padding: 0 0 0 1.5rem;

		li::before {
			content: '\\2022';
			font-weight: bold;
			display: inline-block;
			width: 1rem;
			margin-left: -1rem;
			color: ${colors.black};
		}
	}
	table {
		${styleForTable};
		margin: 2.5rem 0;
	}
	.table-scrollable {
		${styleForScrollableTableWrapper}
	}
	.icon {
		display: inline-block;
		width: ${px2rem(12)};
		height: ${px2rem(12)};
		margin-left: 0.5rem;
		margin-right: 0.3rem;
		background-repeat: no-repeat;
	}
`;

interface RichTextWiwProps {
	textSize?: 'small' | 'medium';
	children: any;
	backgroundColor?: 'blue' | 'white';
}

export const RichTextWiw = styled.div<RichTextWiwProps>`
	${styleForRichTextWiw};

	a {
		color: ${colors.linkColorDark};
		transition: all 0.2s;

		&:hover {
			Text-decoration: none;
		}

		&.pdf:after {
			content: '';
			display: inline-block;
			margin-left: 0.5rem;
			background-color: ${colors.linkColorDark};
			-webkit-mask: url('${pdficon}') no-repeat 0 0 / ${px2rem(16)}
				${px2rem(16)};
			mask: url('${pdficon}') no-repeat 0 0 / ${px2rem(16)} ${px2rem(16)};
			width: ${px2rem(16)};
			height: ${px2rem(16)};
		}

		&.word:after {
			content: '';
			display: inline-block;
			margin-left: 0.5rem;
			background-color: ${colors.linkColorDark};
			-webkit-mask: url('${wordicon}') no-repeat 0 0 / ${px2rem(16)}
				${px2rem(16)};
			mask: url('${wordicon}') no-repeat 0 0 / ${px2rem(16)} ${px2rem(16)};
			width: ${px2rem(16)};
			height: ${px2rem(16)};
		}

		&.excel:after {
			content: '';
			display: inline-block;
			margin-left: 0.5rem;
			background-color: ${colors.linkColorDark};
			-webkit-mask: url('${excelicon}') no-repeat 0 0 / ${px2rem(16)}
				${px2rem(16)};
			mask: url('${excelicon}') no-repeat 0 0 / ${px2rem(16)} ${px2rem(16)};
			width: ${px2rem(16)};
			height: ${px2rem(16)};
		}

		&.external:after {
			content: '';
			display: inline-block;
			margin-left: 0.5rem;
			background-color: ${colors.linkColorDark};
			-webkit-mask: url('${externalicon}') no-repeat 0 0 / ${px2rem(16)}
				${px2rem(16)};
			mask: url('${externalicon}') no-repeat 0 0 / ${px2rem(16)} ${px2rem(16)};
			width: ${px2rem(16)};
			height: ${px2rem(16)};
		}
	}

	p,
	a {
		${({ textSize }) => (textSize === 'small' ? styleForSmallP : styleForP)};
		
		${(props) => {
			if (props.backgroundColor === 'blue') {
				return `color: white`;
			}
		}}}
	}

	li {
		${({ textSize }) => (textSize === 'small' ? styleForSmallP : styleForP)};
		margin-bottom: 1rem;
	}
`;
