/**
 * TableOfContents
 */

import React from 'react';
import { LinkType } from 'types/global';
import { Container, Heading, TocList } from './TableOfContents.styles';

interface Props {
	/** Aria label text */
	label: string;

	/** Toc list items */
	items: LinkType[];
}

/** Toc container */
const TableOfContents: React.FC<Props> = ({ label, items }) => {
	const idList = items.map((link: LinkType) => link.link.substring(1));
	return (
		<Container aria-labelledby="toc-heading">
			<Heading as="h2" id="toc-heading">
				{label}
			</Heading>

			<TocList offset={100} items={idList} currentClassName="is-current">
				{items.map((link: LinkType, index: number) => (
					<li key={index}>
						<a href={link.link}>{link.text}</a>
					</li>
				))}
			</TocList>
		</Container>
	);
};

export default TableOfContents;
