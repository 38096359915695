/**
 * Video
 */

import React from 'react';
import { LinkType, StandardImage } from 'types/global';
import {
	VideoContainer,
	Overlay,
	VideoButton,
	VideoIcon,
	VideoButtonText,
} from './Cards.styles';
import Image from 'components/Image';
import Icon from 'components/Icon';

interface Props {
	/** Video src */
	image: StandardImage;
}

/** Container component for Video. */
const Video: React.FC<Props> = ({ image }) => (
	<VideoContainer>
		<Overlay />
		<Image
			src={image.src}
			alt={image.alt}
			srcSet={`
							${image.sizes[0]} 800w,
							${image.sizes[1]} 400w
						`}
			webpSrcSet={`
							${image.webpSizes[0]} 800w,
							${image.webpSizes[1]} 400w
						`}
			sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 400px"
		/>
		<VideoButton>
			<VideoIcon>
				<Icon icon={'arrowRight'} size={0} aria-hidden="true" />
			</VideoIcon>
			<VideoButtonText>Se videon</VideoButtonText>
		</VideoButton>
	</VideoContainer>
);

export default Video;
