/**
 * ChartBlock
 */

import React from 'react';
import BlockContainer from 'layouts/BlockLayout';
import { ChartDataSet } from 'types/blocks';
import Bar from './Bar';

import {
	ChartArea,
	ChartContainer,
	Container,
	Heading,
	SourceText,
	Text,
	TextArea,
} from '../ChartBlock.styles';

interface Props {
	/** Chart heading */
	heading?: string;

	/** Chart preamble */
	preamble?: string;

	/** Description of chart for screen readers */
	srDescription: string;

	/** Chart source text */
	sourceText?: string;

	/** Label unit */
	unit?: string;

	/** Chart color */
	gridColor?: string;

	/** Determine if rendered as fragment */
	renderedAsFragment?: boolean;

	data: {
		/** Labels */
		labels: string[];

		/** Chart data */
		datasets: ChartDataSet[];
	};

	translations?: {
		/** Chart source translation label */
		sourceLabel?: string;
	};
}

/** ChartBlock. */
const BarChartBlock: React.FC<Props> = ({
	heading,
	preamble,
	srDescription,
	sourceText,
	translations,
	unit,
	gridColor,
	data,
	renderedAsFragment = false,
}) => (
	<BlockContainer lessPadding={renderedAsFragment}>
		<Container>
			<ChartContainer addStyles={false}>
				{(heading || preamble) && (
					<TextArea>
						{heading && <Heading>{heading}</Heading>}
						{preamble && <Text>{preamble}</Text>}
					</TextArea>
				)}

				<ChartArea aria-label={srDescription}>
					<Bar unit={unit} gridColor={gridColor} {...data} />
				</ChartArea>

				{sourceText && (
					<SourceText>{`${translations?.sourceLabel}: ${sourceText}`}</SourceText>
				)}
			</ChartContainer>
		</Container>
	</BlockContainer>
);

export default BarChartBlock;
