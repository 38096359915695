/**
 * ProcessCarouselBlock - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { Grid } from 'components/Grid';

export const Container = styled(Grid)`
	padding: ${px2rem(150)} 0 ${px2rem(117)} 0;
	color: ${colors.black};

	${mediaQueries.phone} {
		padding: ${px2rem(70)} ${px2rem(24)};
	}
`;

export const Preamble = styled.p`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(24)};
	letter-spacing: normal;
	padding-right: ${px2rem(55)};

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(16)};
		line-height: ${px2rem(24)};
	}
`;

export const ButtonContainer = styled.div`
	max-width: ${px2rem(262)};
	margin-top: ${px2rem(28)};

	a {
		margin-bottom: ${px2rem(20)};
		width: 100%;
	}

	a span {
		font-size: ${px2rem(15)};
		line-height: 
		font-weight: ${typography.fontWeight.normal};
	}

	${mediaQueries.phone} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		margin-top: ${px2rem(32)};
	}
`;
