/**
 * ReportPage - styles
 */

import styled, { css } from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { Cell } from 'components/Grid';
import Link from 'components/Link';
import { Preamble } from 'components/Text';

export interface ThemeProps {
	isActive?: boolean;
}

export const baseTextStyles = css`
	font-size: ${px2rem(12)};
	line-height: ${px2rem(15)};
	letter-spacing: ${px2rem(0.28)};
	color: ${colors.offGreyLighter};
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.normal};
`;

export const FragmentsContainer = styled.div`
	font-family: ${typography.fontFamily.alt};
	line-height: ${px2rem(32)};
`;

export const LeftCell = styled(Cell)`
	${mediaQueries.phoneOrTablet} {
		order: 1;
	}
`;

export const RightCell = styled(Cell)`
	width: auto;
	margin: 0 ${px2rem(30)};
	${mediaQueries.phoneOrTablet} {
		margin: 0;
		order: 0;
	}

	${mediaQueries.phone} {
		display: flex;
		flex-direction: column-reverse;
	}
`;

export const AccordionList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

export const AccordionListItem = styled.li``;

export const AccordionCell = styled(Link)`
	display: block;
	color: ${colors.greyDark};
	background-color: ${colors.white};
	padding: ${px2rem(15)} ${px2rem(28)};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(15)};
	text-decoration: none;
	border: ${px2rem(1)} solid ${colors.offGreyLightest};
	border-top: 0;
`;

export const CategoryArea = styled.div`
	display: flex;
`;

export const Seperator = styled.div`
	border-left: 2px solid ${colors.offGreyLighter};
	margin-top: 0.5rem;
	padding-right: 13%;
	height: ${px2rem(40)};
`;

export const PublishedDate = styled.p`
	${baseTextStyles};
	font-size: ${px2rem(14)};
	color: ${colors.grey};

	&:after {
		content: '|';
		margin: 0 ${px2rem(20)};
	}

	${mediaQueries.phone} {
		&:after {
			content: '';
			margin: 0 ${px2rem(50)};
		}

		> span {
			display: block;
		}
	}
`;

export const Category = styled.p`
	${baseTextStyles};
	font-size: ${px2rem(14)};
	color: ${colors.blueDarker};
`;

export const StyledPreamble = styled(Preamble)`
	margin: 0 0 3.5rem;
`;
