interface Option {
	value: string;
	caption: string;
	checked?: boolean;
}

/**
 * Extracts the initial form values for the Form-component.
 */
export const getInitialFormValues = (fields: any, currentStep: number): any => {
	const formValues: any = {};

	Object.keys(fields).map((key) => {
		const el = fields[key];

		if (
			el.type === 'select' ||
			el.type === 'radio' ||
			el.type === 'checkbox' ||
			el.type === 'multiselect'
		) {
			formValues[el.name] = [];

			el.options.forEach((opt: Option) => {
				if (opt.checked) {
					formValues[el.name].push(opt.value);
				}
			});

			if (el.type === 'select') {
				formValues[el.name] = formValues[el.name][0]
					? formValues[el.name][0]
					: '';
			}
		} else if (el.predefinedValue) {
			if (el.name === '__FormCurrentStepIndex') {
				formValues[el.name] = currentStep;
			} else {
				formValues[el.name] = el.predefinedValue;
			}
		}
	});

	return formValues;
};

/**
 * Returns data and visible fields for current form step
 */
export const getStepFields = (currentStep: number, formId: string) => {
	const stepId = `form-${formId}-step-${currentStep}`;
	const currentStepNode = document.getElementById(stepId);
	let formData = new FormData();
	let currentStepFields: any = [];

	formData = getHiddenFieldValues(formData, formId);

	if (currentStepNode) {
		currentStepFields = currentStepNode.querySelectorAll(
			'input, select, textarea'
		);
		const visibleFields: string[] = [];

		currentStepFields.forEach((field: any) => {
			const type = field.getAttribute('type');
			const fieldId = field.getAttribute('id') || field.getAttribute('name');

			if (fieldId.indexOf('-') !== -1) {
				visibleFields.push(fieldId.slice(0, fieldId.indexOf('-')));
			} else {
				visibleFields.push(fieldId);
			}

			if (field.options) {
				formData = getSelectValue(field, formData);
			} else if (type === 'checkbox' || type === 'radio') {
				formData = getCheckboxAndRadioValue(field, formData);
			} else if (type === 'file') {
				formData = getUploadedFiles(field, formData);
			} else {
				formData = getFieldValue(field, formData);
			}
		});

		return [formData, currentStep, visibleFields];
	}
};

/**
 * Appends values of hidden fields to formData
 */
const getHiddenFieldValues = (formData: FormData, formId: string) => {
	const hiddenFieldHolder = document.getElementById(
		`form-${formId}-hiddenfields`
	);

	if (hiddenFieldHolder) {
		const hiddenFields = hiddenFieldHolder.querySelectorAll('input, select');

		hiddenFields.forEach((field: any) => {
			formData.append(field.getAttribute('name'), field.value);
		});
	}
	return formData;
};

/**
 * Appends value of select element to formData
 */
const getSelectValue = (field: any, formData: FormData) => {
	for (var i = 0; i < field.options.length; i++) {
		if (field.options[i].selected) {
			formData.append(field.getAttribute('name'), field.options[i].value);
		}
	}
	return formData;
};

/**
 * Appends value of checkbox or radio button to formData
 */
const getCheckboxAndRadioValue = (field: any, formData: FormData) => {
	if (field.checked) {
		formData.append(field.getAttribute('name'), field.value);
	}
	return formData;
};

/**
 * Appends value of uploaded files to formData
 */
const getUploadedFiles = (field: any, formData: FormData) => {
	for (var j = 0; j < field.files.length; j++) {
		formData.append(field.getAttribute('name'), field.files[j]);
	}
	return formData;
};

/**
 * Appends value of input field to formData
 */
const getFieldValue = (field: any, formData: FormData) => {
	formData.append(field.getAttribute('name'), field.value);
	return formData;
};
