/**
 * CaseListPage - styles
 */

import styled from 'styled-components';
import { typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { Grid, Cell } from 'components/Grid';

export const Content = styled.div`
	max-width: ${px2rem(1200)};
	margin: 0.5rem auto 0;

	@media (max-width: 1024px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
`;

export const ResultsContainer = styled.div`
	${mediaQueries.phone} {
		padding-top: 1rem;
		padding-right: 0;
	}
`;

export const CaseBoxGrid = styled(Grid)`
	font-family: ${typography.fontFamily.alt};
`;

export const CaseBoxCell = styled(Cell)``;
