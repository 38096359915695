/**
 * SearchResultsItem
 */

import React from 'react';
import { LinkType } from 'types/global';
import {
	ListItem,
	Article,
	ArticleHeading,
	ArticlePreamble,
	ArticleLink,
	TopRow,
	Category,
	Date,
	BreadcrumbsList,
} from './SearchResultsList.styles';

interface Props {
	/** Article heading */
	heading: string;

	/** Article published date */
	date?: string;

	/** Article category */
	category: string;

	/** Article link */
	link: LinkType;

	/** Article preamble */
	preamble: string;

	/** Article breadcrumbs */
	children?: any;

	/** EpiFind hitId */
	hitId?: string;

	/** Article id */
	id?: string;
}

/** An item shown within search results. */
const SearchResultsItem: React.FC<Props> = ({
	heading,
	date,
	category,
	link,
	preamble,
	children,
	hitId,
	id,
}) => (
	<ListItem>
		<Article>
			<ArticleLink href={link.link} id={hitId || id}>
				<TopRow>
					<Category>{category}</Category>
					{date && <Date as="time">{date}</Date>}
				</TopRow>

				<ArticleHeading>{heading}</ArticleHeading>
				<ArticlePreamble>{preamble}</ArticlePreamble>
			</ArticleLink>

			{children && <BreadcrumbsList>{children}</BreadcrumbsList>}
		</Article>
	</ListItem>
);

export default SearchResultsItem;
