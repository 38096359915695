/**
 * SearchBox
 */

import React from 'react';
import {
	Container,
	InnerContainer,
	CloseContainer,
	CloseButton,
	CloseIcon,
} from './SearchBox.styles';

import AutoComplete from 'components/AutoComplete';

interface Props {
	/** Whether search is currently opened */
	open?: boolean;

	toggle?: any;

	/** Search container id */
	id: string;

	/** Destination to send searched words */
	action?: string;

	/** Search field placeholder text */
	placeholder?: string;

	/** Close text */
	closeSearchBoxText?: string;

	/** Serach button text */
	searchButtonText?: string;

	searchFieldText?: string;

	/** Autocomplete url */
	autocompleteSearchUrl: string;
}

/** Search box for main search on all pages in header */
const SearchBox: React.FC<Props> = ({
	open = false,
	action,
	toggle,
	id,
	placeholder,
	searchFieldText,
	closeSearchBoxText,
	searchButtonText,
	autocompleteSearchUrl,
}) => (
	<Container aria-hidden={!open} id={id} open={open}>
		<InnerContainer>
			<AutoComplete
				id="pageheader-searchbar"
				label={searchFieldText || ''}
				buttonText={searchButtonText || ''}
				placeholder={placeholder}
				action={action}
				shouldFocus={open}
				autocompleteSearchUrl={autocompleteSearchUrl}
			/>
			<CloseContainer>
				<CloseButton onClick={() => toggle(false)}>
					<CloseIcon icon="close" aria-hidden={true} />
					{closeSearchBoxText}
				</CloseButton>
			</CloseContainer>
		</InnerContainer>
	</Container>
);

export default SearchBox;
