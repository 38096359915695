/**
 * SearchResultsList
 */

import React from 'react';
import { OlList } from './SearchResultsList.styles';

interface Props {
	children: any;
}

/** Calendar search results. */
const SearchResultsList: React.FC<Props> = ({ children }) => {
	return <OlList>{children}</OlList>;
};

export default SearchResultsList;
