/**
 * TextWiw
 */

import React from 'react';
import {
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
	P,
	Preamble,
	Span,
	Div,
	AnchorSpace,
	ScreenReaderText,
} from './TextWiw.styles';
import Space from 'components/Space';

type Tags =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'p'
	| 'preamble'
	| 'span'
	| 'div'
	| 'screenReader';

export interface TextWiwProps {
	/** The TextWiw to render (if no children are present) */
	Text?: string;

	/** The html-tag/Component that will be outputted */
	as: Tags;

	/** The style that will be used (instead of the tag default style) */
	styleAs?: any;

	/** Pass optional classnames for the html */
	className?: string;

	/** Optional other html props will be spread on the component */
	[htmlAttributes: string]: any;
}

const tagMapper = {
	h1: H1,
	h2: H2,
	h3: H3,
	h4: H4,
	h5: H5,
	h6: H6,
	p: P,
	preamble: Preamble,
	span: Span,
	div: Div,
	screenReader: ScreenReaderText,
};

/** TextWiw component to render TextWiws, also has handling for Epi server on page editor. */
const TextWiw: React.FC<TextWiwProps> = ({
	children,
	Text,
	styleAs,
	as,
	className = '',
	...htmlAttributes
}) => {
	const content = children || Text;
	const Component = styleAs || tagMapper[as];
	const ComponentAs: any = as;

	if (!content) {
		return null;
	}

	if (styleAs) {
		return (
			<Component as={ComponentAs} className={className} {...htmlAttributes}>
				{content}
			</Component>
		);
	} else {
		return (
			<Component className={className} {...htmlAttributes}>
				{content}
			</Component>
		);
	}
};

export default TextWiw;
