import { darken } from 'polished';

const colorsValues = {
	black: '#000',
	white: '#ffffff',
	beige: '#F1EDD2',
	greyLightest: '#F1F1F1',
	greyLighter: '#E9E8E7',
	greyLight: '#878787',
	grey: '#757575',
	greyDark: '#333',
	offGreyLight: '#404040',
	offGreyLighter: '#B2B2B2',
	offGreyLightest: '#DEDEDE',
	blueLightest: '#DBE9F5',
	blueLighter: '#D0E6F9',
	blueLight: '#9FC7EA',
	lightBlue: '#DBE9F5',
	blue: '#183E6B',
	blueDark: '#0459A9',
	blueDarker: '#183E6B',
	offBlueLightest: '#EAF4FC',
	offBlueLighter: '#D5E2ED',
	offBlueLessLight: '#BBD4E2', // Used in footer links
	offBlueLight: '#A3C2DB',
	offBlue: '#4B749B',
	yellowLighter: '#FEF9EB',
	yellow: '#FECB00',
	yellowDark: '#F6A800',
	orange: '#EF6E0B',
	orangeLight: '#F69347',
	orangeLighter: '#FFF4EC',
	red: '#E0182D',
	green: '#55925B',
	pink: '#FEE6E6',
	mintGreen: '#D3FFB8',
	babyBlue: '#E6F2FE',
	lavender: '#E6E6FE',
	melon: '#FFE5C6',
	errorColor: '#EB2E2E',
	transparent: 'transparent',

	blackOpacity20: 'rgba(17, 17, 17, 0.2)',

	chartColors: {
		green: '#89C147',
		purple: '#800080',
		blue: '#0B71BC',
		blueDark: '#0459A9',
		blueDarker: '#183E6B',
		blueLight: '#9FC7EA',
		blueLightest: '#DBE9F5',
		yellow: '#FECB00',
		yellowDark: '#F6A800',
		orange: '#FA702A',
		backgroundColor: '#ECF3E0',
	},

	linkColor: '#0B71BC',
	linkColorHover: darken(0.05, '#0B71BC'),
	focusColor: '#0B71BC',
	linkColorDark: '#034C91',
	linkColorDarkHover: darken(0.05, '#034C91'),
} as const;
export type Colors = typeof colorsValues;
export const colors: Colors = colorsValues;
