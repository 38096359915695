/**
 * Card
 */

import React from 'react';
import {
	ReportPageCardModel,
	StandardPageCardModel,
	NewsPageCardModel,
	ReferralPageCardModel,
	ThemePageCardModel,
	CasePageCardModel,
	LatestNewsPageCardModel,
	ListPropertyBaseCardModel,
} from 'types/cards';
import NewsPageCard from './NewsPageCard';
import ReportsPageCard from './ReportPageCard';
import StandardPageCard from './StandardPageCard';
import ReferralPageCard from './ReferralPageCard';
import ThemePageCard from './ThemePageCard';
import CasePageCard from './CasePageCard';
import ListPropertyBaseCard from './ListPropertyBaseCard';

interface Props {
	/** CardItem props */
	cardModel:
		| ListPropertyBaseCardModel
		| StandardPageCardModel
		| ReportPageCardModel
		| NewsPageCardModel
		| ReferralPageCardModel
		| ThemePageCardModel
		| LatestNewsPageCardModel
		| CasePageCardModel;

	backgroundColor?: string;
	index: number;
	usePreambleFallback: boolean;
}

const components = {
	AreaPage: ListPropertyBaseCard,
	CalendarListPage: ListPropertyBaseCard,
	CaseListPage: ListPropertyBaseCard,
	GlossaryPage: ListPropertyBaseCard,
	JobPage: ListPropertyBaseCard,
	NewsListPage: ListPropertyBaseCard,
	ReferralListPage: ListPropertyBaseCard,
	RegisterPage: ListPropertyBaseCard,
	ReportListPage: ListPropertyBaseCard,
	ResearchResultListPage: ListPropertyBaseCard,
	ResearchResultPage: ListPropertyBaseCard,
	RegisterDetailsPage: ListPropertyBaseCard,
	CalendarPage: ListPropertyBaseCard,
	StandardPage: StandardPageCard,
	NewsPage: NewsPageCard,
	ReportPage: ReportsPageCard,
	ReferralPage: ReferralPageCard,
	ThemePage: ThemePageCard,
	CasePage: CasePageCard,
	ProcurementCasePage: CasePageCard,
	CompetitionCasePage: CasePageCard,
};

/** Card component */
const Card: React.FC<Props> = ({
	cardModel,
	backgroundColor,
	index,
	usePreambleFallback,
}) => {
	const CardComponent: any = components[cardModel.modelType];
	return (
		<>
			<CardComponent
				{...cardModel}
				backgroundColor={backgroundColor}
				index={index}
				usePreambleFallback={usePreambleFallback}
			/>
		</>
	);
};

export default Card;
