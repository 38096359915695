/**
 * MobileMenu - styles
 */

import styled, { css } from 'styled-components';
import { baseTextStyles } from 'components/Text/Text.styles';
import { px2rem } from 'theme/utils';
import { colors, typography, animations } from 'theme';
import { TransitionState, MenuLinkVariant } from 'types/global';
import { UnstyledButton } from 'components/Button/Button.styles';
import Icon from 'components/Icon';

interface NavProps {
	height?: number;
}
interface PaneProps {
	state: TransitionState;
	activeTrail?: boolean;
}

interface ListItemProps {
	state?: TransitionState;
	selected?: boolean;
}

interface LinkProps {
	variant?: MenuLinkVariant;
	topNav?: boolean;
}

export const FlagIcon = styled(Icon)`
	width: ${px2rem(18)};
	height: ${px2rem(18)};
	margin-left: 1rem;
`;

const baseLiStyle = css`
	${baseTextStyles};
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${px2rem(57)};
	color: ${colors.greyDark};
	padding: ${px2rem(3)} ${px2rem(23)};
	border-bottom: ${px2rem(1)} solid ${colors.greyLighter};
`;

export const getPaneAnimations = (
	transitionState: string,
	activeTrail: boolean = false
) => {
	let animationTime = '.5s';
	let animationCss = `
		transition: transform ${animationTime} cubic-bezier(.4,0,.2,1), opacity ${animationTime} cubic-bezier(.4,0,.2,1);
		-webkit-perspective: 1000;
		-webkit-backface-visibility: hidden;
		${animations.moveInRight}
		${animations.moveOutRight}
	`;

	if (transitionState === 'exiting') {
		animationCss += `animation: moveOutRight ${animationTime} cubic-bezier(.4,0,.2,1) forwards;`;
	} else if (transitionState === 'entering') {
		animationCss += `animation: moveInRight ${animationTime} cubic-bezier(.4,0,.2,1) forwards;`;
	} else if (transitionState === 'entered' && activeTrail) {
		animationCss += `
			transform: translate3d(-30%, 0, 0);
			opacity: 0;
		`;
	} else if (transitionState === 'entered' && !activeTrail) {
		animationCss += `
			transform: translate3d(0, 0, 0);
			opacity: 1;
		`;
	}

	return animationCss;
};

export const getBackLinkAnimations = (transitionState: TransitionState) => {
	let animationTime = '.5s';
	let animationCss = `
		${animations.moveInLeft}
		${animations.moveOutLeft}
	`;

	if (transitionState === 'exiting' || transitionState === 'exited') {
		animationCss += `animation: moveOutLeft ${animationTime} cubic-bezier(.4,0,.2,1) forwards;`;
	} else if (transitionState === 'entering' || transitionState === 'entered') {
		animationCss += `animation: moveInLeft ${animationTime} cubic-bezier(.4,0,.2,1) forwards;`;
	}

	return animationCss;
};
export const Nav = styled.nav`
	position: fixed;
	top: ${px2rem(78)};
	left: 0;
	bottom: 0;
	right: 0;
	background-color: ${colors.white};
	height: 100%;
	width: 100%;
	// overflow-y: auto;
`;

export const NavMobileWrapper = styled.div<PaneProps>`
	position: relative;
	margin: 0;
	overflow: scroll;
	z-index: 99;
	background-color: ${colors.blackOpacity20};

	${({ state }) =>
		state === 'exiting' && ` animation: fadeOut .5s ease-out forwards; `}
	${({ state }) =>
		state === 'entering' && ` animation: fadeIn .5s ease-out forwards; `}

	${animations.fadeIn}
	${animations.fadeOut}

	& > ${Nav} {
		${({ state }) =>
			state === 'exiting' &&
			` animation: moveOutRight .5s cubic-bezier(.4,0,.2,1) forwards; `}
		${({ state }) =>
			state === 'entering' &&
			` animation: moveInRight .5s cubic-bezier(.4,0,.2,1) forwards; `}

		${animations.moveInRight}
		${animations.moveOutRight}
	}
`;

export const TopNav = styled.ul`
	position: relative;
	display: flex;
	-ms-grid-row: 1;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	margin: 0;
	height: auto;
	padding: 1rem;
	border-bottom: ${px2rem(12)} solid ${colors.blueLighter};
`;

export const SelectedNav = styled.ul`
	position: relative;
	align-items: center;
	list-style: none;
	margin: 0;
	height: auto;
	padding: 0;
	border-bottom: ${px2rem(1)} solid ${colors.greyLighter};
`;

export const TopNavLi = styled.li`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 0 0;

	a {
		border: 0;
	}
`;

export const MainNav = styled.div`
	position: relative;
	height: 100%;
	overflow: hidden;
	-ms-grid-row: 2;
`;

export const Pane = styled.div<PaneProps>`
	margin: 0;
	position: absolute;
	height: calc(100% - 154px); //154 -> height of menu + suggestion/langswitch
	// height: 80%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: ${colors.white};
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	&:focus {
		outline: none;
	}

	${({ state, activeTrail }) => getPaneAnimations(state, activeTrail)};
`;

export const Ul = styled.ul<NavProps>`
	list-style: none;
	padding: 0 0 1.5rem;
	margin: 0 0 4.5rem;
	// overflow: unset;
	height: calc(100% -2cm);
`;
// max-height: ${(props) =>
// 	props.height && props.height > 0
// 		? `calc(100% - ${props.height}px)`
// 		: '100%'};

export const Link = styled.a<LinkProps>`
	display: flex;
	align-items: center;
	text-decoration: none;
	color: inherit;
	min-height: 1.5rem;
	width: ${(props) => (props.topNav ? 'auto' : '100%')};
	height: 100%;

	${({ variant }) => variant === 'showAllLink' && `text-decoration: underline;`}
`;

export const MobileLanguageLi = styled.li<ListItemProps>`
	background-color: ${colors.greyLightest};

	${baseLiStyle};
	font-size: ${px2rem(16)};
	line-height: 1.17;
`;

export const WIWLevel1 = styled.li<ListItemProps>`
	background-color: ${(props) =>
		props.selected ? colors.blueLighter : colors.melon};

	${baseLiStyle};
	font-size: ${px2rem(16)};
	line-height: 1.17;
`;

export const LiLevel1 = styled.li<ListItemProps>`
	background-color: ${(props) =>
		props.selected ? colors.blueLighter : 'inherit'};

	${baseLiStyle};
	font-size: ${px2rem(16)};
	line-height: 1.17;
`;

export const LiLevel2 = styled.li<ListItemProps>`
	background-color: ${(props) =>
		props.selected ? colors.blueLighter : 'inherit'};

	${baseLiStyle};
	font-size: ${px2rem(15)};
	line-height: 1.13;
`;

export const LiLevel3 = styled.li<ListItemProps>`
	background-color: ${(props) =>
		props.selected ? colors.blueLighter : 'inherit'};

	${baseLiStyle};
	font-size: ${px2rem(15)};
	line-height: 1.13;
`;

export const LiBack = styled.li<ListItemProps>`
	${baseLiStyle};
	position: relative;
	justify-content: center;
	text-transform: uppercase;
	font-weight: ${typography.fontWeight.bold};
	font-size: ${px2rem(13)};
	line-height: ${px2rem(28)};
	letter-spacing: ${px2rem(1.86)};
	margin-left: 0;
	margin-right: auto;
	${({ state }) => state && getBackLinkAnimations(state)};

	& ${Link} {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		padding: 0 ${px2rem(30)} 0 ${px2rem(40)};
		width: 100%;
		height: 100%;
		margin: 0;

		& svg {
			position: absolute;
			left: 0;
		}
	}
`;

export const LiBackText = styled.p`
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const IconButton = styled(UnstyledButton)<LinkProps>`
	z-index: 1;
	width: auto;
	padding: ${px2rem(15)};

	&:focus {
		box-shadow: none;
	}

	${({ topNav }) => {
		if (topNav) {
			return css`
				position: absolute;
				left: ${px2rem(15)};
			`;
		}
	}}
`;
