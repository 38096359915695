/**
 * RegisterPage
 */

import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from 'components/Breadcrumb';
import { RegisterPageModel } from 'types/pages';
import { LinkType } from 'types/global';
import { useUrlQuery, useDebounce, useSearchResult } from 'hooks';
import {
	Button,
	Checkbox,
	CheckboxLabel,
	Field,
	Fieldset,
	Form,
	Label,
	Select,
	RadioButton,
} from 'components/Form';
import { Accordion, AccordionItem } from 'components/Accordion';
import { colors } from 'theme';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { getActiveFilters } from 'common/helpers';
import { Cell, Grid, GridProps } from 'components/Grid';
// import Button from 'components/Button';
import DatePicker from 'components/Form/fields/DatePicker';
import { FilterOption, RegisterCase } from 'types/listings';
import LoadMorePagination from 'components/LoadMorePagination';

import {
	Heading,
	HeadingContainer,
	HeadingLeft,
	HeadingRight,
	HeadingHeader,
	HeadingLatestUpdated,
	HeadingLink,
	Search,
	SearchContainer,
	SearchInputContainer,
	SearchIcon,
	Content,
	QuickSelectList,
	DetailSearchRow,
	FlexColumn,
	FlexRow,
	FieldsetRow,
	CheckboxRow,
	RadioButtonRow,
	InputLabel,
	Separator,
	ButtonRow,
	ResultsContainer,
	SearchField,
	SearchFieldButton,
	SearchResultTitle,
	SearchResultsHits,
	ResultsHeader,
	CaseGrid,
	CaseCell,
	SortWrapper,
	QuickSelectListMargin,
	DatepickerColumn,
	DatepickerRow,
	DetailSearchRowDate,
	CaseSelectList,
	LoaderWrapper,
	CaseSelectListLabel,
	PaginationContainer,
} from './RegisterPage.styles';
import { LoaderSmall } from 'components/Loader';
import Text, { SmallP } from 'components/Text';
import RegisterCaseList from 'components/RegisterCaseList/RegisterCaseList';
import RegisterCaseListHeading from 'components/RegisterCaseList/RegisterCaseListHeading';
import LoadMorePaginationButton from 'components/LoadMorePaginationButton';

/** Main description for this component. */
const RegisterPage: React.FC<RegisterPageModel> = ({
	breadcrumbs,
	heading,
	latestUpdated,
	helpLinks,
	quickSelect,
	detailedSearch,
	filter,
	pagination,
	items,
	resultsPhrase,
	translations,
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const [action, setAction] = useState('');
	const activeFilters = getActiveFilters(filter);
	const [quickSetHeader, setQuickSetHeader] = useState<string>('');
	const quickSetRef = useRef<any>();

	const getQuickSelectValues = (values: any) => {
		const initialValues: any = {
			quickSet: null,
		};

		values.lists.forEach((list: any) => {
			const active = list.values
				.filter((item: any) => item.active)
				.map((item: any) => item.value);

			initialValues.quickSet = active[0];
		});

		if (initialValues.quickSet === undefined) {
			return undefined;
		}

		return initialValues;
	};

	const getDetailedValues = (values: any) => {
		const initialValues: any = {};
		for (const [key, value] of Object.entries(values)) {
			if (Array.isArray(value)) {
				const active = value
					.filter((item: any) => item.active)
					.map((item: any) => item.value);

				initialValues[key] = active;
			} else if (typeof value === 'object' && value !== null) {
				for (const [k, val] of Object.entries(value)) {
					if (k === 'values') {
						const active = val
							.filter((item: any) => item.active)
							.map((item: any) => item.value);
						if (active.length === 1) {
							initialValues[key] = active[0];
						} else {
							initialValues[key] = [...active];
						}
					}
				}
			} else {
				initialValues[key] = value;
			}
		}

		return initialValues;
	};

	const quickSelectValues = getQuickSelectValues(quickSelect);
	const detailedValues = getDetailedValues(detailedSearch);

	const [values, setValues] = useState<any>({
		query: filter.query || '',
		...activeFilters,
		...quickSelectValues,
		...detailedValues,
	});

	const [currentPage, setCurrentPage] = useState<number>(
		pagination.currentPage
	);

	//const debouncedInput = useDebounce(values, 500);

	const [updateQuery, response, loading, error] = useUrlQuery({
		query: values,
		page: currentPage,
	});

	const resetForm = () => {
		setValues({});
		setCurrentPage(0);
		updateQuery({ query: '', page: 0, quickSet: '' });
		setAction('replace');
	};

	const [
		filters,
		paginations,
		results,
		responseResultsPhrase,
	] = useSearchResult(
		filter,
		pagination,
		items,
		response,
		action,
		resultsPhrase
	);

	const paginationPhrase = translations.paginationPhrase.split('{0}');

	useEffect(() => {
		if (values.quickSet) {
			/* Ugly way to set quick select header in results as it could not be translated in BE */
			const checked: HTMLElement | null = document.querySelector(
				"input[name='quickSet']:checked"
			);
			const key = (checked!.parentElement!.parentElement!.parentElement!
				.firstChild as HTMLElement).innerText;
			const value = (checked!.parentElement!.lastChild as HTMLElement)
				.innerText;
			setQuickSetHeader(`${key} - ${value}`);
		}
	}, [values]);

	useEffect(() => {
		console.log('paginations', paginations);
	}, [paginations]);

	return (
		<main id="main-content">
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>
			<Heading>
				<HeadingContainer>
					<HeadingLeft>
						<HeadingHeader>{heading}</HeadingHeader>
						<HeadingLatestUpdated>{latestUpdated}</HeadingLatestUpdated>
					</HeadingLeft>
					<HeadingRight>
						{helpLinks && (
							<>
								{helpLinks.map((link: LinkType, i: number) => (
									<HeadingLink key={i} href={link.link}>
										{link.text}
									</HeadingLink>
								))}
							</>
						)}
					</HeadingRight>
				</HeadingContainer>
			</Heading>

			<Search>
				<SearchContainer>
					<Accordion>
						<Form
							action="POST"
							onChange={(field: any, allValues: any) => {
								setQuickSetHeader('');
								let currentPage = 0;
								if (field.value === '') {
									setCurrentPage(0);
									currentPage = 0;
									setValues({
										query: field.value,
										quickSet: '',
										sorting: '',
									});
								} else {
									setCurrentPage(1);

									setValues({
										query: field.value,
										quickSet: '',
										sorting: values.sorting || 'older',
									});
								}
							}}
							onSubmit={() => {
								if (values.query !== '') {
									setCurrentPage(1);
									updateQuery({ ...values, page: currentPage });
									setAction('replace');
								}
							}}
							initialValues={values}
						>
							<SearchField>
								<SearchInputContainer>
									<SearchIcon
										icon={'search'}
										color={colors.blueDark}
										size={1}
										aria-hidden="true"
									/>
									<Field
										type="text"
										name="query"
										id="query"
										aria-label={translations.searchPlaceHolderText}
										label={translations.searchPlaceHolderText}
										placeholder={translations.searchPlaceHolderText}
										clearable
									/>
								</SearchInputContainer>
								{values.query !== '' && (
									<SearchFieldButton>
										<Button icon="none" variant="primary" type="submit">
											{translations.detailSearchSearch}
										</Button>
									</SearchFieldButton>
								)}
							</SearchField>
						</Form>

						<AccordionItem
							key="quickSelect"
							variant="white"
							title={translations.quickSelectTitle}
							titleTight={true}
						>
							<Form
								action="POST"
								onChange={(field: any, allValues: any) => {
									if (field.name === 'quickSet') {
										//resetForm();
										setCurrentPage(1);
										const newValues = {
											query: '',
											quickSet: field.value,
											sorting: values.sorting || 'older',
										};
										setValues({
											query: '',
											quickSet: field.value,
											sorting: values.sorting || 'older',
										});
										updateQuery({ ...newValues, page: 1 });
										setAction('replace');
										return;
									}
								}}
								initialValues={values}
							>
								{quickSelect && quickSelect.lists && (
									<QuickSelectList ref={quickSetRef}>
										{quickSelect.lists.map((list: any, i: number) => (
											<QuickSelectListMargin
												key={i}
												length={quickSelect.lists.length}
												className="quickSelectMargin"
											>
												<RadioButtonRow>
													<Fieldset id={`quickSet${i}`} label={list.title}>
														{list.values.map((opt: FilterOption, j: number) => (
															<InputLabel htmlFor={`quickSet${i}${j}`} key={j}>
																<RadioButton
																	id={`quickSet${i}${j}`}
																	name="quickSet"
																	value={opt.value}
																	label={opt.text}
																	defaultValue={values?.quickSet}
																/>
																<span>{opt.text}</span>
															</InputLabel>
														))}
													</Fieldset>
												</RadioButtonRow>
											</QuickSelectListMargin>
										))}
									</QuickSelectList>
								)}
								<DetailSearchRow>
									<ButtonRow>
										<Button
											icon="none"
											variant="secondary"
											type="reset"
											id="resetformbutton"
											onClick={resetForm}
										>
											{translations.detailSearchClear}
										</Button>
									</ButtonRow>
								</DetailSearchRow>
							</Form>
						</AccordionItem>

						<AccordionItem
							key="detailSearch"
							variant="white"
							title={translations.detailSearchTitle}
							titleTight={true}
						>
							<Form
								action="POST"
								onChange={(field: any, allValues: any) => {
									setQuickSetHeader('');
									if (field.name === 'caseTypeList') {
										const vals = field.value.split(',');
										const caseType = {
											caseTypeFrom: vals[0],
											caseTypeTo: vals[1],
										};
										setValues({
											...values,
											...caseType,
											query: '',
											quickSet: '',
											sorting: values.sorting || 'older',
										});
										const caseTypeFrom = window.document.getElementById(
											'caseTypeFrom'
										) as HTMLInputElement;
										const caseTypeTo = window.document.getElementById(
											'caseTypeTo'
										) as HTMLInputElement;
										if (field && caseTypeFrom && caseTypeTo) {
											caseTypeFrom.value = vals[0];
											caseTypeTo.value = vals[1];
										}
									} else {
										setValues({
											...values,
											[field.name]: field.value,
											query: '',
											quickSet: '',
											sorting: values.sorting || 'older',
										});
									}
								}}
								onSubmit={() => {
									if (values.query === '' && values.quickSet === '') {
										setCurrentPage(1);
										updateQuery({ ...values, page: 1 });
										setAction('replace');
									} else {
										setQuickSetHeader('');
										setValues({
											query: '',
											quickSet: '',
											sorting: values.sorting || 'older',
										});
									}
								}}
								initialValues={values}
							>
								<DetailSearchRow>
									<FlexRow width={25}>
										<FlexColumn>
											<Fieldset
												id="caseNumberSet"
												label={translations.detailSearchCaseNumber}
											>
												<Field
													type="text"
													name="caseNumber"
													id="caseNumber"
													label={translations.detailSearchCaseNumber}
													aria-label={translations.detailSearchCaseNumber}
													disabled={false}
												/>
											</Fieldset>
										</FlexColumn>
										<FlexColumn>
											<Separator>
												<p>/</p>
											</Separator>
										</FlexColumn>
										<FlexColumn>
											<Fieldset
												id="yearSet"
												label={translations.detailSearchYear}
											>
												<Field
													type="text"
													name="year"
													id="year"
													label={translations.detailSearchYear}
													aria-label={translations.detailSearchYear}
													disabled={false}
												/>
											</Fieldset>
										</FlexColumn>
									</FlexRow>
									<FlexColumn width={35}>
										<Fieldset
											id="searchSubjectSet"
											label={translations.detailSearchSubject}
										>
											<Field
												type="text"
												name="subject"
												id="searchSubject"
												label={translations.detailSearchSubject}
												aria-label={translations.detailSearchSubject}
												disabled={false}
											/>
										</Fieldset>
									</FlexColumn>
									<FlexColumn width={35}>
										<Fieldset
											id="searchCounterpartSet"
											label={translations.detailSearchCounterpart}
										>
											<Field
												type="text"
												name="counterpart"
												id="searchCounterpart"
												label={translations.detailSearchCounterpart}
												aria-label={translations.detailSearchCounterpart}
												disabled={false}
											/>
										</Fieldset>
									</FlexColumn>
								</DetailSearchRow>
								<DetailSearchRow>
									{detailedSearch.statusList && (
										<FieldsetRow>
											<Fieldset
												id="statusListSet"
												label={translations.detailSearchStatusTitle}
											>
												<CheckboxRow>
													{detailedSearch.statusList.map(
														(list: any, i: number) => (
															<CheckboxLabel
																htmlFor={`statusList-${i}`}
																key={i}
															>
																<Checkbox
																	id={`statusList-${i}`}
																	name="statusList"
																	value={list.value}
																	label={list.text}
																/>
																<span>{list.text}</span>
															</CheckboxLabel>
														)
													)}
												</CheckboxRow>
											</Fieldset>
										</FieldsetRow>
									)}
								</DetailSearchRow>
								<DetailSearchRowDate>
									<Fieldset
										id="registerDate"
										label={translations.detailSearchRegisterDateTitle}
									>
										<DatepickerRow>
											<DatepickerColumn>
												<DatePicker
													id="registerDateFrom"
													name="registerDateFrom"
													label={`${translations.detailSearchFrom}:`}
													defaultValue={
														detailedSearch.registerDateFrom
															? detailedSearch.registerDateFrom
															: ''
													}
												/>
												<DatePicker
													id="registerDateTo"
													name="registerDateTo"
													label={`${translations.detailSearchTo}:`}
													defaultValue={
														detailedSearch.registerDateTo
															? detailedSearch.registerDateTo
															: ''
													}
												/>
											</DatepickerColumn>
										</DatepickerRow>
									</Fieldset>
									<Fieldset
										id="decisionDate"
										label={translations.detailSearchDecisionDateTitle}
									>
										<DatepickerRow>
											<DatepickerColumn>
												<DatePicker
													id="decisionDateFrom"
													name="decisionDateFrom"
													label={`${translations.detailSearchFrom}:`}
													defaultValue={
														detailedSearch.decisionDateFrom
															? detailedSearch.decisionDateFrom
															: ''
													}
												/>
												<DatePicker
													id="decisionDateTo"
													name="decisionDateTo"
													label={`${translations.detailSearchTo}:`}
													defaultValue={
														detailedSearch.decisionDateTo
															? detailedSearch.decisionDateTo
															: ''
													}
												/>
											</DatepickerColumn>
										</DatepickerRow>
									</Fieldset>
								</DetailSearchRowDate>
								<DetailSearchRow>
									<FlexColumn width={50}>
										{detailedSearch.caseTypeList && (
											<RadioButtonRow>
												<Fieldset
													id="caseTypeList"
													label={translations.detailSearchCaseTypeTitle}
												>
													{detailedSearch.caseTypeList.map(
														(list: any, i: number) => (
															<InputLabel htmlFor={`caseTypeList-${i}`} key={i}>
																<RadioButton
																	id={`caseTypeList-${i}`}
																	name="caseTypeList"
																	value={list.value}
																	label={list.text}
																/>
																<span>{list.text}</span>
															</InputLabel>
														)
													)}
												</Fieldset>
											</RadioButtonRow>
										)}
									</FlexColumn>
									<FlexColumn width={50}>
										<Fieldset
											id="caseType"
											label={translations.detailSearchSelectCaseTypeTitle}
										>
											{detailedSearch.caseTypeFrom && (
												<CaseSelectList>
													<CaseSelectListLabel htmlFor="caseTypeFrom">
														{detailedSearch.caseTypeFrom.title}
														<Select
															id="caseTypeFrom"
															label={detailedSearch.caseTypeFrom.title}
															name="caseTypeFrom"
															required={false}
															options={detailedSearch.caseTypeFrom.values}
														/>
													</CaseSelectListLabel>
												</CaseSelectList>
											)}
											{detailedSearch.caseTypeTo && (
												<CaseSelectList>
													<CaseSelectListLabel htmlFor="caseTypeTo">
														{detailedSearch.caseTypeTo.title}
														<Select
															id="caseTypeTo"
															label={detailedSearch.caseTypeTo.title}
															name="caseTypeTo"
															required={false}
															options={detailedSearch.caseTypeTo.values}
														/>
													</CaseSelectListLabel>
												</CaseSelectList>
											)}
										</Fieldset>
									</FlexColumn>
								</DetailSearchRow>
								<DetailSearchRow>
									<ButtonRow>
										<Button
											icon="none"
											variant="secondary"
											type="reset"
											id="resetformbutton"
											onClick={resetForm}
										>
											{translations.detailSearchClear}
										</Button>
										<Button icon="none" variant="primary" type="submit">
											{translations.detailSearchSearch}
										</Button>
									</ButtonRow>
								</DetailSearchRow>
							</Form>
						</AccordionItem>
					</Accordion>
					{loading && (
						<LoaderWrapper>
							<LoaderSmall />
						</LoaderWrapper>
					)}
					{error && (
						<LoaderWrapper>
							<SmallP>{translations.errorText}</SmallP>
						</LoaderWrapper>
					)}
				</SearchContainer>
			</Search>

			<Content>
				{results && paginations.pageCount > -1 && (
					<ResultsContainer id="case-list">
						<ResultsHeader>
							<HeadingLeft>
								<SearchResultTitle>
									{values && values.quickSet && <p>{quickSetHeader}</p>}
									{translations.searchResultCaseListTitle}
								</SearchResultTitle>
								<SearchResultsHits>
									{translations.searchResultHits}: {paginations.total}
								</SearchResultsHits>
							</HeadingLeft>
							<HeadingRight>
								<Form
									action="POST"
									onChange={(field: any) => {
										if (field.name === 'sorting') {
											const newValues = {
												...values,
												sorting: field.value,
											};
											setValues({
												...values,
												sorting: field.value,
											});
											updateQuery({ ...newValues, page: 1 });
										}

										setAction('replace');
									}}
									initialValues={values}
								>
									{filter && filter.sorting && (
										<SortWrapper>
											{translations.searchResultSort && (
												<Label htmlFor="sorting">
													{translations.searchResultSort}
												</Label>
											)}
											<Select
												name="sorting"
												id="sorting"
												label={translations.searchResultSort || ''}
												options={filter.sorting}
											/>
										</SortWrapper>
									)}
								</Form>
							</HeadingRight>
						</ResultsHeader>

						<RegisterCaseListHeading />
						<div>
							{results.map((result: RegisterCase, i: number) => (
								<RegisterCaseList
									key={i}
									index={i}
									item={result}
									readMoreText="Läs mer"
									headerCaseNumber={translations.listHeaderCaseNumber}
									headerCaseType={translations.listHeaderCaseType}
								/>
							))}
						</div>
					</ResultsContainer>
				)}

				<PaginationContainer>
					<Text as="p">
						Visar sökresultat 1-{results?.length ?? 0} av {paginations.total}
					</Text>
					<LoadMorePaginationButton
						visible={paginations.currentPage < paginations.pageCount}
						loading={loading}
						buttonText={translations.searchResultShowMore}
						totalDisplay={paginations.totalDisplay}
						totalHits={paginations.total}
						variant="primary"
						//paginationPhrase={paginationPhrase}
						onClick={(e: any) => {
							e.preventDefault();
							setCurrentPage((page) => page + 1);
							updateQuery({ ...values, page: currentPage + 1 });
							setAction('replace');
						}}
					/>
				</PaginationContainer>
			</Content>
		</main>
	);
};

export default RegisterPage;
