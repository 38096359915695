/**
 * GlossaryPage - styles
 */

import styled, { css } from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { Cell } from 'components/Grid';
import Button from 'components/Button';
import { H3, Preamble } from 'components/Text';
export interface ThemeProps {
	marginTop?: boolean;
	isActive?: boolean;
}

export const baseTextStyles = css`
	font-size: ${px2rem(12)};
	line-height: ${px2rem(15)};
	letter-spacing: ${px2rem(0.28)};
	color: ${colors.offGreyLighter};
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.normal};
`;

export const LeftCell = styled(Cell)`
	${mediaQueries.phoneOrTablet} {
		order: 1;
	}
`;

export const RightCell = styled(Cell)`
	width: auto;
	margin: 0 ${px2rem(30)};
	${mediaQueries.phoneOrTablet} {
		margin: 0;
		order: 0;
	}

	${mediaQueries.phone} {
		display: flex;
		flex-direction: column-reverse;
	}
`;

export const StickyArea = styled.div`
	${mediaQueries.tabletOrLarger} {
		position: sticky;
		top: ${px2rem(115)};
	}
`;

export const ListHeadings = styled.ul`
	list-style: none;
	display: inline-flex;
	flex-wrap: wrap;
	padding: 0;
	margin-top: 0;

	li {
		padding: ${px2rem(5)};
	}
`;

export const StyledButton = styled(Button)`
	font-size: ${px2rem(18)};
	font-weight: ${typography.fontWeight.bold};
`;

export const Heading = styled(H3)<ThemeProps>`
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.medium};
	margin-bottom: ${px2rem(30)};
	margin-top: ${(props) => (props.marginTop ? px2rem(30) : '0')};

	${mediaQueries.tabletOrLarger} {
		font-size: ${px2rem(36)};
	}
`;

export const GlossaryListContainer = styled.ul`
	font-family: ${typography.fontFamily.alt};
	list-style: none;
	padding: 0;
	margin: 0;

	a {
		svg {
			width: 1rem;
			margin-left: ${px2rem(10)};
		}
	}
`;

export const StyledPreamble = styled(Preamble)`
	margin: 0 0 3.5rem;
`;
