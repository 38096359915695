/**
 * Button
 */

import React from 'react';
import { StandardButton, ButtonIcon } from './Button.styles';
import { colors } from 'theme';
import Link from 'components/Link';

interface Props {
	/** Sets the variant of the button */
	variant?:
		| 'primary'
		| 'secondary'
		| 'outlined'
		| 'white'
		| 'transparent'
		| 'link';

	/** Pass optional classnames for the html */
	className?: string;

	/** If button should be rendered as link or button */
	type?: 'link' | 'button' | 'submit';

	/** Sets the size of the button */
	size?: 'large' | 'medium' | 'small';

	/** Sets the width of the button */
	displayWidth?: 'dynamic' | 'fixed' | 'full';

	/** If a specific icon should be used, set 'none' if no icon */
	icon?: string;

	/** Which side to place the icon */
	iconPosition?: 'right' | 'left';

	/** Set the direction of the icon. */
	direction?: 'left' | 'up' | 'right' | 'down';

	/** On click handler */
	onClick?: (e: any) => void;

	[htmlAttributes: string]: any;
}

/** A reusable button component. */
const Button: React.FC<Props> = ({
	children,
	className = '',
	size = 'medium',
	variant = 'primary',
	displayWidth = 'dynamic',
	type = 'button',
	icon = 'arrowRight',
	iconPosition = 'right',
	direction,
	onClick,
	...htmlAttributes
}) => {
	const Icon = () => (
		<ButtonIcon
			icon={icon}
			color={variant === 'primary' ? colors.white : colors.linkColorDark}
			{...(direction && { direction: direction })}
			aria-hidden={true}
		/>
	);

	return (
		<StandardButton
			$size={size}
			$variant={variant}
			$displayWidth={displayWidth}
			$hasIcon={icon !== 'none'}
			className={className}
			onClick={onClick}
			as={type === 'link' ? Link : 'button'}
			{...htmlAttributes}
		>
			{icon !== 'none' && iconPosition === 'left' && <Icon />}
			<span>{children}</span>
			{icon !== 'none' && iconPosition === 'right' && <Icon />}
		</StandardButton>
	);
};

export default Button;
