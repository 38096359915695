/**
 * SearchResultsItem
 */

import React from 'react';
import {
	ListItem,
	Article,
	Heading,
	ArticleHeading,
	ArticleSubHeading,
	ArticleSubText,
	ArticleDate,
	ArticleLink,
	LeftRow,
	ReadMoreLabel,
	RightRow,
	IconBadge,
} from './SearchResultsList.styles';
import Icon from 'components/Icon';
import { ScreenReaderText } from 'components/Text';

interface Props {
	/** Calendar modeltype */
	modelType: 'CalendarHeading' | 'CalendarPage';

	/** Calendar heading */
	heading: string;

	/** Calendar date */
	date?: string;

	/** Calendar time */
	time?: string;

	/** Calendar location */
	location?: string;

	/** Calendar link */
	link?: string;

	/** ScreenreaderText */
	calendarLinkScreenReaderText: string;

	/** Read more label */
	readMoreLabel: string;

	/** Calendar index */
	index: number;

	/** Calendar id */
	id?: string;

	/** EpiFind hitId */
	hitId?: string;
}

/** An item shown within search results. */
const SearchResultsItem: React.FC<Props> = ({
	modelType,
	heading,
	date,
	time,
	location,
	link,
	calendarLinkScreenReaderText,
	readMoreLabel,
	index,
	id,
	hitId,
}) => (
	<ListItem>
		{modelType === 'CalendarHeading' ? (
			<Heading marginTop={index > 0} tabIndex={-1} id={hitId || id}>
				{heading}
			</Heading>
		) : (
			<Article aria-labelledby={`heading-${index}`}>
				<ArticleLink id={hitId || id} href={link}>
					<LeftRow>
						<ArticleHeading id={`heading-${index}`}>{heading}</ArticleHeading>
						<ArticleSubHeading>
							<ArticleDate>{date}</ArticleDate>
							<ArticleSubText>{time}</ArticleSubText>
							<ArticleSubText>{location}</ArticleSubText>
						</ArticleSubHeading>
					</LeftRow>

					<RightRow>
						<ScreenReaderText>{calendarLinkScreenReaderText}</ScreenReaderText>
						<ReadMoreLabel>{readMoreLabel}</ReadMoreLabel>
						<IconBadge>
							<Icon icon={'arrowRight'} size={0} aria-hidden="true" />
						</IconBadge>
					</RightRow>
				</ArticleLink>
			</Article>
		)}
	</ListItem>
);

export default SearchResultsItem;
