/**
 * ColumnBlock
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { LinkType } from 'types/global';
import {
	ReportPageCardModel,
	StandardPageCardModel,
	NewsPageCardModel,
	ThemePageCardModel,
	ReferralPageCardModel,
	CasePageCardModel,
	ListPropertyBaseCardModel,
} from 'types/cards';
import { Card } from 'components/Cards';

import {
	Container,
	ColumnCardContainer,
	Heading,
	ButtonContainer,
} from './ColumnBlock.styles';
import { Grid, Cell } from 'components/Grid';
import Carousel from 'components/Carousel';
import Button from 'components/Button';
import BlockContainer from 'layouts/BlockLayout';

interface Props {
	/** MobileCarousel */
	mobileCarousel?: boolean;

	/** Block heading */
	heading?: string;

	/** Background color */
	backgroundColor?: 'lightBlue' | 'blue' | 'white';

	/** Heading align */
	headingAlignment?: 'left' | 'center';

	/** Button link */
	button?: LinkType;

	/** ColumnBlock item list */
	contentArea?: (
		| CasePageCardModel
		| StandardPageCardModel
		| ReportPageCardModel
		| NewsPageCardModel
		| ReferralPageCardModel
		| ThemePageCardModel
		| ListPropertyBaseCardModel
	)[];
}

/** Columnblock. */
const ColumnBlock: React.FC<Props> = ({
	heading,
	backgroundColor = 'lightBlue',
	headingAlignment = 'left',
	mobileCarousel = false,
	button,
	contentArea,
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	if (!contentArea) {
		return null;
	}

	const ChildComponent: React.FC<any> = ({ children }) => {
		if (isPhone && mobileCarousel) {
			return (
				<Carousel hidePaginationDesktop displayPagination>
					{children}
				</Carousel>
			);
		}

		return <Grid inner={true}>{children}</Grid>;
	};

	return (
		<BlockContainer backgroundColor={backgroundColor}>
			<Container backgroundColor={backgroundColor}>
				<ColumnCardContainer>
					{heading && (
						<Heading
							backgroundColor={backgroundColor}
							headingAlignment={headingAlignment}
						>
							{heading}
						</Heading>
					)}

					<ChildComponent>
						{contentArea?.map(
							(
								cardModel:
									| CasePageCardModel
									| StandardPageCardModel
									| ReportPageCardModel
									| NewsPageCardModel
									| ThemePageCardModel
									| ReferralPageCardModel
									| ListPropertyBaseCardModel,
								i: number
							) => (
								<Cell key={i} span={isPhone ? 12 : 4}>
									<Card
										backgroundColor={backgroundColor}
										cardModel={cardModel}
										key={i}
										index={i}
										usePreambleFallback={true}
									/>
								</Cell>
							)
						)}
					</ChildComponent>

					{button?.link && (
						<ButtonContainer backgroundColor={backgroundColor}>
							<Button
								type="link"
								variant={
									backgroundColor === 'white' || backgroundColor === 'lightBlue'
										? 'outlined'
										: 'white'
								}
								href={button.link}
							>
								{button.text}
							</Button>
						</ButtonContainer>
					)}
				</ColumnCardContainer>
			</Container>
		</BlockContainer>
	);
};

export default ColumnBlock;
