/**
 * PreviewBlockPage
 */

import React from 'react';
import { PreviewBlockPageModel } from 'types/pages';

import { Grid, Cell } from 'components/Grid';
import EpiContentArea from 'components/EpiContentArea';

/** Page template to enable OPE on blocks */
const PreviewBlockPage: React.FC<PreviewBlockPageModel> = ({ contentArea }) => (
	<Grid>
		<Grid inner={true}>
			<Cell span={2} phone={0}>
				&nbsp;
			</Cell>
			<Cell span={7}>
				<EpiContentArea content={contentArea} />
			</Cell>
		</Grid>
	</Grid>
);

export default PreviewBlockPage;
