/**
 * CaseBox
 */

import React from 'react';
import {
	Content,
	Heading,
	TextArea,
	TextHeading,
	Text,
} from './CaseBox.styles';
import { CaseBoxContentModel } from 'types/listings';
import { getTextWithComma } from 'common/helpers';

interface Props {
	/** Case object */
	caseBoxContents: CaseBoxContentModel[];

	/** Read more text */
	readMoreText?: string;

	/** Case index */
	index: number;

	/** Determine if caselistpage or not */
	isCaseListPage?: boolean;

	/** Item id */
	id?: string;
}

/** Component to display a list of cases in a table. */
const CaseBoxContent: React.FC<Props> = ({
	caseBoxContents,
	index,
	isCaseListPage,
}) => {
	const renderHeading = () => {
		let purchaserIndex = caseBoxContents.findIndex(
			(x) => x.contentName === 'Purchaser'
		);
		if (purchaserIndex != -1) {
			let purchaser = caseBoxContents[purchaserIndex];
			if (purchaser?.value) {
				return (
					<Heading
						as="h2"
						id={`CaseBox-heading-${index}`}
						isCaseListPage={
							isCaseListPage !== undefined && isCaseListPage !== false
						}
					>
						{isCaseListPage
							? getTextWithComma(purchaser.value.slice(0, 6))
							: purchaser.value.map((item: string, i: number) => (
									<Text key={i}>{item}</Text>
							  ))}
					</Heading>
				);
			}
		}
	};

	const renderCaseContent = () => {
		let contents = caseBoxContents.filter((x) => x.contentName !== 'Purchaser');
		contents = contents.sort((a, b) => a.order - b.order);

		return contents.map((caseBoxContent: CaseBoxContentModel, i: number) => (
			<React.Fragment key={i}>
				{caseBoxContent.contentName &&
					caseBoxContent.value &&
					caseBoxContent.label && (
						<>
							{caseBoxContent.value.length > 1 ? (
								<TextArea>
									<TextHeading as="h3">{caseBoxContent.label}</TextHeading>
									{isCaseListPage ? (
										<Text>
											{getTextWithComma(
												caseBoxContent.value.slice(0, 2),
												caseBoxContent.value
											)}
										</Text>
									) : (
										caseBoxContent.value.map((item: string) => (
											<Text>{item}</Text>
										))
									)}
								</TextArea>
							) : (
								<TextArea>
									<TextHeading as="h3">{caseBoxContent.label}</TextHeading>
									<Text>{caseBoxContent.value[0]}</Text>
								</TextArea>
							)}
						</>
					)}
			</React.Fragment>
		));
	};
	return (
		<Content>
			{renderHeading()}
			{renderCaseContent()}
		</Content>
	);
};

export default CaseBoxContent;
