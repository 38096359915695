/**
 * SearchResultsList - styles
 */

import styled from 'styled-components';
import { typography, colors, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { P } from 'components/Text';
import Link from 'components/Link';

export const OlList = styled.ol`
	${mediaQueries.phone} {
		padding-left: 1rem;
	}
`;

export const ListItem = styled.li`
	::marker {
		font-size: ${px2rem(18)};
		font-family: ${typography.fontFamily.alt};
	}
`;

export const Article = styled.article`
	margin-left: ${px2rem(55)};
	border-bottom: ${px2rem(1)} solid ${colors.greyLighter};
	margin-bottom: 1.5rem;
	padding-bottom: ${px2rem(26)};

	${mediaQueries.phone} {
		margin-left: 1rem;
		padding-bottom: 2rem;
	}
`;

export const ArticleLink = styled(Link)`
	text-decoration: none;
	color: ${colors.black};
	display: block;
	position: relative;
	margin-top: 3rem;
	max-width: ${px2rem(780)};

	&:hover {
		h2 {
			text-decoration: underline;
		}
	}

	${mediaQueries.phone} {
		margin-top: 0;
	}
`;

export const TopRow = styled.p`
	position: absolute;
	top: ${px2rem(-36)};
	margin-bottom: 0.5rem;
	display: flex;

	${mediaQueries.phone} {
		position: static;
	}
`;

export const Category = styled.span`
	text-transform: uppercase;
	color: ${colors.linkColorDark};
	font-size: ${px2rem(12)};
	line-height: ${px2rem(14)};
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.bold};

	${mediaQueries.phone} {
		margin-bottom: 0;
	}
`;

export const Date = styled(Category)`
	font-weight: ${typography.fontWeight.normal};
	color: ${colors.grey};

	border-left: ${px2rem(1)} solid ${colors.grey};
	padding-left: 0.5rem;
	margin-left: 0.5rem;

	${mediaQueries.phone} {
		margin-bottom: 0;
	}
`;

export const ArticleHeading = styled.h2`
	font-size: ${px2rem(22)};
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.medium};
	margin-top: 0;
`;

export const ArticlePreamble = styled(P)``;

export const BreadcrumbsList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;

	${mediaQueries.phoneOrTablet} {
		flex-wrap: wrap;
	}
`;

export const Crumb = styled.li`
	margin-right: 0.5rem;

	&:after {
		content: '/';
		color: ${colors.grey};
		padding-left: 0.5rem;
	}

	:last-child {
		:after {
			content: '';
		}
	}

	${mediaQueries.phone} {
		margin-top: 1rem;
	}
`;

export const BreadcrumbLink = styled(Link)`
	color: ${colors.linkColorDark};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(14)};
	line-height: ${px2rem(18)};

	&:hover {
		text-decoration: none;
	}
`;
