/**
 * UrlFragment - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, typography } from 'theme';
import Icon from 'components/Icon';

export const Container = styled.div`
	margin: ${px2rem(5)} 0;
`;

export const WrittenByList = styled.ul`
	list-style: none;
	margin: ${px2rem(5)} 0;
	padding-left: ${px2rem(20)};
`;

export const Text = styled.p`
	font-family: ${typography.fontFamily.alt};
	line-height: ${px2rem(24)};
	margin: 0;
`;

export const StyledIcon = styled(Icon)`
	margin-left: 0.5rem;
	width: 1rem !important;
	height: 1rem !important;
`;
