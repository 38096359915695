/**
 * Pie
 */

import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import Chart from 'chart.js';
import { ChartDataSet } from 'types/blocks';
import { getChartColor } from 'common/helpers';

interface Props {
	/** Label unit */
	unit?: string;

	/** Labels */
	labels: string[];

	/** Chart data */
	datasets: ChartDataSet[];
}

/** Pie chart component. */
const Pie: React.FC<Props> = ({ unit, labels, datasets }) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});
	const chartRef = useRef<any>();
	useEffect(() => {
		let chartData = JSON.parse(
			JSON.stringify({
				labels: [...labels],
				datasets: [...datasets],
			})
		);

		chartData.datasets.map((data: ChartDataSet) => {
			data.fill = true;
			data.backgroundColor = getChartColor(data.backgroundColor);
		});

		new Chart(chartRef.current, {
			type: 'pie',
			data: chartData,
			options: {
				responsive: true,
				legend: {
					position: 'bottom',
					align: isPhone ? 'start' : 'center',
					labels: {
						fontSize: 20,
					},
				},
				tooltips: {
					displayColors: false,
					bodyFontSize: 20,
					callbacks: {
						label: (tooltipItems: any, data: any) =>
							`${data.labels[tooltipItems.index]} ${
								data.datasets[0].data[tooltipItems.index]
							} ${unit ? unit : ''}`,
					},
				},
				maintainAspectRatio: false,
			},
		});
	}, [chartRef]);

	return <canvas ref={chartRef} />;
};

export default Pie;
