/**
 * PageWrapper - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

export const PageWrapper = styled.article`
	max-width: ${px2rem(1440)};
	padding: ${px2rem(24)};

	${mediaQueries.tablet} {
		margin: ${px2rem(40)} auto;
	}

	${mediaQueries.desktop} {
		margin: ${px2rem(80)} auto;
		padding: ${px2rem(36)};
	}

	${mediaQueries.phone} {
		padding: ${px2rem(16)};
	}
`;
