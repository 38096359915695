/**
 * AutoComplete - styles
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

type StyleProps = {
	selected?: boolean;
};

export const HiddenLabel = styled.label`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;
`;

export const Container = styled.div`
	position: absolute;
	font-family: ${typography.fontFamily.alt};
	max-width: ${px2rem(488)};
	width: 100%;
	margin: ${px2rem(10)} auto 0 auto;
	z-index: 1;
	display: flex;
	flex-direction: column;
	padding: 0 ${px2rem(15)};

	${mediaQueries.phone} {
		margin: ${px2rem(-50)} auto;
	}
`;

export const SuggestionsList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	background: ${colors.white};
	box-shadow: 0 ${px2rem(15)} ${px2rem(20)} 0 rgba(0, 0, 0, 0.1);
`;

export const ListItem = styled.li<StyleProps>`
	height: ${px2rem(52)};
	padding: 0 ${px2rem(27)};
	width: 100%;
	display: flex;
	align-items: center;
	color: ${colors.greyDark};
	background-color: ${(props) =>
		props.selected ? colors.offBlueLightest : 'inherit'};
	text-decoration: ${(props) => (props.selected ? 'underline' : 'none')};

	&:hover,
	&:focus {
		text-decoration: underline;
		background-color: ${colors.offBlueLightest};
	}
`;
