/**
  NewsPage
 */

import React from 'react';
import { LinkType, StandardImage } from 'types/global';
import {
	AltCardWrapper,
	Container,
	ImageColumn,
	ImageColumnInner,
	DateArea,
	Heading,
	BottomArea,
	AnchorTextAlt,
	Preamble,
	IconBadgeAlt,
	Date,
	Category,
} from './Cards.styles';
import Image from 'components/Image';
import Icon from 'components/Icon';
import Video from './Video';

interface Props {
	/** NewsPage heading */
	heading: string;

	/** NewsPage preamble */
	preamble?: string;

	/** NewsPage preamble */
	preambleFallback?: string;

	/** NewsPage should use the fallback of preamble */
	usePreambleFallback: boolean;

	/** NewsPage date */
	date: string;

	/** NewsPage category */
	category: string;

	/** NewsPage link */
	link: LinkType;

	/** NewsPage video */
	video?: boolean;

	/** NewsPage image */
	image: StandardImage;

	/** Parent backgroundcolor */
	backgroundColor: 'lightBlue' | 'blue' | 'white';

	/** Card index */
	index: number;

	/** Card id */
	id?: string;

	/** EpiFind hitId */
	hitId?: string;

	hideImages?: boolean;
}

/** Container component for NewsPageCard. */
const NewsPageCard: React.FC<Props> = ({
	date,
	category,
	heading,
	preamble,
	preambleFallback,
	usePreambleFallback,
	link,
	video,
	backgroundColor,
	image,
	hideImages,
	index,
	id,
	hitId,
}) => (
	<AltCardWrapper
		backgroundColor={backgroundColor}
		noImage={hideImages}
		aria-labelledby={`heading-${id}`}
	>
		<Container
			href={link.link && typeof link.link === 'string' ? link.link : link}
			id={hitId || id}
		>
			{!hideImages && (
				<ImageColumn>
					<ImageColumnInner>
						{video ? (
							<Video image={image} />
						) : (
							<Image
								src={image.src}
								alt={image.alt}
								srcSet={`
							${image.sizes[0]} 800w,
							${image.sizes[1]} 400w
						`}
								webpSrcSet={`
							${image.webpSizes[0]} 800w,
							${image.webpSizes[1]} 400w
						`}
								sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 400px"
							/>
						)}
					</ImageColumnInner>
				</ImageColumn>
			)}

			<BottomArea>
				<DateArea>
					<Date>{date}</Date>
					<Category>{category}</Category>
				</DateArea>

				<Heading as="h3" id={`heading-${id}`}>
					{heading}
				</Heading>

				{(preamble || usePreambleFallback) && (
					<Preamble backgroundColor={backgroundColor}>
						{preamble ?? preambleFallback}
					</Preamble>
				)}
				<AnchorTextAlt>
					{link.text}
					<IconBadgeAlt>
						<Icon icon={'arrowRight'} size={0} aria-hidden="true" />
					</IconBadgeAlt>
				</AnchorTextAlt>
			</BottomArea>
		</Container>
	</AltCardWrapper>
);

export default NewsPageCard;
