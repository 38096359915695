/**
 * LoadMorePagination
 */

import React from 'react';
import {
	Container,
	ButtonContainer,
	PaginationPhrase,
} from './LoadMorePagination.styles';
import Button from 'components/Button';
import { LoaderSmall } from 'components/Loader';
import LoadMorePaginationButton from 'components/LoadMorePaginationButton';

interface Props {
	/** If load more button should be visible or not */
	visible: boolean;

	/** If new content is currently loading */
	loading: boolean;

	/** Text for load more button */
	buttonText: string;

	/** Number of items displayed at once (10, 20, 30 etc.) */
	totalDisplay: number;

	/** Total number of hits */
	totalHits: number;

	/** Pagination phrase to use for number of hits */
	paginationPhrase?: string[];

	/** On click function for pressing load more button */
	onClick?: any;
}

/** Component to handle the load more pagination on listing pages */
const LoadMorePagination: React.FC<Props> = ({
	visible,
	loading,
	buttonText,
	totalDisplay,
	totalHits,
	paginationPhrase,
	onClick,
}) => {
	return (
		<Container>
			<LoadMorePaginationButton
				visible={visible}
				loading={loading}
				buttonText={buttonText}
				totalDisplay={totalDisplay}
				totalHits={totalHits}
				paginationPhrase={paginationPhrase}
				onClick={onClick}
			/>
		</Container>
	);
};

export default LoadMorePagination;
