/**
 * BlockContainer - styles
 */

import styled, { css } from 'styled-components';
import { colors, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { Colors } from '../../theme/colors';

interface StyledProps {
	inner?: boolean;
	bgColor?:
		| keyof Colors
		| 'blue'
		| 'blueDark'
		| 'transparent'
		| 'white'
		| 'lightBlue'
		| 'blueDarker'
		| 'offBlueLightest';
	$withBorder?: boolean;
	lessPadding?: boolean;
	noPadding?: boolean;
}

const baseInnerStyles = css`
	margin: 0 auto;
	max-width: ${px2rem(1920)};
	${mediaQueries.phoneOrTablet} {
		padding: 0 ${px2rem(36)};
	}
	${mediaQueries.phone} {
		padding: 0 ${px2rem(16)};
	}
`;

export const BlockWrapper = styled.div<StyledProps>`
	border-bottom: ${(props) =>
		props.$withBorder ? `${px2rem(1)} solid ${colors.greyLighter}` : '0'};
	background-color: ${(props) =>
			props.bgColor ? `${colors[props.bgColor]};` : `${colors.white};`}
		${({ inner, lessPadding, noPadding }) => {
			if (noPadding) {
				return css`
					padding: 0;
				`;
			} else if (inner && lessPadding) {
				return css`
					${baseInnerStyles};
					background: transparent;
					${mediaQueries.desktop} {
						padding: 0 ${px2rem(36)};
					}
				`;
			} else if (inner && !lessPadding) {
				return css`
					${baseInnerStyles};
					background: transparent;
					${mediaQueries.desktop} {
						padding: 0 ${px2rem(120)};
					}
				`;
			}
		}};
`;
