/**
 * SkipToMain - styles
 */

import styled from 'styled-components';
import { colors, typography } from 'theme';
import { px2rem } from 'theme/utils';

export const Wrapper = styled.a`
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;

	background-color: ${colors.blue};
	color: ${colors.white};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(20)};
	text-decoration: none;

	:active,
	:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;

		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1rem;
		box-shadow: none;
		text-decoration: underline;
	}

	:hover {
		text-decoration: underline;
	}
`;

export const IconBlob = styled.span`
	background-color: ${colors.white};
	width: ${px2rem(50)};
	height: ${px2rem(50)};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-left: 2rem;
`;
