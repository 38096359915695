/**
 * CalendarListPage - styles
 */

import styled from 'styled-components';
import { colors, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { P } from 'components/Text/Text.styles';

export const Content = styled.div`
	max-width: ${px2rem(1200)};
	margin: 0.5rem auto 0;

	@media (max-width: 1024px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
`;

export const ResultsContainer = styled.div`
	padding: 0 4rem 0 2rem;

	${mediaQueries.phoneOrTablet} {
		padding: 0;
		padding-right: 2rem;
	}

	${mediaQueries.phone} {
		padding-top: 1rem;
		padding-right: 0;
	}
`;

export const NoEventsText = styled(P)`
	text-align: center;
	margin-bottom: ${px2rem(140)};

	${mediaQueries.phoneOrTablet} {
		margin-bottom: 5rem;
	}

	${mediaQueries.phone} {
		margin-bottom: 4rem;
	}
`;

export const ArchiveButtonContainer = styled.div`
	margin-bottom: 1rem;
	text-align: right;
	${mediaQueries.phone} {
		text-align: center;
	}
`;
