/**
 * ResultsBreadcrumb
 */

import React from 'react';
import { Crumb, BreadcrumbLink } from './SearchResultsList.styles';

interface Props {
	/** Link url */
	link: string;

	/** Link text */
	text: string;
}

/** Breadcrumb for search result */
const ResultsBreadcrumb: React.FC<Props> = ({ link, text }) => {
	return (
		<Crumb>
			<BreadcrumbLink href={link}>{text}</BreadcrumbLink>
		</Crumb>
	);
};

export default ResultsBreadcrumb;
