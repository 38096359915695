import React from 'react';
import { SearchFormProps } from 'types/form';
import { getFiltersToShow } from 'common/helpers';

import {
	Form,
	Multiselect,
	Checkbox,
	CheckboxLabel,
	Select,
	Label,
} from 'components/Form';
import SearchField from 'components/SearchField';
import { SmallP } from 'components/Text';
import { LoaderSmall } from 'components/Loader';
import ActiveFilters from 'components/ActiveFilters';

import {
	SearchHeader,
	SearchHeading,
	InnerContainer,
	BoldText,
	SearchPhrase,
	LoaderWrapper,
	Filters,
	FilterHeading,
	FilterWrapper,
	SortWrapper,
	SearchInfoRow,
	Count,
	HiddenSubmitButton,
} from './SearchForm.styles';
import { Facet, FilterOption } from 'types/listings';
import { HiddenLabel } from 'components/AutoComplete/AutoComplete.styles';

const SearchForm: React.FC<SearchFormProps> = ({
	translations,
	_properties,
	headerType = 'h2',
	isPhone,
	loading,
	error,
	filter,
	pagination,
	values,
	displaySearchHeader,
	displaySortWrapper,
	displayHiddenSubmitButton,
	handleSetAction,
	handleSetCurrentPage,
	handleSetValues,
	handleUpdateQuery,
	resultsPhrase,
	autoSubmitOnClear = false,
}) => {
	const searchResultsPhrase = resultsPhrase && resultsPhrase.split('{0}');
	const filtersToShow = getFiltersToShow(values, filter.facets);

	return (
		<Form
			action="POST"
			onChange={(field: any, allValues: any) => {
				let newValues = allValues;
				if (values.query && allValues.query === undefined) {
					newValues = { ...allValues, query: values.query };
				}

				handleSetValues(newValues);

				if (field.name !== 'query') {
					handleSetCurrentPage(1);
					handleUpdateQuery({ ...newValues, page: 1 });
				}

				handleSetAction('replace');
			}}
			onSubmit={(allValues: any) => {
				const queryValue = {
					query: allValues?.query ?? '',
				};

				document.getElementById('resetformbutton')?.click();
				handleSetValues(queryValue);
				handleSetCurrentPage(1);
				handleUpdateQuery({ ...queryValue, page: 1 });
				handleSetAction('replace');
			}}
			initialValues={values}
		>
			{displaySearchHeader && (
				<SearchHeader>
					<InnerContainer>
						<SearchHeading as={headerType} {..._properties?.searchHeading}>
							{translations.searchHeading}
						</SearchHeading>

						<HiddenLabel htmlFor="main-search">
							{translations.searchFieldLabel || ''}
						</HiddenLabel>

						<SearchField
							name="query"
							id="main-search"
							buttonText={translations.searchButtonLabel || ''}
							label={translations.searchFieldLabel || ''}
							placeholder={translations.searchFieldPlaceholder}
							autoSubmitOnClear={autoSubmitOnClear}
							clearable
						/>

						{isPhone && searchResultsPhrase && (
							<SearchPhrase>
								{filter.query && (
									<>
										{searchResultsPhrase[0]}
										<BoldText>'{filter.query}'</BoldText>
										{searchResultsPhrase[1]}
									</>
								)}

								{pagination.total >= 0 && (
									<>
										<BoldText>{pagination.total}</BoldText>
										{searchResultsPhrase[2]}.
									</>
								)}
							</SearchPhrase>
						)}

						{loading && (
							<LoaderWrapper>
								<LoaderSmall />
							</LoaderWrapper>
						)}
						{error && (
							<LoaderWrapper>
								<SmallP>{translations.errorText}</SmallP>
							</LoaderWrapper>
						)}
					</InnerContainer>
				</SearchHeader>
			)}

			<Filters>
				{translations.filterHeading && (
					<FilterHeading>{translations.filterHeading}</FilterHeading>
				)}
				<FilterWrapper>
					{filter.facets.map((facet: Facet, i: number) => (
						<div key={i}>
							{facet.type === 'Multiselect' && (
								<Multiselect id={facet.name} label={facet.heading}>
									{facet.options.map((opt: FilterOption, i: number) => (
										<CheckboxLabel
											htmlFor={`${facet.name}-${opt.value}`}
											key={i}
										>
											<Checkbox
												id={`${facet.name}-${opt.value}`}
												name={facet.name}
												value={opt.value}
												label={opt.text}
											/>
											<span>{opt.text}</span>

											{/* {opt.count && <Count>{`(${opt.count})`}</Count>} */}
										</CheckboxLabel>
									))}
								</Multiselect>
							)}
						</div>
					))}
				</FilterWrapper>

				{displayHiddenSubmitButton && (
					<HiddenSubmitButton
						type="submit"
						aria-label={translations.searchButtonLabel || 'Sök'}
					>
						{translations.hiddenSubmitText}
					</HiddenSubmitButton>
				)}

				{filtersToShow && filtersToShow.length > 0 && (
					<ActiveFilters
						filters={filtersToShow}
						clearText={translations.clearFilterText}
						clearAllText={translations.clearAllFiltersText}
						onClear={() => {
							handleSetValues({ query: values.query });
							handleSetCurrentPage(1);
							handleUpdateQuery({ query: values.query || '', page: 1 });
							handleSetAction('replace');
						}}
					/>
				)}
			</Filters>

			<SearchInfoRow>
				{!isPhone && searchResultsPhrase && displaySearchHeader && (
					<div role="presentation" aria-live="polite" aria-atomic="true">
						<SearchPhrase>
							{filter.query && (
								<>
									{searchResultsPhrase[0]}
									<span aria-hidden="true">'</span>
									<BoldText>{filter.query}</BoldText>
									<span aria-hidden="true">'</span>
									{searchResultsPhrase[1]}
								</>
							)}

							{pagination.total >= 0 && (
								<>
									<span aria-hidden="true">'</span>
									<BoldText>{pagination.total}</BoldText>
									<span aria-hidden="true">'</span>
									{searchResultsPhrase[2]}.
								</>
							)}
						</SearchPhrase>
					</div>
				)}

				{!isPhone && searchResultsPhrase && !displaySearchHeader && (
					<div role="presentation" aria-live="polite" aria-atomic="true">
						<SearchPhrase>
							{searchResultsPhrase[0]}
							<BoldText>'{pagination.total}'</BoldText>
							{searchResultsPhrase[1]}
						</SearchPhrase>
					</div>
				)}

				{displaySortWrapper && filter.sorting && (
					<SortWrapper>
						{translations.sortBy && (
							<Label htmlFor="sorting">{translations.sortBy}</Label>
						)}
						<Select
							id="sorting"
							name="sorting"
							label={translations.sortBy || ''}
							options={filter.sorting}
						/>
					</SortWrapper>
				)}
			</SearchInfoRow>
		</Form>
	);
};

export default SearchForm;
