/**
 * Datepicker
 * @module components/Datepicker
 */

import React, { useState, useRef, forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import { format, parse, parseISO, isValid } from 'date-fns';

import Icon from 'components/Icon';
import { getDate } from 'common/helpers';

import { DatepickerProps } from 'types/form';
import {
	DatePickerWrapper,
	DatePickerHolder,
	LegendLabel,
} from '../Form.styles';

registerLocale('sv', sv);

const CustomInput = forwardRef((props: any, ref: any) => {
	let { onBlur, onChange, onClick, onFocus, onKeyDown, value } = props;

	const d = value.replace(/\D/g, '');
	if (d.length > 4 && d.length < 7) {
		value = [d.slice(0, 4), d.slice(4)].join('-');
	}
	if (d.length > 6 && d.length < 9) {
		value = [d.slice(0, 4), d.slice(4, 6), d.slice(6)].join('-');
	}
	return (
		<DatePickerHolder>
			<Icon icon="calendar" aria-hidden="true" color="none" />
			<LegendLabel>{props.label}</LegendLabel>
			<input
				type="text"
				onBlur={onBlur}
				onChange={onChange}
				onClick={onClick}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				ref={ref}
				value={value}
				maxLength={10}
			/>
		</DatePickerHolder>
	);
});

const Datepicker: React.FC<DatepickerProps> = (props) => {
	const {
		id,
		label,
		name,
		placeholder,
		required,
		pattern,
		setFocus,
		disabled,
		onFieldChange,
		resetForm,
		autoSubmit,
		defaultValue,
		description,
		validationMessage,
		patternMessage,
		showError,
		visibleFields,
		helpText,
		minDate,
		maxDate,
		...allProps
	} = props;

	const [visibleOptions, setVisibleOptions] = useState(false);

	const [touched, setTouched] = useState(false);
	const [value, setValue] = useState(defaultValue || '');

	const onChange = (e: Date) => {
		let value = '';
		if (isValid(e)) {
			const d = format(e, 'yyyyMMdd');
			if (d.length > 4 && d.length < 7) {
				value = [d.slice(0, 4), d.slice(4)].join('-');
			}
			if (d.length > 6 && d.length < 9) {
				value = [d.slice(0, 4), d.slice(4, 6), d.slice(6)].join('-');
			}
			if (value.length > 10) {
				value = value.slice(0, 10);
			}
		}
		setValue(value ? value : '');
		onFieldChange({
			name,
			value: value ? value : '',
			type: 'Date',
			autoSubmit,
		});
		setVisibleOptions(false);
	};

	return (
		<DatePickerWrapper>
			<DatePicker
				selected={value ? parse(value, 'yyyy-MM-dd', new Date()) : null}
				locale="sv"
				dateFormat="yyyy-MM-dd"
				placeholderText={placeholder || ''}
				onBlur={() => setTouched(true)}
				onChange={onChange}
				customInput={<CustomInput label={label} />}
				showYearDropdown
			/>
		</DatePickerWrapper>
	);
};

export default Datepicker;
