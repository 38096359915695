import React from 'react';
import { GraphicsBlockItemModel } from '../../types/blocks';
import styled from 'styled-components';
import { colors, mediaQueries } from 'theme';
import Image from '../Image';
import Icon from '../Icon';

const GraphicsBlockItemWrapper = styled.div`
	display: flex;
	align-items: start;
	gap: 3.5rem;
	:not(:last-of-type) {
		margin-bottom: 2.5rem;
	}
	${mediaQueries.phone} {
		flex-direction: column;
		gap: 1rem;
		text-align: center;
		align-items: center;
	}
`;

const GraphicsBlockItemHeading = styled.h3`
	font-size: 1rem;
	font-weight: normal;
	font-family: 'Euclid Circular B', sans-serif;
	margin-bottom: 0.5rem;
	margin-top: 0;
`;

const GraphicsBlockItemPreamble = styled.p`
	font-size: 1.75rem;
	font-weight: bolder;
	font-family: 'Euclid Circular B', sans-serif;
	margin-block: 0;
	line-height: 1.75rem;
	${mediaQueries.phone} {
		font-size: 1.5rem;
	}
`;

const GraphicsBlockItemLink = styled.a`
	text-decoration: none;
	font-family: 'Euclid Circular B', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	color: ${colors.linkColorDark};
	display: flex;
	gap: 0.5rem;
	align-items: center;
	margin-block: 1rem;
	&:hover {
		text-decoration: underline;
	}
	${mediaQueries.phone} {
		justify-content: center;
	}
`;

const GraphicsBlockImageWrapper = styled.div`
	min-width: 100px;
	min-height: 100px;
	max-width: 100px;
	max-height: 100px;
`;

const GraphicsBlockItem: React.FC<GraphicsBlockItemModel> = (props) => {
	return (
		<>
			<GraphicsBlockItemWrapper>
				<GraphicsBlockImageWrapper>
					<Image
						src={props.image?.src}
						srcSet={props.image?.srcSet}
						webpSrcSet={props.image?.webpSrcSet}
						sizes={props.image?.sizes}
						alt={props.image?.alt}
					/>
				</GraphicsBlockImageWrapper>
				<div>
					<GraphicsBlockItemHeading>{props.heading}</GraphicsBlockItemHeading>
					<GraphicsBlockItemPreamble>
						{props.preamble}
					</GraphicsBlockItemPreamble>
					{props.link && (
						<GraphicsBlockItemLink href={props.link?.link}>
							{props.link.text && (
								<Icon icon={'arrowRight'} size={0} color={colors.orange} />
							)}
							{props.link.text}
						</GraphicsBlockItemLink>
					)}
				</div>
			</GraphicsBlockItemWrapper>
		</>
	);
};

export default GraphicsBlockItem;
