/**
 * ResearchResultListPage
 */

import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { useUrlQuery, useDebounce, useSearchResult } from 'hooks';
import { getActiveFilters } from 'common/helpers';

import { ResearchResultListPageModel, ResearchResultsItem } from 'types/pages';
import EpiContentArea from 'components/EpiContentArea';

import {
	Content,
	ResultsContainer,
	StyledPreamble,
} from './ResearchResultListPage.styles';

import LoadMorePagination from 'components/LoadMorePagination';
import Text from 'components/Text';

import { LinkText } from 'components/Link/Link.styles';
import PageLayout from 'layouts/PageLayout';

import {
	SearchResultsList,
	SearchResultsItem,
} from 'components/ResearchResultsList';
import Breadcrumb from 'components/Breadcrumb';
import SearchForm from 'components/SearchForm';
import EpiFragments from 'components/EpiFragments';
import Icon from 'components/Icon';
import Link from 'components/Link';

/** Template for a ResearchResultListPage page. */
const ResearchResultListPage: React.FC<ResearchResultListPageModel> = ({
	breadcrumbs,
	topContentArea,
	heading,
	preamble,
	text,
	filter,
	pagination,
	items,
	translations,
	_properties = {},
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const [action, setAction] = useState('');
	const activeFilters = getActiveFilters(filter);
	const [values, setValues] = useState<any>({
		...activeFilters,
	});

	const [currentPage, setCurrentPage] = useState<number>(
		pagination.currentPage
	);

	const debouncedInput = useDebounce(values.query, 500);

	const [updateQuery, response, loading, error] = useUrlQuery({
		...values,
		query: debouncedInput,
		page: currentPage,
	});

	const [filters, paginations, results] = useSearchResult(
		filter,
		pagination,
		items,
		response,
		action
	);

	const renderFragments = (fragments: any) => {
		let newData;
		if (fragments) {
			newData = fragments.map((fragment: any) => {
				if (fragment.modelType === 'UrlFragment') {
					return Object.assign(
						{},
						{ target: '_blank' }, // Default if missing in fragment
						fragment
					);
				}

				return fragment;
			});
		}

		return <EpiFragments fragments={newData} />;
	};

	return (
		<main id="main-content">
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>
			<EpiContentArea
				{..._properties?.topContentArea}
				content={topContentArea}
				isPageHeader={true}
			/>

			<PageLayout>
				{topContentArea && topContentArea.length === 0 && (
					<Text as="h1" {..._properties?.heading} id="research-heading">
						{heading}
					</Text>
				)}

				<SearchForm
					translations={{
						clearFilterText: translations.clearFilterText,
						clearAllFiltersText: translations.clearAllFiltersText,
						hiddenSubmitText: translations.hiddenSubmitText,
						filterHeading: translations.filterHeading,
					}}
					headerType="h2"
					_properties={_properties}
					isPhone={isPhone}
					loading={loading}
					error={error}
					filter={filters}
					pagination={paginations}
					values={values}
					handleSetAction={setAction}
					handleSetCurrentPage={setCurrentPage}
					handleSetValues={setValues}
					handleUpdateQuery={updateQuery}
					displayHiddenSubmitButton
				/>

				{results && results.length > 0 && (
					<Content>
						<ResultsContainer id="search-list">
							<>
								<SearchResultsList>
									{results.map((item: ResearchResultsItem, i: number) => (
										<SearchResultsItem
											{...item}
											translations={{
												readMoreLabel: translations.readMoreLabel,
												readLessLabel: translations.readLessLabel,
											}}
											key={i}
										>
											{item.applicationFile && (
												<Link
													href={item.applicationFile.link}
													target="_blank"
													icon="download"
													size="large"
												>
													<LinkText>
														{item.applicationFile.text}
														<Icon icon="pdf" size={1} aria-hidden="true" />
													</LinkText>
												</Link>
											)}
											{item.text && renderFragments(item.text.fragments)}
										</SearchResultsItem>
									))}
								</SearchResultsList>

								<LoadMorePagination
									visible={paginations.currentPage < paginations.pageCount}
									loading={loading}
									buttonText={translations.showMoreButtonLabel}
									totalDisplay={paginations.totalDisplay}
									totalHits={paginations.total}
									onClick={() => {
										setCurrentPage((page) => page + 1);
										updateQuery({ ...values, page: currentPage + 1 });
										setAction('add');
									}}
								/>
							</>
						</ResultsContainer>
					</Content>
				)}
			</PageLayout>
		</main>
	);
};

export default ResearchResultListPage;
