/**
 * ConfirmationBannerBlock
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { StandardImage, LinkType } from 'types/global';

import {
	Wrapper,
	LeftBlock,
	LeftBlockInner,
	RightBlock,
	Heading,
	StyledPreamble,
	StyledText,
	ButtonWrapper,
	ImageWrapper,
} from './ConfirmationBannerBlock.styles';

import Image from 'components/Image';
import Button from 'components/Button';
import BlockContainer from 'layouts/BlockLayout';

interface Props {
	/** Block heading */
	heading: string;

	/** Block text */
	text?: string;

	/** Block preamble */
	preamble?: string;

	/** Image in desktop */
	image: StandardImage;

	/** Image in mobile */
	imageMobile: StandardImage;

	/** Block button */
	buttonLink?: LinkType;

	/** Buttons in block */
	children?: any;

	/** Epi on-page editing properties */
	_properties?: any;
}

/** ConfirmationBannerBlock with text and image */
const ConfirmationBannerBlock: React.FC<Props> = ({
	heading,
	text,
	preamble,
	image,
	imageMobile,
	buttonLink,
	children,
	_properties = {},
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const imageToShow = isPhone ? imageMobile : image;

	return (
		<BlockContainer backgroundColor="white">
			<Wrapper {..._properties?.block}>
				<LeftBlock>
					<LeftBlockInner>
						<Heading bgColor="white" {..._properties?.heading}>
							{heading}
						</Heading>

						{preamble && (
							<StyledPreamble bgColor="white" {..._properties?.preamble}>
								{preamble}
							</StyledPreamble>
						)}

						{text && (
							<StyledText bgColor="white" {..._properties?.text}>
								{text}
							</StyledText>
						)}

						{buttonLink && (
							<ButtonWrapper>
								<Button href={buttonLink.link} variant="secondary" type="link">
									{buttonLink.text}
								</Button>
							</ButtonWrapper>
						)}
					</LeftBlockInner>
				</LeftBlock>

				<RightBlock {..._properties?.image}>
					{imageToShow && (
						<ImageWrapper>
							<Image
								src={imageToShow.src}
								alt={imageToShow.alt}
								srcSet={
									isPhone
										? `
						${imageToShow.sizes[0]} 800w,
						${imageToShow.sizes[1]} 400w
					`
										: `
						${imageToShow.sizes[0]} 1200w,
						${imageToShow.sizes[1]} 800w,
						${imageToShow.sizes[2]} 400w
					`
								}
								webpSrcSet={
									isPhone
										? `
						${imageToShow.webpSizes[0]} 800w,
						${imageToShow.webpSizes[1]} 400w
					`
										: `
						${imageToShow.webpSizes[0]} 1200w,
						${imageToShow.webpSizes[1]} 800w,
						${imageToShow.webpSizes[2]} 400w
					`
								}
								sizes="(min-width: 1040px) 60vw, (min-width: 768px) 40vw, (max-width: 767px) 100vw, 800px"
							/>
						</ImageWrapper>
					)}
				</RightBlock>
			</Wrapper>
		</BlockContainer>
	);
};

export default ConfirmationBannerBlock;
