/**
 * ProcessCarouselBlock
 */

import React from 'react';
import { LinkType } from 'types/global';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import {
	Container,
	Preamble,
	ButtonContainer,
} from './ProcessCarouselBlock.styles';

import BlockContainer from 'layouts/BlockLayout';
import Carousel from 'components/Carousel';
import Button from 'components/Button';
import { Grid, Cell } from 'components/Grid';
import { H2 } from 'components/Text/Text.styles';

interface Props {
	/** Block heading */
	heading: string;

	/** Block preamble */
	preamble: string;

	/** Screenreader text */
	screenReaderText: string;

	/** Block link */
	link?: LinkType;

	/** ProcessCarousel item list */
	children?: any[];
}

/** ProcessCarouselBlock. */
const ProcessCarouselBlock: React.FC<Props> = ({
	heading,
	preamble,
	link,
	children,
	screenReaderText,
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const isTablet = useMediaQuery({
		minWidth: breakpointsNumber.tablet,
		maxWidth: breakpointsNumber.desktop - 1,
	});

	const isLargeDesktop = useMediaQuery({
		minWidth: breakpointsNumber.largeDesktop,
	});

	const isPhoneOrTablet = isPhone || isTablet;

	const hasChildren = children && children.length > 0;
	let slides = children;

	const buildTwoDimensionalArray = (array: any, totalPerRow: number) => {
		let a = [...array];

		let newArray = [];
		while (a.length) {
			newArray.push(a.splice(0, totalPerRow));
		}
		return newArray;
	};

	if (hasChildren && !isPhoneOrTablet) {
		slides = buildTwoDimensionalArray(children, isLargeDesktop ? 3 : 2);
	}

	let cellSpan = 6;

	if (isPhoneOrTablet) {
		cellSpan = 12;
	} else if (isLargeDesktop) {
		cellSpan = 4;
	}

	return (
		<BlockContainer backgroundColor="lightBlue">
			<Container inner>
				<Cell desktop={hasChildren ? 4 : 12} tablet={12} phone={12}>
					<H2 as="h2">{heading}</H2>

					<Preamble>{preamble}</Preamble>

					{link && (
						<ButtonContainer>
							<Button
								type="link"
								variant="transparent"
								href={link.link}
								target={link.target}
							>
								{link.text}
							</Button>
						</ButtonContainer>
					)}
				</Cell>

				{hasChildren && slides && (
					<Cell desktop={8} tablet={12} phone={12}>
						<Carousel
							displayPagination={slides.length > 1}
							displayButtons={!isPhoneOrTablet && slides.length > 1}
							screenReaderText={screenReaderText}
						>
							{slides?.map((slide, i: number) => (
								<Grid inner key={i}>
									{React.Children.map(slide, (child, j) => (
										<Cell span={cellSpan} key={j}>
											{React.cloneElement(child, child.props)}
										</Cell>
									))}
								</Grid>
							))}
						</Carousel>
					</Cell>
				)}
			</Container>
		</BlockContainer>
	);
};

export default ProcessCarouselBlock;
