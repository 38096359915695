/**
 * FaqBlock
 */

import BlockContainer from 'layouts/BlockLayout';
import React from 'react';
import { FaqContainer, Heading } from './FaqBlock.styles';
import { Accordion, AccordionItem } from 'components/Accordion';

interface Props {
	/** Block title */
	heading: string;

	/** Title alignment */
	headingAlignment: 'left' | 'center';

	/** FaqItem block items */
	children?: any;
}

/** FAQ Kundo Block */
const FaqBlock: React.FC<Props> = ({ heading, headingAlignment, children }) => {
	return (
		<BlockContainer>
			<FaqContainer>
				<Heading headingAlignment={headingAlignment}>{heading}</Heading>

				<Accordion>
					{React.Children.map(children, (child, i) => (
						<AccordionItem variant="list" title={child.props.heading}>
							{React.cloneElement(child, child.props)}
						</AccordionItem>
					))}
				</Accordion>
			</FaqContainer>
		</BlockContainer>
	);
};

export default FaqBlock;
