/**
 * Button
 */

import React from 'react';
import { ButtonProps } from 'types/form';
import { Button as ButtonBase } from '../Form.styles';

const Button: React.FC<ButtonProps> = ({
	type = 'button',
	onFieldChange,
	resetForm,
	showError,
	visibleFields,
	children,
	onClick,
	...propsToTransfer
}) => {
	if (type === 'reset') {
		return (
			<button
				id="resetformbutton"
				type={type}
				onClick={() => {
					if (onClick) {
						onClick();
					}
					resetForm();
				}}
				{...propsToTransfer}
			>
				{children}
			</button>
		);
	} else if (type === 'submit') {
		return (
			<ButtonBase type={type} {...propsToTransfer}>
				{children}
			</ButtonBase>
		);
	} else {
		return (
			<button
				type={type}
				onClick={() => {
					if (onClick) {
						onClick();
					}
				}}
				{...propsToTransfer}
			>
				{children}
			</button>
		);
	}
};

export default Button;
