import { useEffect, useState } from 'react';

interface UseSearchResult {
	(
		filter: any,
		pagination: any,
		items: any,
		response: any,
		action: string,
		phrase?: string
	): any;
}

let firstNewItem: string;

export const useSearchResult: UseSearchResult = (
	filter,
	pagination,
	items,
	response,
	action,
	phrase
) => {
	const [filters, setFilters] = useState<any>(filter);
	const [paginations, setPaginations] = useState<any>(pagination);
	const [results, setResults] = useState<any[]>(items);
	const [resultsPhrase, setResultsPhrase] = useState<string>(phrase || '');

	useEffect(() => {
		if (response) {
			if (action === 'add') {
				setResults([...results, ...response.items]);
			} else if (action === 'replace') {
				setResults(response.items);
			}

			if (response.resultsPhrase) {
				setResultsPhrase(response.resultsPhrase);
			}

			setPaginations(response.pagination);
			setFilters(response.filter);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response]);

	useEffect(() => {
		setResults(items);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	useEffect(() => {
		setFilters(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		setPaginations(pagination);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	return [filters, paginations, results, resultsPhrase];
};
