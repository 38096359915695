/**
 * EpiContentArea
 */

import React from 'react';
import {
	LinkItemBlockModel,
	LinkListColumnBlockModel,
	StartBannerBlockModel,
	PromoBlockModel,
	TopBannerBlockModel,
	ThemeBannerBlockModel,
	ColumnBlockModel,
	LatestNewsBlockModel,
	TextBlockModel,
	CampaignTextBlockModel,
	ProcessCarouselBlockModel,
	ProcessListBlockModel,
	ProcessItemBlockModel,
	FaqBlockModel,
	LinkTreeBlockModel,
	LinkListBlockModel,
	EpiFormsModel,
	BarChartBlockModel,
	PieChartBlockModel,
	LineChartBlockModel,
	SubscriptionBlockModel,
	CalendarListBlockModel,
	GraphicsBlockModel,
	MediaBlockModel,
} from 'types/blocks';
import {
	StartBannerBlock,
	PopularLinks,
	BannerLink,
} from 'components/StartBannerBlock';
import Button from 'components/Button';
import PromoBlock from 'components/PromoBlock';
import TopBannerBlock from 'components/TopBannerBlock';
import ThemeBannerBlock from 'components/ThemeBannerBlock';
import LinkListColumnBlock from 'components/LinkListColumnBlock';
import LinkItemBlock from 'components/LinkItemBlock';
import ColumnBlock from 'components/ColumnBlock';
import TextBlock from 'components/TextBlock';
import CampaignTextBlock from 'components/CampaignTextBlock';
import ProcessCarouselBlock from 'components/ProcessCarouselBlock';
import ProcessListBlock from 'components/ProcessListBlock';
import ProcessItemBlock from 'components/ProcessItemBlock';
import LinkListBlock from 'components/LinkListBlock';
import { FaqBlock, FaqItemBlock } from 'components/FaqBlock';
import LinkTreeBlock from 'components/LinkTreeBlock';
import EpiForms from 'components/EpiForms';
import {
	BarChartBlock,
	LineChartBlock,
	PieChartBlock,
} from 'components/ChartBlock';
import SubscriptionBlock from 'components/SubscriptionBlock';
import CalendarListBlock from 'components/CalendarListBlock';
import LatestNewsBlock from 'components/LatestNewsBlock';
import LatestNewsItemBlock from 'components/LatestNewsBlock/LastestNewsItemBlock';
import GraphicsBlock from '../GraphicsBlock/GraphicsBlock';
import MediaBlock from '../MediaBlock/MediaBlock';

interface Props {
	/** Epi property containing content for the content area */
	content?: any;

	/* Set heading in the block to a H1 */
	isPageHeader?: boolean;

	/** Epi properties for on page editing */
	[htmlAttributes: string]: any;
}

export const renderContent = (
	block:
		| LinkItemBlockModel
		| LinkListColumnBlockModel
		| StartBannerBlockModel
		| PromoBlockModel
		| ColumnBlockModel
		| LatestNewsBlockModel
		| TopBannerBlockModel
		| ThemeBannerBlockModel
		| TextBlockModel
		| CampaignTextBlockModel
		| ProcessCarouselBlockModel
		| ProcessListBlockModel
		| ProcessItemBlockModel
		| FaqBlockModel
		| LinkTreeBlockModel
		| LinkListBlockModel
		| EpiFormsModel
		| BarChartBlockModel
		| LineChartBlockModel
		| PieChartBlockModel
		| SubscriptionBlockModel
		| GraphicsBlockModel
		| CalendarListBlockModel
		| MediaBlockModel,
	i: number,
	isPageHeader: boolean
) => {
	switch (block.modelType) {
		case 'MediaBlock':
			return <MediaBlock {...block} key={i} />;
		case 'GraphicsBlock':
			return <GraphicsBlock {...block} key={i} />;
		case 'SubscriptionBlock':
			return <SubscriptionBlock {...block} key={i} />;
		case 'LinkListBlock':
			return <LinkListBlock key={i} {...block} />;
		case 'LinkTreeBlock':
			return <LinkTreeBlock key={i} {...block} />;
		case 'FaqBlock':
			return (
				<FaqBlock key={i} {...block}>
					{block.items.map((item, i) => (
						<FaqItemBlock key={i + 1} {...item} linkText={block.linkText} />
					))}
				</FaqBlock>
			);
		case 'ThemeBannerBlock':
			return <ThemeBannerBlock key={i} {...block} />;
		case 'ProcessListBlock':
			return (
				<ProcessListBlock {...block} key={i}>
					{block.contentArea?.map((item, i) => (
						<ProcessItemBlock
							backgroundColor={block.backgroundColor}
							{...item}
							count={i + 1}
							key={i}
							accordion
						/>
					))}
				</ProcessListBlock>
			);
		case 'ProcessCarouselBlock':
			return (
				<ProcessCarouselBlock {...block} key={i}>
					{block.contentArea?.map((item, i) => (
						<ProcessItemBlock {...item} count={i + 1} key={i} />
					))}
				</ProcessCarouselBlock>
			);
		case 'ColumnBlock':
			return <ColumnBlock {...block} key={i} />;
		case 'LatestNewsBlock':
			return (
				<LatestNewsBlock {...block} key={i}>
					{block.contentArea?.map((item, i) => (
						<LatestNewsItemBlock
							key={i}
							backgroundColor={block.backgroundColor}
							index={i}
							cardModel={{ ...item, hideImages: block.hideImages }}
						/>
					))}
				</LatestNewsBlock>
			);
		case 'LinkListColumnBlock':
			return (
				<LinkListColumnBlock {...block} key={i}>
					{block.contentArea?.map((item, i) => (
						<LinkItemBlock
							key={i}
							{...item}
							backgroundColor={block.backgroundColor}
						/>
					))}
				</LinkListColumnBlock>
			);
		case 'TextBlock':
			return (
				<TextBlock
					{...block}
					isPageHeader={isPageHeader}
					key={i}
					backgroundColor={block.backgroundColor}
				/>
			);
		case 'CampaignTextBlock':
			return (
				<CampaignTextBlock
					{...block}
					isPageHeader={isPageHeader}
					key={i}
					backgroundColor={block.backgroundColor}
				/>
			);
		case 'StartBannerBlock':
			const { linkList, ...startBannerProps } = block;

			return (
				<StartBannerBlock {...startBannerProps} key={i}>
					<PopularLinks heading={linkList.heading}>
						{linkList.links.map((link: any, i: number) => (
							<BannerLink href={link.link} key={i}>
								{link.text}
							</BannerLink>
						))}
					</PopularLinks>
				</StartBannerBlock>
			);
		case 'PromoBlock':
			return (
				<PromoBlock {...block} key={i} isPageHeader={isPageHeader}>
					{block.buttons?.map((btn: any, i: number) => (
						<Button
							href={btn.link}
							variant={
								block.backgroundColor === 'blueDark' ? 'white' : 'primary'
							}
							key={i}
							type="link"
							displayWidth="fixed"
						>
							{btn.text}
						</Button>
					))}
				</PromoBlock>
			);
		case 'TopBannerBlock':
			return (
				<TopBannerBlock {...block} key={i}>
					{block.buttons?.map((btn: any, i: number) => (
						<Button
							href={btn.link}
							variant={
								block.backgroundColor === 'blue' ? 'secondary' : 'primary'
							}
							type="link"
							key={i}
							displayWidth="fixed"
						>
							{btn.text}
						</Button>
					))}
				</TopBannerBlock>
			);
		case 'FormContainer':
			return <EpiForms {...block} key={i} />;
		case 'BarChartBlock':
			return <BarChartBlock {...block} key={i} />;
		case 'LineChartBlock':
			return <LineChartBlock {...block} key={i} />;
		case 'PieChartBlock':
			return <PieChartBlock {...block} key={i} />;
		case 'CalendarListBlock':
			return block.items && block.items.length > 0 ? (
				<CalendarListBlock {...block} key={i} />
			) : null;
		default:
			return null;
	}
};

/** Epi content area for handling blocks */
const EpiContentArea: React.FC<Props> = ({
	content = [],
	isPageHeader = false,
	...htmlAttributes
}) => (
	<div {...htmlAttributes}>
		{content.map((block: any, index: number) => {
			return renderContent(block, index, isPageHeader);
		})}
	</div>
);

export default EpiContentArea;
