/**
 * Video
 */

import React, { useState } from 'react';
import { useUuid } from 'hooks';
import {
	ButtonContainer,
	TabButton,
	Wrapper,
	TopContent,
} from './Video.styles';

import { H2, H5, SmallP } from 'components/Text';
import ResponsiveIframe from 'components/ResponsiveIframe';

interface Props {
	/** Video heading */
	heading?: string;

	/** Video description */
	description?: string;

	/** Video options heading */
	optionsHeading?: string;

	/** Video title */
	videoTitle?: string;

	/** Video url (with captions) */
	videoUrl?: string;

	/** Video url (with sign language) */
	signLanguageUrl?: string;

	/** Video url (with audio description) */
	audioDescriptionUrl?: string;

	/** Captions text (translation) */
	captions?: string;

	/** Audio description text (translation) */
	audioDescription?: string;

	/** Sign language text (translation) */
	signLanguage?: string;
}

/** Main description for this component. */
const Video: React.FC<Props> = ({
	heading,
	description,
	optionsHeading = 'Videoalternativ',
	videoTitle,
	videoUrl,
	audioDescriptionUrl,
	signLanguageUrl,
	captions = 'Textad',
	audioDescription = 'Syntolkad',
	signLanguage = 'Teckenspråk',
}) => {
	const [getUuid] = useUuid();
	const [activeTab, setActiveTab] = useState('standard');
	const standardId = getUuid().toString();
	const audioDescriptionId = getUuid().toString();
	const signLanguageId = getUuid().toString();

	return (
		<Wrapper>
			<TopContent>
				{heading && <H2>{heading}</H2>}
				{description && <SmallP>{description}</SmallP>}

				<H5 as={heading ? 'h3' : 'h2'}>{optionsHeading}:</H5>
				<ButtonContainer role="tablist">
					{videoUrl && (
						<li
							role="tab"
							aria-selected={activeTab === 'standard'}
							aria-controls={standardId}
						>
							<TabButton
								onClick={() => setActiveTab('standard')}
								icon={activeTab === 'standard' ? 'check' : 'none'}
								iconPosition="left"
								variant={activeTab === 'standard' ? 'outlined' : 'primary'}
								disabled={activeTab === 'standard'}
							>
								{captions}
							</TabButton>
						</li>
					)}
					{audioDescriptionUrl && (
						<li
							role="tab"
							aria-selected={activeTab === 'audioDescription'}
							aria-controls={audioDescriptionId}
						>
							<TabButton
								onClick={() => setActiveTab('audioDescription')}
								icon={activeTab === 'audioDescription' ? 'check' : 'none'}
								iconPosition="left"
								variant={
									activeTab === 'audioDescription' ? 'outlined' : 'primary'
								}
								disabled={activeTab === 'audioDescription'}
							>
								{audioDescription}
							</TabButton>
						</li>
					)}
					{signLanguageUrl && (
						<li
							role="tab"
							aria-selected={activeTab === 'signLanguage'}
							aria-controls={signLanguageId}
						>
							<TabButton
								onClick={() => setActiveTab('signLanguage')}
								icon={activeTab === 'signLanguage' ? 'check' : 'none'}
								iconPosition="left"
								variant={activeTab === 'signLanguage' ? 'outlined' : 'primary'}
								disabled={activeTab === 'signLanguage'}
							>
								{signLanguage}
							</TabButton>
						</li>
					)}
				</ButtonContainer>
			</TopContent>

			{videoUrl && (
				<div role="tabpanel" id={standardId} hidden={activeTab !== 'standard'}>
					<ResponsiveIframe
						src={`${videoUrl}?cc_load_policy=1`}
						aria-label={`${captions}: ${videoTitle}`}
					/>
				</div>
			)}
			{audioDescriptionUrl && (
				<div
					role="tabpanel"
					id={audioDescriptionId}
					hidden={activeTab !== 'audioDescription'}
				>
					<ResponsiveIframe
						src={`${audioDescriptionUrl}?cc_load_policy=1`}
						aria-label={`${audioDescription}: ${videoTitle}`}
					/>
				</div>
			)}
			{signLanguageUrl && (
				<div
					role="tabpanel"
					id={signLanguageId}
					hidden={activeTab !== 'signLanguage'}
				>
					<ResponsiveIframe
						src={`${signLanguageUrl}?cc_load_policy=1`}
						aria-label={`${signLanguage}: ${videoTitle}`}
					/>
				</div>
			)}
		</Wrapper>
	);
};

export default Video;
