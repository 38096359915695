/**
 * LinkTreeBlock
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { LinkType } from 'types/global';

import { Container, LinkListContainer, Heading } from './LinkTreeBlock.styles';
import BlockContainer from 'layouts/BlockLayout';
import LinkTreeUl from './LinkTreeUl';
import { Accordion, AccordionItem } from 'components/Accordion';

interface Props {
	/** Block heading */
	heading: string;

	/** Heading align */
	headingAlignment: 'left' | 'center';

	/** Link tree list, left */
	linkListLeft?: LinkType[];

	/** Link tree list, center */
	linkListCenter?: LinkType[];

	/** Link tree list, right */
	linkListRight?: LinkType[];
}

/** LinkTree block. */
const LinkTreeBlock: React.FC<Props> = ({
	heading,
	headingAlignment,
	linkListLeft,
	linkListCenter,
	linkListRight,
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});
	const isTablet = useMediaQuery({
		minWidth: breakpointsNumber.tablet,
		maxWidth: breakpointsNumber.desktop - 1,
	});

	return (
		<BlockContainer backgroundColor="offBlueLightest">
			<Container>
				<Heading headingAlignment={headingAlignment}>{heading}</Heading>

				{isPhone || isTablet ? (
					<Accordion>
						{linkListLeft?.map((link: LinkType, i: number) => (
							<AccordionItem
								key={i}
								variant="transparent"
								title={link.text}
								titleLink={link.link}
							>
								{link.children && (
									<LinkTreeUl level={2} isFirst linkList={link.children} />
								)}
							</AccordionItem>
						))}

						{linkListCenter?.map((link: LinkType, j: number) => (
							<AccordionItem
								key={j}
								variant="transparent"
								title={link.text}
								titleLink={link.link}
							>
								{link.children && (
									<LinkTreeUl level={2} isFirst linkList={link.children} />
								)}
							</AccordionItem>
						))}

						{linkListRight?.map((link: LinkType, k: number) => (
							<AccordionItem
								key={k}
								variant="transparent"
								title={link.text}
								titleLink={link.link}
							>
								{link.children && (
									<LinkTreeUl level={2} isFirst linkList={link.children} />
								)}
							</AccordionItem>
						))}
					</Accordion>
				) : (
					<LinkListContainer>
						{linkListLeft && (
							<LinkTreeUl level={1} isFirst linkList={linkListLeft} />
						)}

						{linkListCenter && (
							<LinkTreeUl level={1} linkList={linkListCenter} />
						)}

						{linkListRight && <LinkTreeUl level={1} linkList={linkListRight} />}
					</LinkListContainer>
				)}
			</Container>
		</BlockContainer>
	);
};

export default LinkTreeBlock;
