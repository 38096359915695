/**
 * ConfirmationPage
 */

import React from 'react';
import { ConfirmationPageModel } from 'types/pages';
import ConfirmationBannerBlock from 'components/ConfirmationBannerBlock';
// import Button from 'components/Button';
import Breadcrumb from 'components/Breadcrumb';

/** Page template for an confirmation page. */
const ConfirmationPage: React.FC<ConfirmationPageModel> = ({
	breadcrumbs,
	translations,
	confirmationBannerBlock,
}) => (
	<main id="main-content">
		{breadcrumbs && (
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>
		)}

		<ConfirmationBannerBlock {...confirmationBannerBlock} />
	</main>
);

export default ConfirmationPage;
