/**
 * EpiForms
 */

import React, { useReducer, useRef, useEffect } from 'react';

import { useForceUpdate, useFormRenderer, usePrevious } from 'hooks';
import { getInitialFormValues, getStepFields } from './EpiForms.helpers';
import { submitForm } from './EpiForms.submit';

import {
	FormContainer,
	FormContainerWrapper,
	FormHeading,
	FormSubText,
} from './EpiForms.styles';

import { Form } from 'components/Form';
import Space from 'components/Space';
import { Grid, Cell } from 'components/Grid';
// import FormStep from './FormStep';
import FormMessage from './FormMessage';

export interface EpiFormsProps {
	/** Form id */
	id: string;

	/** Fields within the Form */
	fields: any[];

	/** Form steps */
	steps: any[];

	/** Message to show when successfully submitted */
	confirmationMessage: string | null;

	/** Form method */
	method?: string;

	/** Form action */
	action?: string;

	/** Form encoding type */
	encodingType?: string;

	/** Form dependencies */
	dependencies?: any[];

	/** Form title (h1) */
	title?: string | null;

	/** Form description (p) */
	description?: string | null;

	/** Url to redirect to when successfully submitted */
	redirectUrl?: string;

	/** Epi properties for on-page editing */
	_properties?: any;

	/** Form backgroundcolor */
	backgroundColor?: 'lightBlue' | 'white';

	[htmlAttributes: string]: any;
}

const initialState = {
	isLoading: false,
	invalidFields: [],
	validationMessages: {},
	successMessage: '',
	currentStep: 0,
	values: {},
	submissionId: '',
	serverValidationError: false,
};

const reducer = (state: any, action: any) => {
	switch (action.type) {
		case 'UPDATE_STATE':
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

/** Rendering of an EpiForms block. Uses the Form component. */
const EpiForms: React.FC<EpiFormsProps> = ({
	fields,
	method = 'GET',
	action,
	encodingType = 'multipart/form-data',
	steps,
	dependencies,
	id,
	title,
	description,
	confirmationMessage,
	redirectUrl,
	backgroundColor,
	_properties = {},
}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { currentStep, successMessage, submissionId, invalidFields } = state;

	const forceUpdate = useForceUpdate();
	const [renderFormElement, fieldActionExists] = useFormRenderer(
		fields,
		state,
		dependencies
	);

	const formValues = getInitialFormValues(fields, currentStep);

	const messageHolderRef = useRef<any>(null);
	const prevInvalidFields = usePrevious(invalidFields);
	let prevStep = usePrevious(currentStep);

	if (prevStep === undefined) {
		prevStep = 0;
	}

	// Focus messageHolderRef if success or error
	useEffect(() => {
		const successfullySubmitted =
			invalidFields.length > 0 || confirmationMessage || successMessage;
		const formMessageElement = messageHolderRef && messageHolderRef.current;

		if (
			successfullySubmitted &&
			formMessageElement &&
			prevInvalidFields !== invalidFields
		) {
			messageHolderRef.current.focus();
		}
	}, [invalidFields, messageHolderRef]);

	// Jump back to top if step changes
	useEffect(() => {
		if (window && prevStep !== currentStep && steps.length > 2) {
			window.location.href = `#${id}`;
		}
	}, [currentStep]);

	// If we have dependencies (conditional fields), we re-render.
	const onChange = (field: any, values: any) => {
		if ((dependencies && dependencies.length > 0) || fieldActionExists) {
			dispatch({
				type: 'UPDATE_STATE',
				payload: { values },
			});

			if (dependencies && dependencies.length > 0) {
				forceUpdate();
			}
		}
	};

	const onSubmit = (
		values: any,
		stepIndex: number,
		currentValidationMessages: any,
		currentInvalidFields: string[]
	) => {
		if (currentInvalidFields && currentInvalidFields.length > 0) {
			dispatch({
				type: 'UPDATE_STATE',
				payload: {
					invalidFields: currentInvalidFields,
					validationMessages: currentValidationMessages,
				},
			});
			return;
		}

		submitForm(values, stepIndex, dispatch, steps, {
			currentStep,
			submissionId,
			action,
			method,
			redirectUrl,
		});
	};

	let submitFieldKey = '';
	for (let v of Object.values(fields)) {
		if (
			v !== undefined &&
			typeof v === 'object' &&
			v.hasOwnProperty('type') &&
			v.hasOwnProperty('name') &&
			v.type === 'submit'
		) {
			submitFieldKey = v.name;
		}
	}

	return (
		<FormContainerWrapper backgroundColor={backgroundColor}>
			<FormContainer>
				<Form
					initialValues={formValues}
					sendDataType="formdata"
					method={method}
					action={action}
					enctype={encodingType}
					onSubmit={onSubmit}
					onChange={onChange}
					multiStep={steps.length > 2}
					currentStep={currentStep}
					getStepFields={getStepFields}
					id={id}
				>
					<header>
						{title && (
							<Space bottom={1.5}>
								<FormHeading {..._properties?.title}>{title}</FormHeading>
							</Space>
						)}

						{description && (
							<Space bottom={2.5}>
								<FormSubText {..._properties?.description}>
									{description}
								</FormSubText>
							</Space>
						)}
					</header>

					<Grid inner {..._properties?.fields}>
						{steps.map(
							(step: any, i: number) =>
								step.index === -1 ? (
									<div id={`form-${id}-hiddenfields`} key="-1" hidden>
										{step.fields.map(renderFormElement)}
									</div>
								) : (
									step.fields.map((field: string, j: number) =>
										j % 2 === 0 && field === submitFieldKey ? (
											<>
												<Cell desktop={6} phone={12}></Cell>
												<Cell desktop={6} phone={12} key={j}>
													{renderFormElement(field)}
												</Cell>
											</>
										) : (
											<Cell desktop={6} phone={12} key={j}>
												{renderFormElement(field)}
											</Cell>
										)
									)
								)

							// : (
							// 			<FormStep
							// 				key={i}
							// 				step={step}
							// 				nrOfSteps={steps.length}
							// 				formId={id}
							// 				currentStep={currentStep}
							// 				onPrevious={(state: any) => {
							// 					dispatch({
							// 						type: 'UPDATE_STATE',
							// 						payload: state,
							// 					});
							// 				}}
							// 			>
							// 				{step.fields.map((field: string, j: number) => (
							// 					<Cell desktop={6} phone={12} key={j}>
							// 						{renderFormElement(field)}
							// 					</Cell>
							// 				))}
							// 			</FormStep>
							// 		)
						)}
					</Grid>

					<footer>
						{state.invalidFields.length > 0 ||
							(successMessage && (
								<FormMessage
									ref={messageHolderRef}
									confirmationMessage={confirmationMessage}
									state={state}
								/>
							))}
					</footer>
				</Form>
			</FormContainer>
		</FormContainerWrapper>
	);
};

export default EpiForms;
