/**
 * LoadMorePagination
 */

import React from 'react';
import {
	ButtonContainer,
	PaginationPhrase,
} from './LoadMorePaginationButton.styles';
import Button from 'components/Button';
import { LoaderSmall } from 'components/Loader';

interface Props {
	/** If load more button should be visible or not */
	visible: boolean;

	/** If new content is currently loading */
	loading: boolean;

	/** Text for load more button */
	buttonText: string;

	/** Number of items displayed at once (10, 20, 30 etc.) */
	totalDisplay: number;

	/** Total number of hits */
	totalHits: number;

	/** Pagination phrase to use for number of hits */
	paginationPhrase?: string[];

	/** On click function for pressing load more button */
	onClick?: any;

	variant?:
		| 'primary'
		| 'secondary'
		| 'outlined'
		| 'white'
		| 'transparent'
		| 'link';
}

/** Component to handle the load more pagination on listing pages */
const LoadMorePaginationButton: React.FC<Props> = ({
	visible,
	loading,
	buttonText,
	totalDisplay,
	totalHits,
	paginationPhrase,
	onClick,
	variant = 'outlined',
}) => {
	return (
		<>
			{visible && (
				<ButtonContainer>
					<Button
						variant={variant}
						icon={loading ? 'none' : 'plus'}
						onClick={onClick}
					>
						{!loading && buttonText}
						{loading && <LoaderSmall />}
					</Button>
				</ButtonContainer>
			)}

			{paginationPhrase && (
				<PaginationPhrase>
					{paginationPhrase[0]} 1-{totalDisplay}
					{paginationPhrase[1]}
					{totalHits}
				</PaginationPhrase>
			)}
		</>
	);
};

export default LoadMorePaginationButton;
