/**
 * EpiFragments
 */

import React from 'react';
import { EpiFormsProps } from 'components/EpiForms/EpiForms';
import {
	FragmentModelTypes,
	RawFragmentModel,
	HeadingFragmentModel,
	ImageFragmentModel,
	VideoFragmentModel,
	TableFragmentModel,
	FactItemHtmlFragmentModel,
	AccordionHtmlFragmentModel,
	UrlFragmentModel,
	ListFragmentModel,
	BarChartFragmentModel,
	LineChartFragmentModel,
	PieChartFragmentModel,
} from 'types/fragments';

import Space from 'components/Space';
import Image from 'components/Image';
import RichText from 'components/RichText';
import EpiForms from 'components/EpiForms';
import Video from 'components/Video';
import Text, { ScrollableTableWrapper, AnchorSpace } from 'components/Text';
import AccordionHtmlFragment from 'components/AccordionHtmlFragment';
import FactItemHtmlFragment, {
	FactTextContainer,
} from 'components/FactItemHtmlFragment';
import UrlFragment from 'components/UrlFragment';
import {
	BarChartBlock,
	LineChartBlock,
	PieChartBlock,
} from 'components/ChartBlock';

interface Props {
	/** Epi properties to use for on page editing */
	[htmlAttributes: string]: any;

	/** Fragments that should be rendered. */
	fragments?: FragmentModelTypes[];
	backgroundColor?: 'blue' | 'white';
}

const getHeadingLevel = (level: number) => {
	switch (level) {
		case 1:
			return 'h1';
		case 2:
			return 'h2';
		case 3:
			return 'h3';
		case 4:
			return 'h4';
		case 5:
			return 'h5';
		case 6:
			return 'h6';
		default:
			return 'h2';
	}
};

const getFragment = (
	fragment: FragmentModelTypes,
	index: number,
	backgroundColor?: 'blue' | 'white'
) => {
	switch (fragment.modelType) {
		case 'UrlFragment':
			const urlFragmentsData = fragment as UrlFragmentModel;
			return (
				<UrlFragment key={index} {...urlFragmentsData}>
					{urlFragmentsData.writtenBy && (
						<ul>
							{urlFragmentsData.writtenBy.map((text: string, index: number) => (
								<li key={index}>
									<p>{text}</p>
								</li>
							))}
						</ul>
					)}
				</UrlFragment>
			);
		case 'FactItemHtmlFragment':
			const blockFragmentData = fragment as FactItemHtmlFragmentModel;
			return (
				<FactItemHtmlFragment
					variant={blockFragmentData.type}
					{...(blockFragmentData.fragments && {
						fragments: blockFragmentData.fragments,
					})}
					key={index}
				>
					<FactTextContainer
						dangerouslySetInnerHTML={{ __html: blockFragmentData.raw }}
					/>
				</FactItemHtmlFragment>
			);

		case 'HeadingFragment':
			const headingFragmentData = fragment as HeadingFragmentModel;
			return (
				<Text as={getHeadingLevel(headingFragmentData.level)} key={index}>
					<AnchorSpace id={headingFragmentData.id}></AnchorSpace>
					<span dangerouslySetInnerHTML={{ __html: headingFragmentData.raw }} />
				</Text>
			);
		case 'Image':
			const imgData = fragment as ImageFragmentModel;

			return (
				<Image
					widthAuto={true}
					src={imgData.src}
					alt={imgData.alt}
					prop={imgData.prop}
					figCaption={imgData.caption}
					key={index}
				/>
			);
		case 'VideoFragment':
			const videoData = fragment as VideoFragmentModel;
			return (
				<Space bottom={1} key={index}>
					<Video
						optionsHeading={videoData.translations.optionsHeading}
						captions={videoData.translations.captions}
						audioDescription={videoData.translations.audioDescription}
						signLanguage={videoData.translations.signLanguage}
						{...videoData}
					/>
				</Space>
			);
		case 'TableFragment':
			const tableData = fragment as TableFragmentModel;
			return (
				<Space bottom={2.5} top={2.5} key={index}>
					<ScrollableTableWrapper
						dangerouslySetInnerHTML={{ __html: tableData.raw }}
					/>
				</Space>
			);
		case 'FormContainer':
			const formContainer = fragment as EpiFormsProps;

			return (
				<Space bottom={2.5} top={2.5} key={index}>
					<EpiForms {...formContainer} />
				</Space>
			);
		case 'RawFragment':
			const rawFragment = fragment as RawFragmentModel;
			return (
				<RichText key={index} backgroundColor={backgroundColor}>
					<div
						dangerouslySetInnerHTML={{
							__html: rawFragment.raw,
						}}
					/>
				</RichText>
			);
		case 'ListFragment':
			return (
				<RichText key={index}>
					<div
						key={index}
						dangerouslySetInnerHTML={{
							__html: (fragment as ListFragmentModel).raw,
						}}
					/>
				</RichText>
			);
		case 'AccordionHtmlFragment':
			return (
				<AccordionHtmlFragment
					{...(fragment as AccordionHtmlFragmentModel)}
					key={index}
				/>
			);

		case 'BarChartFragment':
			return (
				<BarChartBlock
					{...(fragment as BarChartFragmentModel).chartData}
					key={index}
					renderedAsFragment={true}
				/>
			);
		case 'LineChartFragment':
			return (
				<LineChartBlock
					{...(fragment as LineChartFragmentModel).chartData}
					key={index}
					renderedAsFragment={true}
				/>
			);
		case 'PieChartFragment':
			return (
				<PieChartBlock
					{...(fragment as PieChartFragmentModel).chartData}
					key={index}
					renderedAsFragment={true}
				/>
			);
		default:
			return null;
	}
};

/** Component for rendering Episerver fragments. */
const EpiFragments: React.FC<Props> = ({
	fragments,
	backgroundColor,
	...htmlAttributes
}) => {
	return (
		<div {...htmlAttributes}>
			{fragments?.map((fragment, index) => {
				return getFragment(fragment, index, backgroundColor);
			})}
		</div>
	);
};

export default EpiFragments;
