/**
 * SubscriptionBlock - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { H6, H1, P } from 'components/Text/Text.styles';
import { Form } from 'components/Form';

type StyleProps = {
	$success?: boolean;
};

const commonStyle = css`
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.normal};
`;

export const Container = styled.div`
	color: ${colors.black};
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: ${px2rem(118)} 0;
	width: 100%;

	@media (max-width: 1040px) {
		padding: 6rem 0;
	}

	${mediaQueries.phone} {
		padding: 0;
	}
`;

export const TextColumn = styled.div`
	display: flex;
	flex: 1;

	${mediaQueries.phone} {
		padding: 2rem;
		text-align: center;
	}
`;

export const TextColumnInner = styled.div`
	max-width: ${px2rem(618)};
`;

export const Heading = styled(H1)`
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.normal};
	font-size: ${px2rem(44)};
	line-height: ${px2rem(54)};
	margin-bottom: ${px2rem(20)};
`;

export const SubHeading = styled(H6)`
	${commonStyle};
	color: ${colors.blueDark};
	text-transform: uppercase;
	font-size: ${px2rem(14)};
	line-height: ${px2rem(15)};
	letter-spacing: ${px2rem(1.4)};
	margin-bottom: ${px2rem(10)};

	${mediaQueries.phoneOrTablet} {
		margin-bottom: 0.25rem;
	}
`;

export const Text = styled.p`
	${commonStyle};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(24)};
	margin-bottom: ${px2rem(21)};
	color: ${colors.black};

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(16)};
		line-height: ${px2rem(24)};
	}
`;

export const SubscribeContainer = styled.div`
	display: flex;
	flex: 1;

	${mediaQueries.desktop} {
		margin: 2rem 2rem 0 2rem;
	}

	${mediaQueries.phoneOrTablet} {
		flex-wrap: wrap;
		width: 100%;
		padding: 2rem;
	}
`;

export const SubscribeForm = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;

	input,
	select {
		border: 0;
		color: ${colors.black};
		border: ${px2rem(1)} solid ${colors.greyDark};
		font-size: ${px2rem(16)};
		height: 3.5rem;
		padding: 1rem 4rem 1rem 1.5rem;
		width: 100%;
		font-weight: ${typography.fontWeight.light};
		font-family: ${typography.fontFamily.alt};

		::placeholder {
			color: ${colors.greyDark};
		}

		&:focus {
			box-shadow: 0 0 0 ${px2rem(3)} ${colors.focusColor};
			outline: none;
		}

		${mediaQueries.tablet} {
			width: 80%;
		}
		${mediaQueries.phone} {
			width: 100%;
		}
	}
`;

export const SubscribeGroupItem = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1rem;
	position: relative;
`;

export const SubscribeInputContainer = styled.div`
	display: flex;
`;

export const SubscribeLabel = styled.label`
	color: ${colors.greyDark};
	font-size: ${px2rem(14)};
	font-family: ${typography.fontFamily.alt};

	${mediaQueries.desktop} {
		margin-bottom: 0.5rem;
	}
	${mediaQueries.phoneOrTablet} {
		margin-bottom: 1rem;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-bottom: 1rem;
`;

export const Link = styled.a`
	display: flex;
	align-items: center;
	text-decoration: none;
	color: inherit;
	min-height: 1.5rem;
	height: 100%;
	text-decoration: none;
	color: ${colors.linkColorDark};

	&:hover {
		text-decoration: underline;
	}
`;

export const MessageText = styled(P)`
	font-size: ${px2rem(15)};
	letter-spacing: 0;
	line-height: ${px2rem(32)};
	margin: 0;
	padding: 1rem 2rem;
`;

export const MessageArea = styled.div<StyleProps>`
	position: relative;
	background-color: ${colors.white};

	&:before {
		background-color: ${(props) =>
			props.$success ? colors.green : colors.errorColor};
		content: ' ';
		height: 100%;
		position: absolute;
		left: 0;
		width: ${px2rem(7)};
	}
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
	position: absolute;
	left: 3px;
	top: 12px;
	opacity: 0;
	margin: 0;
	outline: none;
	padding: 0 !important;
	height: auto !important;
	width: auto !important;
	box-shadow: none !important;
`;
