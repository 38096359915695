/**
 * RegisterCaseList
 */

import React from 'react';

import { HeadingContent, HeadingText } from './RegisterCaseList.styles';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';

interface Props {}

/** Main description for this component. */
const RegisterCaseListHeading: React.FC<Props> = ({}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.desktop - 1,
	});

	return (
		<HeadingContent aria-labelledby={`CaseBox-heading`} tabIndex={-1}>
			<HeadingText>Diarienr</HeadingText>

			{!isPhone && <HeadingText>Status</HeadingText>}

			{!isPhone && <HeadingText>Beslut</HeadingText>}

			<HeadingText>Ärendemening</HeadingText>

			<HeadingText>Ärendetyp</HeadingText>

			{!isPhone && <HeadingText>Reg. datum</HeadingText>}

			{!isPhone && <HeadingText>Avsändare/mottagare</HeadingText>}
		</HeadingContent>
	);
};

export default RegisterCaseListHeading;
