/**
 * BlockContainer
 */

import React from 'react';

import { BlockWrapper } from './BlockContainer.styles';
import { Colors } from '../../theme/colors';

type Props = {
	/** Block item */
	children?: any;

	/** Backgroundcolor */
	backgroundColor?:
		| keyof Colors
		| 'blue'
		| 'blueDark'
		| 'white'
		| 'lightBlue'
		| 'transparent'
		| 'offBlueLightest';

	/** Border bottom */
	withBorder?: boolean;

	/** Less padding on desktop */
	lessPadding?: boolean;

	/** No padding at all */
	noPadding?: boolean;
};

/** BaseLayout for blocks that sets max-width on inner component. */
const BlockContainer: React.FC<Props> = ({
	children,
	backgroundColor,
	withBorder,
	lessPadding,
	noPadding,
}) => (
	<BlockWrapper bgColor={backgroundColor} $withBorder={withBorder}>
		<BlockWrapper
			bgColor={backgroundColor}
			inner={true}
			lessPadding={lessPadding}
			noPadding={noPadding}
		>
			{children}
		</BlockWrapper>
	</BlockWrapper>
);

export default BlockContainer;
