/**
 * CalendarPage
 */

import React from 'react';
import { CalendarPageModel } from 'types/pages';
import Text from 'components/Text';
import Space from 'components/Space';
import EpiFragments from 'components/EpiFragments';
import EpiContentArea from 'components/EpiContentArea';
import Breadcrumb from 'components/Breadcrumb';
import PageLayout from 'layouts/PageLayout';
import { Grid } from 'components/Grid';
import {
	FragmentsContainer,
	LeftCell,
	RightCell,
	DateTime,
	LocationsArea,
	Location,
	LastModifiedDate,
	StyledButton,
	FormContainer,
	FormSubText,
	StyledPreamble,
	FormHeading,
} from './CalendarPage.styles';
import BlockContainer from 'layouts/BlockLayout';

/** CalendarPage. */
const CalendarPage: React.FC<CalendarPageModel> = ({
	heading,
	preamble,
	breadcrumbs,
	text,
	date,
	location,
	lastModifiedDate,
	translations,
	contentArea,
	organizer,
	bookable,
	formBackgroundColor = 'lightBlue',
	_properties = {},
}) => (
	<main id="main-content">
		{breadcrumbs && (
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>
		)}

		<PageLayout labelledBy="article-heading">
			<Grid inner={true}>
				<LeftCell span={7} phone={12}>
					<Text as="h1" {..._properties?.heading} id="article-heading">
						{heading}
					</Text>

					{(_properties?.preamble || preamble) && (
						<StyledPreamble {..._properties?.preamble}>
							{preamble}
						</StyledPreamble>
					)}

					<FragmentsContainer>
						<EpiFragments {..._properties?.text} fragments={text?.fragments} />
					</FragmentsContainer>

					<LastModifiedDate>{`${translations.latestUpdated}: ${lastModifiedDate}`}</LastModifiedDate>
				</LeftCell>
				<RightCell span={5}>
					<LocationsArea>
						<Location>{location}</Location>
						<DateTime>{date}</DateTime>
						{bookable && contentArea && (
							<StyledButton href="#formcontainer" type="link" icon="none">
								{translations.bookButtonLabel}
							</StyledButton>
						)}
					</LocationsArea>
				</RightCell>
			</Grid>
		</PageLayout>
		{bookable && (
			<BlockContainer backgroundColor={formBackgroundColor} noPadding={true}>
				<FormContainer id="formcontainer">
					<Space bottom={1.5}>
						<FormHeading as="h2">{translations.formHeadingLabel}</FormHeading>
					</Space>
					<Space bottom={2.5}>
						{organizer && (
							<FormSubText>{`${translations.organizerLabel}: ${organizer}`}</FormSubText>
						)}
						<FormSubText>{`${translations.dateLabel}: ${date}`}</FormSubText>
						<FormSubText>{`${translations.locationLabel}: ${location}`}</FormSubText>
					</Space>
					<EpiContentArea {..._properties?.contentArea} content={contentArea} />
				</FormContainer>
			</BlockContainer>
		)}
	</main>
);

export default CalendarPage;
