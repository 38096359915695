/**
 * SearchPage
 */

import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { useUrlQuery, useDebounce, useSearchResult } from 'hooks';
import { getActiveFilters } from 'common/helpers';

import { SearchPageModel } from 'types/pages';
import { LinkType } from 'types/global';
import { SearchResultItem } from 'types/listings';

import { Content, ResultsContainer } from './SearchPage.styles';

import LoadMorePagination from 'components/LoadMorePagination';

import {
	SearchResultsList,
	SearchResultsItem,
	ResultsBreadcrumb,
} from 'components/SearchResultsList';
import Breadcrumb from 'components/Breadcrumb';
import SearchForm from 'components/SearchForm';

/** Template for a search page. */
const SearchPage: React.FC<SearchPageModel> = ({
	breadcrumbs,
	filter,
	pagination,
	items,
	translations,
	resultsPhrase,
	_properties = {},
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const [action, setAction] = useState('');
	const activeFilters = getActiveFilters(filter);
	const [values, setValues] = useState<any>({
		query: filter.query || '',
		...activeFilters,
	});

	const [currentPage, setCurrentPage] = useState<number>(
		pagination.currentPage
	);

	const debouncedInput = useDebounce(values.query, 500);

	const [updateQuery, response, loading, error] = useUrlQuery({
		...values,
		query: debouncedInput,
		page: currentPage,
	});

	const [
		filters,
		paginations,
		results,
		responseResultsPhrase,
	] = useSearchResult(
		filter,
		pagination,
		items,
		response,
		action,
		resultsPhrase
	);

	const paginationPhrase = translations.paginationPhrase.split('{0}');

	return (
		<main id="main-content">
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>

			<SearchForm
				translations={{
					searchHeading: translations.searchHeading,
					searchButtonLabel: translations.searchButtonLabel,
					searchFieldLabel: translations.searchFieldLabel,
					searchFieldPlaceholder: translations.searchFieldPlaceholder,
					errorText: translations.errorText,
					sortBy: translations.sortBy,
					clearFilterText: translations.clearFilterText,
					clearAllFiltersText: translations.clearAllFiltersText,
					filterHeading: translations.filterHeading,
					hiddenSubmitText: translations.hiddenSubmitText,
				}}
				resultsPhrase={responseResultsPhrase}
				headerType="h1"
				_properties={_properties}
				isPhone={isPhone}
				loading={loading}
				error={error}
				filter={filters}
				pagination={paginations}
				values={values}
				handleSetAction={setAction}
				handleSetCurrentPage={setCurrentPage}
				handleSetValues={setValues}
				handleUpdateQuery={updateQuery}
				displaySearchHeader
				displaySortWrapper
				displayHiddenSubmitButton={false}
			/>

			<Content>
				<ResultsContainer id="search-list">
					{results && results.length > 0 && (
						<SearchResultsList>
							{results.map((item: SearchResultItem, i: number) => (
								<SearchResultsItem {...item} key={i}>
									{item.breadcrumbs?.map((crumb: LinkType, i: number) => (
										<ResultsBreadcrumb {...crumb} key={i} />
									))}
								</SearchResultsItem>
							))}
						</SearchResultsList>
					)}
				</ResultsContainer>

				<LoadMorePagination
					visible={paginations.currentPage < paginations.pageCount}
					loading={loading}
					buttonText={translations.showMoreButtonLabel}
					totalDisplay={paginations.totalDisplay}
					totalHits={paginations.total}
					paginationPhrase={paginationPhrase}
					onClick={() => {
						setCurrentPage((page) => page + 1);
						updateQuery({ ...values, page: currentPage + 1 });
						setAction('add');
					}}
				/>
			</Content>
		</main>
	);
};

export default SearchPage;
