/**
 * SitemapPage - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, typography, mediaQueries } from 'theme';
import Link from 'components/Link';
import { H1 } from 'components/Text';

const siteLinkCommonStyle = css`
	text-decoration: none;
	color: ${colors.linkColorDark};
	font-family: ${typography.fontFamily.alt};
	:hover {
		text-decoration: underline;
	}
	margin-left: 4rem;
`;

export const Content = styled.article`
	margin: 4rem 0;

	@media (max-width: 1024px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	${mediaQueries.phone} {
		margin: 2rem 0rem;
		padding: 0;
	}
`;

export const UlList = styled.ul`
	${mediaQueries.phone} {
		padding-left: 0;
		margin-left: ${px2rem(15)};
	}
`;

export const BorderedUl = styled.ul`
	position: relative;
	${mediaQueries.phone} {
		padding-left: ${px2rem(15)};
	}
`;

export const BorderOuterLeft = styled.div`
	border-left: ${px2rem(1)} solid ${colors.greyLight};
	width: ${px2rem(1)};
	position: absolute;
	top: ${px2rem(15)};
	bottom: 0;
`;

export const BorderInnerLeft = styled.div`
	border-left: ${px2rem(1)} solid ${colors.greyLight};
	width: ${px2rem(1)};
	position: absolute;
	top: ${px2rem(5)};
	left: ${px2rem(80)};
	bottom: 0;

	${mediaQueries.phone} {
		left: ${px2rem(20)};
	} ;
`;

export const ListItem = styled.li`
    list-style: none;
}`;

export const SiteLink = styled(Link)`
	${siteLinkCommonStyle}
	font-size: ${px2rem(16)};
	line-height: ${px2rem(29)};
	margin-left: ${px2rem(95)};

	${mediaQueries.phone} {
		margin-left: ${px2rem(25)};
		padding: 0;
		font-size: ${px2rem(16)};
		text-decoration: underline;
	}

	${mediaQueries.tablet} {
		text-decoration: underline;
	}
`;

export const SiteHeading = styled(H1)`
	margin: 0rem 0rem 4rem 2rem;
	font-size: ${px2rem(46)};
	line-height: ${px2rem(56)};
	font-weight: ${typography.fontWeight.normal};

	${mediaQueries.phone} {
		font-size: ${px2rem(28)};
		margin: ${px2rem(15)};
		padding: 0;
	}
`;

export const SiteSubHeading = styled(Link)`
	${siteLinkCommonStyle};
	font-size: ${px2rem(22)};
	line-height: ${px2rem(36)};
	margin-left: ${px2rem(-16)};

	&::before {
		display: inline-block;
		content: '';
		border-top: 0.1rem solid ${colors.greyLight};
		width: ${px2rem(50)};
		margin: 0 1rem;
		transform: translateY(${px2rem(-9)});
	}

	${mediaQueries.phone} {
		margin-left: ${px2rem(-5)};
		padding: 0;
		font-size: ${px2rem(24)};
		text-decoration: underline;

		&::before {
			display: inline-block;
			content: '';
			border-top: 0.1rem solid ${colors.greyLight};
			width: ${px2rem(10)};
			margin: 0 0.3rem;
			transform: translateY(${px2rem(-8)});
		}
	}

	${mediaQueries.tablet} {
		text-decoration: underline;
	}
`;

export const SiteLowLevelHeading = styled(Link)`
	${siteLinkCommonStyle}
	font-size: ${px2rem(18)};
	line-height: ${px2rem(29)};
	margin-left: ${px2rem(25)};

	&::before {
		display: inline-block;
		content: '';
		border-top: 0.1rem solid ${colors.greyLight};
		width: ${px2rem(30)};
		margin: 0 1rem;
		transform: translateY(${px2rem(-5)});
	}

	${mediaQueries.phone} {
		margin: 0rem;
		padding: 0;
		font-size: ${px2rem(16)};
		margin-left: 0;
		text-decoration: underline;

		&::before {
			display: inline-block;
			content: '';
			border-top: 0.1rem solid ${colors.greyLight};
			width: ${px2rem(10)};
			margin: 0 0.3rem;
			transform: translateY(${px2rem(-5)});
		}
	}

	${mediaQueries.tablet} {
		text-decoration: underline;
	}
`;
