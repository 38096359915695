/**
 * TextBlock - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, typography, mediaQueries } from 'theme';
import { H1, Preamble } from 'components/Text/Text.styles';

export interface ThemeProps {
	headingAlignment?: 'left' | 'center';
	blockAlignment?: 'left' | 'center';
	backgroundColor?:
		| 'lightBlue'
		| 'yellowLighter'
		| 'greyLighter'
		| 'white'
		| 'transparent';
}

export interface BlockProps {
	blockAlignment?: 'left' | 'center';
}

const commonStyle = css`
	color: ${colors.black};
	font-family: ${typography.fontFamily.alt};
	font-size: ${typography.baseFontSize};
`;

export const WrapperContainer = styled.div<ThemeProps>`
	background-color: ${(props) =>
		props.backgroundColor
			? `${colors[props.backgroundColor]};`
			: `${colors.white};`};
`;

export const OuterContainer = styled.div<ThemeProps>`
	max-width: 90rem;
	margin: 0rem auto;
	padding: 3rem 2.25rem;

	@media (max-width: 1440px) {
		margin: 0;
	}

	${mediaQueries.phone} {
		padding: 3rem 1rem;
		margin: 0;
	}
`;

export const Container = styled.div<ThemeProps>`
	${commonStyle};
	display: flex;
	flex-direction: column;
	max-width: 90rem;

	background-color: ${(props) =>
		props.backgroundColor
			? `${colors[props.backgroundColor]};`
			: `${colors.white};`}

	align-items: ${({ blockAlignment }) =>
		blockAlignment === 'left' ? 'start' : 'center'};
`;

export const TextContainer = styled.div`
	${commonStyle};
	max-width: ${px2rem(788)};

	${mediaQueries.tablet} {
		max-width: ${px2rem(645)};
	}

	p {
		font-weight: ${typography.fontWeight.normal};
	}
`;

export const StyledPreamble = styled(Preamble)`
	margin: 0 0 3.5rem;
	font-weight: ${typography.fontWeight.medium} !important;
`;

export const Heading = styled(H1)<ThemeProps>`
	${commonStyle};
	padding-top: 1.2rem;
	margin-top: -0.2rem;
	margin-bottom: 2rem;
	text-align: ${({ headingAlignment }) =>
		headingAlignment === 'left' ? 'left' : 'center'};
`;
