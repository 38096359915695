/**
 * RegisterDetailsPage
 */

import React from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumb from 'components/Breadcrumb';
import { RegisterDetailsPageModel } from 'types/pages';
import {
	Back,
	Case,
	CaseContainer,
	CaseHeader,
	CaseLeft,
	CaseRight,
	CaseRow,
	CaseText,
	Heading,
	HeadingContainer,
	HeadingHeader,
	HeadingLeft,
	PdfLink,
} from './RegisterDetailsPage.styles';
import Button from 'components/Button';

/** Main description for this component. */
const RegisterDetailsPage: React.FC<RegisterDetailsPageModel> = ({
	breadcrumbs,
	heading,
	caseNumber,
	subject,
	type,
	department,
	counterpart,
	status,
	arrivalDate,
	registerDate,
	decisionDate,
	expiryDate,
	decisionLink,
	summaryLink,
	translations,
}) => {
	const history = useHistory();
	return (
		<main id="main-content">
			<Breadcrumb
				links={breadcrumbs}
				label={translations?.breadcrumbsLabel}
				withBorder
			/>
			<Heading>
				<HeadingContainer>
					<HeadingLeft>
						<HeadingHeader>{heading}</HeadingHeader>
					</HeadingLeft>
				</HeadingContainer>
			</Heading>
			<Case>
				<CaseContainer>
					<CaseRow>
						<CaseLeft>
							<CaseHeader>{translations?.caseNumber}</CaseHeader>
							<CaseText>{caseNumber}</CaseText>
							<CaseHeader>{translations?.subject}</CaseHeader>
							<CaseText>{subject}</CaseText>
							<CaseHeader>{translations?.type}</CaseHeader>
							<CaseText>{type}</CaseText>
							<CaseHeader>{translations?.department}</CaseHeader>
							<CaseText>{department}</CaseText>
							<CaseHeader>{translations?.counterpart}</CaseHeader>
							<CaseText>{counterpart}</CaseText>
						</CaseLeft>
						<CaseRight>
							<CaseHeader>{translations?.status}</CaseHeader>
							<CaseText>{status}</CaseText>
							<CaseHeader>{translations?.arrivalDate}</CaseHeader>
							<CaseText>{arrivalDate}</CaseText>
							<CaseHeader>{translations?.registerDate}</CaseHeader>
							<CaseText>{registerDate}</CaseText>
							<CaseHeader>{translations?.decisionDate}</CaseHeader>
							<CaseText>{decisionDate}</CaseText>
							<CaseHeader>{translations?.expiryDate}</CaseHeader>
							<CaseText>{expiryDate}</CaseText>
						</CaseRight>
					</CaseRow>
					<CaseRow>
						{decisionLink && (
							<PdfLink href={decisionLink} target="_blank">
								{translations?.decisionLink}
							</PdfLink>
						)}
						{summaryLink && (
							<PdfLink href={summaryLink} target="_blank">
								{translations?.summaryLink}
							</PdfLink>
						)}
					</CaseRow>
				</CaseContainer>
				<Back>
					<Button
						variant="transparent"
						type="button"
						icon="arrowRight"
						iconPosition="left"
						onClick={() => history.goBack()}
					>
						Tillbaka
					</Button>
				</Back>
			</Case>
		</main>
	);
};

export default RegisterDetailsPage;
