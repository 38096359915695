import axios from 'axios';

export const getEpiPage = async (apiUrl: string, route: string) => {
	const appendUrl = '.json';

	if (route === '') {
		route = `index${appendUrl}`;
	} else if (route.indexOf('?') > -1) {
		let index = route.indexOf('?');
		let query = route.substring(index);
		route = route.substring(0, index);

		if (route.endsWith('/')) {
			route += `index${appendUrl}`;
		} else {
			route += appendUrl;
		}

		route += query;
	} else {
		route += appendUrl;
	}

	const url = `${apiUrl}${route}`;
	const { data } = await axios({
		url,
		method: 'GET',
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'content-type': 'application/json; charset=utf-8',
		},
	});
	return data;
};
