/**
 * LinkListColumnBlock
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import {
	Container,
	LinkBlockContainer,
	LinkItemBlockContainerCell,
	Heading,
} from './LinkListColumnBlock.styles';
import { Grid } from 'components/Grid';
import BlockContainer from 'layouts/BlockLayout';

interface Props {
	/** Block heading */
	heading?: string;

	/** Background color */
	backgroundColor: 'lightBlue' | 'yellowLighter' | 'greyLighter' | 'white';

	/** Heading align */
	headingAlignment: 'left' | 'center';

	/** LinkListColumnBlock item list */
	children?: any[];
}

/** LinkListColumnBlock. */
const LinkListColumnBlock: React.FC<Props> = ({
	heading,
	backgroundColor = 'lightBlue',
	headingAlignment = 'left',
	children,
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	if (!children) {
		return null;
	}

	const span = (children.length === 1 && 12) || (children.length > 2 ? 4 : 6);

	const renderChildren = () => {
		return (
			<Grid inner={true}>
				{React.Children.map(children, (child, i) => (
					<LinkItemBlockContainerCell span={isPhone ? 12 : span} key={i}>
						{React.cloneElement(child, child.props)}
					</LinkItemBlockContainerCell>
				))}
			</Grid>
		);
	};

	return (
		<BlockContainer backgroundColor={backgroundColor}>
			<Container background={backgroundColor}>
				<LinkBlockContainer>
					{heading && (
						<Heading
							background={backgroundColor}
							headingAlignment={headingAlignment}
						>
							{heading}
						</Heading>
					)}

					{children && renderChildren()}
				</LinkBlockContainer>
			</Container>
		</BlockContainer>
	);
};

export default LinkListColumnBlock;
