/**
 * PageHeader - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, typography, mediaQueries } from 'theme';
import { lighten } from 'polished';
import Icon from 'components/Icon';

export const linkCss = css`
	font-size: ${px2rem(15)};
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.medium};
	color: ${colors.greyDark};
	text-decoration: none;
`;

export const Header = styled.header`
	background-color: ${colors.white};
	border-bottom: ${px2rem(1)} solid ${colors.greyLighter};
	position: sticky;
	top: 0;
	z-index: 99;
`;

export const InnerWrapper = styled.div`
	max-width: ${px2rem(1920)};
	margin: 0 auto;
	display: flex;
	justify-content: space-between;

	${mediaQueries.desktop} {
		padding: 0 ${px2rem(36)};
	}
`;

export const LogoLink = styled.a`
	padding: ${px2rem(20)} 0;

	&:focus {
		background-color: ${colors.blueLighter};
		box-shadow: none;
	}

	${mediaQueries.phoneOrTablet} {
		margin-left: 1.25rem;
		margin-right: 1rem;
	}
`;

export const Logo = styled.img`
	height: ${px2rem(50)};

	${mediaQueries.phoneOrTablet} {
		height: ${px2rem(37)};
	}
`;

const linkItemCss = css`
	display: flex;
	align-items: center;
	height: 100%;
	border: none;
	border-left: ${px2rem(1)} solid ${colors.greyLightest};
	padding: 0 2rem;
	transition: all 0.2s;

	${mediaQueries.tabletOrLarger} {
		&:hover,
		&:focus {
			background-color: ${colors.blueLighter};
		}
	}

	&:focus {
		box-shadow: none;
	}

	${mediaQueries.phoneOrTablet} {
		padding: 0 ${px2rem(25)};
	}
`;

export const LinkItem = styled.a<StyledProps>`
	${linkCss}
	${linkItemCss}
	background-color: ${({ active }) =>
		active ? colors.blueLighter : colors.white};
	&:hover,
	&:focus {
		background-color: ${colors.blueLightest};
	}
	cursor: pointer;
`;

export const LinkWrapper = styled.div`
	height: 100%;

	a {
		${linkCss}
		${linkItemCss}
		background-color: ${colors.white};
	}
`;

export const LinkList = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;

	li {
		&:last-child {
			${LinkItem} {
				border-right: ${px2rem(1)} solid ${colors.greyLightest};
			}
		}
	}

	#languages {
		${mediaQueries.phoneOrTablet} {
			display: none;
		}
	}
`;

export const ListItem = styled.li`
	@media (min-width: 840px) {
		min-width: ${px2rem(175)};
	}
	> * {
		width: 100%;
		justify-content: center;
	}
`;

interface StyledProps {
	active?: boolean;
	readLang?: string;
}

export const LinkIcon = styled(Icon)`
	margin-left: 1.5rem;
	height: ${px2rem(20)};
	width: ${px2rem(20)};

	${mediaQueries.phone} {
		margin: 0;
	}
`;

export const LanguagesIcon = styled(Icon)`
	margin-left: 1.5rem;
	height: ${px2rem(20)};
	width: ${px2rem(20)};
`;

export const MenuIcon = styled(LinkIcon)`
	height: ${px2rem(12)};
	width: ${px2rem(16)};
`;

export const IconWrapper = styled.span`
	display: inline-flex;
	background-color: ${colors.white};
	height: 2.25rem;
	width: 2.25rem;
	border-radius: 50%;
	padding: ${px2rem(9)};
	position: absolute;
	top: ${px2rem(5)};
	right: ${px2rem(5)};
`;

export const SuggestionButton = styled.a`
	${linkCss}
	padding: ${px2rem(14)} ${px2rem(53)} ${px2rem(14)} ${px2rem(17)};
	background-color: ${colors.yellow};
	border-radius: ${px2rem(25.5)};
	transition: all 0.2s;
	position: relative;
	display: block;

	&:hover {
		background-color: ${lighten(0.2, colors.yellow)};
	}

	${mediaQueries.tabletOrLarger} {
		margin: ${px2rem(20)} 0;
		margin-right: 2rem;
	}
`;

export const SuggestionIcon = styled(Icon)`
	height: ${px2rem(20)};
	width: ${px2rem(20)};
`;

export const HiddenMobile = styled.div`
	${mediaQueries.phoneOrTablet} {
		border: 0;
		clip: rect(0 0 0 0);
		height: 0.1rem;
		margin: -0.1rem;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 0.1rem;
	}
`;
