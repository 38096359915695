/**
 * JobPage - styles
 */

import styled from 'styled-components';
import { typography, colors, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { P, H3 } from 'components/Text';
import Link from 'components/Link';

export const FragmentsContainer = styled.div`
	font-family: ${typography.fontFamily.alt};
	line-height: ${px2rem(32)};
`;

export const ReadMoreLabel = styled.span`
	color: ${colors.blueDark};
	font-size: ${px2rem(15)};
	font-weight: ${typography.fontWeight.medium};
	margin: 0 ${px2rem(15)};

	${mediaQueries.phoneOrTablet} {
		display: none;
	}
`;

export const Article = styled.article`
	font-family: ${typography.fontFamily.alt};
	border-top: ${px2rem(1)} solid ${colors.greyLighter};
	padding: ${px2rem(15)} 0;

	&:hover {
		${ReadMoreLabel} {
			text-decoration: underline;
		}
	}
`;

export const ArticleHeading = styled(H3)`
	font-weight: ${typography.fontWeight.medium};
	margin-top: 0;

	${mediaQueries.phone} {
		font-size: ${px2rem(18)};
		line-height: ${px2rem(28)};
		letter-spacing: ${px2rem(-0.14)};
	}
`;

export const ArticleSubHeading = styled.div`
	font-size: ${px2rem(14)};
	font-weight: ${typography.fontWeight.medium};
	letter-spacing: 0;
	line-height: ${px2rem(20)};
	display: flex;
	flex-flow: wrap;

	p {
		> &:not:(:last-child) {
			&:after {
				content: '|';
				margin: 0 ${px2rem(10)};
				color: ${colors.grey};
			}
		}
	}
`;

export const ArticleSubText = styled(P)`
	margin-bottom: 0;
	color: ${colors.grey};

	&:after {
		content: '|';
		margin: 0 ${px2rem(10)};
		color: ${colors.grey};
	}

	&:last-child:after {
		content: '';
	}
`;

export const ArticleLink = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-decoration: none;
	color: ${colors.black};
	position: relative;

	${mediaQueries.phone} {
		margin-top: 0;
	}
`;

export const LeftRow = styled.div`
	max-width: ${px2rem(780)};
`;

export const RightRow = styled.div`
	display: flex;
	align-items: center;
`;

export const IconBadge = styled.div`
	align-items: center;
	background-color: ${colors.greyLightest};
	border-radius: 1.5rem;
	display: flex;
	height: 3rem;
	width: 3rem;
	justify-content: center;
	margin-right: 1rem;

	:last-child {
		margin-right: 0;
	}

	svg {
		color: ${colors.blueDark};
		height: ${px2rem(13)};
		width: ${px2rem(13)};
		margin-left: 0;
	}
`;

export const TopArea = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${mediaQueries.phone} {
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 1rem;
	}
`;
