import React from 'react';
import EpiContentArea from '../../components/EpiContentArea';
import { InformationPageModel } from '../../types/pages';
import styled from 'styled-components';
import { colors, mediaQueries, typography } from '../../theme';
import { StyledPreamble } from '../../components/TextBlock/TextBlock.styles';
import { Colors } from '../../theme/colors';
import { px2rem } from '../../theme/utils';
import MediaBlock from '../../components/MediaBlock/MediaBlock';
import Breadcrumb from '../../components/Breadcrumb';
import CampaignNavigation from '../../components/CampaignNavigation/CampaignNavigation';

export const InformationPageSubheading = styled.p`
	font-size: ${px2rem(64)};
	line-height: ${px2rem(68)};
	font-family: 'Euclid Circular B', sans-serif;
	font-weight: ${typography.fontWeight.light};
	margin: 0;
	${mediaQueries.phone} {
		font-size: ${px2rem(24)};
		line-height: ${px2rem(32)};
	}
`;

export const InformationPageHeading = styled.h1`
	font-size: ${px2rem(88)};
	line-height: ${px2rem(94)};
	font-weight: ${typography.fontWeight.bold};
	font-family: 'Euclid Circular B', sans-serif;
	margin-top: 0;
	margin-bottom: 2rem;
	${mediaQueries.phone} {
		font-size: ${px2rem(36)};
		line-height: ${px2rem(44)};
	}
`;

const InformationPageContainer = styled.div<{
	$hasMediaBlock?: boolean;
	$plateColor: keyof Colors;
}>`
	display: flex;
	flex-direction: column;
	padding-top: 4rem;
	padding-bottom: 0;
	padding-inline: 1rem;
	${mediaQueries.phone} {
		background: ${(props) =>
			props.$hasMediaBlock
				? `linear-gradient(to bottom, ${
						colors[props.$plateColor]
				  } calc(100% - 180px), #fff 10%)`
				: colors[props.$plateColor]};
	}
	background: ${(props) =>
		props.$hasMediaBlock
			? `linear-gradient(to bottom, ${
					colors[props.$plateColor]
			  } calc(100% - 320px), #fff 10%)`
			: colors[props.$plateColor]};
`;

const MediaBlockWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 788px;
	max-width: 100%;
	margin-inline: auto;
	&& img {
		width: 788px;
		height: 457px;
		${mediaQueries.phone} {
			height: 280px;
		}
	}
`;

const InformationPageTextContainer = styled.div<{
	$hasMediaBlock?: boolean;
}>`
	max-width: ${px2rem(788)};
	${mediaQueries.tablet} {
		max-width: ${px2rem(645)};
	}
	margin-inline: auto;
	padding-bottom: 3rem;
`;

export const InformationPagePreamble = styled(StyledPreamble)<{
	$hasMediaBlock?: boolean;
}>`
	font-size: ${px2rem(22)};
	line-height: ${px2rem(32)};
	font-weight: ${typography.fontWeight.medium};
	margin-bottom: 0;

	${mediaQueries.phone} {
		font-size: ${px2rem(16)};
		line-height: ${px2rem(24)};
		font-weight: ${typography.fontWeight.normal};
	}
`;

const InformationPage: React.FC<InformationPageModel> = ({
	heading,
	subHeading,
	_properties,
	preamble,
	plateColor,
	contentArea,
	breadcrumbs,
	topContentArea,
	campaignNavigation,
}) => {
	const hasMediaBlock = topContentArea?.some(
		(item) => item.modelType === 'MediaBlock'
	);

	return (
		<main id="main-content">
			<CampaignNavigation
				links={campaignNavigation.children}
				{...campaignNavigation}
			>
				<Breadcrumb links={breadcrumbs} />
			</CampaignNavigation>
			<InformationPageContainer
				$hasMediaBlock={hasMediaBlock}
				$plateColor={plateColor}
			>
				<InformationPageTextContainer $hasMediaBlock={hasMediaBlock}>
					<InformationPageSubheading>{subHeading}</InformationPageSubheading>
					<InformationPageHeading>{heading}</InformationPageHeading>
					<InformationPagePreamble>{preamble}</InformationPagePreamble>
				</InformationPageTextContainer>
				{hasMediaBlock && (
					<MediaBlockWrapper>
						<MediaBlock insideTopContent={true} {...topContentArea[0]} />
					</MediaBlockWrapper>
				)}
			</InformationPageContainer>
			<EpiContentArea {..._properties?.contentArea} content={contentArea} />
		</main>
	);
};

export default InformationPage;
