/**
 * JobPage
 */

import React, { useState } from 'react';
import Breadcrumb from 'components/Breadcrumb';
import { JobPageModel } from 'types/pages';
import PageLayout from 'layouts/PageLayout';
import EpiContentArea from 'components/EpiContentArea';
import Text from 'components/Text';
import Button from 'components/Button';
import JobPageItem from './JobPageItem';
import { PositionsType } from 'types/global';
import { useUrlQuery } from 'hooks';

import { FragmentsContainer, TopArea } from './JobPage.styles';
import EpiFragments from 'components/EpiFragments';

const ASC_ORDER = 'ascending';
const DESC_ORDER = 'descending';

/** JobPage, listing positons from varbi. */
const JobPage: React.FC<JobPageModel> = ({
	breadcrumbs,
	heading,
	translations,
	contentArea,
	positions,
	text,
	order,
	_properties,
}) => {
	const [sort, setSort] = useState(order || ASC_ORDER);

	const [updateQuery, response] = useUrlQuery({
		order,
	});

	const positionsList = response?.positions || positions;

	const handleSort = () => {
		const newSort = sort === ASC_ORDER ? DESC_ORDER : ASC_ORDER;

		updateQuery({ order: newSort });
		setSort(newSort);
	};

	return (
		<main id="main-content">
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>

			<PageLayout labelledBy="job-heading">
				<TopArea>
					<Text as="h1" {..._properties?.heading} id="job-heading">
						{heading}
					</Text>

					{positionsList && (
						<Button
							variant="link"
							icon="arrowRight"
							direction={sort === DESC_ORDER ? 'down' : 'up'}
							onClick={handleSort}
						>
							{translations.positionsSortLabel}
						</Button>
					)}
				</TopArea>

				<FragmentsContainer>
					<EpiFragments {..._properties?.text} fragments={text?.fragments} />
				</FragmentsContainer>

				{positionsList?.map((position: PositionsType, index: number) => (
					<JobPageItem
						key={index}
						{...position}
						index={index}
						positionEndsLabel={translations.positionEndsLabel}
						positionTownLabel={translations.positionTownLabel}
						positionApplyLabel={translations.positionApplyLabel}
					/>
				))}
			</PageLayout>

			<EpiContentArea {..._properties?.contentArea} content={contentArea} />
		</main>
	);
};

export default JobPage;
