/**
 * MobileMenu Li
 */

import React, { useEffect } from 'react';
import Icon from 'components/Icon';
import { LinkType, MenuLinkVariant } from 'types/global';
import { ScreenReaderText } from 'components/Text';
import {
	Link,
	WIWLevel1,
	LiLevel1,
	LiLevel2,
	LiLevel3,
	IconButton,
} from './MobileMenu.styles';

interface LiProps {
	/** A menu link structured as JSON */
	link: LinkType;

	/** The link level (1, 2, 3) */
	level: number;

	/** Variant of the link */
	variant?: MenuLinkVariant;

	/** Callback for link onclick event. */
	onLinkClick: any;

	handleSetActive: any;
}

/** Returns the correct styled li for each level */
const getLiLevel = (level: number, isCampaignPage: boolean) => {
	switch (level) {
		case 1:
			return isCampaignPage ? WIWLevel1 : LiLevel1;
		case 2:
			return LiLevel2;
		case 3:
		default:
			return LiLevel3;
	}
};

/** A nav item (li) used by NavMobile */
const Li: React.FC<LiProps> = ({
	link,
	level,
	variant,
	onLinkClick,
	handleSetActive,
}) => {
	const linkLevel = variant === 'headerLink' ? 2 : level;
	const LiItem = getLiLevel(linkLevel, link.isCampaignPage);

	useEffect(() => {
		setTimeout(() => {
			if (link.active) {
				handleSetActive(link, level);
			}
		}, 200);
	}, []);

	return (
		<LiItem selected={link.selected}>
			<Link href={link.link} variant={variant}>
				{link.text}
			</Link>

			{link.children && link.children.length > 0 && (
				<IconButton onClick={(e: any) => onLinkClick(e, link)}>
					<ScreenReaderText>{`${link.text}`}</ScreenReaderText>
					<Icon size={0} icon="arrowRight" aria-hidden={true} />
				</IconButton>
			)}
		</LiItem>
	);
};

export default Li;
