/**
 * LinkListColumnBlock - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { H2 } from 'components/Text/Text.styles';
import { Cell } from 'components/Grid';

export interface ThemeProps {
	background?: 'lightBlue' | 'yellowLighter' | 'greyLighter' | 'white';
	headingAlignment?: 'left' | 'center';
}

export interface ContainerCellProps {
	noChildren?: number;
}

export const Container = styled.div<ThemeProps>`
	display: flex;
	flex-direction: column;
	padding: 3rem 0;

	${({ background }) => {
		if (background === 'lightBlue') {
			return `
                color: ${colors.white};
            `;
		} else {
			return `
                color: ${colors.black};
            `;
		}
	}};

	@media (max-width: 1040px) {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	${mediaQueries.phone} {
		padding: 3rem 0;
	}
`;

export const LinkBlockContainer = styled.div`
	${mediaQueries.tabletOrLarger} {
		width: 100%;
		margin: 0 auto;
	}

	a h3 {
		font-size: ${px2rem(28)};
		line-height: ${px2rem(36)};
	}
`;

export const Heading = styled(H2)<ThemeProps>`
	color: black;
	text-align: ${({ headingAlignment }) =>
		headingAlignment === 'left' ? 'left' : 'center'};
	margin: 0;
	padding-bottom: ${px2rem(32)};
`;

export const LinkItemBlockContainerCell = styled(Cell)<ContainerCellProps>``;

export const LinkItemBlockContainer = styled.div<ThemeProps>`
	background-color: ${colors.greyLightest};
`;
