// import fetch from 'unfetch';

/**
 * Submits an Episerver form
 */
export const submitForm = (
	values: any,
	stepIndex: number,
	dispatch: any,
	steps: any[],
	form: any
) => {
	const { currentStep, submissionId, action, method, redirectUrl } = form;

	const multiStep = steps.length > 2 && stepIndex !== steps.length - 2;
	const nextStep = multiStep ? stepIndex + 1 : currentStep + 1;

	values.set('__FormCurrentStepIndex', currentStep);
	values.set('__FormSubmissionId', submissionId);

	dispatch({
		type: 'UPDATE_STATE',
		payload: { isLoading: true, serverValidationError: false },
	});

	fetch(action, {
		body: values,
		cache: 'no-cache',
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
		},
		method,
		credentials: 'same-origin',
		redirect: 'follow',
		referrer: 'no-referrer',
	})
		.then((response) => {
			if (response.ok) {
				return response;
			} else {
				throw new Error(response.statusText);
			}
		})
		.then((response) => response.json())
		.then((response) => {
			return handleResponse(
				response,
				multiStep,
				nextStep,
				dispatch,
				redirectUrl
			);
		})
		.catch((error) => {
			const message =
				typeof error === 'string'
					? error
					: 'An error occurred, try again later';

			alert(message);
			dispatch({
				type: 'UPDATE_STATE',
				payload: { isLoading: false },
			});
		});
};

/**
 * Handles the submission response
 */
const handleResponse = (
	response: any,
	multiStep: boolean,
	nextStep: number,
	dispatch: any,
	redirectUrl: string
) => {
	dispatch({
		type: 'UPDATE_STATE',
		payload: {
			isLoading: false,
			invalidFields: [],
			validationMessages: {},
			currentStep: nextStep,
		},
	});

	if (!response.IsSuccess) {
		handleServerValidationError(
			response.AdditionalParams.__FormField,
			dispatch
		);
	}

	if (multiStep && response.IsSuccess) {
		dispatch({
			type: 'UPDATE_STATE',
			payload: {
				submissionId: response.Data ? response.Data.SubmissionId : '',
			},
		});
	} else if (response.Message && response.Message !== '') {
		if (
			(response.RedirectUrl && response.RedirectUrl.length > 0) ||
			(redirectUrl && redirectUrl.length > 0)
		) {
			window.location.href = response.RedirectUrl || redirectUrl;
			return;
		}

		dispatch({
			type: 'UPDATE_STATE',
			payload: { successMessage: response.Message },
		});
	}
};

/**
 * Sums up server validation errors with fields and validationmessages
 */
const handleServerValidationError = (fieldErrors: any[], dispatch: any) => {
	const invalidFields: string[] = [];
	const validationMessages: any = {};

	fieldErrors.forEach((field) => {
		invalidFields.push(field.InvalidElement);
		validationMessages[field.InvalidElement] = {
			label: field.InvalidElementLabel,
			message: field.ValidationMessage,
		};
	});

	dispatch({
		type: 'UPDATE_STATE',
		payload: {
			invalidFields,
			validationMessages,
			serverValidationError: true,
		},
	});
};
