import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { mediaQueries, colors } from '../../theme';
import Icon from '../Icon';

interface Props {
	children: ReactNode;
	startPage: {
		link: string;
		text: string;
		selected: boolean;
	};
	links: any[];
	navigationHeading: string;
}

const NavigationContainer = styled.div`
	width: 100%;
	border-bottom: 0.0625rem solid #e9e8e7;
	background: #fff;
	${mediaQueries.phone} {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		position: sticky;
		top: 1rem;
	}
	z-index: 98;
`;

const NavigationWrapper = styled.div`
	position: relative;
	font-family: 'Euclid Circular B', sans-serif;
	max-width: 120rem;
	margin-inline: auto;
`;

const NavigationButton = styled.button`
	position: absolute;
	top: 1rem;
	right: 2.25rem;
	background: transparent;
	border: 0;
	padding-inline: 2rem;
	padding-block: 1rem;
	z-index: 2;
	width: 20rem;
	text-align: left;
	display: flex;
	justify-content: space-between;
	font-family: 'Euclid Circular B', sans-serif;
	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;
	${mediaQueries.phoneOrTablet} {
		position: relative;
		padding-block: 1.5rem;
		padding-right: 1.25rem;
		width: 100%;
		top: 0;
		right: 0;
		border-top: 0.0625rem solid #e9e8e7;
	}
`;

const NavigationLink = styled.a`
	text-decoration: none;
	color: ${colors.linkColorDark};
	width: max-content;
	display: block;
	border-radius: 0.5rem;
`;

const NavigationMenuLinkWrapper = styled.div`
	padding: 0.5rem;
	margin-left: 1rem;
	width: fit-content;
	border-radius: 0.25rem;

	&:hover {
		background: #f1f1f1;
	}

	&.active a {
		font-weight: 500;
		color: #000;
	}

	&.active:hover {
		background: transparent;
	}
`;

const NavigationMenu = styled.div`
	position: absolute;
	top: 0;
	right: 2.25rem;
	background: #fff;
	border-radius: 4px;
	padding-inline: 2rem;
	padding-top: 5rem;
	padding-bottom: 40px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	width: 20rem;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;

	${mediaQueries.phoneOrTablet} {
		padding-block: 1rem;
		padding-inline: 2.25rem;
		width: 100%;
		top: 8.5rem;
		right: 0;
	}
	${mediaQueries.phone} {
		top: 8.5rem;
	}
`;

const CampaignNavigation: React.FC<Props> = ({
	children,
	links,
	startPage,
	navigationHeading,
}) => {
	const [open, setOpen] = useState(false);
	return (
		<NavigationContainer>
			<NavigationWrapper>
				{children}
				<NavigationButton
					onClick={() => setOpen(!open)}
					aria-controls={'campaign-menu'}
					aria-expanded={open}
				>
					{open && (
						<>
							{!startPage.selected && (
								<NavigationLink href={startPage.link}>
									{startPage.text}
								</NavigationLink>
							)}
							{startPage.selected && <span>{startPage.text}</span>}
							<Icon direction={'left'} icon={'chevron'} />
						</>
					)}
					{!open && (
						<>
							<span>{navigationHeading}</span>
							<Icon icon={'chevron'} />
						</>
					)}
				</NavigationButton>
				{open && (
					<NavigationMenu id={'campaign-menu'}>
						{links?.map((link, index) => (
							<NavigationMenuLinkWrapper
								key={index}
								className={link.selected ? 'active' : ''}
							>
								<NavigationLink href={link.link} key={index}>
									{link.text}
								</NavigationLink>
							</NavigationMenuLinkWrapper>
						))}
					</NavigationMenu>
				)}
			</NavigationWrapper>
		</NavigationContainer>
	);
};

export default CampaignNavigation;
