/**
 * Link - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors } from 'theme';
import { P } from 'components/Text/Text.styles';

interface StyledProps {
	/** Sets the size of the text */
	$size?: 'large' | 'medium' | 'small';
}

export const LinkText = styled(P)`
	margin-bottom: 0;
	padding-top: 0 !important;
	color: ${colors.blueDark};
	letter-spacing: 0;
	line-height: ${px2rem(23)};
`;

const getSize = (size: string) => {
	switch (size) {
		case 'small':
			return `
                svg {
                    width: 1rem;
                    height: 1rem;
                }

                ${LinkText} {
                    font-size: ${px2rem(14)};
                }
            `;
		case 'large':
			return `
                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                }

                ${LinkText} {
                    font-size: ${px2rem(18)};
                }
			`;
		default:
			return `
                svg {
                    width: 1.2rem;
                    height: 1.2rem;
                }

                ${LinkText} {
                    font-size: ${px2rem(16)};
                }
			`;
	}
};

export const StyledLink = styled.a<StyledProps>`
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	color: ${colors.linkColorDark};

	${({ $size }) => getSize($size || 'medium')};

	&:hover {
		${LinkText} {
			text-decoration: underline;
		}
	}
`;
