/**
 * DownloadButton
 */

import React from 'react';
import {
	StandardButton,
	ChildArea,
	PdfIcon,
	ButtonIcon,
	LinkArea,
	LinkText,
	IconWrapper,
} from './DownloadButton.styles';
import { colors } from 'theme';
import Link from 'components/Link';

interface Props {
	/** Download text */
	text: string;

	/** Download href */
	href: string;

	/** If button should be rendered as link or button */
	type?: 'link' | 'button';

	/** If button should be opened in new window */
	target?: string;

	/** On click handler */
	onClick?: (e: any) => void;

	[htmlAttributes: string]: any;
}

/** A reusable downloadbutton component. */
const DownloadButton: React.FC<Props> = ({
	children,
	text,
	href,
	type = 'button',
	target,
	onClick,
}) => {
	return (
		<StandardButton
			{...(onClick && { onClick: onClick })}
			{...(type === 'link' && { href })}
			{...(type === 'link' && { download: true })}
			{...(type === 'link' && { target })}
			as={type === 'link' ? Link : 'button'}
		>
			<ChildArea>
				<PdfIcon icon="pdf" />
				<LinkText>{children}</LinkText>
			</ChildArea>

			<LinkArea>
				<LinkText>{text}</LinkText>
				<IconWrapper>
					<ButtonIcon
						icon="arrowRight"
						direction="down"
						color={colors.blueDark}
					/>
				</IconWrapper>
			</LinkArea>
		</StandardButton>
	);
};

export default DownloadButton;
