/**
 * Download - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { H4 } from 'components/Text/Text.styles';

export const Wrapper = styled.div`
	font-family: ${typography.fontFamily.alt};
	background-color: ${colors.offBlueLightest};
	padding: ${px2rem(46)} ${px2rem(35)};
	margin-bottom: ${px2rem(35)};
`;

export const Heading = styled(H4)`
	margin-bottom: ${px2rem(20)};
	font-weight: ${typography.fontWeight.semiBold};
`;

export const Preamble = styled.p`
	padding: 0;
	margin: 0;
	margin-bottom: ${px2rem(20)};
`;

export const DownloadButtonMobile = styled.div`
	${mediaQueries.phone} {
		margin: ${px2rem(25)} 0;
	}
`;
