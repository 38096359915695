/**
 * SiteMapPage
 */

import React from 'react';
import Breadcrumb from 'components/Breadcrumb';

import { SitemapPageModel } from 'types/pages';
import { LinkType } from 'types/global';

import {
	Content,
	UlList,
	BorderOuterLeft,
	BorderInnerLeft,
	BorderedUl,
	ListItem,
	SiteLink,
	SiteHeading,
	SiteSubHeading,
	SiteLowLevelHeading,
} from './SitemapPage.styles';
import BlockContainer from 'layouts/BlockLayout';

/** Main description for this Sitemap component.*/
const SitemapPage: React.FC<SitemapPageModel> = ({
	breadcrumbs,
	translations,
	heading,
	sitemap,
	_properties = {},
}) => {
	const renderSitemapLinks = ({
		text,
		link,
		children,
		depthLevel,
	}: LinkType) => {
		let currentDepthLevel = depthLevel || 1;

		return (
			<ListItem key={`${link}-${currentDepthLevel}`}>
				{currentDepthLevel == 1 && (
					<>
						<BorderOuterLeft />
						<SiteSubHeading key={link} href={link}>
							{text}
						</SiteSubHeading>
					</>
				)}
				{currentDepthLevel == 2 && (
					<>
						<BorderInnerLeft />
						<SiteLowLevelHeading key={link} href={link}>
							{text}
						</SiteLowLevelHeading>
					</>
				)}
				{currentDepthLevel >= 3 && (
					<SiteLink key={link} href={link}>
						{text}
					</SiteLink>
				)}

				{children && currentDepthLevel < 2 && (
					<BorderedUl>
						{children.map((childLinkType: LinkType) => {
							return renderSitemapLinks(childLinkType);
						})}
					</BorderedUl>
				)}

				{children && currentDepthLevel >= 2 && (
					<UlList>
						{children.map((childLinkType: LinkType) => {
							return renderSitemapLinks(childLinkType);
						})}
					</UlList>
				)}
			</ListItem>
		);
	};

	return (
		<main id="main-content">
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>
			<BlockContainer>
				<Content>
					<SiteHeading as="h1">{heading}</SiteHeading>
					{sitemap && (
						<BorderedUl>{sitemap.map(renderSitemapLinks)}</BorderedUl>
					)}
				</Content>
			</BlockContainer>
		</main>
	);
};

export default SitemapPage;
