const historyScrollPosition = new Map<string, number>();
const isBrowser = () => typeof window !== 'undefined';

if (isBrowser()) {
	window.addEventListener('beforeunload', () => {
		window.localStorage.setItem('scrollPosition', window.scrollY.toString());
	});
}

export function setScrollPosition() {
	historyScrollPosition.set(window.location.pathname, window.scrollY);
}

export function getScrollPosition(pathname: string) {
	const latestPosition = historyScrollPosition.get(pathname);

	if (document.referrer.includes(window.location.hostname)) {
		return;
	}

	if (!latestPosition) {
		const scrollPosition = window.localStorage.getItem('scrollPosition');

		window.localStorage.removeItem('scrollPosition');

		return parseInt(scrollPosition ?? '0');
	}

	historyScrollPosition.delete(pathname);
	return latestPosition;
}
