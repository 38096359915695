/**
 * Icon
 */

import React from 'react';
import icons, { IconList } from 'theme/icons';
import { StyledIcon } from './Icon.styles';

interface Props extends IconList {
	/** Set the size of the icon. <br/>1 = 24px<br/> 2 = 32px<br/> 3 = 40px <br/> 4 = 48px*/
	size?: 0 | 1 | 2 | 3 | 4;

	/** Set the color of the icon */
	color?: string;

	/** Set the direction of the icon. */
	direction?: 'left' | 'up' | 'right' | 'down';

	/** Animate the icon. */
	animate?: 'spin';

	/** Pass optional classnames for the html */
	className?: string;

	[htmlAttributes: string]: any;
}

/** This is the icon component that handles all the icons in the project. <br /> The user will be able to set the <strong>color</strong>, <strong>size</strong>, <strong>direction</strong> and <strong>animation</strong> for all the icons. */
const Icon: React.FC<Props> = ({
	icon,
	color = '#000',
	size = 1,
	direction = 'right',
	animate = undefined,
	className,
	expanded,
	...htmlAttributes
}) => {
	return (
		<StyledIcon
			as={icons[icon]}
			color={color}
			size={size}
			direction={direction}
			animate={animate}
			className={className}
			{...htmlAttributes}
		/>
	);
};

export default Icon;
