/**
 * TopBannerBlock - styles
 */

import styled from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { H1, P, Preamble } from 'components/Text';
import { PropsButtonWrapper } from 'components/StartBannerBlock/StartBannerBlock.styles';

interface StyledProps {
	bgColor: 'blue' | 'white';
}

export const Wrapper = styled.div`
	display: flex;

	${mediaQueries.phone} {
		flex-direction: column;
	}
	${mediaQueries.phoneOrTablet} {
		flex-direction: column;
	}
	${mediaQueries.tabletLarger} {
		flex-direction: column;
		padding-left: 0 !important;
	}
`;

export const LeftBlock = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	padding: 0 6rem;
	width: 50%;

	${mediaQueries.tabletLarger} {
		max-width: 100% !important;
		width: 100%;
		justify-content: center;
		text-align: center;
		padding: 4.75rem 2rem;
	}
	${mediaQueries.phoneOrTablet} {
		justify-content: center;
		text-align: center;
		width: 100%;
		padding: 4.75rem 2rem;
	}
	${mediaQueries.phone} {
		justify-content: center;
		text-align: center;
		width: 100%;
		padding: 4.75rem 1rem;
	}
`;

export const LeftBlockInner = styled.div`
	max-width: ${px2rem(618)};
	padding: 0 0;

	${mediaQueries.tabletLarger} {
		max-width: 100%;
	}
	${mediaQueries.phoneOrTablet} {
		max-width: unset;
		padding: 0;
	}
`;

export const RightBlock = styled.div`
	width: 50%;

	${mediaQueries.tabletLarger} {
		width: 100%;
	}
	${mediaQueries.tablet} {
		width: 100%;
	}
	${mediaQueries.phone} {
		width: 100%;
	}
	${mediaQueries.phoneOrTablet} {
		width: 100%;
	}

	img {
		height: 100%;
		object-fit: cover;
	}
`;
// display: inline-flex;
// flex-direction: column;

export const Heading = styled(H1)<StyledProps>`
	color: ${({ bgColor }) =>
		bgColor === 'blue' ? colors.white : colors.greyDark};
	margin-bottom: ${px2rem(16)};
	${mediaQueries.phone} {
		font-size: ${px2rem(34)};
	}
	${mediaQueries.phoneOrTablet} {
		text-align: center;
	}
	${mediaQueries.tabletLarger} {
		text-align: center;
	}
`;

export const StyledPreamble = styled(Preamble)<StyledProps>`
	color: ${({ bgColor }) =>
		bgColor === 'blue' ? colors.white : colors.greyDark};
`;

export const ImageWrapper = styled.div`
	height: 100%;
`;

export const StyledText = styled(P)<StyledProps>`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(18)};
	line-height: ${px2rem(30)};
	color: ${({ bgColor }) =>
		bgColor === 'blue' ? colors.white : colors.greyDark};

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(16)};
		line-height: ${px2rem(24)};
		text-align: center;
	}
`;

export const ButtonWrapper = styled.div<PropsButtonWrapper>`
	display: flex;
	flex-direction: ${(props) =>
		props.$hasMoreThanTwoChilds ? 'row' : 'column'};
	flex-wrap: ${(props) => props.$hasMoreThanTwoChilds && 'wrap'};
	justify-content: ${(props) => props.$hasMoreThanTwoChilds && 'space-between'};
	align-items: flex-start;
	margin-bottom: ${px2rem(20)};

	${mediaQueries.phone} {
		flex-direction: column;
		align-items: center;
	}
	${mediaQueries.tablet} {
		flex-direction: column;
		align-items: center;
	}

	${mediaQueries.tabletLarger} {
		flex-direction: column;
		align-items: center;
	}
	> * {
		min-width: ${px2rem(240)};
		margin-top: ${px2rem(20)};
	}
`;
