/**
 * Loader
 */

import styled from 'styled-components';
import { colors } from 'theme';

export const LoaderSmall = styled.div`
	border: 0.25rem solid ${colors.greyLighter};
	border-top: 0.25rem solid ${colors.blue};
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	animation: spin 2s linear infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export const Loader = styled.div`
	border: 0.5rem solid #f3f3f3;
	border-top: 0.5rem solid #666;
	border-radius: 50%;
	width: 10rem;
	height: 10rem;
	animation: spin 2s linear infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export const LoaderStatic = styled(Loader)`
	position: fixed;
	top: 50%;
	left: 50%;
	margin: -5rem 0 0 -5rem;
`;
