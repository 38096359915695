import StartPage from './StartPage';
import ErrorPage404 from './ErrorPage404';
/* PLOP_INJECT_IMPORT */
import RegisterDetailsPage from './RegisterDetailsPage';
import RegisterPage from './RegisterPage';
import UnsubscribePage from './UnsubscribePage';
import ResearchResultListPage from './ResearchResultListPage';
import GlossaryPage from './GlossaryPage';
import JobPage from './JobPage';
import ConfirmationPage from './ConfirmationPage';
import CalendarPage from './CalendarPage';
import CalendarListPage from './CalendarListPage';
import ReferralListPage from './ReferralListPage';
import ReferralPage from './ReferralPage';
import SitemapPage from './SitemapPage';
import ReportListPage from './ReportListPage';
import ReportPage from './ReportPage';
import CaseListPage from './CaseListPage';
import NewsListPage from './NewsListPage';
import CasePage from './CasePage';
import SearchPage from './SearchPage';
import StandardPage from './StandardPage';
import NewsPage from './NewsPage';
import ErrorPage from './ErrorPage';
import AreaPage from './AreaPage';
import ThemePage from './ThemePage';
import ResearchResultPage from './ResearchResultPage';
import PreviewBlockPage from './PreviewBlockPage';
import InformationPage from './InformationPage/InformationPage';
import { ContentTypes } from 'types/pages';
import CampaignStartPage from './CampaignStartPage/CampaignStartPage';

const Pages: any = {
	StartPage,
	ErrorPage404,
	/* PLOP_INJECT_PAGE */
	RegisterDetailsPage,
	RegisterPage,
	UnsubscribePage,
	ResearchResultListPage,
	GlossaryPage,
	JobPage,
	ConfirmationPage,
	CalendarPage,
	CalendarListPage,
	ReferralListPage,
	ResearchResultPage,
	ReferralPage,
	SitemapPage,
	ReportListPage,
	ReportPage,
	CaseListPage,
	NewsListPage,
	CasePage,
	SearchPage,
	StandardPage,
	NewsPage,
	ErrorPage,
	AreaPage,
	ThemePage,
	PreviewBlockPage,
	InformationPage,
	CampaignStartPage,
};

export const PagesMapper = (pageContent: ContentTypes | undefined) => {
	if (pageContent) {
		let page: any = Pages[pageContent.modelType];

		if (page) {
			return page;
		}
	}

	return ErrorPage404;
};
