import React from 'react';
import styled from 'styled-components';
import { CampaignStartPageIconModel } from '../../types/blocks';
import { colors, Colors } from '../../theme/colors';
import { mediaQueries } from '../../theme';

interface Props extends CampaignStartPageIconModel {}

const IconCard = styled.div``;

const IconCardTop = styled.div<{
	$background: keyof Colors;
}>`
	background: ${(props) => colors[props.$background]};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	border-radius: 4px;
	height: 166px;
	${mediaQueries.phoneOrTablet} {
		padding: 1rem;
	}
	& img {
		height: 90px;
		width: 110px;
	}
`;

const IconCardBottom = styled.div<{
	$color: keyof Colors;
}>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Euclid Circular B', sans-serif;
	font-weight: 500;
	height: 48px;
	text-align: center;
	& a {
		color: #000;
		font-size: 1.25rem;
		line-height: 1.5;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	::after {
		position: absolute;
		width: 100%;
		height: 1rem;
		content: '';
		right: 0;
		bottom: 0;
		border-right: 2px solid ${(props) => colors[props.$color]};
		border-bottom: 2px solid ${(props) => colors[props.$color]};
		border-left: 2px solid ${(props) => colors[props.$color]};
		border-bottom-left-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
	}
`;

const CampaignStartPageIcon: React.FC<Props> = (props) => {
	return (
		<IconCard>
			<IconCardTop $background={props.color}>
				<img alt={props.image.alt} src={props.image.src} />
			</IconCardTop>
			<IconCardBottom $color={props.color}>
				<a href={props.link.link}>{props.link.text}</a>
			</IconCardBottom>
		</IconCard>
	);
};

export default CampaignStartPageIcon;
