/**
 * ReportPage
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { ReportPageModel } from 'types/pages';
import { LinkType } from 'types/global';
import Text from 'components/Text';
import { Grid } from 'components/Grid';
import EpiFragments from 'components/EpiFragments';
import EpiContentArea from 'components/EpiContentArea';
import Breadcrumb from 'components/Breadcrumb';
import TableOfContents from 'components/TableOfContents';
import { Accordion, AccordionItem } from 'components/Accordion';
import {
	FragmentsContainer,
	LeftCell,
	RightCell,
	AccordionList,
	AccordionListItem,
	AccordionCell,
	CategoryArea,
	PublishedDate,
	Category,
	Seperator,
	StyledPreamble,
} from './ReportPage.styles';
import Download from 'components/Download';
import PageLayout from 'layouts/PageLayout';

/** ReportPage. */
const ReportPage: React.FC<ReportPageModel> = ({
	heading,
	preamble,
	breadcrumbs,
	text,
	toc,
	hideToC,
	translations,
	contentArea,
	publishedDate,
	download,
	numberOfPages,
	category,
	_properties = {},
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const isTablet = useMediaQuery({
		maxWidth: breakpointsNumber.desktop - 1,
	});

	return (
		<main id="main-content">
			{breadcrumbs && (
				<Breadcrumb
					links={breadcrumbs}
					label={translations.breadcrumbsLabel}
					withBorder
				/>
			)}

			<PageLayout labelledBy="article-heading">
				<Grid inner={true}>
					<LeftCell span={7} phone={12}>
						<CategoryArea>
							<PublishedDate>
								<span>{`${translations.publishedText}`}</span>
								{` ${publishedDate}`}
							</PublishedDate>
							{isPhone && <Seperator />}
							<Category>{category}</Category>
						</CategoryArea>

						<Text as="h1" {..._properties?.heading} id="article-heading">
							{heading}
						</Text>

						{(_properties?.preamble || preamble) && (
							<StyledPreamble {..._properties?.preamble}>
								{preamble}
							</StyledPreamble>
						)}

						<FragmentsContainer>
							<EpiFragments
								{..._properties?.text}
								fragments={text?.fragments}
							/>
						</FragmentsContainer>
					</LeftCell>
					<RightCell span={isTablet ? 12 : 5}>
						{download.link && (
							<Download
								download={download}
								downloadHeading={translations.downloadHeading}
								downloadText={translations.downloadText}
								downloadButtonText={translations.downloadButtonText}
								downloadPagesText={translations.numberOfPages}
								numberOfPages={numberOfPages}
							/>
						)}

						{toc &&
							!hideToC &&
							(isPhone ? (
								<Accordion>
									<AccordionItem
										title={translations.accordionHeading || ''}
										variant={'lightBlue'}
									>
										<AccordionList>
											{toc.map((link: LinkType, index: number) => (
												<AccordionListItem key={index}>
													<AccordionCell href={link.link}>
														{link.text}
													</AccordionCell>
												</AccordionListItem>
											))}
										</AccordionList>
									</AccordionItem>
								</Accordion>
							) : (
								<TableOfContents label={translations.tocHeading} items={toc} />
							))}
					</RightCell>
				</Grid>
			</PageLayout>
			<EpiContentArea {..._properties?.contentArea} content={contentArea} />
		</main>
	);
};

export default ReportPage;
