/**
 * AutoComplete
 */

import React, { useEffect } from 'react';
import Downshift from 'downshift';
import {
	HiddenLabel,
	Container,
	SuggestionsList,
	ListItem,
} from './AutoComplete.styles';
import { useAutoComplete } from 'hooks';
import SearchField from 'components/SearchField';
import { Form } from 'components/Form';

interface Props {
	id: string;
	label: string;

	/** Focus */
	shouldFocus: boolean;

	/** Buttontext */
	buttonText: string;

	/** Placeholder */
	placeholder?: string;

	/** Destination to send searched words */
	action?: string;

	/** Autocomplete url */
	autocompleteSearchUrl: string;
}

/** Auto complete component */
const Autocomplete: React.FC<Props> = ({
	id,
	label,
	placeholder,
	shouldFocus,
	buttonText,
	action,
	autocompleteSearchUrl,
}) => {
	const [setQuery, setSuggestions, query, suggestions] = useAutoComplete(
		autocompleteSearchUrl
	);

	const handleClearSuggestions = () => {
		setQuery('');
		setSuggestions();
	};

	const handleInputValueChange = (inputValue: string, stateAndItems: any) => {
		switch (stateAndItems.type) {
			case Downshift.stateChangeTypes.changeInput:
			case Downshift.stateChangeTypes.keyDownEnter:
			case Downshift.stateChangeTypes.clickItem:
				const { selectedItem } = stateAndItems;

				if (!inputValue && selectedItem) {
					handleSubmit(selectedItem);
				} else {
					if (
						(!selectedItem || selectedItem.text !== inputValue) &&
						inputValue?.length >= 3
					) {
						setQuery(inputValue);
					}
					if ((inputValue === '' || !inputValue) && selectedItem) {
						stateAndItems.clearSelection();
						handleClearSuggestions();
						setQuery(selectedItem);
					}
					if (inputValue === '') {
						handleClearSuggestions();
					}
				}
			default:
				return null;
		}
	};

	const handleSubmit = (value: string) => {
		if (value.length >= 3) {
			setQuery(value);
			const url = `${action}?query=${value}`;
			window.location.href = url;
		}
	};

	useEffect(() => {
		return () => {
			handleClearSuggestions();
		};
	}, []);

	return (
		<Downshift
			onInputValueChange={handleInputValueChange}
			itemToString={(item) => (item ? item.text : '')}
			initialInputValue={query}
		>
			{({
				getLabelProps,
				getMenuProps,
				getInputProps,
				getItemProps,
				openMenu,
				reset,
				isOpen,
				highlightedIndex,
			}) => (
				<div>
					<HiddenLabel {...getLabelProps()}>{label}</HiddenLabel>
					<Form action="POST" onSubmit={() => handleSubmit(query)}>
						<SearchField
							{...getInputProps()}
							name="query"
							id={id}
							focus={shouldFocus}
							buttonText={buttonText}
							label={label}
							placeholder={placeholder}
							clearable
							handleResetForm={reset}
							onFocus={openMenu}
							autocomplete="off"
						/>
					</Form>
					{isOpen && suggestions && suggestions.length > 0 && (
						<Container>
							<SuggestionsList {...getMenuProps()}>
								{suggestions.map((item: string, index: number) => (
									<ListItem
										{...getItemProps({
											key: item,
											index,
											item,
											selected: highlightedIndex === index,
										})}
									>
										{item}
									</ListItem>
								))}
							</SuggestionsList>
						</Container>
					)}
				</div>
			)}
		</Downshift>
	);
};
export default Autocomplete;
