/**
 * RegisterCaseList
 */

import React from 'react';
import { RegisterCase } from 'types/listings';
import Icon from 'components/Icon';
import Link from 'components/Link';

import { Content, TextArea, Text, LinkedText } from './RegisterCaseList.styles';
import { colors } from 'theme';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';

interface Props {
	/** Case index */
	index: number;

	/** Case object */
	item: RegisterCase;

	/** Read more text */
	readMoreText?: string;

	/** Headers */
	headerCaseNumber?: string;
	headerStatus?: string;
	headerDecision?: string;
	headerSubject?: string;
	headerCaseType?: string;
	headerRegisterDate?: string;
	headerCounterpart?: string;
}

/** Main description for this component. */
const RegisterCaseList: React.FC<Props> = ({ index, item }) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.desktop - 1,
	});

	return (
		<Content
			aria-labelledby={`CaseBox-heading-${index}`}
			id={item.caseNumber}
			tabIndex={-1}
		>
			<TextArea>
				<Link href={item.url}>
					<LinkedText>{item.caseNumber}</LinkedText>
				</Link>
			</TextArea>

			{!isPhone && (
				<TextArea>
					<Text>{item.status}</Text>
				</TextArea>
			)}

			{!isPhone && (
				<TextArea className="text-center">
					{item.decisionLink && (
						<Link href={item.decisionLink} target="_blank">
							<Icon icon="pdf" color={colors.linkColorDark}></Icon>
						</Link>
					)}
				</TextArea>
			)}

			<TextArea>
				<Text>{item.subject}</Text>
			</TextArea>

			<TextArea>
				<Text>{item.type}</Text>
			</TextArea>

			{!isPhone && (
				<TextArea>
					<Text>{new Date(item.registrationDate).toLocaleDateString()}</Text>
				</TextArea>
			)}

			{!isPhone && (
				<TextArea>
					<Text>{item.counterpart}</Text>
				</TextArea>
			)}
		</Content>
	);
};

export default RegisterCaseList;
