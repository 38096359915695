/**
 * LinkItemBlock
 */

import React from 'react';
import {
	Container,
	SubHeading,
	Heading,
	Preamble,
	AnchorIcon,
	IconBadge,
} from './LinkItemBlock.styles';
import { colors } from 'theme';

import Icon from 'components/Icon';

interface Props {
	/** Block heading */
	heading: string;

	/** Background color */
	backgroundColor: 'lightBlue' | 'white';

	/** Subheading */
	category: string;

	/** Preamble */
	preamble: string;

	/* Link */
	link: string;
}

/** Linkitemblock. */
const LinkItemBlock: React.FC<Props> = ({
	heading,
	backgroundColor = 'lightBlue',
	category,
	preamble,
	link,
}) => (
	<Container href={link} $background={backgroundColor}>
		{category && <SubHeading>{category}</SubHeading>}

		<Heading>{heading}</Heading>

		{preamble && <Preamble>{preamble}</Preamble>}

		<AnchorIcon>
			<IconBadge>
				<Icon
					icon={'arrowRight'}
					color={colors.white}
					size={0}
					aria-hidden="true"
				/>
			</IconBadge>
		</AnchorIcon>
	</Container>
);

export default LinkItemBlock;
