/**
 * ErrorPage404 - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, typography, mediaQueries } from 'theme';

export const Container = styled.div`
	padding: 0;
	text-align: center;
	max-width: ${px2rem(800)};
	margin: 6rem auto;

	${mediaQueries.phone} {
		padding: 0 ${px2rem(44)};
		margin-top: 5rem;
	}
`;

export const Image = styled.img`
	display: block;
	margin: 0 auto;
	padding: 0 0 3rem 0;
`;

const textStyles = css`
	font-size: ${px2rem(22)};
	line-height: ${px2rem(28)};
	font-family: ${typography.fontFamily.alt};
	color: ${colors.blueDarker};
`;

export const ErrorHeading = styled.h1`
	${textStyles}
	font-size: ${px2rem(46)};
	line-height: ${px2rem(55)};
	font-weight: ${typography.fontWeight.bold};
	margin-bottom: 0.5rem;
`;

export const TextContainer = styled.div`
	${textStyles}
	line-height: 3.5rem;
	font-weight: ${typography.fontWeight.medium};
	margin-bottom: ${px2rem(30)};
`;
