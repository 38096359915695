/**
 * NewsPage - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, typography, mediaQueries } from 'theme';
import { Cell } from 'components/Grid';
import { Preamble } from 'components/Text';

export const FragmentsContainer = styled.div`
	font-family: ${typography.fontFamily.alt};
	line-height: ${px2rem(32)};
`;

export const DateArea = styled.p`
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.semiBold};
	position: sticky;
	top: ${px2rem(115)};
	display: inline-flex;
	flex-direction: column;
	text-align: left;

	${mediaQueries.phoneOrTablet} {
		position: relative;
		top: 0;
		margin: ${px2rem(30)} 0;
	}
`;

export const Category = styled.span`
	font-size: ${px2rem(16)};
	line-height: ${px2rem(16)};
	letter-spacing: normal;
	color: ${colors.blue};
	text-align: left;
`;

export const PublishedDate = styled.span`
	font-size: ${px2rem(24)};
	line-height: ${px2rem(32)};
	letter-spacing: normal;
	color: ${colors.black};
`;

export const LastModifiedDate = styled.p`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(24)};
	letter-spacing: normal;
	color: ${colors.greyDark};
	margin-top: ${px2rem(60)};
	margin-bottom: 0;
`;

export const LeftCell = styled(Cell)`
	${mediaQueries.phoneOrTablet} {
		order: 1;
	}
`;

export const RightCell = styled(Cell)`
	width: auto;
	margin: 0 ${px2rem(30)};
	text-align: center;

	${mediaQueries.phoneOrTablet} {
		order: 0;
		margin: 0;
		text-align: left;
	}
`;

export const StyledPreamble = styled(Preamble)`
	margin: 0 0 3.5rem;
`;
