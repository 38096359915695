/**
 * RegisterCaseBox - styles
 */

import styled, { css } from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import Link from 'components/Link';
import {
	H5,
	H6,
	P,
	Span,
	headingTextStyles,
	styleForP,
} from 'components/Text/Text.styles';

export const Heading = styled(H5)`
	margin-bottom: ${px2rem(15)};
	font-size: ${px2rem(18)};
	word-break: break-all;
`;

export const Content = styled.div`
	display: grid;
	grid-template-columns: 7rem 7rem 5rem 1fr 1fr 8rem 1fr;

	${mediaQueries.phoneOrTablet} {
		grid-template-columns: 7rem 1fr 1fr;
	}

	border-bottom: ${px2rem(1)} solid ${colors.greyLighter};
	&:nth-child(2n + 1) {
		background-color: ${colors.greyLightest};
	}

	word-break: break-word;
	hyphens: auto;
`;

export const HeadingContent = styled.div`
	display: grid;
	grid-template-columns: 7rem 7rem 5rem 1fr 1fr 8rem 1fr;

	${mediaQueries.phoneOrTablet} {
		grid-template-columns: 7rem 1fr 1fr;
	}

	border-top: ${px2rem(1)} solid ${colors.greyLighter};
`;

export const HeadingText = styled.p`
	${headingTextStyles};
	font-size: ${px2rem(16)};
	padding: 0 ${px2rem(16)} 0 ${px2rem(16)};

	${mediaQueries.phoneOrTablet} {
		grid-template-columns: 7rem 1fr 1fr;
	}
`;

export const TextArea = styled.div`
	padding: ${px2rem(15)};

	&:not(:last-child) {
		border-right: ${px2rem(1)} solid ${colors.greyLighter};
	}

	&.text-center {
		text-align: center;
	}

	&.text-blue {
		stroke: ${colors.blueLight};
	}
`;

export const LinkedText = styled(Span)`
	${styleForP}
	font-size: ${px2rem(16)};
`;

export const Text = styled(P)`
	margin: 0;
	font-size: ${px2rem(15)};
	line-height: ${px2rem(20)};
`;
