/**
 * DesktopMenu - Ul
 */

import React from 'react';
import { LinkType } from 'types/global';
import {
	LiLevel1,
	StyledLink,
	LiItemTopArea,
	LinkText,
	StyledUl,
	FlagIcon,
} from './DesktopMenu.styles';
import Li from './DesktopMenu.Li';

interface PaneProps {
	/** Links for the pane structured as JSON */
	links: LinkType[];

	/** Languages link */
	languages?: LinkType;

	/** The pane level (1, 2, 3) */
	level: number;

	/** Determine if childelement or not for indent */
	isChild?: boolean;

	/** Screenreadertext for mobile (toggle open) */
	screenReaderText: string;

	/** Callback method when the menu closes */
	onClose?: any;
}

/** A ul used by DesktopMenu */
const Ul: React.FC<PaneProps> = ({
	links,
	languages,
	level,
	isChild,
	onClose,
	screenReaderText,
}) => (
	<StyledUl isChild={isChild} childLevel={level}>
		{links.map((link, index) => (
			<Li
				link={link}
				level={level}
				key={index}
				isChild={isChild}
				onClose={onClose}
				screenReaderText={screenReaderText}
			/>
		))}
		{languages && languages.link && (
			<LiLevel1>
				<LiItemTopArea childLevel={1} bgColor>
					<StyledLink
						lang={languages.link.includes('en') ? 'en' : 'sv'}
						href={languages.link}
						target="_top"
					>
						<LinkText>{languages.text}</LinkText>
						<FlagIcon icon="flagEn" aria-hidden={true} />
					</StyledLink>
				</LiItemTopArea>
			</LiLevel1>
		)}
	</StyledUl>
);

export default Ul;
