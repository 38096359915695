/**
 * UnsubscribePage - styles
 */

import styled from 'styled-components';
import { typography } from 'theme';
import { px2rem } from 'theme/utils';
import { Preamble } from 'components/Text';

export const FragmentsContainer = styled.div`
	font-family: ${typography.fontFamily.alt};
	line-height: ${px2rem(32)};
`;

export const StyledPreamble = styled(Preamble)`
	margin: 0 0 3.5rem;
`;
