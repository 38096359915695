/**
 * SubscriptionBlock
 */

import BlockContainer from 'layouts/BlockLayout';
import React, { useState } from 'react';
import Button from 'components/Button';
import { useSelector } from 'react-redux';

import { selectApi } from 'store/modules/model';

import { CheckboxLabel } from 'components/Form';

import { Input, SelectBase, CheckboxIcon } from 'components/Form/Form.styles';

import {
	Container,
	SubHeading,
	TextColumn,
	Heading,
	Text,
	Link,
	TextColumnInner,
	SubscribeContainer,
	SubscribeForm,
	SubscribeGroupItem,
	SubscribeInputContainer,
	SubscribeLabel,
	ButtonContainer,
	MessageArea,
	MessageText,
	CheckboxInput,
} from './SubscriptionBlock.styles';
import { Option } from 'types/form';
import { LinkType } from 'types/global';
import { subscribe } from 'api/subscribeApi';

interface Props {
	/** Block heading */
	heading: string;

	/** Kicker heading */
	kickerHeading?: string;

	/** Block text content */
	text?: string;

	intervals: Option[];

	translations: {
		categoriesText: string;
		emailText: string;
		emailPlaceholder: string;
		buttonText: string;
	};

	pageId: number;

	termsOfServiceLink: LinkType;

	/** Epi properties if in edit mode */
	_properties?: any;
}

/** Main description for this component. */
const SubscriptionBlock: React.FC<Props> = ({
	heading = '',
	kickerHeading = '',
	text = '',
	translations,
	intervals,
	pageId,
	termsOfServiceLink,
	_properties,
}) => {
	const api: any = useSelector(selectApi);
	const [formValues, setFormValues] = useState({
		email: '',
		interval: intervals[0].value,
		sId: pageId,
		termsApproved: false,
	});

	const [success, setSuccess] = useState<boolean>(false);
	const [message, setMessage] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	const handleSubmit = (e: React.FormEvent<HTMLElement>) => {
		e.preventDefault();
		if (formValues.email && formValues.termsApproved) {
			setLoading(true);
			subscribe(api.subscribeUrl, formValues)
				.then((res: any) => {
					setLoading(false);
					if (res) {
						setSuccess(res.statusCode === 200);
						setMessage(res.statusDescription || '');
					}
				})
				.catch((err) => {
					setLoading(false);
					if (err) {
						setSuccess(false);
						setMessage(err);
					}
				});
		}
	};

	const handleOnChange = (e: React.FormEvent<HTMLElement>) => {
		const target = e.target as HTMLFormElement;

		setFormValues({
			...formValues,
			[target.name]: target.type === 'checkbox' ? target.checked : target.value,
		});
	};

	return (
		<BlockContainer backgroundColor="lightBlue">
			<Container>
				<TextColumn>
					<TextColumnInner>
						{kickerHeading && (
							<SubHeading as="h2" {..._properties?.kickerHeading}>
								{kickerHeading}
							</SubHeading>
						)}
						<Heading as={kickerHeading ? 'h3' : 'h2'} {..._properties?.heading}>
							{heading}
						</Heading>

						{text && <Text {..._properties?.text}>{text}</Text>}
					</TextColumnInner>
				</TextColumn>
				<SubscribeContainer>
					<SubscribeForm onSubmit={handleSubmit}>
						<SubscribeGroupItem>
							<SubscribeLabel htmlFor="email">
								{translations.emailText}
							</SubscribeLabel>
							<SubscribeInputContainer>
								<Input
									type="email"
									name="email"
									id="email"
									aria-label="Email"
									autoComplete="on"
									required={true}
									placeholder={translations.emailPlaceholder}
									disabled={loading}
									aria-describedby="form__email__desc"
									onChange={handleOnChange}
								/>
							</SubscribeInputContainer>
						</SubscribeGroupItem>
						<SubscribeGroupItem>
							<SubscribeLabel>{translations.categoriesText}</SubscribeLabel>
							<SubscribeInputContainer>
								<SelectBase
									id="interval"
									name="interval"
									onChange={handleOnChange}
									aria-label="Intervals"
									disabled={loading}
									aria-describedby="form__select__desc"
								>
									{intervals.map(({ value, text }) => {
										return (
											<option value={value} key={value}>
												{text}
											</option>
										);
									})}
								</SelectBase>
							</SubscribeInputContainer>
						</SubscribeGroupItem>

						<SubscribeGroupItem>
							<CheckboxLabel htmlFor="subscriptionBlockAcceptTermsOfService">
								<div>
									<CheckboxInput
										type="checkbox"
										id="subscriptionBlockAcceptTermsOfService"
										name="termsApproved"
										value={formValues.termsApproved ? '1' : '0'}
										checked={formValues.termsApproved}
										onChange={handleOnChange}
										required={true}
										disabled={loading}
										aria-describedby={`form__checkbox__desc`}
									/>
									<span>
										<CheckboxIcon icon="check" />
									</span>
								</div>
								<Link
									href={termsOfServiceLink.link}
									target={termsOfServiceLink.target}
								>
									{termsOfServiceLink.text}
								</Link>
							</CheckboxLabel>
						</SubscribeGroupItem>
						<ButtonContainer>
							<Button type="submit" disabled={loading}>
								{translations.buttonText}
							</Button>
						</ButtonContainer>

						{message && (
							<MessageArea $success={success}>
								<MessageText>{message}</MessageText>
							</MessageArea>
						)}
					</SubscribeForm>
				</SubscribeContainer>
			</Container>
		</BlockContainer>
	);
};

export default SubscriptionBlock;
