/**
 * StartBannerBlock - styles
 */

import styled from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { H1, H4 } from 'components/Text';
import { StandardImage } from 'types/global';

interface StyledProps {
	bgColor: 'blue' | 'white';
}

interface ImageContainerProps {
	image: StandardImage;
	imageMobile: StandardImage;
}

export const Wrapper = styled.div<StyledProps>`
	display: flex;

	background-color: ${({ bgColor }) =>
		bgColor === 'blue' ? colors.blueDarker : colors.white};
	${mediaQueries.phone} {
		flex-direction: column;
	}
	${mediaQueries.tablet} {
		flex-direction: column;
	}
	${mediaQueries.tabletLarger} {
		flex-direction: column;
		padding-left: 0 !important;
	}
	${mediaQueries.desktop} {
		padding-left: ${px2rem(120)};
	}
`;

export const LeftBlock = styled.div`
	width: 50%;
	padding: ${px2rem(140)} ${px2rem(120)} ${px2rem(140)} 0;

	${mediaQueries.tabletLarger} {
		max-width: 100% !important;
		width: 100%;
		justify-content: center;
		padding-left: 2rem;
		padding-right: 2rem;
		text-align: center;
	}

	${mediaQueries.tablet} {
		width: 100%;
		justify-content: center;
		padding-left: 2rem;
		padding-right: 2rem;
		text-align: center;
	}

	${mediaQueries.phone} {
		justify-content: center;
		width: 100%;
		padding: ${px2rem(76)} ${px2rem(8)};
		text-align: center;
	}

	${mediaQueries.desktop} {
		max-width: ${px2rem(900)};
		margin-left: auto;
	}
`;

export const LeftBlockInner = styled.div`
	max-width: ${px2rem(672)};
	${mediaQueries.tabletLarger} {
		max-width: 100%;
	}
	${mediaQueries.tablet} {
		max-width: 100%;
	}
	${mediaQueries.phone} {
		width: 100%;
	}
`;

export const RightBlock = styled.div`
	display: inline-flex;
	flex-direction: column;
	width: 50%;

	${mediaQueries.tabletLarger} {
		width: 100%;
	}
	${mediaQueries.tablet} {
		width: 100%;
	}
	${mediaQueries.phone} {
		width: 100%;
	}
`;

export const ImageContainer = styled.div<ImageContainerProps>`
	img {
		width: 100%;
		object-fit: cover;
		max-height: ${px2rem(374)};
	}
`;

export const LinksContainer = styled.div`
	background-color: ${colors.blueLightest};
	padding: 3.5rem 0 3.5rem ${px2rem(70)};
	flex-grow: 1;

	${mediaQueries.tabletLarger} {
		padding: 3.5rem 2rem 3.5rem 3rem;
	}
	${mediaQueries.tablet} {
		padding: 3.5rem 2rem 3.5rem 3rem;
	}

	${mediaQueries.phone} {
		padding: ${px2rem(60)} ${px2rem(16)};
	}
`;

export const LinksContainerInner = styled.div`
	max-width: ${px2rem(620)};
`;

export const Heading = styled(H1)<StyledProps>`
	margin-bottom: 1rem;
	word-break: break-word;
	color: ${({ bgColor }) =>
		bgColor === 'blue' ? colors.white : colors.greyDark};

	${mediaQueries.phone} {
		font-size: ${px2rem(34)};
	}
`;

export const Preamble = styled.p<StyledProps>`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(19)};
	line-height: ${px2rem(30)};
	color: ${({ bgColor }) =>
		bgColor === 'blue' ? colors.white : colors.greyDark};

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(16)};
		line-height: ${px2rem(24)};
	}
`;

export const LinksHeading = styled(H4)`
	color: ${colors.blueDarker};
	font-weight: ${typography.fontWeight.medium};
	margin-bottom: 2rem;

	${mediaQueries.phone} {
		text-align: center;
	}
`;

export const List = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	column-count: 2;
`;

export const ListItem = styled.li`
	margin-bottom: 1.5rem;
	// Fix for chrome chopping off right column top
	padding-top: ${px2rem(3)};
`;

export const LinkItem = styled.a`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	color: ${colors.linkColorDark};

	${mediaQueries.phone} {
		display: block;
		font-size: ${px2rem(15)};
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&:hover {
		text-decoration: none;
	}
`;

export interface PropsButtonWrapper {
	$hasMoreThanTwoChilds?: boolean;
}

export interface ImageColumnProps {
	numberOfChildren?: number;
}

export const ButtonWrapper = styled.div<PropsButtonWrapper>`
	display: ${(props) => (props.$hasMoreThanTwoChilds ? 'flex' : 'inline-flex')};
	flex-direction: ${(props) =>
		props.$hasMoreThanTwoChilds ? 'row' : 'column'};
	flex-wrap: ${(props) => props.$hasMoreThanTwoChilds && 'wrap'};
	justify-content: ${(props) => props.$hasMoreThanTwoChilds && 'space-between'};
	align-items: flex-start;
	text-align: start;
	width: ${(props) => props.$hasMoreThanTwoChilds && '618px'};

	a {
		margin-bottom: ${px2rem(20)};
	}

	a span {
		max-width: ${px2rem(240)};
		font-size: ${px2rem(16)};
		font-weight: ${typography.fontWeight.medium};
	}

	a svg {
		font-size: ${px2rem(14)} !important;
		width: ${px2rem(14)} !important;
		height: ${px2rem(14)} !important;
	}

	${mediaQueries.mediumDesktop} {
		width: 100%;
		flex-direction: column;
		align-items: left;
	}

	${mediaQueries.tabletLarger} {
		width: 100%;
		flex-direction: column;
		align-items: center;
	}

	${mediaQueries.tablet} {
		width: 100%;
		flex-direction: column;
		align-items: center;
	}

	${mediaQueries.phone} {
		width: 100%;
		flex-direction: column;
		align-items: center;
	}
`;
