/**
 * Breadcrumb - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import Link from 'components/Link';
import Icon from 'components/Icon';

interface StyledProps {
	$withBorder?: boolean;
}

export const baseTextStyles = css`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(14)};
	line-height: ${px2rem(17)};

	${mediaQueries.phone} {
		font-size: ${px2rem(12)};
		line-height: ${px2rem(15)};
	}
`;

export const Container = styled.nav<StyledProps>`
	${({ $withBorder }) => {
		if ($withBorder) {
			return `border-bottom: ${px2rem(1)} solid ${colors.greyLighter};`;
		}
	}}
`;

export const BreadcrumbList = styled.ul`
	margin: 0 auto;
	list-style: none;
	display: flex;
	flex-flow: wrap;

	${mediaQueries.phone} {
		padding: ${px2rem(16)};
	}

	${mediaQueries.tabletOrLarger} {
		padding: ${px2rem(26)} 0;
	}
`;

export const BreadcrumbListItem = styled.li`
	display: inline-flex;
	align-items: center;
	line-height: ${px2rem(29)};
`;

export const BreadcrumbLink = styled(Link)`
	${baseTextStyles};
	color: ${colors.linkColorDark};
	&:hover {
		text-decoration: none;
	}
`;

export const BreadcrumbText = styled.span`
	${baseTextStyles};
	color: ${colors.offGreyLight};
`;

export const BreadcrumbButton = styled.button`
	border: none;
	padding: 0;
`;

export const IconDots = styled(Icon)``;

export const Dot = styled.span`
	background-color: white;
	width: ${px2rem(4.7)};
	height: ${px2rem(4.7)};
	border-radius: 50%;
	display: inline-block;

	&:not(:last-child) {
		margin-right: 3px;
	}
`;

export const ArrowRight = styled.span`
	&:after {
		padding: 0 ${px2rem(14)};
		content: '›';
		text-decoration: none;
	}
`;
