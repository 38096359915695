/**
 * LinkListBlock - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, typography, mediaQueries } from 'theme';
import Link from 'components/Link';
import { H2 } from 'components/Text/Text.styles';
import { darken } from 'polished';
import { Grid, Cell } from 'components/Grid';

export interface ThemeProps {
	headingAlignment?: 'left' | 'center';
	blockAlignment?: 'left' | 'center';
	backgroundColor?:
		| 'lightBlue'
		| 'yellowLighter'
		| 'greyLighter'
		| 'white'
		| 'transparent';
}

export const WrapperContainer = styled.div<ThemeProps>`
	background-color: ${(props) =>
		props.backgroundColor
			? `${colors[props.backgroundColor]};`
			: `${colors.white};`};
`;

export const Container = styled(Grid)`
	margin: 0 auto;
	max-width: ${px2rem(1920)};

	font-family: ${typography.fontFamily.alt};
	padding: 3rem 7.5rem;

	${mediaQueries.phone} {
		padding: 3rem 1rem;
	}
`;

export const StyledCell = styled(Cell)`
	display: flex;
	flex-direction: column;

	&.addLargeTopPadding {
		padding-top: 8.5rem;

		${mediaQueries.phoneOrTablet} {
			padding-top: 0;
		}
	}

	&.addMediumTopPadding {
		padding-top: 5rem;

		${mediaQueries.phoneOrTablet} {
			padding-top: 0;
		}
	}
`;

export const LinkList = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
`;

export const LinkListItem = styled.li`
	${mediaQueries.desktop} {
		&:first-child {
			border-top: ${px2rem(1)} solid ${darken(0.05, colors.greyLightest)};
		}
	}

	border-bottom: ${px2rem(1)} solid ${darken(0.05, colors.greyLightest)};
	::marker {
		font-size: ${px2rem(18)};
		font-family: ${typography.fontFamily.alt};
	}
`;

export const Heading = styled(H2)`
	color: ${colors.greyDark};
	font-size: ${px2rem(24)};
	line-height: ${px2rem(36)};
	font-weight: ${typography.fontWeight.semiBold};
	letter-spacing: 0;
	margin: 0 0 ${px2rem(32)} 0;

	${mediaQueries.phone} {
		text-align: center;
		font-size: ${px2rem(18)};
		line-height: ${px2rem(24)};
	}
`;

export const Preamble = styled.p`
	color: ${colors.greyDark};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(24)};
	letter-spacing: ${px2rem(0.38)};
	margin-bottom: 2rem;

	${mediaQueries.phone} {
		text-align: center;
	}

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(16)};
		line-height: ${px2rem(24)};
	}
`;

export const ReadMoreLabel = styled.span`
	font-size: ${px2rem(18)};
	font-weight: ${typography.fontWeight.medium};
	letter-spacing: 0;
	line-height: ${px2rem(24)};
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 0;
	color: ${colors.linkColorDark};

	&:hover {
		text-decoration: underline;
		-webkit-text-decoration-color: ${colors.linkColorDark};
		text-decoration-color: ${colors.linkColorDark};
	}
`;

export const LeftRow = styled.div`
	max-width: 80%;
	overflow: hidden;
`;

export const RightRow = styled.div`
	display: flex;
	align-items: center;
`;

export const IconBadge = styled.div`
	align-items: center;
	background-color: ${colors.greyLightest};
	border-radius: 1.5rem;
	display: flex;
	height: 3rem;
	width: 3rem;
	justify-content: center;
	margin-right: 1rem;

	:last-child {
		margin-right: 0;
	}

	svg {
		color: ${colors.linkColorDark};
		height: ${px2rem(13)};
		width: ${px2rem(13)};
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 2rem;
`;
