/**
 * MobileMenu
 */

import React, { useState } from 'react';
import { LinkType } from 'types/global';
import { NavMobileWrapper, Nav, MainNav } from './MobileMenu.styles';
import { Transition } from 'react-transition-group';
import { TransitionState } from 'types/global';
import BackLinkProvider from './MobileMenu.context';
import MenuPane from './MobileMenu.Pane';
import MobileMenuTopNav from './MobileMenu.TopNav';
import MobileMenuSelectedNav from './MobileMenu.SelectedNav';
import { ModelTranslations } from 'types/epi';

interface Props {
	/** Array of menu items */
	items: any[];

	/** Current menu state (open/closed) */
	open: boolean;

	/** Callback method when the menu closes */
	onClose?: any;

	/** Language links */
	languages?: LinkType;

	/** Translations */
	translations: ModelTranslations;

	/** PaneMaxHeight */
	paneMaxHeight: number;

	/** Function for setting paneMaxHeight */
	handleSetPaneMaxHeight: any;

	/** Current Language links */
	currentLang?: 'sv' | 'en';

	/** suggestion */
	buttonLink: LinkType;
}

/** Mobile menu. */
const MobileMenu: React.FC<Props> = ({
	items,
	open,
	translations,
	languages,
	handleSetPaneMaxHeight,
	paneMaxHeight,
	buttonLink,
	currentLang,
}) => {
	const [linkText, setLinkText] = useState('');
	const [linkUrl, setLinkUrl] = useState('');

	return (
		<BackLinkProvider>
			<Transition in={open} timeout={500}>
				{(state: TransitionState) => (
					<NavMobileWrapper state={state} hidden={state === 'exited'}>
						{state !== 'exited' && (
							<Nav aria-label={translations.menuLabel}>
								<MobileMenuTopNav
									suggestionButtonText={translations.suggestionButtonText}
									languages={languages}
									handleSetPaneMaxHeight={handleSetPaneMaxHeight}
									buttonLink={buttonLink}
									translations={translations}
									currentLang={currentLang}
								/>

								{linkUrl && (
									<MobileMenuSelectedNav
										backLinkText={linkText || translations.backLinkText}
										backLinkUrl={linkUrl}
									/>
								)}

								<MainNav>
									<MenuPane
										label={translations.menuLabel}
										level={1}
										links={items}
										initialState="open"
										languages={languages}
										open={open}
										paneMaxHeight={paneMaxHeight}
										handleSetLinkText={setLinkText}
										handleSetLinkUrl={setLinkUrl}
									/>
								</MainNav>
							</Nav>
						)}
					</NavMobileWrapper>
				)}
			</Transition>
		</BackLinkProvider>
	);
};

export default MobileMenu;
