import React from 'react';
import { GraphicsBlockModel } from '../../types/blocks';
import { GraphicsBlockItemModel } from '../../types/blocks';
import EpiFragmentsWiw from '../EpiFragmentsWiw';
import GraphicsBlockItem from '../GraphicsBlockItem/GraphicsBlockItem';
import styled from 'styled-components';
import { colors, mediaQueries } from 'theme';
import { px2rem } from '../../theme/utils';
import { Colors } from '../../theme/colors';
import Icon from '../Icon';

const GraphicsBlockWrapper = styled.div<{ $backgroundColor: keyof Colors }>`
	background: ${(props) => colors[props.$backgroundColor] || '#fff'};
	width: 100%;
	${mediaQueries.phone} {
		text-align: center;
	}
`;

const GraphicsBlockInner = styled.div`
	max-width: ${px2rem(788)};
	margin-inline: auto;
	padding-top: 1rem;
	padding-bottom: 4rem;
	${mediaQueries.phone} {
		padding-inline: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	${mediaQueries.tablet} {
		padding-inline: 4rem;
	}
`;

const GraphicsBlockHeading = styled.h2`
	margin-block: 0;
	padding-top: 4rem;
	font-size: 2.75rem;
	font-family: 'Euclid Circular B', sans-serif;
	text-align: left;
	margin-bottom: 1rem;
	${mediaQueries.phone} {
		font-size: 2rem;
		text-align: center;
	}
`;

const GraphicsBlockDivider = styled.hr`
	width: 100%;
	height: 2px;
	border: 1px solid ${colors.offGreyLightest};
	margin-bottom: 56px;
`;

const GraphicsBlockLink = styled.a`
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
	width: fit-content;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-family: 'Euclid Circular B', sans-serif;
	color: ${colors.linkColorDark};
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`;

const IconContainer = styled.div`
	margin-right: 0.5rem;

	${mediaQueries.phone} {
		text-align: right;
	}
`;

const LinkTextContainer = styled.div`
	padding-top: 2.5px;
	text-align: start;
`;

const GraphicsBlock: React.FC<GraphicsBlockModel> = (props) => {
	return (
		<GraphicsBlockWrapper $backgroundColor={props.backgroundColor}>
			<GraphicsBlockInner>
				<GraphicsBlockHeading>{props.heading}</GraphicsBlockHeading>
				<EpiFragmentsWiw fragments={props?.text?.fragments} />
				{props.link && (
					<GraphicsBlockLink href={props.link.link}>
						{props.link.link && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
								}}
							>
								<IconContainer>
									<Icon icon={'arrowRight'} size={0} color={colors.orange} />
								</IconContainer>
								<LinkTextContainer>{props.link.text}</LinkTextContainer>
							</div>
						)}
					</GraphicsBlockLink>
				)}
				<GraphicsBlockDivider />
				{props?.graphicsContentArea?.map(
					(
						item: GraphicsBlockItemModel,
						index: string | number | null | undefined
					) => (
						<GraphicsBlockItem
							key={index}
							preamble={item.preamble}
							heading={item.heading}
							image={item.image}
							link={item.link}
						/>
					)
				)}
			</GraphicsBlockInner>
		</GraphicsBlockWrapper>
	);
};

export default GraphicsBlock;
