/**
 * NavMobile - SelectedNav
 */

import React, { useContext } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionState } from 'types/global';
import Icon from 'components/Icon';
import { BackLinkContext } from './MobileMenu.context';
import {
	SelectedNav,
	Link,
	LiBack,
	LiBackText,
	IconButton,
} from './MobileMenu.styles';

interface Props {
	/** Back link text */
	backLinkText?: string;

	/** Back link url */
	backLinkUrl?: string;
}

const NavMobileSelectedNav: React.FC<Props> = ({
	backLinkText,
	backLinkUrl,
}) => {
	const { backLinkProps } = useContext(BackLinkContext);

	return (
		<SelectedNav>
			<Transition
				in={backLinkProps?.onClick !== undefined}
				timeout={500}
				mountOnEnter={true}
				unmountOnExit={true}
			>
				{(state: TransitionState) => (
					<LiBack state={state}>
						<IconButton
							topNav
							onClick={(e) => {
								e.preventDefault();
								backLinkProps?.onClick();
							}}
						>
							<Icon
								icon="arrowRight"
								size={0}
								aria-hidden={true}
								direction="left"
							/>
						</IconButton>
						<Link href={backLinkUrl}>
							<LiBackText>{backLinkText}</LiBackText>
						</Link>
					</LiBack>
				)}
			</Transition>
		</SelectedNav>
	);
};

export default NavMobileSelectedNav;
