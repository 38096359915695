/**
 * CaseListPage
 */

import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { useUrlQuery, useDebounce, useSearchResult } from 'hooks';
import { getActiveFilters } from 'common/helpers';

import { CaseListPageModel } from 'types/pages';
import { CaseHitList } from 'types/listings';

import {
	Content,
	ResultsContainer,
	CaseBoxGrid,
	CaseBoxCell,
} from './CaseListPage.styles';

import { GridProps } from 'components/Grid';

import SearchForm from 'components/SearchForm';
import LoadMorePagination from 'components/LoadMorePagination';
import EpiContentArea from 'components/EpiContentArea';

import Breadcrumb from 'components/Breadcrumb';
import CaseLinkBox from 'components/CaseBox/CaseLinkBox';

/** Page template for a case list page. */
const CaseListPage: React.FC<CaseListPageModel> = ({
	topContentArea,
	breadcrumbs,
	filter,
	pagination,
	items,
	translations,
	resultsPhrase,
	_properties = {},
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const [action, setAction] = useState('');
	const activeFilters = getActiveFilters(filter);
	const [values, setValues] = useState<any>({
		query: filter.query || '',
		...activeFilters,
	});

	const [currentPage, setCurrentPage] = useState<number>(
		pagination.currentPage
	);

	const debouncedInput = useDebounce(values.query, 500);

	const [updateQuery, response, loading, error] = useUrlQuery({
		...values,
		query: debouncedInput,
		page: currentPage,
	});

	const [
		filters,
		paginations,
		results,
		responseResultsPhrase,
	] = useSearchResult(
		filter,
		pagination,
		items,
		response,
		action,
		resultsPhrase
	);

	const paginationPhrase = translations.paginationPhrase.split('{0}');

	return (
		<main id="main-content">
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>
			<EpiContentArea
				{..._properties?.topContentArea}
				content={topContentArea}
				isPageHeader={true}
			/>

			<SearchForm
				translations={{
					searchHeading: translations.searchHeading,
					searchButtonLabel: translations.searchButtonLabel,
					searchFieldLabel: translations.searchFieldLabel,
					searchFieldPlaceholder: translations.searchFieldPlaceholder,
					errorText: translations.errorText,
					sortBy: translations.sortBy,
					clearFilterText: translations.clearFilterText,
					clearAllFiltersText: translations.clearAllFiltersText,
					hiddenSubmitText: translations.hiddenSubmitText,
					filterHeading: translations.filterHeading,
				}}
				resultsPhrase={responseResultsPhrase}
				_properties={_properties}
				isPhone={isPhone}
				loading={loading}
				error={error}
				filter={filters}
				pagination={paginations}
				values={values}
				handleSetAction={setAction}
				handleSetCurrentPage={setCurrentPage}
				handleSetValues={setValues}
				handleUpdateQuery={updateQuery}
				displaySearchHeader
				displaySortWrapper
				displayHiddenSubmitButton
				autoSubmitOnClear={true}
			/>

			<Content>
				<ResultsContainer id="case-list">
					{results && results.length > 0 && (
						<>
							<CaseBoxGrid inner={true} manualGutter={GridProps.smallGap}>
								{results.map((row: CaseHitList, i: number) => (
									<CaseBoxCell key={i} span={isPhone ? 12 : 4}>
										<CaseLinkBox
											index={i}
											item={row}
											isCaseListPage
											readMoreText={translations.readMoreLabel}
										/>
									</CaseBoxCell>
								))}
							</CaseBoxGrid>

							<LoadMorePagination
								visible={paginations.currentPage < paginations.pageCount}
								loading={loading}
								buttonText={translations.showMoreButtonLabel}
								totalDisplay={paginations.totalDisplay}
								totalHits={paginations.total}
								paginationPhrase={paginationPhrase}
								onClick={() => {
									setCurrentPage((page) => page + 1);
									updateQuery({ ...values, page: currentPage + 1 });
									setAction('add');
								}}
							/>
						</>
					)}
				</ResultsContainer>
			</Content>
		</main>
	);
};

export default CaseListPage;
