/**
 * DesktopMenu - styles
 */

import styled, { css } from 'styled-components';
import { baseTextStyles } from 'components/Text/Text.styles';
import { px2rem } from 'theme/utils';
import { customMediaQueryValues } from 'theme/media-queries';
import { colors, typography, animations } from 'theme';
import { lighten } from 'polished';
import { TransitionState, MenuLinkVariant } from 'types/global';
import Link from 'components/Link';
import Icon from 'components/Icon';
import { UnstyledButton } from 'components/Button/Button.styles';

import { linkCss } from 'components/PageHeader/PageHeader.styles';

interface PaneProps {
	state: TransitionState;
	activeTrail?: boolean;
}

interface ListItemProps {
	state?: TransitionState;
	active?: boolean;
	selected?: boolean;
	isChild?: boolean;
	hasChildren?: boolean;
	childLevel?: number;
	bgColor?: boolean;
}

interface LinkProps {
	variant?: MenuLinkVariant;
}

const baseLiStyle = css`
	${baseTextStyles};
	font-weight: ${typography.fontWeight.normal};
	min-height: ${px2rem(57)};
	color: ${colors.greyDark};
`;

const baseLiChildStyle = css`
	font-size: ${px2rem(15)};
	line-height: 1.13;
`;

export const getPaneAnimations = (
	transitionState: string,
	activeTrail: boolean = false
) => {
	let animationTime = '.5s';
	let animationCss = `
		transition: transform ${animationTime} cubic-bezier(.4,0,.2,1), opacity ${animationTime} cubic-bezier(.4,0,.2,1);
		-webkit-perspective: 1000;
		-webkit-backface-visibility: hidden;
		${animations.moveInRight}
		${animations.moveOutRight}
	`;

	if (transitionState === 'exiting') {
		animationCss += `animation: moveOutRight ${animationTime} cubic-bezier(.4,0,.2,1) forwards;`;
	} else if (transitionState === 'entering') {
		animationCss += `animation: moveInRight ${animationTime} cubic-bezier(.4,0,.2,1) forwards;`;
	} else if (transitionState === 'entered' && activeTrail) {
		animationCss += `
			transform: translate3d(-30%, 0, 0);
			opacity: 0;
		`;
	} else if (transitionState === 'entered' && !activeTrail) {
		animationCss += `
			transform: translate3d(0, 0, 0);
			opacity: 1;
		`;
	}

	return animationCss;
};

export const getBackLinkAnimations = (transitionState: TransitionState) => {
	let animationTime = '.5s';
	let animationCss = `
		${animations.moveInLeft}
		${animations.moveOutLeft}
	`;

	if (transitionState === 'exiting' || transitionState === 'exited') {
		animationCss += `animation: moveOutLeft ${animationTime} cubic-bezier(.4,0,.2,1) forwards;`;
	} else if (transitionState === 'entering' || transitionState === 'entered') {
		animationCss += `animation: moveInLeft ${animationTime} cubic-bezier(.4,0,.2,1) forwards;`;
	}

	return animationCss;
};
export const Nav = styled.nav`
	position: absolute;
	right: 0;
	height: 100%;
	background-color: ${colors.white};
	min-width: ${px2rem(379)};

	${customMediaQueryValues(1920)} {
		right: ${px2rem(28)};
	}
`;

export const NavDesktopWrapper = styled.div<PaneProps>`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: 0;
	overflow: hidden;
	z-index: 99;
	background-color: ${colors.blackOpacity20};

	${({ state }) =>
		state === 'exiting' && ` animation: fadeOut .5s ease-out forwards; `}
	${({ state }) =>
		state === 'entering' && ` animation: fadeIn .5s ease-out forwards; `}

	${animations.fadeIn}
	${animations.fadeOut}

	& > ${Nav} {
		overflow-x: hidden;

		${({ state }) =>
			state === 'exiting' &&
			` animation: moveOutRight .5s cubic-bezier(.4,0,.2,1) forwards; `}
		${({ state }) =>
			state === 'entering' &&
			` animation: moveInRight .5s cubic-bezier(.4,0,.2,1) forwards; `}

		${animations.moveInRight}
		${animations.moveOutRight}
	}
`;

export const NavDesktop = styled.div`
	max-width: ${px2rem(1920)};
	margin: 0 auto;
	height: 100%;
	position: relative;
`;

export const TopNav = styled.div`
	position: relative;
	height: ${px2rem(91)};
	margin: 0;
	padding: 0 ${px2rem(19)};
	background-color: ${colors.blueLighter};

	${customMediaQueryValues(1920)} {
		padding-right: 0;
	}
`;

export const SelectedNav = styled.ul`
	position: relative;
	align-items: center;
	list-style: none;
	margin: 0;
	height: auto;
	padding: 0;
	border-bottom: ${px2rem(1)} solid ${colors.greyLighter};
`;

export const TopNavLi = styled.li`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1 0 0;

	&:not(:last-child) {
		border-right: ${px2rem(1)} solid ${colors.greyLightest};
	}
`;

export const MainNav = styled.div`
	overflow: auto;
	max-height: calc(100vh - ${px2rem(91)});
`;

export const Pane = styled.div`
	margin: 0;
	height: 100%;
	width: 100%;
	background-color: ${colors.white};
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	&:focus {
		outline: none;
	}
`;

export const StyledUl = styled.ul<ListItemProps>`
	list-style: none;
	padding: 0;
	margin: 0;

	${({ isChild, childLevel }) => {
		if (isChild) {
			return css`
				background-color: ${childLevel && childLevel % 2 == 0
					? lighten(0.02, colors.greyLightest)
					: colors.white};
			`;
		}
	}}
`;

export const LinkText = styled.span``;

export const StyledLink = styled(Link)<LinkProps>`
	text-decoration: none;
	color: inherit;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	min-height: 1.5rem;

	&:hover {
		${LinkText} {
			text-decoration: underline;
		}
	}

	${({ variant }) => variant === 'showAllLink' && `text-decoration: underline;`}
`;

export const LinkSmall = styled.a`
	display: block;
	text-decoration: none;
	color: inherit;
	margin: 0 0 1rem;
`;

export const LiItemTopArea = styled.div<ListItemProps>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${px2rem(57)};
	padding: ${px2rem(3)} ${px2rem(31)};
	border-bottom: ${px2rem(1)} solid ${colors.greyLighter};
	padding-left: ${(props) =>
		props.childLevel ? px2rem(props.childLevel * 20) : px2rem(18)};

	background-color: ${({ selected, bgColor }) =>
		selected ? colors.blueLighter : bgColor ? colors.greyLightest : 'inherit'};

	text-decoration: ${(props) =>
		props.active || props.selected ? 'underline' : 'none'};

	${({ bgColor }) =>
		bgColor ? `border-top: ${px2rem(1)} solid ${colors.greyLighter};` : ''}
`;

export const WIWLevel1 = styled.li<ListItemProps>`
	${baseLiStyle};
	font-size: ${px2rem(16)};
	line-height: 1.17;
	background-color: ${colors.melon};
`;

export const LiLevel1 = styled.li<ListItemProps>`
	${baseLiStyle};
	font-size: ${px2rem(16)};
	line-height: 1.17;
`;

export const LiLevel2 = styled.li<ListItemProps>`
	${baseLiStyle};
	${baseLiChildStyle};
`;

export const LiLevel3 = styled.li<ListItemProps>`
	${baseLiStyle};
	${baseLiChildStyle};
	font-weight: ${typography.fontWeight.normal};
`;

export const LinkItem = styled(Link)`
	${linkCss}
	font-size: 0.9375rem;
	font-weight: 500;
	text-decoration: none;
	display: flex;
	align-items: center;
	height: 100%;
	border: none;
	padding: ${px2rem(34)} 2rem ${px2rem(30)} 2rem;
	transition: all 0.2s;
	background-color: ${colors.blueLighter};
	margin-left: auto;

	&:hover,
	&:focus {
		background-color: ${colors.blueLighter};
	}
	cursor: pointer;
`;

export const IconButton = styled(UnstyledButton)`
	width: auto;
	padding: ${px2rem(15)};
	cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
	width: ${px2rem(12)};
	height: ${px2rem(12)};
`;

export const FlagIcon = styled(Icon)`
	width: ${px2rem(18)};
	height: ${px2rem(18)};
	margin-left: 1rem;
`;
