/**
 * BaseLayout
 */

import React from 'react';
import { useSelector } from 'react-redux';
import {
	selectHeader,
	selectFooter,
	selectTranslations,
	selectApi,
} from 'store/modules/model';

import Meta from 'components/Meta';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import SkipToMain from 'components/SkipToMain';

/** BaseLayout that adds header and footer to the page. */
const BaseLayout: React.FC = ({ children }) => {
	const header: any = useSelector(selectHeader);
	const footer: any = useSelector(selectFooter);
	const translations: any = useSelector(selectTranslations);
	const api: any = useSelector(selectApi);

	return (
		<>
			<Meta />
			<SkipToMain target="#main-content" text={translations.skipToMainText} />
			<PageHeader {...header} translations={translations} api={api} />
			{children}
			<PageFooter {...footer} translations={translations} />
		</>
	);
};

export default BaseLayout;
