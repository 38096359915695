/**
 * MobileMenu - Pane
 */

import React, {
	useEffect,
	useState,
	useContext,
	useRef,
	MouseEvent,
} from 'react';

import { Transition } from 'react-transition-group';
import { BackLinkContext } from './MobileMenu.context';
import { useFocus } from 'hooks';
import { TransitionState, LinkType } from 'types/global';
import { Pane, Ul } from './MobileMenu.styles';
import Li from './MobileMenu.Li';
import MobileMenuLanguage from './MobileMenuLanguage';

type States =
	| 'open'
	| 'closed'
	| 'activeTrail'
	| 'activeTrailIn'
	| 'activeTrailOut';

interface PaneProps {
	/** Aria-label for the pane */
	label: string;

	/** Links for the pane structured as JSON */
	links: LinkType[];

	/** The pane level (1, 2, 3) */
	level: number;

	/** Function to control the parent pane state */
	setParentState?: any;

	/** Initial state for the pane */
	initialState?: States;

	/** Function for setting the backlinktext */
	handleSetLinkText: any;

	/** Function for setting the backlinkurl */
	handleSetLinkUrl: any;

	/** Current menu state (open/closed) */
	open: boolean;

	/** PaneMaxHeight */
	paneMaxHeight: number;

	/** Language links */
	languages?: LinkType;
}

/** A menu pane (ul) used by MobileMenu */
const MenuPane: React.FC<PaneProps> = ({
	label,
	links,
	level,
	open,
	setParentState,
	handleSetLinkText,
	handleSetLinkUrl,
	paneMaxHeight,
	languages,
	initialState = 'closed',
}) => {
	const [paneRef, setPaneFocus] = useFocus();
	// const [paneMaxHeight, setPaneMaxHeight] = useState(0);
	const [state, setState] = useState(initialState);
	const [child, setChild] = useState<any>(null);
	const { setBackLinkProps } = useContext(BackLinkContext);
	const prevState = useRef<States>();

	const backClick = () => {
		setState('closed');
		if (setParentState) {
			setParentState('activeTrailIn');
		}
	};

	const handleSetActive = (link: LinkType, currentLevel: number) => {
		const childLinks = link.children || [];
		let links: LinkType[] = [];

		if (currentLevel >= 2) {
			links = [
				{
					text: link.text,
					url: link.link,
					variant: 'headerLink',
				},
				...childLinks,
			];
		} else {
			links = childLinks;
		}
		handleSetLinkText(link.text);
		handleSetLinkUrl(link.link);

		setChild(
			<MenuPane
				label={link.text}
				level={currentLevel + 1}
				links={links}
				setParentState={setState}
				open={open}
				paneMaxHeight={paneMaxHeight}
				handleSetLinkText={handleSetLinkText}
				handleSetLinkUrl={handleSetLinkUrl}
			/>
		);
		setState('activeTrail');
	};

	const paneLinkClick = (e: MouseEvent, link: LinkType) => {
		e.preventDefault();
		handleSetActive(link, level);
	};

	useEffect(() => {
		prevState.current = state;
	});

	useEffect(() => {
		setState('open');
	}, []);

	useEffect(() => {
		if (state === 'open') {
			setTimeout(() => {
				setPaneFocus();
				setChild(null);
			}, 100);

			if (level > 1) {
				handleSetLinkText(label);
				setBackLinkProps({ onClick: backClick });
			} else {
				setBackLinkProps({});
			}
		} else if (state === 'activeTrail') {
			setTimeout(() => {
				setState('activeTrailOut');
			}, 500);
		} else if (state === 'activeTrailIn') {
			setTimeout(() => {
				setState('open');
			}, 10);
		}
		// eslint-disable-next-line
	}, [state]);

	return (
		<>
			<Transition in={state !== 'closed'} timeout={500}>
				{(transitionState: TransitionState) => (
					<Pane
						state={transitionState}
						activeTrail={
							state === 'activeTrail' ||
							state === 'activeTrailIn' ||
							state === 'activeTrailOut'
						}
						hidden={state === 'activeTrailOut' || transitionState === 'exited'}
						aria-label={label}
						ref={paneRef}
						tabIndex={-1}
					>
						<Ul
						// height={paneMaxHeight}
						// {...paneRef.current && { height: paneRef.current.scrollHeight }}
						>
							{links.map((link: LinkType, index) => {
								if (link.text !== label) {
									return (
										<Li
											link={link}
											level={level}
											onLinkClick={paneLinkClick}
											key={index}
											handleSetActive={handleSetActive}
										/>
									);
								}
							})}

							{languages?.link && <MobileMenuLanguage languages={languages} />}
						</Ul>
					</Pane>
				)}
			</Transition>
			{child}
		</>
	);
};

export default MenuPane;
