/**
 * CasePage
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { ResearchResultPageModel } from 'types/pages';
import { Grid } from 'components/Grid';
import Breadcrumb from 'components/Breadcrumb';
import {
	FragmentsContainer,
	InfoContent,
	InfoWrapper,
	LeftCell,
	RightCell,
	TextArea,
	InfoHeading,
	InfoText,
	InfoDownload,
} from './ResearchResultPage.styles';
import PageLayout from 'layouts/PageLayout';
import RichText from 'components/RichText';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { colors } from 'theme';
import EpiFragments from 'components/EpiFragments';

/** CasePage. */
const ResearchResultPage: React.FC<ResearchResultPageModel> = ({
	researchResultCategory,
	statusResearchResult,
	researchResultYear,
	applicationFile,
	applicationFileText,
	text,
	id,
	heading,
	url,
	modelType,
	breadcrumbs,
	translations,
	_properties = {},
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	return (
		<main id="main-content">
			{breadcrumbs && (
				<Breadcrumb
					links={breadcrumbs}
					label={translations.breadcrumbsLabel}
					withBorder
				/>
			)}

			<PageLayout labelledBy="article-heading">
				<Grid inner={true}>
					<LeftCell span={7} phone={12}>
						<Text as="h1" {..._properties?.heading} id="article-heading">
							{heading}
						</Text>

						<InfoDownload
							aria-label={applicationFileText}
							href={applicationFile}
							target="_blank"
						>
							<span>{applicationFileText}</span>
							<span>
								<Icon
									icon="pdf"
									color={colors.blue}
									size={0}
									aria-hidden="true"
								></Icon>
							</span>
						</InfoDownload>

						<FragmentsContainer>
							<EpiFragments
								{..._properties?.text}
								fragments={text?.fragments}
							/>
						</FragmentsContainer>
					</LeftCell>

					<RightCell span={5}>
						<InfoWrapper>
							<InfoContent>
								<TextArea>
									<InfoHeading as="h2">{translations.category}</InfoHeading>
									<InfoText>{researchResultCategory}</InfoText>

									<InfoHeading as="h2">{translations.year}</InfoHeading>
									<InfoText>{researchResultYear}</InfoText>

									<InfoHeading as="h2">{translations.status}</InfoHeading>
									<InfoText>{statusResearchResult}</InfoText>
								</TextArea>
							</InfoContent>
						</InfoWrapper>
					</RightCell>
				</Grid>
			</PageLayout>
		</main>
	);
};

export default ResearchResultPage;
