/**
 * Checks and returns outcome of field actions the given field is dependent on
 * @param {Array} fieldActions - Actions the field is depending on
 * @param {Object} values - The current values in form
 */
export const checkFieldActions = (fieldActions: any[], values: any) => {
	let actions = {};
	if (fieldActions && fieldActions.length > 0) {
		let allActionOutcomes: any = {
			required: [],
		};

		fieldActions.forEach((action) => {
			const fieldId = `__field_${action.field}`;

			switch (action.thenAction) {
				case 'Hem.Features.Forms.Validators.IsRequiredValidator':
					const operatorResult = checkOperator(
						action.operator,
						values[fieldId],
						action.whenValue
					);
					allActionOutcomes.required.push(operatorResult);
					break;
				default:
					break;
			}
		});

		Object.keys(allActionOutcomes).forEach((attribute) => {
			if (allActionOutcomes[attribute].includes(false)) {
				actions = { ...actions, [attribute]: false };
			} else {
				actions = { ...actions, [attribute]: true };
			}
		});
	}
	return actions;
};

/**
 * Checks the outcome of an operator
 * @param {String} operator - equal
 * @param {String/Number} dependencyField - Value of the field the action is connected to
 * @param {String} compareValue - Value to compare against/expected value
 */
const checkOperator = (
	operator: string,
	dependencyField: string,
	compareValue: string
) => {
	switch (operator) {
		case 'equal':
			return dependencyField === compareValue;
		default:
			break;
	}
};
