/**
 * SearchBox - styles
 */

import styled, { css } from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import Icon from 'components/Icon';

interface StyledProps {
	open?: boolean;
}

const textCss = css`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(20)};
`;

export const Container = styled.div<StyledProps>`
	background-color: ${colors.blueLighter};
	padding: ${px2rem(75)} 0 ${px2rem(50)};
	box-shadow: 0 ${px2rem(2)} ${px2rem(4)} 0 rgba(0, 0, 0, 0.25);

	display: ${({ open }) => (open ? 'block' : 'none')};
	position: absolute;
	right: 0;
	left: 0;
	top: ${px2rem(91)};

	${mediaQueries.phoneOrTablet} {
		top: ${px2rem(78)};
	}

	${mediaQueries.phone} {
		padding: ${px2rem(56)} 0;
	}
`;

export const InnerContainer = styled.div`
	max-width: ${px2rem(622)};
	margin: 0 auto;
`;

export const CloseContainer = styled.div`
	max-width: ${px2rem(311)};
	padding-left: ${px2rem(25)};
	margin: inherit;
`;

export const CloseIcon = styled(Icon)`
	width: ${px2rem(14)};
	height: ${px2rem(14)};
	margin-right: 0.5rem;
	margin-bottom: ${px2rem(2)};
`;

export const CloseButton = styled.button`
	${textCss}
	color: ${colors.blue};
	text-decoration: underline;
	font-weight: ${typography.fontWeight.medium};

	margin-top: ${px2rem(20)};
	background-color: transparent;
	border: none;

	&:hover,
	&:focus {
		background-color: ${colors.blueLighter};
	}
	cursor: pointer;

	${mediaQueries.phone} {
		display: none;
	}
`;
