/**
 * CampaignTextBlock
 */

import React from 'react';
import {
	Container,
	TextContainer,
	Heading,
	StyledPreamble,
	WrapperContainer,
} from './CampaignTextBlock.styles';
import EpiFragmentsWiw from 'components/EpiFragmentsWiw';
import { LinkType } from '../../types/global';
import TextWiw from '../TextWiw';
import Link from '../Link';
import styled from 'styled-components';
import { colors } from 'theme';

const CampaignTextBlockLink = styled(Link)`
	color: ${colors.linkColorDark};
	display: block;
	margin-block: 1.25rem;
	font-size: 1.125rem;
	line-height: 2.25rem;
	&:hover {
		text-decoration: none;
	}
`;

interface Props {
	heading?: string;
	preamble?: string;
	text?: any;
	backgroundColor?: 'lightBlue' | 'white' | 'transparent';
	headingAlignment?: 'left' | 'center';
	blockAlignment?: 'left' | 'center';
	isPageHeader?: boolean;
	links?: LinkType[];
	headingForLinks?: string;
}

/** CampaignTextBlock */
const CampaignTextBlock: React.FC<Props> = ({
	heading,
	preamble,
	text,
	links,
	headingForLinks,
	backgroundColor = 'transparent',
	headingAlignment = 'left',
	blockAlignment = 'left',
	isPageHeader,
}) => {
	return (
		<WrapperContainer backgroundColor={backgroundColor}>
			<Container
				backgroundColor={backgroundColor}
				blockAlignment={blockAlignment}
			>
				<TextContainer>
					{heading && (
						<Heading
							as={isPageHeader ? 'h1' : 'h2'}
							headingAlignment={headingAlignment}
						>
							{heading}
						</Heading>
					)}

					{preamble && <StyledPreamble>{preamble}</StyledPreamble>}

					<EpiFragmentsWiw fragments={text?.fragments} />

					{headingForLinks && links && (
						<div>
							<TextWiw as={'h2'}>{headingForLinks}</TextWiw>
							{links.map((link, index) => (
								<CampaignTextBlockLink key={index} href={link.link}>
									{link.text}
								</CampaignTextBlockLink>
							))}
						</div>
					)}
				</TextContainer>
			</Container>
		</WrapperContainer>
	);
};

export default CampaignTextBlock;
