/**
 * CaseBox - styles
 */

import styled, { css } from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import Link from 'components/Link';
import { H5, H6, P } from 'components/Text/Text.styles';

export interface StyleProps {
	isCaseListPage?: boolean;
}

export const baseStatusStyle = css`
	flex: 1 0 0;
	margin: 0;
	text-align: center;
	padding: ${px2rem(15)};
`;

export const Heading = styled(H5)<StyleProps>`
	margin-bottom: ${px2rem(15)};
	font-size: ${px2rem(18)};
	word-break: normal;

	${({ isCaseListPage }) =>
		isCaseListPage &&
		`
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
	`}
`;

export const CaseWrapper = styled.article<StyleProps>`
	display: flex;
	color: black;
	flex-direction: column;
	justify-content: space-between;
	height: ${(props) => (props.isCaseListPage ? '100%' : 'auto')};
	border: ${px2rem(1)} solid ${colors.offGreyLightest};
	&:not(:last-child) {
		margin-bottom: ${px2rem(13.5)};
	}
`;

export const Content = styled.div`
	padding: ${px2rem(30)};
	width: 100%;
	height: 100%;

	${mediaQueries.phone} {
		padding: ${px2rem(16)};
	}
`;

export const CaseLink = styled(Link)`
	color: ${colors.black};
	text-decoration: none;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	&:hover {
		text-decoration: none;
	}
`;

export const ArrowLink = styled.div`
	background-color: ${colors.offBlueLightest};
	color: ${colors.linkColorDark};
	padding: ${px2rem(15)};
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: ${typography.fontWeight.medium};
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	svg {
		margin-left: ${px2rem(10)};
		width: ${px2rem(14)};
		height: ${px2rem(14)};
	}
`;

export const TextArea = styled.div`
	&:not(:last-child) {
		margin-bottom: ${px2rem(15)};
	}
	word-break: normal;
`;

export const TextHeading = styled(H6)`
	font-size: ${px2rem(16)};
	word-break: normal;
`;

export const Text = styled(P)`
	margin: 0;
	font-size: ${px2rem(16)};
	line-height: ${px2rem(20)};
	word-break: normal;
`;
