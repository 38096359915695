import { math } from 'polished';

export interface Breakpoints {
	[device: string]: string;
}

export interface BreakpointsNumber {
	[device: string]: number;
}

// Must be set in numbers
export const breakpointsNumber: BreakpointsNumber = {
	largeDesktop: 1440,
	mediumDesktop: 1024,
	desktop: 840,
	tablet: 480,
	phone: 0,
};

// Must be set in pixels
export const breakpoints: Breakpoints = {
	largeDesktop: `${breakpointsNumber.largeDesktop}px`,
	mediumDesktop: `${breakpointsNumber.mediumDesktop}px`,
	desktop: `${breakpointsNumber.desktop}px`,
	tablet: `${breakpointsNumber.tablet}px`,
	phone: `${breakpointsNumber.phone}px`,
};

export const customMediaQueryValues = (min: number, max?: number) =>
	`@media screen and (min-width: ${min}px) ${
		max ? 'and (max-width: ${max}px)' : ''
	}`;

const mediaQueryValues = {
	largeDesktop: `@media screen and (min-width: ${breakpoints.largeDesktop})`,
	mediumDesktop: `@media screen and (min-width: ${
		breakpoints.mediumDesktop
	}) and (max-width: ${math(`${breakpoints.largeDesktop} - 1px`)})`,
	desktop: `@media screen and (min-width: ${breakpoints.desktop})`,
	toDesktop: `@media screen and (min-width: ${
		breakpoints.phone
	}) and (max-width: ${math(`${breakpoints.desktop} - 1px`)})`,
	tabletOrLarger: `@media screen and (min-width: ${breakpoints.tablet})`,
	phoneOrTablet: `@media screen and (min-width: ${
		breakpoints.phone
	}) and (max-width: ${math(`${breakpoints.desktop} - 1px`)})`,
	tabletLarger: `@media screen and (min-width: ${
		breakpoints.desktop
	}) and (max-width: ${math(`${breakpoints.mediumDesktop}`)})`,
	tablet: `@media screen and (min-width: ${
		breakpoints.tablet
	}) and (max-width: ${math(`${breakpoints.desktop} - 1px`)})`,
	phone: `@media screen and (min-width: ${
		breakpoints.phone
	}) and (max-width: ${math(`${breakpoints.tablet} - 1px`)})`,
} as const;
type MediaQueries = typeof mediaQueryValues;
export const mediaQueries: MediaQueries = mediaQueryValues;
