/**
 * PageWrapper
 */

import React from 'react';

import { PageWrapper } from './PageLayout.styles';

type Props = {
	/** Page item */
	children?: any;

	labelledBy?: string;
};

/** BaseLayout for blocks that sets max-width on inner component. */
const PageLayout: React.FC<Props> = ({ children, labelledBy }) => (
	<PageWrapper as={labelledBy ? 'article' : 'div'} aria-labelledby={labelledBy}>
		{children}
	</PageWrapper>
);

export default PageLayout;
