const baseFontSize: number = 16;

export const px = (sizeInPx: number) => sizeInPx / baseFontSize;
export const px2rem = (sizeInPx: number) => `${px(sizeInPx)}rem`;

/**
 * Get rgba from hex and alpha
 * hex2rgba(#0459A9, 0.2) returns rgba(24,62,107,0.2)
 * @param hex String
 * @param alpha Number
 */
export const hex2rgba = (hex: string = '#000000', alpha: number = 1) => {
	const [r, g, b] = (hex.match(/\w\w/g) || []).map((x) => parseInt(x, 16));
	return `rgba(${r},${g},${b},${alpha})`;
};
