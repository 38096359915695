import { useRef } from 'react';

interface UseFocus {
	(): [any, any];
}

export const useFocus: UseFocus = () => {
	const htmlElRef = useRef<any>(null);
	const setFocus = () => {
		htmlElRef.current && htmlElRef.current.focus();
	};

	return [htmlElRef, setFocus];
};
