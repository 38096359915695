/**
 * NewsPage
 */

import React from 'react';
import { NewsPageModel } from 'types/pages';

import Text from 'components/Text';
import { Grid } from 'components/Grid';
import EpiFragments from 'components/EpiFragments';
import EpiContentArea from 'components/EpiContentArea';
import Breadcrumb from 'components/Breadcrumb';

import {
	FragmentsContainer,
	DateArea,
	Category,
	PublishedDate,
	LastModifiedDate,
	LeftCell,
	RightCell,
	StyledPreamble,
} from './NewsPage.styles';
import PageLayout from 'layouts/PageLayout';

/** Page template for news page. */
const NewsPage: React.FC<NewsPageModel> = ({
	heading,
	contentArea,
	translations,
	breadcrumbs,
	preamble,
	category,
	publishedDate,
	lastModifiedDate,
	text,
	_properties = {},
}) => (
	<main id="main-content">
		{breadcrumbs && (
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>
		)}

		<PageLayout labelledBy="article-heading">
			<Grid inner={true}>
				<LeftCell span={7} phone={12}>
					<Text as="h1" {..._properties?.heading} id="article-heading">
						{heading}
					</Text>

					{(_properties?.preamble || preamble) && (
						<StyledPreamble {..._properties?.preamble}>
							{preamble}
						</StyledPreamble>
					)}

					<FragmentsContainer>
						<EpiFragments {..._properties?.text} fragments={text?.fragments} />
					</FragmentsContainer>
					<LastModifiedDate>{`${translations.latestUpdated}: ${lastModifiedDate}`}</LastModifiedDate>
				</LeftCell>
				<RightCell span={5}>
					<DateArea>
						<Category>{category}</Category>
						<PublishedDate>{publishedDate}</PublishedDate>
					</DateArea>
				</RightCell>
			</Grid>
		</PageLayout>
		<EpiContentArea {..._properties?.contentArea} content={contentArea} />
	</main>
);

export default NewsPage;
