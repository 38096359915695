import React from 'react';
import {
	Article,
	ArticleHeading,
	ArticleSubHeading,
	ArticleSubText,
	ArticleLink,
	LeftRow,
	ReadMoreLabel,
	RightRow,
	IconBadge,
} from './JobPage.styles';
import Icon from 'components/Icon';

type Props = {
	index: number;
	heading: string;
	applyUri: string;
	town: string;
	ends: string;
	positionTownLabel: string;
	positionEndsLabel: string;
	positionApplyLabel: string;
};

const JobPageItem: React.FC<Props> = ({
	index,
	applyUri,
	ends,
	heading,
	town,
	positionApplyLabel,
	positionEndsLabel,
	positionTownLabel,
}) => (
	<Article aria-labelledby={`heading-${index}`}>
		<ArticleLink href={applyUri} target="_blank">
			<LeftRow>
				<ArticleHeading id={`heading-${index}`}>{heading}</ArticleHeading>
				<ArticleSubHeading>
					<ArticleSubText>{`${positionTownLabel}: ${town}`}</ArticleSubText>
					<ArticleSubText>{`${positionEndsLabel}: ${ends}`}</ArticleSubText>
				</ArticleSubHeading>
			</LeftRow>

			<RightRow>
				<ReadMoreLabel>{positionApplyLabel}</ReadMoreLabel>
				<IconBadge>
					<Icon icon={'external'} size={0} aria-hidden="true" />
				</IconBadge>
			</RightRow>
		</ArticleLink>
	</Article>
);

export default JobPageItem;
