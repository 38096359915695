import { ReactComponent as search } from './icon-search.svg';
import { ReactComponent as comment } from './icon-comment.svg';
import { ReactComponent as flagEn } from './icon-flag-en.svg';
import { ReactComponent as menu } from './icon-menu.svg';
import { ReactComponent as arrowRight } from './icon-arrow-right.svg';
import { ReactComponent as close } from './icon-close.svg';
import { ReactComponent as plus } from './icon-plus.svg';
import { ReactComponent as minus } from './icon-minus.svg';
import { ReactComponent as chevron } from './icon-chevron.svg';
import { ReactComponent as check } from './icon-check.svg';

import { ReactComponent as youtube } from './icon-youtube.svg';
import { ReactComponent as twitter } from './icon-twitter.svg';
import { ReactComponent as linkedin } from './icon-linkedin.svg';
import { ReactComponent as envelope } from './icon-envelope.svg';
import { ReactComponent as podcast } from './icon-podcast.svg';
import { ReactComponent as dots } from './icon-dots.svg';
import { ReactComponent as pdf } from './icon-pdf.svg';
import { ReactComponent as diamond } from './icon-diamond.svg';
import { ReactComponent as circlecheck } from './icon-circlecheck.svg';
import { ReactComponent as external } from './icon-external.svg';
import { ReactComponent as arrowDiagonal } from './icon-arrow-diagonal.svg';
import { ReactComponent as arrowLarge } from './icon-arrow-large.svg';
import { ReactComponent as paragraph } from './icon-paragraph.svg';
import { ReactComponent as listen } from './icon-listen.svg';
import { ReactComponent as excel } from './icon-excel.svg';
import { ReactComponent as word } from './icon-word.svg';
import { ReactComponent as calendar } from './icon-calendar.svg';

export interface IconList {
	/** The icon to be shown */
	icon:
		| 'arrowRight'
		| 'close'
		| 'plus'
		| 'minus'
		| 'chevron'
		| 'check'
		| 'comment'
		| 'envelope'
		| 'flagEn'
		| 'linkedin'
		| 'menu'
		| 'podcast'
		| 'search'
		| 'twitter'
		| 'dots'
		| 'youtube'
		| 'pdf'
		| 'diamond'
		| 'circlecheck'
		| 'external'
		| 'arrowDiagonal'
		| 'arrowLarge'
		| 'paragraph'
		| 'listen'
		| 'word'
		| 'excel'
		| 'calendar';
}

export default {
	arrowRight,
	close,
	plus,
	minus,
	chevron,
	check,
	comment,
	envelope,
	flagEn,
	linkedin,
	menu,
	podcast,
	search,
	twitter,
	dots,
	youtube,
	pdf,
	diamond,
	circlecheck,
	external,
	arrowDiagonal,
	arrowLarge,
	paragraph,
	listen,
	excel,
	word,
	calendar,
};
