import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { TransitionState } from 'types/global';

interface UseTransitionToggle {
	(initialState: boolean, dropdown?: boolean): [boolean, () => void, any, any];
}

interface ContentWrapperProps {
	timeout?: number;
	timeoutSec?: string;
	[htmlAttributes: string]: any;
}

interface AnimatedDivProps {
	state: TransitionState;
	timeoutSec?: string;
	initialHeight?: number;
}

const AnimatedDiv = styled.div<AnimatedDivProps>`
	position: relative;
	overflow: hidden;

	${({ initialHeight, state }) =>
		initialHeight && state === 'exited' && ` height: ${initialHeight}px; `}
	${({ state, timeoutSec }) =>
		state === 'exiting' &&
		` animation: hiddenOut ${timeoutSec} ease-out forwards; `}
	${({ state, timeoutSec }) =>
		state === 'entering' &&
		` animation: hiddenIn ${timeoutSec} ease-in forwards; `}


	@keyframes hiddenIn {
		0% {
			display: none;
			height: ${({ initialHeight }) => (initialHeight ? initialHeight : 0)}px;
			opacity: 0;
		}
		1% {
			display: block;
			height: auto;
		}
		100% {
			display: block;
			opacity: 1;
		}
	}
	@keyframes hiddenOut {
		0% {
			display: block;
			opacity: 1;
			height: auto;
		}
		99% {
			display: block;
		}
		100% {
			display: none;
			opacity: 0;
			height: 0;
		}
	}
`;

export const Content = styled.div`
	position: relative;
	overflow: hidden;
`;

const ContentWrapper: React.FC<ContentWrapperProps> = ({
	children,
	expanded,
	initialHeight,
	containerRef,
	timeout = 500,
	timeoutSec = '.5s',
	...htmlAttributes
}) => {
	return (
		<Transition in={expanded} timeout={timeout}>
			{(state: TransitionState) => (
				<AnimatedDiv
					{...htmlAttributes}
					ref={containerRef}
					state={state}
					initialHeight={initialHeight}
					hidden={state === 'exited' && !initialHeight}
					timeoutSec={timeoutSec}
				>
					<Content>{children}</Content>
				</AnimatedDiv>
			)}
		</Transition>
	);
};

export const useTransitionToggle: UseTransitionToggle = (
	initialState,
	dropdown = false
) => {
	const [expanded, setExpanded] = useState(initialState || false);
	const containerRef = useRef<any>(null);

	const handleClickOutsideComponent = (e: MouseEvent) => {
		if (containerRef && !containerRef.current.contains(e.target) && expanded) {
			setExpanded(false);
		}
	};

	const toggle = () => setExpanded((expanded) => !expanded);

	useEffect(() => {
		if (dropdown) {
			document.addEventListener('click', handleClickOutsideComponent);
		}

		return () => {
			if (dropdown) {
				document.removeEventListener('click', handleClickOutsideComponent);
			}
		};
	});

	return [expanded, toggle, containerRef, ContentWrapper];
};
