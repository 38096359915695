/**
 * CaseBox
 */

import React from 'react';
import { CaseWrapper, ArrowLink, CaseLink } from './CaseBox.styles';
import { colors } from 'theme';
import Icon from 'components/Icon';
import { CaseHitList } from 'types/listings';
import CaseBoxContent from './CaseBoxContent';

interface Props {
	/** Case object */
	item: CaseHitList;

	/** Read more text */
	readMoreText?: string;

	/** Case index */
	index: number;

	/** Determine if caselistpage or not */
	isCaseListPage?: boolean;

	/** Item id */
	id?: string;
}

/** Component to display a list of cases in a table. */
const CaseBox: React.FC<Props> = ({
	item,
	readMoreText,
	index,
	isCaseListPage,
}) => (
	<CaseWrapper
		aria-labelledby={`CaseBox-heading-${index}`}
		id={item.id}
		tabIndex={-1}
		isCaseListPage={isCaseListPage}
	>
		<CaseLink href={item.link}>
			<CaseBoxContent
				index={index}
				caseBoxContents={item.caseBoxContents}
				isCaseListPage={isCaseListPage}
			></CaseBoxContent>

			{readMoreText && item.link && (
				<ArrowLink>
					{readMoreText}
					<Icon icon="arrowRight" color={colors.blue} aria-hidden="true" />
				</ArrowLink>
			)}
		</CaseLink>
	</CaseWrapper>
);

export default CaseBox;
