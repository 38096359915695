/**
 * PageFooter
 */

import React from 'react';
import logoSvg from './logo-kkv.svg';
import { LinkType } from 'types/global';
import { ModelTranslations } from 'types/epi';
import { colors } from 'theme';

import {
	BottomList,
	BottomLink,
	BottomContainer,
	BottomLinkList,
	BottomListItem,
	BottomLogo,
	Copyright,
	FollowHeading,
	FollowLinksContainer,
	Footer,
	FooterContainer,
	IconBadge,
	MiddleContainer,
	OrganizationNumber,
	TopContainer,
	TopLinksColumn,
	TopLinksContainer,
	TopLinksContent,
	TopLinksHeading,
	TopLogo,
} from './PageFooter.styles';

import Icon from 'components/Icon';
import Link from 'components/Link';
import RichText from 'components/RichText';

export type SocialLink = {
	link: string;
	modelType: string;
	text: string;
};

const iconMap: { [index: string]: any } = {
	YoutubeLink: 'youtube',
	LinkedInLink: 'linkedin',
	TwitterLink: 'twitter',
	EmailLink: 'envelope',
	PodcastLink: 'podcast',
};

interface Props {
	/** RTE-columns with heading and text content */
	columns: any[];

	/** Newsletter */
	newsletter: any;

	/** Copyright disclaimer */
	copyright: string;

	/** Organization number */
	organizationNumber: string;

	/** An array of Link objects */
	links: LinkType[];

	/** An array of SocialLink objects */
	socialLinks: SocialLink[];

	/** Translations */
	translations: ModelTranslations;
}

/** Pagefooter */
const PageFooter: React.FC<Props> = ({
	columns,
	newsletter,
	copyright,
	links = [],
	organizationNumber,
	socialLinks = [],
	translations,
}) => {
	return (
		<Footer>
			<FooterContainer>
				<TopContainer>
					<div>
						<TopLogo src={logoSvg} alt="" />
					</div>

					<TopLinksContainer>
						{columns &&
							columns.map((item: any, i: number) => (
								<TopLinksColumn key={i}>
									<TopLinksHeading as="h2">{item.heading}</TopLinksHeading>
									<RichText textSize="small">
										<TopLinksContent
											dangerouslySetInnerHTML={{ __html: item.text }}
										/>
									</RichText>
								</TopLinksColumn>
							))}
					</TopLinksContainer>
				</TopContainer>

				<MiddleContainer>
					<FollowLinksContainer>
						<FollowHeading>{`${translations.followUsText}`}</FollowHeading>

						{socialLinks &&
							socialLinks.map((item: SocialLink, i: number) => (
								<IconBadge
									href={item.link}
									key={i}
									aria-label={item.text}
									as={Link}
								>
									<Icon
										icon={iconMap[item.modelType]}
										color={colors.blueDarker}
										aria-hidden="true"
									/>
								</IconBadge>
							))}
					</FollowLinksContainer>
				</MiddleContainer>

				<BottomContainer>
					<BottomLogo src={logoSvg} alt="" />

					<BottomList>
						<BottomLinkList>
							{links &&
								links.map((item: LinkType, i: number) => (
									<BottomListItem key={i}>
										<BottomLink href={item.link} as={Link}>
											{item.text}
										</BottomLink>
									</BottomListItem>
								))}
						</BottomLinkList>
						<OrganizationNumber>{organizationNumber}</OrganizationNumber>
					</BottomList>

					<Copyright>{copyright}</Copyright>
				</BottomContainer>
			</FooterContainer>
		</Footer>
	);
};

export default PageFooter;
