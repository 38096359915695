/**
 * SkipToMain
 */

import React from 'react';
import { Wrapper, IconBlob } from './SkipToMain.styles';
import { colors } from 'theme';

import Icon from 'components/Icon';

interface Props {
	/** Target element to skip to */
	target: string;
	text: string;
}

/** Skip to main component for accessibility. */
const SkipToMain: React.FC<Props> = ({ text, target }) => {
	return (
		<Wrapper aria-label={text} href={target} accessKey="s">
			{text}

			<IconBlob>
				<Icon
					icon="arrowRight"
					aria-hidden="true"
					size={0}
					color={colors.blueDarker}
				/>
			</IconBlob>
		</Wrapper>
	);
};

export default SkipToMain;
