/**
 * LinkTreeBlock - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { H2 } from 'components/Text/Text.styles';
import Link from 'components/Link';

export interface StyleProps {
	headingAlignment?: 'left' | 'center';
	isChildLink?: boolean;
	level?: number;
	isFirst?: boolean;
}

export const Container = styled.div`
	font-family: ${typography.fontFamily.alt};
	padding: 5.5rem 0;
`;

export const Heading = styled(H2)<StyleProps>`
	text-align: ${(props) => props.headingAlignment};
	margin: 0 0 ${px2rem(50)} 0;
`;

export const LinkListContainer = styled.div`
	display: flex;
`;

export const StyledLink = styled(Link)`
	color: inherit;
	width: 100%;
	height: 100%;
	letter-spacing: 0;
	line-height: ${px2rem(24)};

	&:hover {
		text-decoration: none;
	}
`;

export const LinkContainer = styled.div<StyleProps>`
	${({ isChildLink }) => {
		if (!isChildLink) {
			return css`
				${StyledLink} {
					font-weight: ${typography.fontWeight.semiBold};
				}
			`;
		}
	}}
`;

export const Ul = styled.ul<StyleProps>`
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: column;

	> li {
		:not(:last-child) {
			margin-bottom: ${(props) => (props.level === 1 ? '3rem' : 0)};

			${LinkContainer} {
				${({ isChildLink }) => {
					if (!isChildLink) {
						return css`
							margin-bottom: 0.5rem;

							${mediaQueries.phoneOrTablet} {
								margin-bottom: 1.5rem;
							}
						`;
					}
				}}
			}
		}

		${LinkContainer} {
			margin-bottom: ${px2rem(10)};
		}
	}

	${({ level, isFirst }) => {
		if (level === 1) {
			if (isFirst) {
				return css`
					width: calc(100% / 3);
					padding-right: ${px2rem(50)};
				`;
			} else {
				return css`
					width: calc(100% / 3);
					padding: 0 ${px2rem(50)};
					border-left: ${px2rem(1)} solid ${colors.blueLighter};
				`;
			}
		}
	}}
`;

export const WiwLevel1 = styled.li`
	display: inline-block;
	${StyledLink} {
		color: ${colors.greyDark};
		font-size: ${px2rem(22)};
	}
	background-color: ${colors.melon};
`;

export const LiLevel1 = styled.li`
	display: inline-block;
	${StyledLink} {
		color: ${colors.greyDark};
		font-size: ${px2rem(22)};
	}
`;

export const LiLevel2 = styled.li`
	display: inline-block;
	${StyledLink} {
		color: ${colors.linkColorDark};
		font-size: ${px2rem(16)};
	}
`;

export const LiLevel3 = styled(LiLevel2)`
	margin-left: 1rem;
`;
