/**
 * SearchField
 */

import React from 'react';
import {
	SearchInput,
	FieldWrapper,
	SearchIcon,
	FlexWrapper,
	SearchButton,
} from './SearchField.styles';
import { LinkType } from 'types/global';

interface Props {
	/** Search field placeholder */
	placeholder?: string;

	/** Search field label */
	label: string;

	/** Search field name */
	name: string;

	/** Search field id (if not set, uses name) */
	id?: string;

	/** Text in submit button */
	buttonText: string;

	/** Whether search icon should be shown or not */
	showSearchIcon?: boolean;

	/** If searchfield should be clearable or not */
	clearable?: boolean;

	/** Value of field (passed in by Form) */
	defaultValue?: string;

	/** Reset form */
	handleResetForm?: any;

	/** Autocomplete links */
	links?: LinkType[];

	autoSubmit?: boolean;

	[htmlAttributes: string]: any;
}

/** Searchfield with submit button */
const SearchField: React.FC<Props> = ({
	placeholder,
	label,
	name,
	id = name,
	buttonText,
	showSearchIcon = false,
	clearable = false,
	defaultValue,
	handleResetForm,
	autoSubmit = false,
	links,

	...htmlAttributes
}) => (
	<FlexWrapper>
		<FieldWrapper>
			<SearchInput
				type="text"
				name={name}
				label={label}
				id={id}
				aria-label={label}
				placeholder={placeholder}
				$hasIcon={showSearchIcon}
				clearable={clearable}
				{...htmlAttributes}
			/>

			{showSearchIcon && <SearchIcon icon="search" aria-hidden={true} />}
		</FieldWrapper>

		<SearchButton type="submit" aria-label={buttonText}>
			{buttonText}
		</SearchButton>
	</FlexWrapper>
);

export default SearchField;
