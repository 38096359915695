/**
 * NavMobile - TopNav
 */

import React, { useEffect, useRef } from 'react';
import { LinkType } from 'types/global';
import { TopNav, Link, TopNavLi } from './MobileMenu.styles';

import {
	SuggestionButton,
	SuggestionIcon,
	IconWrapper,
} from 'components/PageHeader/PageHeader.styles'; // TODO: This should probably be its own component
import { ModelTranslations } from 'types/epi';

interface Props {
	/** Language links */
	languages?: LinkType;

	/** Suggestion button text */
	suggestionButtonText?: string;

	/** Function for setting paneMaxHeight */
	handleSetPaneMaxHeight: any;

	/* suggestion */
	buttonLink: LinkType;

	/** Current Language links */
	currentLang?: 'sv' | 'en';

	/** Translations */
	translations: ModelTranslations;
}

const NavMobileTopNav: React.FC<Props> = ({
	languages,
	suggestionButtonText,
	handleSetPaneMaxHeight,
	buttonLink,
	currentLang,
	translations,
}) => {
	const topNavRef = useRef<any>();

	useEffect(() => {
		const innerContent = document.getElementById('pageheaderinnerwrapper');

		if (innerContent && topNavRef.current) {
			handleSetPaneMaxHeight(
				innerContent.scrollHeight + topNavRef.current.scrollHeight
			);
		}
	}, []);

	return (
		<div ref={topNavRef}>
			<TopNav>
				<TopNavLi>
					<SuggestionButton as={Link} topNav href={buttonLink.link}>
						{suggestionButtonText}
						<IconWrapper>
							<SuggestionIcon
								icon="comment"
								color={'#333'}
								aria-hidden={true}
							/>
						</IconWrapper>
					</SuggestionButton>
				</TopNavLi>
			</TopNav>
		</div>
	);
};

export default NavMobileTopNav;
