/**
 * ProcessListBlock
 */

import React from 'react';
import { LinkType } from 'types/global';
import {
	Container,
	Preamble,
	ButtonContainer,
	ProcessListContainer,
	ProcessItemBlockContainer,
	DividerWrapper,
	Divider,
	ProcessText,
} from './ProcessListBlock.styles';

import BlockContainer from 'layouts/BlockLayout';
import Button from 'components/Button';
import { Accordion, AccordionItem } from 'components/Accordion';
import { Cell } from 'components/Grid';
import { H2 } from 'components/Text/Text.styles';

interface Props {
	/** Block heading */
	heading: string;

	/** Block preamble */
	preamble: string;

	/** Block link */
	link?: LinkType;

	/** Process status, total completed */
	totalCompleted: number;

	/** Background color */
	backgroundColor: 'offBlueLightest' | 'white';

	/** ProcessCarousel item list */
	children?: any[];

	/** Text between the completed and uncompleted states */
	processText?: string;
}

/** ProcessListBlock. */
const ProcessListBlock: React.FC<Props> = ({
	heading,
	preamble,
	link,
	totalCompleted,
	backgroundColor,
	processText,
	children,
}) => {
	const hasChildren = children && children.length > 0;

	const getVariant = (backgroundColor: string, isCompleted: boolean) => {
		if (backgroundColor === 'white') {
			return isCompleted ? 'lightBlue' : 'greyLightest';
		}

		return isCompleted ? 'blueLightest' : 'white';
	};

	return (
		<BlockContainer backgroundColor={backgroundColor}>
			<Container inner>
				<Cell desktop={hasChildren ? 4 : 12} tablet={12} phone={12}>
					<H2 as="h2">{heading}</H2>

					<Preamble>{preamble}</Preamble>

					{link && (
						<ButtonContainer>
							<Button
								type="link"
								variant="transparent"
								href={link.link}
								target={link.target}
							>
								{link.text}
							</Button>
						</ButtonContainer>
					)}
				</Cell>

				{hasChildren && (
					<Cell desktop={8} tablet={12} phone={12}>
						<Accordion>
							<ProcessListContainer>
								{React.Children.map(children, (child, i) => (
									<li key={i}>
										{totalCompleted > 0 && i === totalCompleted && processText && (
											<DividerWrapper>
												<Divider />
												<ProcessText>{processText}</ProcessText>
												<Divider />
											</DividerWrapper>
										)}

										<ProcessItemBlockContainer
											lastCompleted={i + 1 === totalCompleted}
										>
											<AccordionItem
												title={child.props.heading}
												variant={getVariant(
													backgroundColor,
													i + 1 <= totalCompleted
												)}
												count={i + 1}
												displayCheckIcon={i + 1 <= totalCompleted}
											>
												{React.cloneElement(child, child.props)}
											</AccordionItem>
										</ProcessItemBlockContainer>
									</li>
								))}
							</ProcessListContainer>
						</Accordion>
					</Cell>
				)}
			</Container>
		</BlockContainer>
	);
};

export default ProcessListBlock;
