/**
 * FactItem
 */

import React from 'react';
import {
	Container,
	IconArea,
	TextContainer,
	LinksContainer,
} from './FactItemHtmlFragment.styles';
import Icon from 'components/Icon';
import EpiFragments from 'components/EpiFragments';
import { UrlFragmentModel } from 'types/fragments';
import Space from 'components/Space';
interface Props {
	/** Content to display */
	children?: any;

	/** Factitem icon variant (text, link or download) */
	variant?:
		| 'text'
		| 'link'
		| 'download'
		| 'text-no-icon'
		| 'link-no-icon'
		| 'download-no-icon';

	/** A list of URL fragments */
	fragments?: UrlFragmentModel[];
}

/** Factitem html fragment. */
const FactItem: React.FC<Props> = ({ children, variant, fragments }) => {
	const getIcon = () => {
		if (variant === 'text') {
			return 'paragraph';
		} else if (variant === 'link') {
			return 'arrowDiagonal';
		} else if (variant === 'download') {
			return 'arrowLarge';
		}

		return null;
	};

	let newData;
	if (fragments && (variant === 'download' || variant === 'link')) {
		newData = fragments.map((fragment: UrlFragmentModel) =>
			Object.assign({}, fragment, {
				iconType: variant === 'download' ? 'pdf' : 'external',
			})
		);
	}

	const icon = getIcon();

	return (
		<Space bottom={1} top={1}>
			<Container $variant={variant}>
				<TextContainer>
					{children}

					{newData && (
						<LinksContainer>
							<EpiFragments fragments={newData} />
						</LinksContainer>
					)}
				</TextContainer>

				{icon && getIcon() && (
					<IconArea $variant={variant}>
						<Icon icon={icon} size={0} aria-hidden="true" />
					</IconArea>
				)}
			</Container>
		</Space>
	);
};

export default FactItem;
