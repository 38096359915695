import React, { useState } from 'react';

interface ContextProps {
	backLinkProps: any;
	setBackLinkProps: any;
}

export const BackLinkContext = React.createContext<Partial<ContextProps>>({});

const BackLinkProvider: React.FC = ({ children }) => {
	const [backLinkProps, setBackLinkProps] = useState<any>(null);

	return (
		<BackLinkContext.Provider
			value={{
				backLinkProps,
				setBackLinkProps,
			}}
		>
			{children}
		</BackLinkContext.Provider>
	);
};

export default BackLinkProvider;
export const BackLinkConsumer = BackLinkContext.Consumer;
