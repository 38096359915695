/**
 * ReferralListPage
 */

import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { ReferralListPageModel } from 'types/pages';
import { useUrlQuery, useSearchResult } from 'hooks';
import { getActiveFilters } from 'common/helpers';

import { ReferralPageCardModel } from 'types/cards';

import { Content, ResultsContainer } from './ReferralListPage.styles';

import EpiContentArea from 'components/EpiContentArea';
import LoadMorePagination from 'components/LoadMorePagination';
import { ReferralPageCard } from 'components/Cards';
import { Grid, Cell, GridProps } from 'components/Grid';
import Breadcrumb from 'components/Breadcrumb';

import SearchForm from 'components/SearchForm';

/** Page template for a reportlist page. */
const ReferralListPage: React.FC<ReferralListPageModel> = ({
	breadcrumbs,
	topContentArea,
	filter,
	pagination,
	items,
	hideImages,
	translations,
	_properties = {},
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const [action, setAction] = useState('');
	const activeFilters = getActiveFilters(filter);
	const [values, setValues] = useState<any>({ ...activeFilters });

	const [currentPage, setCurrentPage] = useState<number>(
		pagination.currentPage
	);

	const [updateQuery, response, loading, error] = useUrlQuery({
		...values,
		page: currentPage,
	});

	const [filters, paginations, results] = useSearchResult(
		filter,
		pagination,
		items,
		response,
		action
	);

	const paginationPhrase = translations.paginationPhrase.split('{0}');

	return (
		<main id="main-content">
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>

			<EpiContentArea
				{..._properties?.topContentArea}
				content={topContentArea}
				isPageHeader={true}
			/>

			<SearchForm
				translations={{
					searchHeading: translations.searchHeading,
					searchButtonLabel: translations.searchButtonLabel,
					searchFieldLabel: translations.searchFieldLabel,
					searchFieldPlaceholder: translations.searchFieldPlaceholder,
					errorText: translations.errorText,
					clearFilterText: translations.clearFilterText,
					clearAllFiltersText: translations.clearAllFiltersText,
					filterHeading: translations.filterHeading,
					hiddenSubmitText: translations.hiddenSubmitText,
				}}
				_properties={_properties}
				isPhone={isPhone}
				loading={loading}
				error={error}
				filter={filters}
				pagination={paginations}
				values={values}
				handleSetAction={setAction}
				handleSetCurrentPage={setCurrentPage}
				handleSetValues={setValues}
				handleUpdateQuery={updateQuery}
				displaySearchHeader
				displayHiddenSubmitButton
				autoSubmitOnClear={true}
			/>

			<Content>
				<ResultsContainer id="report-list">
					{results && results.length > 0 && (
						<Grid inner manualGutter={GridProps.smallGap}>
							{results.map((item: ReferralPageCardModel, i: number) => (
								<Cell span={4} key={i}>
									<ReferralPageCard
										{...item}
										index={i}
										hideImages={hideImages}
										translations={{
											registrationNumber: translations.referralNumber,
										}}
										usePreambleFallback={false}
									/>
								</Cell>
							))}
						</Grid>
					)}
				</ResultsContainer>

				<LoadMorePagination
					visible={paginations.currentPage < paginations.pageCount}
					loading={loading}
					buttonText={translations.showMoreButtonLabel}
					totalDisplay={paginations.totalDisplay}
					totalHits={paginations.total}
					paginationPhrase={paginationPhrase}
					onClick={() => {
						setCurrentPage((page) => page + 1);
						updateQuery({ ...values, page: currentPage + 1 });
						setAction('add');
					}}
				/>
			</Content>
		</main>
	);
};

export default ReferralListPage;
