/**
 * RegisterDetailsPage - styles
 */

import styled from 'styled-components';
import { colors, mediaQueries, typography } from 'theme';
import { px2rem } from 'theme/utils';
import pdficon from '../../theme/icons/icon-pdf.svg';

export const Heading = styled.div`
	background-color: ${colors.blueDarker};
	display: flex;
`;

export const HeadingContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0.5rem auto;
	max-width: ${px2rem(1920)};
	padding: ${px2rem(30)} 7.5rem ${px2rem(15)};
	width: 100%;

	${mediaQueries.phoneOrTablet} {
		flex-direction: column;
		padding: ${px2rem(14)} 1rem;
	}

	@media screen and (min-width: 840px) and (max-width: 1280px) {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}
`;

export const HeadingLeft = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	${mediaQueries.phoneOrTablet} {
		width: 100%;
	}
`;

export const HeadingHeader = styled.h1`
	color: ${colors.white};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(30)};
	line-height: 1.2;
	margin-top: 0;
	padding-top: 0;

	${mediaQueries.phoneOrTablet} {
		margin-bottom: ${px2rem(12)};
	}
`;

export const Case = styled.div`
	margin: 0.5rem auto 0;
	padding: ${px2rem(30)} 7.5rem ${px2rem(15)};
	max-width: ${px2rem(1920)};

	${mediaQueries.phoneOrTablet} {
		padding: 3rem 1rem;
	}

	@media screen and (min-width: 840px) and (max-width: 1280px) {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}
`;

export const CaseContainer = styled.div`
	border: 1px solid ${colors.offGreyLightest};
	border-radius: ${px2rem(20)};
	padding: ${px2rem(47)} ${px2rem(82)};

	${mediaQueries.phoneOrTablet} {
		border: none;
		border-radius: initial;
		padding: ${px2rem(16)} 0;
	}
`;

export const CaseRow = styled.div`
	display: flex;
	flex-direction: row;
	margin: ${px2rem(24)} 0;

	&:first-child {
		margin-top: 0;
	}

	${mediaQueries.phoneOrTablet} {
		flex-direction: column;
	}
`;

export const CaseLeft = styled.div`
	width: 100%;

	${mediaQueries.desktop} {
		width: 50%;
	}
`;

export const CaseRight = styled.div`
	width: 100%;

	${mediaQueries.desktop} {
		border-left: 1px solid ${colors.greyLight};
		padding-left: ${px2rem(81)};
		width: 50%;
	}
`;

export const CaseHeader = styled.h2`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	margin: 0;
	padding-bottom: ${px2rem(7)};
	padding-top: ${px2rem(32)};

	&:first-child {
		${mediaQueries.desktop} {
			padding-top: 0;
		}
	}
`;

export const CaseText = styled.p`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	margin: 0;
	padding-top: 0;
	padding-bottom: 0;
`;

export const PdfLink = styled.a`
	color: ${colors.linkColorDark};
	font-family: ${typography.fontFamily.alt};
	margin-right: ${px2rem(45)};
	transition: all 0.2s;

	&:hover {
		text-decoration: none;
	}

	&:after {
		content: '';
		display: inline-block;
		margin-left: 0.5rem;
		background-color: ${colors.linkColorDark};
		-webkit-mask: url('${pdficon}') no-repeat 0 0 / ${px2rem(16)} ${px2rem(16)};
		mask: url('${pdficon}') no-repeat 0 0 / ${px2rem(16)} ${px2rem(16)};
		width: ${px2rem(16)};
		height: ${px2rem(16)};
	}
`;

export const Back = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: ${px2rem(48)};

	button {
		cursor: pointer;
		${mediaQueries.toDesktop} {
			width: 100%;
		}

		svg {
			margin-left: 0;
			margin-right: 0.5rem;
			transform: rotate(180deg);
		}
	}
`;
