/**
 * LoadMorePaginationButton - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { mediaQueries, colors } from 'theme';
import { SmallP } from 'components/Text';

export const ButtonContainer = styled.div`
	text-align: center;
	margin-bottom: ${px2rem(90)};

	${mediaQueries.phoneOrTablet} {
		margin-bottom: 4rem;
	}

	button {
		cursor: pointer;
	}
`;

export const PaginationPhrase = styled(SmallP)`
	color: ${colors.greyDark};
	margin-bottom: 1.5rem;

	${mediaQueries.phone} {
		text-align: center;
	}
`;
