/**
 * SearchField - styles
 */

import styled from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import Icon from 'components/Icon';
import { Field } from 'components/Form';
import { Button } from 'components/Form/Form.styles';

interface InputProps {
	$hasIcon?: boolean;
}

export const FlexWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;

	${mediaQueries.phone} {
		flex-direction: column;
		padding: 0 0.25rem;

		> button {
			height: 3rem;
			line-height: ${px2rem(10)};
		}
	}
`;

export const FieldWrapper = styled.div`
	position: relative;
	display: inline-block;

	${mediaQueries.phone} {
		flex-grow: 1;
		margin-right: 0.25rem;
	}
`;

export const SearchInput = styled(Field)<InputProps>`
	padding: ${px2rem(22)} ${px2rem(30)} ${px2rem(20)};
	padding-left: ${({ $hasIcon }) => ($hasIcon ? px2rem(64) : px2rem(30))};

	height: auto;
	width: ${px2rem(490)};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(20)};

	color: ${colors.greyDark};
	border: ${px2rem(1)} solid ${colors.offBlueLight};
	border-radius: ${px2rem(32)};
	margin-right: 0.5rem;

	&:focus {
		box-shadow: 0 0 0 ${px2rem(3)} ${colors.focusColor};
		outline: none;
	}

	${mediaQueries.phone} {
		height: 3rem;
		text-align: center;
		max-width: 100%;
		margin-bottom: ${px2rem(10)};
	}
`;

export const SearchIcon = styled(Icon)`
	position: absolute;
	top: ${px2rem(21)};
	left: ${px2rem(28)};

	${mediaQueries.phone} {
		top: ${px2rem(12)};
		left: ${px2rem(20)};
	}
`;

export const SearchButton = styled(Button)`
	&:focus {
		border: 0.1875rem solid rgb(255, 255, 255);
	}
`;
