/**
 * ProcessListBlock - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { Grid } from 'components/Grid';

type StyleProps = {
	lastCompleted?: boolean;
};

export const Container = styled(Grid)`
	padding: ${px2rem(150)} 0 ${px2rem(117)} 0;
	color: ${colors.black};

	${mediaQueries.phone} {
		padding: ${px2rem(70)} ${px2rem(24)};
	}
`;

export const Preamble = styled.p`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(24)};
	letter-spacing: normal;
	padding-right: ${px2rem(55)};

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(18)};
		line-height: ${px2rem(24)};
	}
`;

export const ProcessListContainer = styled.ul`
	list-style: none;
	padding-left: 0;
`;

export const ProcessItemBlockContainer = styled.div<StyleProps>`
	margin-bottom: ${(props) => (props.lastCompleted ? 0 : px2rem(10))};
`;

export const ButtonContainer = styled.div`
	max-width: ${px2rem(262)};
	margin-top: ${px2rem(28)};

	a {
		margin-bottom: ${px2rem(20)};
		width: 100%;
	}

	a span {
		font-size: ${px2rem(15)};
		line-height: 
		font-weight: ${typography.fontWeight.normal};
	}

	${mediaQueries.phone} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		margin-top: ${px2rem(32)};
	}
`;

export const DividerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Divider = styled.div`
	flex: 1;
	height: ${px2rem(1)};
	opacity: 0.15;
	background-color: ${colors.blueDarker};
`;

export const ProcessText = styled.p`
	margin: ${px2rem(10)};
	font-family: ${typography.fontFamily.alt};
	color: ${colors.grey};
	font-size: ${px2rem(14)};
	font-weight: ${typography.fontWeight.semiBold};
	letter-spacing: 0;
	line-height: ${px2rem(18)};
	text-align: center;
`;
