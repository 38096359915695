/**
 * FilterChip - styles
 */

import styled from 'styled-components';
import { colors, typography } from 'theme';
import { px2rem } from 'theme/utils';
import { lighten } from 'polished';
import Icon from 'components/Icon';
import { Button } from 'components/Form';

interface ChipProps {
	$color?: string;
}

export const Chip = styled(Button)<ChipProps>`
	color: ${colors.blueDark};
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.semiBold};
	font-size: ${px2rem(14)};
	padding: 0.25rem 0.5rem;

	display: flex;
	align-items: center;

	border: none;
	border-radius: 0;
	background-color: ${({ $color }) =>
		$color === 'blue' ? colors.offBlueLightest : colors.greyLightest};
	transition: all 0.2s;

	&:hover {
		background-color: ${({ $color }) => {
			if ($color === 'blue') {
				return lighten(0.02, colors.offBlueLightest);
			} else {
				return lighten(0.02, colors.greyLightest);
			}
		}};
	}
`;

export const CloseIcon = styled(Icon)`
	width: ${px2rem(10)};
	height: ${px2rem(10)};
	margin-left: 0.5rem;
`;
