import { useState, useEffect } from 'react';
import { useFirstRender } from 'hooks';
import axios from 'axios';

export const useAutoComplete = (autocompleteSearchUrl: string) => {
	const isFirstRender = useFirstRender();
	const [query, setQuery] = useState<string>('');
	const [suggestions, setSuggestions] = useState<any>();
	const [error, setError] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (!isFirstRender && !loading && query) {
			let baseUrl = window.location.origin + autocompleteSearchUrl;

			if (process.env.NODE_ENV === 'development') {
				baseUrl = baseUrl + `.json`;
			}

			setLoading(true);

			axios({
				url: `${baseUrl}?query=${query}`,
				method: 'GET',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'content-type': 'application/json; charset=utf-8',
				},
			})
				.then((response: any) => {
					if (response.status === 200) {
						let data = response.data;
						if (process.env.NODE_ENV === 'development') {
							data = data;
						}

						setSuggestions(data.suggestions);
						setLoading(false);
					}
				})
				.catch((err) => {
					setError(err);
					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	return [setQuery, setSuggestions, query, suggestions, loading, error];
};
