/**
 * ConfirmationBannerBlock - styles
 */

import styled from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { H1, H6, P, Preamble } from 'components/Text';

interface StyledProps {
	bgColor: 'blue' | 'white';
}

export const Wrapper = styled.div`
	display: flex;

	${mediaQueries.phone} {
		flex-direction: column;
		margin-bottom: 4.75rem;
	}
`;

export const LeftBlock = styled.div`
	width: 50%;
	padding: ${px2rem(204)} ${px2rem(120)} ${px2rem(140)} 0;

	@media (max-width: 1040px) {
		width: 48%;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	${mediaQueries.tablet} {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	${mediaQueries.phone} {
		justify-content: center;
		width: 100%;
		padding: ${px2rem(76)} 0;
		text-align: center;
	}
`;

export const LeftBlockInner = styled.div`
	max-width: ${px2rem(618)};
`;

export const ImageWrapper = styled.div``;

export const RightBlock = styled.div`
	display: inline-flex;
	flex-direction: column;
	width: 50%;

	${mediaQueries.phone} {
		width: 100%;
	}

	img {
		height: 100%;
		object-fit: cover;
	}
`;

export const Heading = styled(H1)<StyledProps>`
	margin-bottom: ${px2rem(23)};
	color: ${({ bgColor }) =>
		bgColor === 'blue' ? colors.white : colors.greyDark};

	${mediaQueries.phone} {
		font-size: ${px2rem(34)};
	}
`;

export const StyledPreamble = styled(Preamble)<StyledProps>`
	font-size: ${px2rem(24)};
	line-height: ${px2rem(36)};
	color: ${({ bgColor }) =>
		bgColor === 'blue' ? colors.white : colors.greyDark};
	margin-bottom: ${px2rem(36)};

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(18)};
		line-height: ${px2rem(28)};
		margin-bottom: ${px2rem(20)};
	}
	${mediaQueries.phone} {
		text-align: left;
	}
`;

export const StyledText = styled(P)<StyledProps>`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(18)};
	line-height: ${px2rem(32)};
	color: ${({ bgColor }) =>
		bgColor === 'blue' ? colors.white : colors.greyDark};

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(16)};
		line-height: ${px2rem(24)};
	}

	${mediaQueries.phone} {
		text-align: left;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	${mediaQueries.phone} {
		align-items: center;
	}

	> * {
		min-width: ${px2rem(226)};
		margin-top: ${px2rem(20)};
	}
`;
