/**
 * AccordionHtmlFragment
 */

import React from 'react';
import { Accordion, AccordionItem } from 'components/Accordion';
import EpiFragments from 'components/EpiFragments';
import { StepsModel } from 'types/fragments';

import { ListContainer, ListItem } from './AccordionHtmlFragment.styles';
import BlockContainer from 'layouts/BlockLayout';

interface Props {
	/** Child item list */
	steps?: StepsModel[];
}

/** AccordionHtmlFragment*/
const AccordionHtmlFragment: React.FC<Props> = ({ steps }) => {
	return (
		<BlockContainer noPadding>
			<Accordion>
				<ListContainer>
					{steps?.map((step: any, index: number) => (
						<ListItem key={index}>
							<AccordionItem noHover title={step.heading} variant="html">
								<EpiFragments fragments={step.fragments} />
							</AccordionItem>
						</ListItem>
					))}
				</ListContainer>
			</Accordion>
		</BlockContainer>
	);
};

export default AccordionHtmlFragment;
