/**
 * ListPropertyBase
 */

import React from 'react';
import { LinkType, StandardImage } from 'types/global';
import {
	AltCardWrapper,
	BottomArea,
	Container,
	ImageColumn,
	ImageColumnInner,
	TextColumnAlt,
	Preamble,
	Heading,
	AnchorTextAlt2,
	IconBadge,
} from './Cards.styles';
import Image from 'components/Image';
import Icon from 'components/Icon';
import Video from './Video';

interface Props {
	/** ListPropertyBase heading */
	heading: string;

	/** ListPropertyBase preamble */
	preamble?: string;

	/** ListPropertyBase link */
	link: LinkType;

	/** ListPropertyBase video */
	video?: boolean;

	/** ListPropertyBase image */
	image: StandardImage;

	/** Parent backgroundcolor */
	backgroundColor: 'lightBlue' | 'blue' | 'white';

	/** Card index */
	index: number;

	id?: string;
}

/** Container component for ListPropertyBaseCard. */
const ListPropertyBaseCard: React.FC<Props> = ({
	heading,
	preamble,
	link,
	video,
	image,
	backgroundColor,
	index,
	id,
}) => (
	<AltCardWrapper
		aria-labelledby={`heading-${id}`}
		backgroundColor={backgroundColor}
	>
		<Container
			href={link.link && typeof link.link === 'string' ? link.link : link}
			id={id}
		>
			<ImageColumn>
				<ImageColumnInner>
					{video ? (
						<Video image={image} />
					) : (
						<Image
							src={image.src}
							alt={image.alt}
							srcSet={`
							${image.sizes[0]} 800w,
							${image.sizes[1]} 400w
						`}
							webpSrcSet={`
							${image.webpSizes[0]} 800w,
							${image.webpSizes[1]} 400w
						`}
							sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 400px"
						/>
					)}
				</ImageColumnInner>
			</ImageColumn>

			<BottomArea>
				<TextColumnAlt>
					<Heading
						as="h3"
						id={`heading-${id}`}
						backgroundColor={backgroundColor}
					>
						{heading}
					</Heading>

					{preamble && (
						<Preamble backgroundColor={backgroundColor}>{preamble}</Preamble>
					)}
				</TextColumnAlt>

				<AnchorTextAlt2 backgroundColor={backgroundColor}>
					{link.text}
					<IconBadge backgroundColor={backgroundColor}>
						<Icon icon={'arrowRight'} size={0} aria-hidden="true" />
					</IconBadge>
				</AnchorTextAlt2>
			</BottomArea>
		</Container>
	</AltCardWrapper>
);

export default ListPropertyBaseCard;
