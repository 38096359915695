export const subscribe = async (subscribeUrl: string, formValues: any) => {
	const values = { ...formValues };
	delete values.termsApproved;
	let baseUrl = window.location.origin + subscribeUrl;

	if (process.env.NODE_ENV === 'development') {
		baseUrl = baseUrl + `.json`;
	}

	try {
		const response = await fetch(baseUrl, {
			body: JSON.stringify(values),
			cache: 'no-cache',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'content-type': 'application/json; charset=utf-8',
				'X-API-Key': '9e172fb1-c523-4126-b419-4dd07dca5ced', //TODO: Needs to be moved somewhere safer
			},
			method: 'POST',
			credentials: 'same-origin',
			redirect: 'follow',
			referrer: 'no-referrer',
		});
		if (response.ok) {
			return response.json();
		} else {
			throw new Error(response.statusText);
		}
	} catch (err) {
		return err;
	}
};
