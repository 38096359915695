/**
 * ResponsiveIframe - styles
 */

import styled from 'styled-components';

export interface ResponsiveIframeWrapperProps {
	/** The aspect ratio of the video:
	 *
	 * - 16:9 - most common format, used by YouTube videos.
	 * - 4:3 - used mostly by older videos.
	 */
	aspectRatio?: '16:9' | '4:3';
}

export const ResponsiveIframeWrapper = styled.div<ResponsiveIframeWrapperProps>`
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: ${({ aspectRatio = '16:9' }) =>
		aspectRatio === '16:9' ? '56.25%' : '75%'};
`;

export const Iframe = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border: 0;
`;
