/**
 * ChartBlock - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { H2, Preamble, P } from 'components/Text/Text.styles';
import { getChartColor } from 'common/helpers';

interface StyledProps {
	$chartColor?: string;
	addStyles?: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 5.5rem 0;
	font-family: ${typography.fontFamily.alt};

	${mediaQueries.phone} {
		padding: 3rem 0;
	}
`;

export const Heading = styled(H2)`
	font-weight: ${typography.fontWeight.semiBold};
	margin: 0;
`;

export const Text = styled(Preamble)`
	font-weight: ${typography.fontWeight.normal};
	margin: 0;
`;

export const TextArea = styled.div<StyledProps>`
	display: flex;
	flex-direction: column;
	align-items: baseline;
	padding-bottom: ${px2rem(10)};
	margin-bottom: ${px2rem(15)};

	${mediaQueries.phone} {
		align-items: flex-start;
	}

	${({ $chartColor }) => {
		if ($chartColor) {
			return css`
				border-bottom: ${px2rem(3)} solid ${getChartColor($chartColor)};
			`;
		}
	}}
`;

export const SourceText = styled(P)``;

export const ChartContainer = styled.div<StyledProps>`
	${({ addStyles }) => {
		if (addStyles) {
			return css`
				padding: 1rem;
				background-color: ${colors.chartColors.backgroundColor};

				${TextArea} {
					align-items: center;
					justify-content: center;
				}
			`;
		}
	}}
`;

export const ChartArea = styled.div`
	height: ${px2rem(500)};
`;
