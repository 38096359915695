import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Model } from 'types/epi';
import { ContentTypes } from 'types/pages';
import { RootState, AppThunk } from 'store';
import { getEpiPage } from 'api/epiApi';
import { stat } from 'fs';

interface ModelState {
	model: Model;
	error: string | null;
	loading: boolean;
}

const initialState: ModelState = {
	error: null,
	loading: false,
	model: {
		framework: undefined,
		content: undefined,
	},
};

const slice = createSlice({
	name: 'model',
	initialState,
	reducers: {
		getModelStart: (state) => {
			state.loading = true;
		},
		getModelSuccess: (state, action: PayloadAction<Model>) => {
			state.model = action.payload;
			state.error = null;
			state.loading = false;

			if (window.__model) {
				window.__model.content = action.payload.content;
			}

			let epiQuickNav = document.getElementById('epi-quickNavigator');
			if (epiQuickNav) {
				// Update the pageId in EpiServers QuickNavigator after browsing to another page
				const pageId = (state.model.content as any)?.id ?? '';
				if (pageId !== '') {
					epiQuickNav.innerHTML = epiQuickNav.innerHTML.replace(
						/(<a href=\"[a-zA-Z\/\?=#]+epi.cms.contentdata:\/{2})(?<pId>\/\d+)(\")/gi,
						`$1/${pageId}$3`
					);
				}
			}
			document.title = state.model.framework?.meta?.title ?? 'Konkurrensverket';
		},
		getModelFailed: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loading = false;
		},
		updateContent: (state, action: PayloadAction<ContentTypes>) => {
			state.model.content = action.payload;
		},
	},
});

export const fetchPage = (
	routeApiUrl: string,
	route: string
): AppThunk => async (dispatch) => {
	let data;
	dispatch(getModelStart());

	try {
		data = await getEpiPage(routeApiUrl, route);
	} catch (err) {
		dispatch(getModelFailed(err.toString()));
		return;
	}

	dispatch(getModelSuccess(data));
};

export const selectModel = (state: RootState) => state.model;
export const selectContent = (state: RootState) => state.model.model.content;
export const selectMetaData = (state: RootState) =>
	state.model.model.framework?.meta;
export const selectHeader = (state: RootState) =>
	state.model.model.framework?.header;
export const selectFooter = (state: RootState) =>
	state.model.model.framework?.footer;
export const selectTranslations = (state: RootState) =>
	state.model.model.framework?.translations;
export const selectApi = (state: RootState) => state.model.model.framework?.api;

export const {
	getModelStart,
	getModelSuccess,
	getModelFailed,
	updateContent,
} = slice.actions;

export default slice.reducer;
