/**
 * ErrorPage - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

export const Div = styled.div`
	margin: 8rem 0;
	padding: 0;
	text-align: center;

	${mediaQueries.phone} {
		padding: 0 ${px2rem(44)};
		margin-top: 5rem;
	}
`;
