/**
 * BannerLink
 */

import React from 'react';
import { LinkItem, ListItem } from './StartBannerBlock.styles';

interface Props {
	/** Link url */
	href: string;

	/** Link text */
	children: any;
}

/** Link in link list (for startbanner). */
const BannerLink: React.FC<Props> = ({ href, children }) => (
	<ListItem>
		<LinkItem href={href}>{children}</LinkItem>
	</ListItem>
);

export default BannerLink;
