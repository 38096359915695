/**
 * ResearchResultListPage - styles
 */

import styled from 'styled-components';
import { mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { colors } from 'theme';
import { Preamble } from 'components/Text';

export const Content = styled.div`
	max-width: ${px2rem(1200)};
	margin: 0.5rem auto 0;

	@media (max-width: 1024px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	${mediaQueries.phone} {
		margin: 0 auto;
		padding: 0;
	}
`;

export const ResultsContainer = styled.div`
	padding: 0 4rem 0 2rem;

	svg {
		margin-left: ${px2rem(15)};
		color: ${colors.blueDark};
	}

	${mediaQueries.phoneOrTablet} {
		padding: 0;
		padding-right: 2rem;
	}

	${mediaQueries.phone} {
		padding: 1rem 0;
	}
`;

export const StyledPreamble = styled(Preamble)`
	margin: 0 0 3.5rem;
`;
