/**
 * Image
 */

import React from 'react';
import { Img, Figure, FigCaption } from './Image.styles';

export interface ImageProps {
	/** The image src */
	src?: string;

	/** The image alt */
	alt?: string;

	/** List of image sizes in jpg */
	srcSet?: string;

	/** List of image sizes in webp */
	webpSrcSet?: string;

	/** Media queries for custom handling of images */
	sizes?: string;

	/** Pass optional classnames for the html */
	className?: string;

	/** The image figure caption */
	figCaption?: string;

	/** Optional other html props will be spread on the component */
	[htmlAttributes: string]: any;
}

/** The image component with or without the figure caption. */
const Image: React.FC<ImageProps> = ({
	widthAuto,
	src,
	alt,
	srcSet,
	webpSrcSet,
	sizes,
	className,
	figCaption,
	...htmlAttributes
}) => {
	if (!src) {
		return null;
	}
	return (
		<>
			{!figCaption && (
				<picture>
					{webpSrcSet && (
						<source type="image/webp" srcSet={srcSet} sizes={sizes} />
					)}
					<Img
						widthAuto={widthAuto}
						src={src}
						alt={alt}
						aria-hidden={alt === '' ? 'true' : undefined}
						srcSet={srcSet || undefined}
						sizes={sizes}
						className={className}
						{...htmlAttributes}
					/>
				</picture>
			)}

			{figCaption && (
				<Figure>
					<Img
						widthAuto={widthAuto}
						src={src}
						alt={alt}
						className={className}
						{...htmlAttributes}
					/>
					<FigCaption>{figCaption}</FigCaption>
				</Figure>
			)}
		</>
	);
};

export default Image;
