/**
 * LoadMorePagination - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { mediaQueries, colors } from 'theme';
import { SmallP } from 'components/Text';

export const Container = styled.div`
	max-width: ${px2rem(1100)};
	margin: 4rem auto ${px2rem(140)};

	${mediaQueries.phoneOrTablet} {
		padding: 0;
		padding-right: 2rem;
		margin-bottom: 5rem;
	}

	${mediaQueries.phone} {
		margin-bottom: 4rem;
		margin-top: 3rem;
	}
`;

export const ButtonContainer = styled.div`
	text-align: center;
	margin-bottom: ${px2rem(90)};

	${mediaQueries.phoneOrTablet} {
		margin-bottom: 4rem;
	}
`;

export const PaginationPhrase = styled(SmallP)`
	color: ${colors.greyDark};
	margin-bottom: 1.5rem;

	${mediaQueries.phone} {
		text-align: center;
	}
`;
