/**
 * Checkbox
 */

import React, { useState, useEffect, useRef } from 'react';
import { useFieldValidation } from '../hooks/useFieldValidation';
import { useFormValue } from '../context/Form.context';
import { CheckboxProps } from 'types/form';
import { CheckboxIcon, CheckboxInput } from '../Form.styles';

/**
 * Checks if the checkbox should be checked.
 */
const shouldBeChecked = (value: string, defaultValue: any) => {
	if (Array.isArray(defaultValue)) {
		return defaultValue.indexOf(value) !== -1;
	} else {
		return value === defaultValue;
	}
};

const Checkbox: React.FC<CheckboxProps> = (props) => {
	const {
		id,
		name,
		onFieldChange,
		autoSubmit,
		value,
		single,
		showError,
		className,
		visibleFields,
		disabled,
		index,
	} = props;
	const [checked, setChecked] = useState(
		shouldBeChecked(props.value, props.defaultValue)
	);

	const [{ invalidFields }, dispatch] = useFormValue();
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef(null);
	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props
	);

	useEffect(() => {
		if (single) {
			const validatedField = validateField(checked);

			if (validatedField.message) {
				setValid(false);
				dispatch({ type: 'FIELD_INVALID', field: validatedField });
			} else {
				setValid(true);

				if (invalidFields.includes(validatedField.id)) {
					dispatch({ type: 'FIELD_VALID', field: validatedField });
				}
			}

			if (touched && !valid) {
				showFieldError(validatedField);
			} else if (
				(showError && !valid && visibleFields && visibleFields.includes(id)) ||
				(showError && !valid && !visibleFields)
			) {
				setTouched(true);
				showFieldError(validatedField);
			} else {
				hideFieldError();
			}
		}
	}, [touched, showError, invalidFields, visibleFields, checked]);

	const onChange = (e: React.FormEvent<HTMLElement>) => {
		const target = e.target as HTMLFormElement;

		setChecked(target.checked);
		if (!touched) {
			setTouched(true);
		}

		onFieldChange({
			name,
			value: target.value,
			checked: target.checked,
			autoSubmit,
			type: 'Checkbox',
		});
	};

	const error = showError === undefined || !showError ? 'false' : 'true';

	return (
		<span>
			<CheckboxInput
				type="checkbox"
				id={typeof index === 'number' && index >= 0 ? `${id}-${index}` : id}
				name={name}
				className={className}
				value={value}
				checked={checked}
				disabled={disabled}
				onChange={onChange}
				ref={fieldRef}
				aria-describedby={`form${id}__desc`}
				aria-invalid={single ? !valid : undefined}
			/>
			<span>
				<CheckboxIcon icon="check" showerror={error} />
			</span>
		</span>
	);
};

export default Checkbox;
