/**
 * EpiForms - styles
 */
import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { H3, P } from 'components/Text';

interface StyledProps {
	type?: 'success' | 'error';
}

interface StyledWrapperProps {
	backgroundColor?: 'lightBlue' | 'white';
}

export const FormContainerWrapper = styled.div<StyledWrapperProps>`
	${({ backgroundColor }) => {
		if (backgroundColor === 'lightBlue') {
			return `background-color: ${colors.blueLightest}`;
		} else if (backgroundColor === 'white') {
			return `background-color: ${colors.white}`;
		}
	}}
`;

export const FormContainer = styled.div`
	max-width: ${px2rem(790)};
	margin: 0 auto;
	padding: 1rem 0;
	font-family: ${typography.fontFamily.alt};

	@media (max-width: 1040px) {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	${mediaQueries.phone} {
		padding: 1rem;
	}
`;

export const FormHeading = styled(H3)`
	font-size: ${px2rem(28)};
	font-weight: ${typography.fontWeight.semiBold};
	letter-spacing: 0;
	line-height: ${px2rem(55)};
	margin: 0;
	text-align: center;
`;

export const FormSubText = styled(P)`
	font-size: ${px2rem(16)};
	font-weight: ${typography.fontWeight.normal};
	margin: 0;
	line-height: ${px2rem(24)};
	letter-spacing: ${px2rem(0.35)};
	text-align: center;
`;

/**
 * FormMessage
 */
export const Message = styled.div`
	margin: 24px 0;
`;

export const StatusMessage = styled.div<StyledProps>`
	position: relative;
	background-color: ${colors.white};

	&:before {
		content: ' ';
		height: 100%;
		position: absolute;
		left: 0;
		width: ${px2rem(7)};
		background-color: ${colors.red};
	}

	${({ type }) => {
		switch (type) {
			case 'success':
				return `
					&:before {
						background-color: ${colors.green};
					}
				`;
			case 'error':
				return `
					&:before {
						background-color: ${colors.red};
					}
				`;
			default:
				return `
					&:before {
						background-color: ${colors.grey};
					}
				`;
		}
	}}
`;

export const TextContent = styled.div`
	font-family: ${typography.fontFamily.alt};
	padding: ${px2rem(35)} ${px2rem(60)};
	${mediaQueries.phone} {
		padding: ${px2rem(67)} ${px2rem(34)} ${px2rem(45)} ${px2rem(34)};
	}
`;

export const SummaryHeading = styled.h2`
	font-size: 16px;
`;

export const RichText = styled.div`
	font-size: 14px;

	ul {
		margin-top: 0;
		padding-bottom: 0;
	}

	p,
	li {
		font-size: 14px;
	}

	li {
		margin-bottom: 0;
	}

	a {
		color: blue;
		text-decoration: underline;
	}
`;
