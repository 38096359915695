/**
  ReportsPage
 */

import React from 'react';
import { LinkType, StandardImage } from 'types/global';
import {
	AltCardWrapper,
	AnchorTextAlt2,
	BottomArea,
	Container,
	Heading,
	ImageColumn,
	ImageColumnInner,
	TextColumnAlt,
	Preamble,
	IconBadge,
} from './Cards.styles';
import Image from 'components/Image';
import Icon from 'components/Icon';

interface Props {
	/** ReportsPage heading */
	heading: string;

	/** ReportsPage subHeading */
	subHeading: string;

	/** ReportsPage preamble */
	preamble?: string;

	/** ReportsPage link */
	link: LinkType;

	/** ReportsPage image */
	image: StandardImage;

	/** Parent backgroundcolor */
	backgroundColor: 'lightBlue' | 'blue' | 'white';

	/** Card index */
	index: number;

	/** Card id */
	id?: string;

	/** EpiFind hitId */
	hitId?: string;
}

/** Container component for ReportPageCard. */
const ReportsPageCard: React.FC<Props> = ({
	heading,
	subHeading,
	preamble,
	link,
	image,
	backgroundColor,
	index,
	hitId,
	id,
}) => (
	<AltCardWrapper
		backgroundColor={backgroundColor}
		aria-labelledby={`heading-${id}`}
	>
		<Container
			href={link.link && typeof link.link === 'string' ? link.link : link}
			id={hitId || id}
		>
			<ImageColumn>
				<ImageColumnInner>
					<Image
						src={image.src}
						alt={image.alt}
						srcSet={`
						${image.sizes[0]} 800w,
						${image.sizes[1]} 400w
					`}
						webpSrcSet={`
						${image.webpSizes[0]} 800w,
						${image.webpSizes[1]} 400w
					`}
						sizes="(min-width: 768px) 60vw, (max-width: 767px) 70vw, 400px"
					/>
				</ImageColumnInner>
			</ImageColumn>

			<BottomArea>
				<TextColumnAlt>
					<Heading
						as="h3"
						id={`heading-${id}`}
						backgroundColor={backgroundColor}
					>
						{heading}
					</Heading>

					{preamble && (
						<Preamble backgroundColor={backgroundColor}>{preamble}</Preamble>
					)}
				</TextColumnAlt>

				<AnchorTextAlt2 backgroundColor={backgroundColor}>
					{link.text}
					<IconBadge backgroundColor={backgroundColor}>
						<Icon icon={'arrowRight'} size={0} aria-hidden="true" />
					</IconBadge>
				</AnchorTextAlt2>
			</BottomArea>
		</Container>
	</AltCardWrapper>
);

export default ReportsPageCard;
