import { createGlobalStyle } from 'styled-components';
import { colors } from 'theme';
import { px2rem } from 'theme/utils';

export const GlobalStyle = createGlobalStyle`
	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
	html {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		width: 100%;
		overflow-x: hidden;

		/* Smooth scrolling 
		scroll-behavior: smooth;
		*/
		/* Font smoothing */
		text-rendering: optimizeLegibility;
		-moz-osx-font-smoothing: grayscale;
		font-smoothing: antialiased;
		-webkit-font-smoothing: antialiased;
		text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
	}
	body {
		margin: 0;
		padding: 0;
		background-color: ${colors.white};
	}
	img {
		border: 0 none;
		height: auto;
		max-width: 100%;
		vertical-align: middle;
	}
	.usability--tab-focus {
		outline: 0.2rem solid ${colors.blue};
	}

	a, button {
		&:focus {
			box-shadow: 0 0 0 ${px2rem(3)} ${colors.focusColor};
			outline: none;
		}
	}

	h1[id], h2[id], a[id]:not([href]) {
		/* header logo image height + padding */
		padding-top: 1.2rem;
		margin-top: -0.2rem;
		margin-bottom: 2rem;
	}

	/** SPECTRAL */
	@font-face {
		font-family: 'Spectral';
		src: url('/assets/fonts/spectral/Spectral-Bold.ttf') format('truetype');
		font-weight: 700;
		font-style: normal;
	}
	@font-face {
		font-family: 'Spectral';
		src: url('/assets/fonts/spectral/Spectral-BoldItalic.ttf') format('truetype');
		font-weight: 700;
		font-style: italic;
	}
	@font-face {
		font-family: 'Spectral';
		src: url('/assets/fonts/spectral/Spectral-Regular.ttf') format('truetype');
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: 'Spectral';
		src: url('/assets/fonts/spectral/Spectral-Italic.ttf') format('truetype');
		font-weight: 400;
		font-style: italic;
	}

	/** EUCLID CIRCULAR B */
	@font-face {
		font-family: 'Euclid Circular B';
		src: url('/assets/fonts/euclid/EuclidCircularB-Bold-WebS.eot');
		src: url('/assets/fonts/euclid/EuclidCircularB-Bold-WebS.eot?#iefix') format('embedded-opentype'),
			url('/assets/fonts/euclid/EuclidCircularB-Bold-WebS.woff2') format('woff2'),
			url('/assets/fonts/euclid/EuclidCircularB-Bold-WebS.woff') format('woff'),
			url('/assets/fonts/euclid/EuclidCircularB-Bold-WebS.ttf') format('truetype'),
			url('/assets/fonts/euclid/EuclidCircularB-Bold-WebS.svg#TradeGothicLTStd-Bd2Obl') format('svg');
		font-weight: bold;
		font-style: normal;
	}
	@font-face {
		font-family: 'Euclid Circular B';
		src: url('/assets/fonts/euclid/EuclidCircularB-BoldItalic-WebS.eot');
		src: url('/assets/fonts/euclid/EuclidCircularB-BoldItalic-WebS.eot?#iefix') format('embedded-opentype'),
			url('/assets/fonts/euclid/EuclidCircularB-BoldItalic-WebS.woff2') format('woff2'),
			url('/assets/fonts/euclid/EuclidCircularB-BoldItalic-WebS.woff') format('woff'),
			url('/assets/fonts/euclid/EuclidCircularB-BoldItalic-WebS.ttf') format('truetype'),
			url('/assets/fonts/euclid/EuclidCircularB-BoldItalic-WebS.svg#TradeGothicLTStd-Bd2Obl') format('svg');
		font-weight: bold;
		font-style: italic;
	}
	@font-face {
		font-family: 'Euclid Circular B';
		src: url('/assets/fonts/euclid/EuclidCircularB-Light-WebS.eot');
		src: url('/assets/fonts/euclid/EuclidCircularB-Light-WebS.eot?#iefix') format('embedded-opentype'),
			url('/assets/fonts/euclid/EuclidCircularB-Light-WebS.woff2') format('woff2'),
			url('/assets/fonts/euclid/EuclidCircularB-Light-WebS.woff') format('woff'),
			url('/assets/fonts/euclid/EuclidCircularB-Light-WebS.ttf') format('truetype'),
			url('/assets/fonts/euclid/EuclidCircularB-Light-WebS.svg#TradeGothicLTStd-Bd2Obl') format('svg');
		font-weight: 300;
		font-style: normal;
	}
	@font-face {
		font-family: 'Euclid Circular B';
		src: url('/assets/fonts/euclid/EuclidCircularB-LightItalic-WebS.eot');
		src: url('/assets/fonts/euclid/EuclidCircularB-LightItalic-WebS.eot?#iefix') format('embedded-opentype'),
			url('/assets/fonts/euclid/EuclidCircularB-LightItalic-WebS.woff2') format('woff2'),
			url('/assets/fonts/euclid/EuclidCircularB-LightItalic-WebS.woff') format('woff'),
			url('/assets/fonts/euclid/EuclidCircularB-LightItalic-WebS.ttf') format('truetype'),
			url('/assets/fonts/euclid/EuclidCircularB-LightItalic-WebS.svg#TradeGothicLTStd-Bd2Obl') format('svg');
		font-weight: 300;
		font-style: italic;
	}
	@font-face {
		font-family: 'Euclid Circular B';
		src: url('/assets/fonts/euclid/EuclidCircularB-Medium-WebS.eot');
		src: url('/assets/fonts/euclid/EuclidCircularB-Medium-WebS.eot?#iefix') format('embedded-opentype'),
			url('/assets/fonts/euclid/EuclidCircularB-Medium-WebS.woff2') format('woff2'),
			url('/assets/fonts/euclid/EuclidCircularB-Medium-WebS.woff') format('woff'),
			url('/assets/fonts/euclid/EuclidCircularB-Medium-WebS.ttf') format('truetype'),
			url('/assets/fonts/euclid/EuclidCircularB-Medium-WebS.svg#TradeGothicLTStd-Bd2Obl') format('svg');
		font-weight: 500;
		font-style: normal;
	}
	@font-face {
		font-family: 'Euclid Circular B';
		src: url('/assets/fonts/euclid/EuclidCircularB-MediumItalic-WebS.eot');
		src: url('/assets/fonts/euclid/EuclidCircularB-MediumItalic-WebS.eot?#iefix') format('embedded-opentype'),
			url('/assets/fonts/euclid/EuclidCircularB-MediumItalic-WebS.woff2') format('woff2'),
			url('/assets/fonts/euclid/EuclidCircularB-MediumItalic-WebS.woff') format('woff'),
			url('/assets/fonts/euclid/EuclidCircularB-MediumItalic-WebS.ttf') format('truetype'),
			url('/assets/fonts/euclid/EuclidCircularB-MediumItalic-WebS.svg#TradeGothicLTStd-Bd2Obl') format('svg');
		font-weight: 500;
		font-style: italic;
	}
	@font-face {
		font-family: 'Euclid Circular B';
		src: url('/assets/fonts/euclid/EuclidCircularB-Regular-WebS.eot');
		src: url('/assets/fonts/euclid/EuclidCircularB-Regular-WebS.eot?#iefix') format('embedded-opentype'),
			url('/assets/fonts/euclid/EuclidCircularB-Regular-WebS.woff2') format('woff2'),
			url('/assets/fonts/euclid/EuclidCircularB-Regular-WebS.woff') format('woff'),
			url('/assets/fonts/euclid/EuclidCircularB-Regular-WebS.ttf') format('truetype'),
			url('/assets/fonts/euclid/EuclidCircularB-Regular-WebS.svg#TradeGothicLTStd-Bd2Obl') format('svg');
		font-weight: normal;
		font-style: normal;
	}
	@font-face {
		font-family: 'Euclid Circular B';
		src: url('/assets/fonts/euclid/EuclidCircularB-RegularItalic-WebS.eot');
		src: url('/assets/fonts/euclid/EuclidCircularB-RegularItalic-WebS.eot?#iefix') format('embedded-opentype'),
			url('/assets/fonts/euclid/EuclidCircularB-RegularItalic-WebS.woff2') format('woff2'),
			url('/assets/fonts/euclid/EuclidCircularB-RegularItalic-WebS.woff') format('woff'),
			url('/assets/fonts/euclid/EuclidCircularB-RegularItalic-WebS.ttf') format('truetype'),
			url('/assets/fonts/euclid/EuclidCircularB-RegularItalic-WebS.svg#TradeGothicLTStd-Bd2Obl') format('svg');
		font-weight: normal;
		font-style: italic;
	}
	@font-face {
		font-family: 'Euclid Circular B';
		src: url('/assets/fonts/euclid/EuclidCircularB-Semibold-WebS.eot');
		src: url('/assets/fonts/euclid/EuclidCircularB-Semibold-WebS.eot?#iefix') format('embedded-opentype'),
			url('/assets/fonts/euclid/EuclidCircularB-Semibold-WebS.woff2') format('woff2'),
			url('/assets/fonts/euclid/EuclidCircularB-Semibold-WebS.woff') format('woff'),
			url('/assets/fonts/euclid/EuclidCircularB-Semibold-WebS.ttf') format('truetype'),
			url('/assets/fonts/euclid/EuclidCircularB-Semibold-WebS.svg#TradeGothicLTStd-Bd2Obl') format('svg');
		font-weight: 600;
		font-style: normal;
	}
	@font-face {
		font-family: 'Euclid Circular B';
		src: url('/assets/fonts/euclid/EuclidCircularB-SemiboldItalic-WebS.eot');
		src: url('/assets/fonts/euclid/EuclidCircularB-SemiboldItalic-WebS.eot?#iefix') format('embedded-opentype'),
			url('/assets/fonts/euclid/EuclidCircularB-SemiboldItalic-WebS.woff2') format('woff2'),
			url('/assets/fonts/euclid/EuclidCircularB-SemiboldItalic-WebS.woff') format('woff'),
			url('/assets/fonts/euclid/EuclidCircularB-SemiboldItalic-WebS.ttf') format('truetype'),
			url('/assets/fonts/euclid/EuclidCircularB-SemiboldItalic-WebS.svg#TradeGothicLTStd-Bd2Obl') format('svg');
		font-weight: 600;
		font-style: italic;
	}
`;
