/**
 * CaseBox
 */

import React from 'react';
import { CaseWrapper } from './CaseBox.styles';
import { CaseBoxContentModel } from 'types/listings';
import CaseBoxContent from './CaseBoxContent';

interface Props {
	/** Case object */
	caseBoxContents: CaseBoxContentModel[];

	/** Read more text */
	readMoreText?: string;

	/** Case index */
	index: number;

	/** Determine if caselistpage or not */
	isCaseListPage?: boolean;

	/** Item id */
	id?: string;
}

/** Component to display a list of cases in a table. */
const CaseBox: React.FC<Props> = ({
	caseBoxContents,
	index,
	isCaseListPage,
	id,
}) => (
	<CaseWrapper
		aria-labelledby={`CaseBox-heading-${index}`}
		id={id}
		tabIndex={-1}
		isCaseListPage={isCaseListPage}
	>
		<CaseBoxContent
			index={index}
			caseBoxContents={caseBoxContents}
			isCaseListPage={isCaseListPage}
		></CaseBoxContent>
	</CaseWrapper>
);

export default CaseBox;
