/**
 * GlossaryPage
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { GlossaryItemModel, GlossaryPageModel } from 'types/pages';
import Text from 'components/Text';
import Space from 'components/Space';
import { Grid } from 'components/Grid';
import Breadcrumb from 'components/Breadcrumb';
import {
	LeftCell,
	RightCell,
	StickyArea,
	ListHeadings,
	StyledButton,
	GlossaryListContainer,
	StyledPreamble,
} from './GlossaryPage.styles';
import Download from 'components/Download';
import PageLayout from 'layouts/PageLayout';
import GlossaryItem from './GlossaryItem';

/** GlossaryPage. */
const GlossaryPage: React.FC<GlossaryPageModel> = ({
	heading,
	preamble,
	breadcrumbs,
	listHeadings,
	translations,
	glossaryList,
	download,
	_properties = {},
}) => {
	const isTablet = useMediaQuery({
		maxWidth: breakpointsNumber.desktop - 1,
	});

	return (
		<main id="main-content">
			{breadcrumbs && (
				<Breadcrumb
					links={breadcrumbs}
					label={translations.breadcrumbsLabel}
					withBorder
				/>
			)}

			<PageLayout labelledBy="article-heading">
				<Grid inner={true}>
					<LeftCell span={7} phone={12}>
						<Text as="h1" {..._properties?.heading} id="article-heading">
							{heading}
						</Text>

						{(_properties?.preamble || preamble) && (
							<StyledPreamble {..._properties?.preamble}>
								{preamble}
							</StyledPreamble>
						)}

						<Space bottom={2}>
							<ListHeadings>
								{listHeadings.split(',').map((heading: string, i: number) => (
									<li key={i}>
										<StyledButton
											icon="none"
											variant="link"
											type="link"
											href={`#${heading}`}
										>
											{heading}
										</StyledButton>
									</li>
								))}
							</ListHeadings>
						</Space>

						<GlossaryListContainer>
							{glossaryList.map((item: GlossaryItemModel, j: number) => (
								<li key={j}>
									<GlossaryItem {...item} index={j} />
								</li>
							))}
						</GlossaryListContainer>
					</LeftCell>
					<RightCell span={isTablet ? 12 : 5}>
						{download.link && (
							<StickyArea>
								<Download
									download={download}
									downloadHeading={translations.downloadHeading}
									downloadButtonText={translations.downloadButtonText}
								/>
							</StickyArea>
						)}
					</RightCell>
				</Grid>
			</PageLayout>
		</main>
	);
};

export default GlossaryPage;
