/**
 * FilterChip
 */

import React from 'react';
import { colors } from 'theme';
import { Chip, CloseIcon } from './FilterChip.styles';

interface Props {
	/** Filter chip text */
	children: any;

	/** Filter chip background color */
	color: 'blue' | 'grey';

	/** Filter chip button type */
	type?: 'button' | 'reset' | undefined;

	/** Function to call on click */
	onClick?: any;
}

/** A filter chip to use for listing filters */
const FilterChip: React.FC<Props> = ({
	children,
	color,
	onClick,
	type,
	...props
}) => {
	return (
		<Chip $color={color} type={type} onClick={onClick} {...props}>
			<span>{children}</span>
			<CloseIcon icon="close" color={colors.blueDark} />
		</Chip>
	);
};

export default FilterChip;
