/**
 * Accordion - styles
 */

import styled, { css } from 'styled-components';
import Icon from 'components/Icon';
import { colors, mediaQueries, typography } from 'theme';
import { px2rem } from 'theme/utils';
import { darken } from 'polished';
import { H6 } from 'components/Text/Text.styles';
import Link from 'components/Link';
interface StyleProps {
	expanded?: boolean;

	$variant?:
		| 'white'
		| 'lightBlue'
		| 'blueLightest'
		| 'greyLightest'
		| 'html'
		| 'list'
		| 'transparent';

	linkStyled?: boolean;

	noHover?: boolean;
	titleTight?: boolean;
}

const getVariant = (variant: string, expanded?: boolean) => {
	switch (variant) {
		case 'transparent':
			return css`
				border: ${px2rem(1)} solid ${darken(0.05, colors.greyLightest)};
				border-left: none;
				border-right: none;
				background: transparent;
				color: ${colors.blueDarker};

				&:hover {
					background: ${colors.white};
					border-left: none;
					border-right: none;
				}
			`;
		case 'html':
			return css`
				border-color: ${colors.offBlueLightest};
				background: ${colors.offBlueLightest};
				color: ${colors.greyDark};

				border-top-left-radius: ${px2rem(30)};
				border-top-right-radius: ${px2rem(30)};
				border-bottom-left-radius: ${expanded ? 0 : px2rem(30)};
				border-bottom-right-radius: ${expanded ? 0 : px2rem(30)};
			`;
		case 'lightBlue':
			return css`
				border-color: ${colors.offBlueLightest};
				background: ${expanded
					? darken(0.05, colors.offBlueLightest)
					: colors.offBlueLightest};
				color: ${colors.greyDark};

				&:hover {
					background: ${darken(0.05, colors.offBlueLightest)};
				}
			`;
		case 'white':
			return css`
				border-color: ${colors.white};
				background: ${expanded ? darken(0.05, colors.white) : colors.white};
				color: ${colors.greyDark};

				&:hover {
					background: ${darken(0.05, colors.white)};
				}
			`;
		case 'blueLightest':
			return css`
				border-color: ${colors.offBlueLighter};
				background: ${expanded
					? darken(0.05, colors.offBlueLighter)
					: colors.offBlueLighter};
				color: ${colors.blueDarker};

				&:hover {
					background: ${darken(0.05, colors.offBlueLighter)};
				}
			`;
		case 'greyLightest':
			return css`
				border-color: ${colors.greyLightest};
				background: ${expanded
					? darken(0.05, colors.greyLightest)
					: colors.greyLightest};
				color: ${colors.greyDark};

				&:hover {
					background: ${darken(0.05, colors.greyLightest)};
				}
			`;
		case 'list':
			return css`
				border: ${px2rem(1)} solid ${darken(0.05, colors.greyLightest)};
				border-bottom: ${expanded
					? '0'
					: `${px2rem(1)} solid ${darken(0.05, colors.greyLightest)}`};
				border-left: none;
				border-right: none;
				background: ${expanded ? colors.greyLightest : colors.white};
				color: ${colors.black};

				&:hover {
					background: ${expanded ? colors.greyLightest : colors.white};
					border-left: none;
					border-right: none;
				}
			`;
		default:
			return css`
				border-color: ${colors.offBlueLightest};
				background: ${expanded
					? darken(0.05, colors.offBlueLightest)
					: colors.offBlueLightest};
				color: ${colors.blueDark};

				&:hover {
					background: ${darken(0.05, colors.offBlueLightest)};
				}
			`;
	}
};

export const AccordionItemWrapper = styled.div`
	& + & {
		margin-top: -1px;
	}
`;

export const Header = styled.h2<StyleProps>`
	font-family: ${typography.fontFamily.alt};
	font-weight: ${({ linkStyled }) =>
		!linkStyled
			? `${typography.fontWeight.bold}`
			: `${typography.fontWeight.normal}`};
	font-size: ${px2rem(15)};
	line-height: ${px2rem(15)};
	margin: 0;
`;

export const HeaderButton = styled.span<StyleProps>`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(18)};
	font-weight: ${typography.fontWeight.medium};
	display: flex;
	position: relative;
	width: 100%;
	width: ${({ linkStyled }) => (!linkStyled ? '100%' : 'auto')};
	text-decoration: ${({ linkStyled }) => (linkStyled ? 'underline' : 'none')};
	align-items: center;
	justify-content: space-between;
	border: 0;
	transition: background 0.2s;
	outline: 0;
	border: ${px2rem(1)} solid transparent;
	padding: ${(props) => (props.$variant === 'html' ? px2rem(5) : px2rem(22))};
	${mediaQueries.phone} {
		padding: ${(props) => (props.$variant === 'html' ? px2rem(5) : px2rem(0))};
	}

	${({ $variant, expanded }) => getVariant($variant || 'lightBlue', expanded)};

	${(props) =>
		props.noHover &&
		`
			&:hover {
				border: ${px2rem(1)} solid transparent;
			}
	`}

	&:hover,
	&:focus {
		${mediaQueries.desktop} {
			background-color: ${colors.greyLightest};
			background: ${colors.greyLightest};
		}
	}
	cursor: pointer;
`;

export const HeaderIcon = styled(Icon)`
	width: ${px2rem(12)};
	height: ${px2rem(12)};
`;

export const HeaderNoIcon = styled.div`
	height: ${px2rem(46)};
	width: ${px2rem(46)};
`;

export const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ContentArea = styled.div<StyleProps>`
	${({ $variant, expanded }) => {
		if ($variant === 'html') {
			return css`
				border-color: ${colors.offBlueLightest};
				background: ${colors.offBlueLightest};
				color: ${colors.greyDark};

				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border-bottom-left-radius: ${px2rem(30)};
				border-bottom-right-radius: ${px2rem(30)};

				&:focus {
					outline: none;
				}

				${InnerContent} {
					padding: ${px2rem(10)} ${px2rem(50)};
				}
			`;
		} else if ($variant === 'list') {
			return css`
				border-bottom: ${expanded
					? `${px2rem(1)} solid ${darken(0.05, colors.greyLightest)}`
					: 0};

				&:focus {
					outline: none;
				}

				${InnerContent} {
					padding: ${px2rem(8)} ${px2rem(40)} ${px2rem(40)} ${px2rem(66)};
					${mediaQueries.phone} {
						padding: ${px2rem(8)} ${px2rem(1)} ${px2rem(40)} ${px2rem(24)};
					}
				}
			`;
		} else if ($variant === 'transparent') {
			return css`
				border-bottom: ${expanded
					? `${px2rem(1)} solid ${darken(0.05, colors.greyLightest)}`
					: 0};

				&:focus {
					outline: none;
				}

				${InnerContent} {
					padding: ${px2rem(15)} ${px2rem(40)};
				}
			`;
		}
	}}
`;

export const AccordionUl = styled.ul`
	margin: 0;
	padding: 0.5rem 0;
	list-style: none;
`;

export const AccordionLi = styled.li`
	margin-bottom: 1rem;
	a {
		font-family: ${typography.fontFamily.standard};
		font-size: ${px2rem(15)};
		font-weight: normal;
		line-height: 1.13;
		text-decoration: none;
		:hover {
			text-decoration: underline;
		}
	}
`;

export const CountIcon = styled.span<StyleProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	svg {
		height: ${px2rem(45)};
		width: ${px2rem(45)};
		color: ${colors.chartColors.blue};
		margin-left: 0 !important;
	}

	${({ $variant }) => {
		if ($variant === 'blueLightest') {
			return css`
				svg {
					color: ${colors.white};
				}
			`;
		}
	}}
`;

export const CheckIcon = styled(Icon)`
	fill: ${colors.white};
	margin-left: ${px2rem(15)};
	border-radius: 100%;
	width: ${px2rem(25)};
	height: ${px2rem(25)};
	transition: none;
	background-color: ${colors.green};
`;

export const Counter = styled.span<StyleProps>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: ${colors.white};
	font-size: ${px2rem(21)};
	font-weight: ${typography.fontWeight.semiBold};
	letter-spacing: 0;
	line-height: ${px2rem(28)};

	${({ $variant }) => {
		if ($variant === 'blueLightest') {
			return css`
				color: ${colors.greyDark};
			`;
		}
	}}
`;

export const TitleArea = styled.p`
	display: flex;
	align-items: center;
	margin: 0;
	max-width: 75%;
	word-break: break-word;

	${mediaQueries.phone} {
		max-width: 100%;
		word-break: unset;
		padding: 0.5rem !important;
	}
`;

export const SubHeading = styled(H6)`
	color: ${colors.blueDarker};
	text-transform: none;
	font-weight: ${typography.fontWeight.bold};
	font-size: ${px2rem(16)};

	${mediaQueries.tablet} {
		margin-bottom: 0.5rem;
	}
`;

export const Title = styled.span<StyleProps>`
	margin: ${(props) =>
		props.titleTight ? `0 ${px2rem(18)}` : `0 ${px2rem(50)}`};
	text-align: left;
	line-height: ${px2rem(22)};
	font-weight: 500;

	${mediaQueries.phone} {
		font-size: ${px2rem(16)};
		font-weight: ${typography.fontWeight.medium};
		letter-spacing: 0;
		margin: ${px2rem(10)};
	}
`;

export const HeaderIconArea = styled.button<StyleProps>`
	height: ${px2rem(46)};
	width: ${px2rem(46)};
	background-color: ${(props) =>
		props.expanded ? colors.greyLightest : colors.white};
	border: 0;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	svg {
		margin-left: 0 !important;
	}

	${({ $variant, expanded }) => {
		if (
			$variant === 'greyLightest' ||
			$variant === 'lightBlue' ||
			$variant === 'blueLightest'
		) {
			return css`
				background-color: ${expanded ? colors.white : colors.greyLightest};
			`;
		} else if ($variant === 'transparent' || $variant === 'list') {
			return css`
				background-color: ${expanded ? colors.white : colors.greyLightest};
			`;
		} else if ($variant === 'white') {
			return css`
				background-color: ${expanded ? colors.white : colors.greyLightest};
			`;
		}
	}}
`;

export const ReadMoreLabel = styled.span`
	color: ${colors.greyDark};
	font-size: ${px2rem(15)};
	font-weight: ${typography.fontWeight.medium};
	margin: 0 ${px2rem(15)};

	${mediaQueries.phoneOrTablet} {
		display: none;
	}
`;

export const RightArea = styled.div`
	display: flex;
	align-items: center;
`;

export const TitleLink = styled(Link)`
	color: ${colors.blue};
	text-decoration: underline;
`;
