/**
 * SearchPage - styles
 */

import styled from 'styled-components';
import { colors, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

export const Content = styled.div`
	max-width: ${px2rem(1200)};
	margin: 0.5rem auto 0;

	@media (max-width: 1024px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
`;

export const ResultsContainer = styled.div`
	border-top: ${px2rem(1)} solid ${colors.greyLighter};
	padding: 0 4rem 0 2rem;

	${mediaQueries.phoneOrTablet} {
		padding: 0;
		padding-right: 2rem;
	}

	${mediaQueries.phone} {
		padding-top: 1rem;
		padding-right: 0;
	}
`;
