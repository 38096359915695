/**
 * PromoBlock - styles
 */

import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { H6, H1 } from 'components/Text/Text.styles';
import {
	ImageColumnProps,
	PropsButtonWrapper,
} from 'components/StartBannerBlock/StartBannerBlock.styles';

export interface ThemeProps {
	backgroundColor?: 'blueDark' | 'blue' | 'white';
	imageAlign?: 'left' | 'right';
}

const commonStyle = css`
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.normal};
`;

export const Container = styled.div<ThemeProps>`
	display: flex;
	flex-wrap: nowrap;
	padding: 3rem 0px;
	gap: ${px2rem(90)};

	${({ backgroundColor }) => {
		if (backgroundColor === 'blueDark' || backgroundColor === 'blue') {
			return `
				 color: ${colors.white};
			 `;
		} else {
			return `
				 color: ${colors.black};
			 `;
		}
	}}

	${({ imageAlign }) => {
		if (imageAlign === 'right') {
			return `
				 ${mediaQueries.phoneOrTablet} {
					 flex-direction: column;
					 gap: ${px2rem(40)};
				 }
 
				 ${mediaQueries.desktop} {
					 flex-direction: row-reverse;
				 }
			 `;
		} else {
			return `
				 ${mediaQueries.phoneOrTablet} {
					 flex-direction: column;
					 gap: ${px2rem(40)};
					 
				 }
			 `;
		}
	}}
`;

export const ImageColumn = styled.div<ThemeProps>`
	width: 44%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;

	${mediaQueries.tablet} {
		width: 100%;
		flex-direction: row;

		img {
			min-width: 100%;
		}
	}

	${mediaQueries.phone} {
		width: 100%;
		flex-direction: row;

		img {
			min-width: 100%;
		}
	}
`;

export const ImageColumnInner = styled.div<ImageColumnProps>`
	width: 100%;
`;

export const TextColumn = styled.div<ThemeProps>`
	display: flex;
	width: 56%;

	p {
		max-width: 44rem;
	}

	${mediaQueries.tablet} {
		width: 100%;
	}

	${mediaQueries.phone} {
		width: 100%;
	}
`;

export const TextColumnInner = styled.div<ThemeProps>`
	width: 100%;
`;

export const Heading = styled(H1)`
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.semiBold};
	font-size: ${px2rem(44)};
	line-height: ${px2rem(54)};
	margin-bottom: ${px2rem(20)};

	${mediaQueries.phoneOrTablet} {
		margin-top: ${px2rem(16)};
	}
`;

export const SubHeading = styled(H6)<ThemeProps>`
	${commonStyle};
	color: ${({ backgroundColor }) =>
		backgroundColor === 'blueDark' || backgroundColor === 'blue'
			? colors.white
			: colors.blueDarker};
	text-transform: uppercase;
	font-size: ${px2rem(14)};
	font-weight: ${typography.fontWeight.bold};
	margin-bottom: 1.25rem;

	${mediaQueries.phoneOrTablet} {
		margin-bottom: 0.25rem;
	}
	${mediaQueries.phone} {
		font-size: ${px2rem(12)};
	}
`;

export const Preamble = styled.p<ThemeProps>`
	${commonStyle};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(24)};
	margin-bottom: ${px2rem(21)};

	color: ${({ backgroundColor }) =>
		backgroundColor === 'blueDark' || backgroundColor === 'blue'
			? colors.white
			: colors.black};

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(16)};
		line-height: ${px2rem(24)};
	}
`;

interface ContainerProps extends PropsButtonWrapper {
	backgroundColor?: 'blueDark' | 'blue' | 'white';
}

export const ButtonContainer = styled.div<ContainerProps>`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: start;
	margin: 0 auto;
	margin-top: ${px2rem(32)};
	gap: ${px2rem(20)};
	flex-wrap: wrap;

	a {
		width: 18.65rem;

		&:focus {
			border: 0.1875rem solid rgb(255, 255, 255);

			background-color: ${({ backgroundColor }) =>
				backgroundColor === 'blueDark' || backgroundColor === 'blue'
					? darken(0.05, colors.blueLightest)
					: darken(0.05, colors.blueDark)};
		}
	}

	a span {
		max-width: ${px2rem(240)};
	}

	${mediaQueries.tablet} {
		width: 100%;
		align-items: start;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: start;
	}

	${mediaQueries.phone} {
		width: 100%;
		align-items: center;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
	}
`;
