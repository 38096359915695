/**
 * SearchPage
 */

import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { useUrlQuery, useDebounce, useSearchResult } from 'hooks';
import { getActiveFilters } from 'common/helpers';

import { CalendarListPageModel } from 'types/pages';
import { CalenderListItem } from 'types/listings';

import EpiContentArea from 'components/EpiContentArea';
import {
	Content,
	ResultsContainer,
	ArchiveButtonContainer,
} from './CalendarListPage.styles';

import LoadMorePagination from 'components/LoadMorePagination';

import {
	SearchResultsList,
	SearchResultsItem,
} from 'components/CalendarResultsList';
import { NoEventsText } from './CalendarListPage.styles';
import Breadcrumb from 'components/Breadcrumb';
import SearchForm from 'components/SearchForm';
import Button from 'components/Button';

/** Template for a calendarlist page. */
const CalendarListPage: React.FC<CalendarListPageModel> = ({
	breadcrumbs,
	topContentArea,
	filter,
	pagination,
	items,
	translations,
	archiveLink,
	_properties = {},
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const [action, setAction] = useState('');
	const activeFilters = getActiveFilters(filter);
	const [values, setValues] = useState<any>({
		...activeFilters,
	});

	const [currentPage, setCurrentPage] = useState<number>(
		pagination.currentPage
	);

	const debouncedInput = useDebounce(values.query, 500);

	const [updateQuery, response, loading, error] = useUrlQuery({
		...values,
		query: debouncedInput,
		page: currentPage,
	});

	const [filters, paginations, results] = useSearchResult(
		filter,
		pagination,
		items,
		response,
		action
	);

	return (
		<main id="main-content">
			<Breadcrumb
				links={breadcrumbs}
				label={translations.breadcrumbsLabel}
				withBorder
			/>

			<EpiContentArea
				{..._properties?.topContentArea}
				content={topContentArea}
				isPageHeader={true}
			/>

			<SearchForm
				translations={{
					clearFilterText: translations.clearFilterText,
					clearAllFiltersText: translations.clearAllFiltersText,
					hiddenSubmitText: translations.hiddenSubmitText,
				}}
				headerType="h2"
				_properties={_properties}
				isPhone={isPhone}
				loading={loading}
				error={error}
				filter={filters}
				pagination={paginations}
				values={values}
				handleSetAction={setAction}
				handleSetCurrentPage={setCurrentPage}
				handleSetValues={setValues}
				handleUpdateQuery={updateQuery}
				displayHiddenSubmitButton
			/>

			<Content>
				<ResultsContainer id="search-list">
					{results && results.length > 0 ? (
						<>
							<SearchResultsList>
								{results.map((item: CalenderListItem, i: number) => (
									<SearchResultsItem
										index={i}
										calendarLinkScreenReaderText={
											translations.calendarLinkScreenReaderText
										}
										readMoreLabel={translations.readMoreLabel}
										{...item}
										key={i}
									/>
								))}
							</SearchResultsList>

							<LoadMorePagination
								visible={paginations.currentPage < paginations.pageCount}
								loading={loading}
								buttonText={translations.showMoreButtonLabel}
								totalDisplay={paginations.totalDisplay}
								totalHits={paginations.total}
								onClick={() => {
									setCurrentPage((page) => page + 1);
									updateQuery({ ...values, page: currentPage + 1 });
									setAction('add');
								}}
							/>
						</>
					) : (
						<NoEventsText>{translations.noEventsText}</NoEventsText>
					)}
				</ResultsContainer>
				{archiveLink?.link && (
					<ArchiveButtonContainer>
						<Button variant="secondary" type="link" href={archiveLink.link}>
							{archiveLink.text}
						</Button>
					</ArchiveButtonContainer>
				)}
			</Content>
		</main>
	);
};

export default CalendarListPage;
