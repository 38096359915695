/**
 * DesktopMenu
 */

import React, { useRef } from 'react';
import { useOutsideClick } from 'hooks';
import {
	NavDesktop,
	NavDesktopWrapper,
	TopNav,
	LinkItem,
	Nav,
	MainNav,
	Pane,
} from './DesktopMenu.styles';
import { Transition } from 'react-transition-group';
import { colors } from 'theme';
import { TransitionState } from 'types/global';
import Ul from './DesktopMenu.Ul';

import { MenuIcon } from 'components/PageHeader/PageHeader.styles'; // TODO: This should probably be its own component

interface Props {
	/** Array of menu items */
	items: any[];

	/** Languages link */
	languages?: any;

	/** Current menu state (open/closed) */
	open: boolean;

	/** Callback method when the menu closes */
	onClose?: any;

	/** Translations */
	translations: {
		menuCloseText: string;
		menuText: string;
		menuLabel: string;
		screenReaderText: string;
	};
}

/** Desktop menu. */
const DesktopMenu: React.FC<Props> = ({
	items,
	languages,
	open,
	onClose,
	translations,
}) => {
	const fieldRef = useRef<any>();
	useOutsideClick(fieldRef, onClose);
	return (
		<Transition in={open} timeout={500}>
			{(state: TransitionState) => (
				<NavDesktopWrapper state={state} hidden={state === 'exited'}>
					<NavDesktop>
						{state !== 'exited' && (
							<Nav aria-label={translations.menuLabel} ref={fieldRef}>
								<TopNav>
									<LinkItem
										onClick={onClose}
										as="button"
										aria-expanded={open}
										id="inner-menu-button"
									>
										<span>{translations.menuCloseText}</span>
										<MenuIcon
											icon="close"
											color={colors.greyDark}
											aria-hidden={true}
										/>
									</LinkItem>
								</TopNav>
								<MainNav>
									<Pane tabIndex={-1}>
										<Ul
											links={items}
											languages={languages}
											level={1}
											onClose={onClose}
											screenReaderText={translations.screenReaderText}
										/>
									</Pane>
								</MainNav>
							</Nav>
						)}
					</NavDesktop>
				</NavDesktopWrapper>
			)}
		</Transition>
	);
};

export default DesktopMenu;
