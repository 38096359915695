/**
 * Fieldset
 */

import React, { useState, useRef, useEffect } from 'react';
import { useFieldValidation } from '../hooks/useFieldValidation';
import { useFormValue } from '../context/Form.context';
import { FieldsetProps } from 'types/form';
import { ErrorSpan } from '../Labels';
import { FieldsetBase, Legend } from '../Form.styles';

const Fieldset: React.FC<FieldsetProps> = (props) => {
	const {
		label,
		description,
		children,
		id,
		required,
		hiddenLabel,
		validationMessage,
		isInvalid,
		showError,
		visibleFields,
		className,
	} = props;

	const [{ invalidFields }, dispatch] = useFormValue();
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef(null);
	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props
	);

	useEffect(() => {
		const validatedField = validateField('fieldset');

		if (validatedField.message) {
			setValid(false);
			dispatch({ type: 'FIELD_INVALID', field: validatedField });
		} else {
			setValid(true);

			if (invalidFields.includes(validatedField.id)) {
				dispatch({ type: 'FIELD_VALID', field: validatedField });
			}
		}

		const partOfVisibleFields =
			visibleFields &&
			visibleFields.filter((fieldName) => fieldName.includes(id));

		if (touched && !valid) {
			showFieldError(validatedField);
		} else if (
			(showError &&
				!valid &&
				visibleFields &&
				partOfVisibleFields &&
				partOfVisibleFields.length > 0) ||
			(showError && !valid && !visibleFields)
		) {
			setTouched(true);
			showFieldError(validatedField);
		} else {
			hideFieldError();
		}
	}, [
		valid,
		dispatch,
		touched,
		showError,
		setValid,
		validateField,
		showFieldError,
		hideFieldError,
		invalidFields,
		visibleFields,
		id,
	]);

	return (
		<FieldsetBase
			className={className}
			id={id}
			title={description || undefined}
			ref={fieldRef}
			onBlur={() => setTouched(true)}
		>
			<Legend $visuallyHidden={hiddenLabel}>
				{label}
				{required && <abbr title="Obligatoriskt">*</abbr>}
			</Legend>

			<div>{children}</div>

			<ErrorSpan fieldId={id} invalid={isInvalid} tabIndex={-1}>
				{validationMessage || ''}
			</ErrorSpan>
		</FieldsetBase>
	);
};

export default Fieldset;
