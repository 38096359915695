/**
 * useUuid
 */

interface GetUuid {
	(): number;
}

interface ResetUuid {
	(): void;
}

interface UseUuid {
	(): [GetUuid, ResetUuid];
}

const DEFAULT: number = 0;
let counter: number = DEFAULT;

export const useUuid: UseUuid = () => {
	const getUuid: GetUuid = () => {
		const current = counter;
		counter = counter + 1;

		return current;
	};

	const resetUuid: ResetUuid = () => {
		counter = DEFAULT;
	};

	return [getUuid, resetUuid];
};
