/**
 * LinkItemBlock - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { H2, H3 } from 'components/Text/Text.styles';
import Link from 'components/Link';

export interface ThemeProps {
	$background?: 'lightBlue' | 'white';
	headingAlignment?: 'left' | 'center';
}

export const Container = styled(Link)<ThemeProps>`
	width: 100%;
	height: 100%;
	min-height: ${px2rem(303)};
	color: ${colors.greyDark};
	text-decoration: none;
	display: inline-block;
	padding: ${px2rem(33)};
	position: relative;
	border: ${px2rem(1)} solid transparent;
	background-color: ${({ $background }) =>
		$background != 'white' ? colors.white : colors.greyLightest};

	&:hover {
		background-color: ${colors.white};
		box-shadow: 0 ${px2rem(8)} ${px2rem(36)} 0 rgba(0, 0, 0, 0.1);
		border-color: ${colors.offGreyLighter};
	}

	${mediaQueries.phone} {
		padding: 1rem;
	}
`;

export const Heading = styled(H3)`
	margin-top: 0px;
`;

export const SubHeading = styled(H2)<ThemeProps>`
	color: ${colors.blueDark};
	text-transform: uppercase;
	margin-bottom: 1.25rem;
	font-size: ${px2rem(14)};
	line-height: ${px2rem(15)};
	letter-spacing: ${px2rem(1.2)};

	${mediaQueries.phoneOrTablet} {
		margin-bottom: 0.25rem;
	}
`;

export const Preamble = styled.p<ThemeProps>`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(24)};
	letter-spacing: normal;
	padding-bottom: ${px2rem(50)};

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(16)};
		line-height: ${px2rem(24)};
	}
`;

export const AnchorIcon = styled.div`
	position: absolute;
	right: ${px2rem(29)};
	bottom: ${px2rem(29)};
`;

export const IconBadge = styled.div`
	align-items: center;
	background-color: ${colors.blueDark};
	border-radius: 1.5rem;
	color: ${colors.blueDarker};
	display: flex;
	height: 3rem;
	width: 3rem;
	justify-content: center;
	margin-right: 1rem;

	:last-child {
		margin-right: 0;
	}

	svg {
		height: ${px2rem(13)};
		width: ${px2rem(13)};
	}
`;
