/***
 * TopBannerBlock
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { StandardImage } from 'types/global';

import {
	Wrapper,
	LeftBlock,
	LeftBlockInner,
	RightBlock,
	Heading,
	StyledPreamble,
	StyledText,
	ButtonWrapper,
	ImageWrapper,
} from './TopBannerBlock.styles';

import Image from 'components/Image';
import BlockContainer from 'layouts/BlockLayout';
import EpiFragments from 'components/EpiFragments';

interface Props {
	/** Block heading */
	heading: string;

	/** Block text */
	text?: any;

	/** Block preamble */
	preamble?: string;

	/** Image in desktop */
	image: StandardImage;

	/** Image in mobile */
	imageMobile: StandardImage;

	/** Background color of left side of block */
	backgroundColor: 'blue' | 'white';

	/** Buttons in block */
	children?: any;

	/** Epi on-page editing properties */
	_properties?: any;
}

/** TopBannerBlock with text and image */
const TopBannerBlock: React.FC<Props> = ({
	heading,
	text,
	preamble,
	image,
	imageMobile,
	backgroundColor = 'blue',
	children,
	_properties = {},
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const imageToShow = isPhone ? imageMobile : image;

	return (
		<BlockContainer backgroundColor={backgroundColor} noPadding={true}>
			<Wrapper {..._properties?.block}>
				<LeftBlock>
					<LeftBlockInner>
						<Heading bgColor={backgroundColor} {..._properties?.heading}>
							{heading}
						</Heading>

						{preamble && (
							<StyledPreamble
								bgColor={backgroundColor}
								{..._properties?.preamble}
							>
								{preamble}
							</StyledPreamble>
						)}

						{text && (
							<EpiFragments
								{..._properties?.text}
								fragments={text?.fragments}
								backgroundColor={backgroundColor}
							/>
						)}

						{children && (
							<ButtonWrapper $hasMoreThanTwoChilds={children.length > 2}>
								{children}
							</ButtonWrapper>
						)}
					</LeftBlockInner>
				</LeftBlock>

				<RightBlock {..._properties?.image}>
					{imageToShow && (
						<ImageWrapper>
							<Image
								src={imageToShow.src}
								alt={imageToShow.alt}
								srcSet={
									isPhone
										? `
						${imageToShow.sizes[0]} 800w,
						${imageToShow.sizes[1]} 400w
					`
										: `
						${imageToShow.sizes[0]} 1200w,
						${imageToShow.sizes[1]} 800w,
						${imageToShow.sizes[2]} 400w
					`
								}
								webpSrcSet={
									isPhone
										? `
						${imageToShow.webpSizes[0]} 800w,
						${imageToShow.webpSizes[1]} 400w
					`
										: `
						${imageToShow.webpSizes[0]} 1200w,
						${imageToShow.webpSizes[1]} 800w,
						${imageToShow.webpSizes[2]} 400w
					`
								}
								sizes="(min-width: 1040px) 60vw, (min-width: 768px) 40vw, (max-width: 767px) 100vw, 800px"
							/>
						</ImageWrapper>
					)}
				</RightBlock>
			</Wrapper>
		</BlockContainer>
	);
};

export default TopBannerBlock;
