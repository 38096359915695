/**
 * PageOverlay
 */

import styled, { css } from 'styled-components';
import { colors } from 'theme/colors';

export const overlayCss = css`
	background: ${colors.blackOpacity20};
	padding: 1rem 1rem 0 1rem;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 80;
	animation: fadeIn 0.4s ease-out forwards;

	@keyframes fadeIn {
		0% {
			display: none;
			opacity: 0;
		}
		1% {
			display: block;
		}
		100% {
			display: block;
			opacity: 1;
		}
	}
`;

export const PageOverlay = styled.div`
	.search-overlay & {
		${overlayCss};
	}
`;
