/**
 * Download
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import {
	Wrapper,
	Heading,
	DownloadButtonMobile,
	Preamble,
} from './Download.styles';
import { LinkType } from 'types/global';
import DownloadButton from 'components/DownloadButton';

interface Props {
	/** Download linktype */
	download: LinkType;

	/** Heading text */
	downloadHeading: string;

	/** Download text */
	downloadText?: string;

	/** Download button text */
	downloadButtonText: string;

	/** Number of pages text */
	downloadPagesText?: string;

	/** Number of pages */
	numberOfPages?: string;
}

/** Download component. */
const Download: React.FC<Props> = ({
	download,
	downloadHeading,
	downloadText,
	downloadButtonText,
	downloadPagesText,
	numberOfPages,
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	return isPhone ? (
		<DownloadButtonMobile>
			<DownloadButton
				type="link"
				href={download.link}
				text={download.text}
				target={download.target ? download.target : undefined}
			>
				{downloadButtonText}
			</DownloadButton>
		</DownloadButtonMobile>
	) : (
		<Wrapper>
			<Heading as="h2">{downloadHeading}</Heading>
			{downloadText && <Preamble>{downloadText}</Preamble>}
			{downloadPagesText && numberOfPages && (
				<Preamble>
					{downloadPagesText}: {numberOfPages}
				</Preamble>
			)}
			<DownloadButton
				type="link"
				href={download.link}
				text={download.text}
				target={download.target ? download.target : undefined}
			>
				{downloadButtonText}
			</DownloadButton>
		</Wrapper>
	);
};

export default Download;
