/**
 * RegisterPage - styles
 */

import styled from 'styled-components';
import { colors, mediaQueries, typography } from 'theme';
import { px2rem, hex2rgba } from 'theme/utils';
import Link from 'components/Link';
import { Field } from 'components/Form';
import Icon from 'components/Icon';
import { Cell, Grid } from 'components/Grid';

interface WidthProps {
	width?: number;
}

interface ItemsProps {
	items?: number;
	length?: number;
}

export const Heading = styled.div`
	background-color: ${colors.blueDarker};
	display: flex;
`;

export const HeadingContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0.5rem auto 0;
	max-width: ${px2rem(1920)};
	padding: ${px2rem(30)} 7.5rem ${px2rem(15)};
	width: 100%;

	${mediaQueries.phoneOrTablet} {
		flex-direction: column;
		padding: ${px2rem(14)} 1rem;
	}

	@media screen and (min-width: 840px) and (max-width: 1280px) {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}
`;

export const HeadingLeft = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	${mediaQueries.phoneOrTablet} {
		width: 100%;
	}
`;

export const HeadingRight = styled.div`
	align-items: end;
	display: flex;
	justify-content: end;
	flex-direction: row;
	width: 50%;
	${mediaQueries.phoneOrTablet} {
		align-items: initial;
		flex-direction: column;
		width: 100%;
	}
`;

export const HeadingHeader = styled.h1`
	color: ${colors.white};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(30)};
	line-height: 1.2;
	margin-top: 0;
	padding-top: 0;

	${mediaQueries.phoneOrTablet} {
		margin-bottom: ${px2rem(12)};
	}
`;

export const HeadingLatestUpdated = styled.p`
	color: ${colors.white};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(17)};
	line-height: 1.3;
	margin-top: 0;
	padding-top: 0;

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(14)};
	}
`;

export const HeadingLink = styled(Link)`
	align-items: center;
	color: ${colors.white};
	display: flex;
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(17)};
	justify-content: space-between;
	line-height: 1.3;
	padding: 1rem;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	${mediaQueries.phoneOrTablet} {
		padding: ${px2rem(6)} 0;
	}
`;

export const Search = styled.div`
	margin: 0.5rem auto 0;
	max-width: ${px2rem(1920)};
	padding: ${px2rem(30)} 7.5rem ${px2rem(15)};

	${mediaQueries.phoneOrTablet} {
		padding: 3rem 1rem;
	}

	@media screen and (min-width: 840px) and (max-width: 1280px) {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	fieldset {
		> label,
		> legend {
			font-family: ${typography.fontFamily.alt};
			font-size: 1rem;
			font-weight: ${typography.fontWeight.bold};
			margin-bottom: 1.5rem;
		}
	}
`;

export const SearchContainer = styled.div`
	border: 1px solid ${colors.offGreyLightest};
	border-radius: ${px2rem(20)};
	padding: ${px2rem(48)};

	${mediaQueries.phoneOrTablet} {
		border: none;
		border-radius: initial;
		padding: ${px2rem(16)} 0;
	}

	[id^='accordion-item-button'] {
		padding: 0.3rem 1.375rem;
	}
`;

export const SearchField = styled.div`
	display: flex;
	${mediaQueries.phone} {
		display: flex;
		flex-direction: column;
	}
`;

export const SearchInputContainer = styled.div`
	margin-bottom: ${px2rem(32)};
	position: relative;
	width: 100%;
	display: flex;
	input {
		width: 100%;
		border-radius: ${px2rem(35)};
		height: ${px2rem(60)};
		padding-left: ${px2rem(82)};
		position: relative;
	}
`;
export const SearchFieldButton = styled.div`
	margin-left: ${px2rem(32)};
	${mediaQueries.phone} {
		margin-left: 0px;
		width: 100%;
		margin-bottom: ${px2rem(32)};
		button {
			width: 100%;
			height: ${px2rem(50)};
		}
	}
`;

export const SearchIcon = styled(Icon)`
	left: 37px;
	position: absolute;
	top: 22px;
	z-index: 1;
`;

export const ClearIcon = styled(Icon)`
	position: absolute;
	right: 40px;
	top: 25px;
`;

export const Content = styled.div`
	margin: 0.5rem auto 0;
	max-width: ${px2rem(1920)};
	padding: ${px2rem(30)} 7.5rem ${px2rem(15)};

	${mediaQueries.phoneOrTablet} {
		padding: 3rem 1rem;
	}

	@media screen and (min-width: 840px) and (max-width: 1280px) {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}
`;

export const QuickSelectList = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 1rem;

	${mediaQueries.desktop} {
		padding: ${px2rem(15)} ${px2rem(40)};
	}

	select {
		width: 100%;
	}
`;

export const QuickSelectListMargin = styled.div<ItemsProps>`
	${({ items }) => items && `margin-bottom: ${3 + 1.625 * items}rem`};
	${({ length }) => length && `width: ${100 / length}%`};
`;

export const DetailSearchRow = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 0.5rem;

	${mediaQueries.desktop} {
		border-bottom: 1px solid ${hex2rgba(colors.blueDarker, 0.2)};
		flex-direction: row;
		margin-bottom: 1rem;
		padding: ${px2rem(15)} ${px2rem(40)};

		&:last-child {
			border-bottom: none;
		}
	}
`;

export const DetailSearchRowDate = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 0.5rem;

	${mediaQueries.desktop} {
		border-bottom: 1px solid ${hex2rgba(colors.blueDarker, 0.2)};
		margin-bottom: 1rem;
		padding: ${px2rem(15)} ${px2rem(40)};
	}

	${mediaQueries.largeDesktop} {
		border-bottom: 1px solid ${hex2rgba(colors.blueDarker, 0.2)};
		flex-direction: row;
		margin-bottom: 1rem;
		padding: ${px2rem(15)} ${px2rem(40)};

		&:last-child {
			border-bottom: none;
		}
	}
`;

export const FlexColumn = styled.div<WidthProps>`
	display: flex;
	flex-direction: column;
	padding-bottom: 1rem;

	${mediaQueries.desktop} {
		${({ width }) => width && `width: ${width}%;`}
	}

	fieldset {
		legend {
			font-family: ${typography.fontFamily.alt};
			font-size: 1rem;
			font-weight: ${typography.fontWeight.bold};
			margin-bottom: 1.5rem;
		}
	}
`;

export const FlexRow = styled.div<WidthProps>`
	display: flex;
	flex-direction: row;

	${mediaQueries.desktop} {
		${({ width }) => width && `width: ${width}%;`}
	}
`;

export const FieldsetRow = styled.div<WidthProps>`
	display: flex;
	flex-direction: row;

	${mediaQueries.desktop} {
		${({ width }) => width && `width: ${width}%;`}
	}

	fieldset {
		legend {
			font-family: ${typography.fontFamily.alt};
			font-size: 1rem;
			margin-bottom: 1.5rem;
		}
	}
`;

export const CheckboxRow = styled(FieldsetRow)<WidthProps>`
	display: flex;
	flex-direction: column;
	> div {
		padding-right: 1rem;
	}

	${mediaQueries.desktop} {
		flex-direction: row;
	}
`;

export const RadioButtonRow = styled(FieldsetRow)<WidthProps>`
	fieldset {
		> div {
			display: flex;
			flex-direction: column;

			label {
				margin-bottom: 1rem;
			}
		}
	}
`;

export const InputLabel = styled.label`
	color: ${colors.greyDark};
	display: flex;
	font-size: ${px2rem(14)};
	font-family: ${typography.fontFamily.alt};

	${mediaQueries.desktop} {
		margin-bottom: 0.5rem;
	}
	${mediaQueries.phoneOrTablet} {
		margin-bottom: 1rem;
	}
`;

export const DatepickerRow = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	${mediaQueries.mediumDesktop} {
		flex-direction: row;
		width: 100%;
	}
`;

export const DatepickerColumn = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	${mediaQueries.desktop} {
		flex-direction: row;
		width: 100%;
	}

	${mediaQueries.mediumDesktop} {
		flex-direction: row;
		width: 100%;
	}
`;

export const CaseSelectList = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 1rem;
	width: 100%;

	select {
		width: 100%;
	}
`;

export const CaseSelectListLabel = styled.label`
	font-family: ${typography.fontFamily.alt};
	font-size: 1rem;
	font-weight: ${typography.fontWeight.bold};
	width: 100%;
`;

export const Separator = styled.div`
	display: flex;
	font-size: ${px2rem(25)};
	margin-top: 2.5rem;
	padding: 0 0.5rem;
`;

export const ButtonRow = styled.div`
	display: flex;
	flex-direction: column;

	button {
		border: none;
		border-radius: 3.125rem;
		height: 3.125rem;
		margin-top: 1rem;
		padding: 1.375rem 2.5rem;

		&:first-child {
			background-color: ${colors.offBlueLightest};
			color: ${colors.blueDark};
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 0.9375rem;
			transition: all 0.2s;
			font-weight: ${typography.fontWeight.medium};
		}
	}

	${mediaQueries.tabletOrLarger} {
		flex-direction: row;
		margin-left: auto;
		margin-top: 1rem;
		button:last-child {
			margin-left: 1rem;
		}
	}
`;

export const ResultsContainer = styled.div`
	${mediaQueries.phone} {
		padding-top: 1rem;
		padding-right: 0;
	}
`;

export const ResultsHeader = styled.div`
	display: flex;
	margin-bottom: ${px2rem(16)};
`;

export const SearchResultTitle = styled.h2`
	color: ${colors.black};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(24)};
	line-height: 1.2;
	margin-top: 0;
	padding-top: 0;

	${mediaQueries.phoneOrTablet} {
		margin-bottom: ${px2rem(12)};
	}
`;

export const SearchResultsHits = styled.h3`
	color: ${colors.black};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(18)};
	line-height: 1.2;
	margin-top: 0;
	padding-top: 0;

	${mediaQueries.phoneOrTablet} {
		margin-bottom: ${px2rem(12)};
	}
`;

export const SortWrapper = styled.div`
	display: flex;
	align-items: baseline;
`;

export const CaseGrid = styled(Grid)`
	font-family: ${typography.fontFamily.alt};
`;

export const CaseCell = styled(Cell)``;

export const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const PaginationContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: ${px2rem(32)};
`;
