/**
 * SearchResultsItem
 */

import React from 'react';
import { AccordionItem } from 'components/Accordion';

interface Props {
	/** Results heading */
	heading: string;

	/** KickerHeading (above heading) */
	kickerHeading?: string;

	/** Results child items */
	children?: any;

	/** Toggle translations */
	translations?: {
		readMoreLabel: string;
		readLessLabel: string;
	};

	/** Item id */
	id?: string;

	/** EpiFind hitId */
	hitId?: string;
}

/** An item shown within search results. */
const SearchResultsItem: React.FC<Props> = ({
	heading,
	translations,
	kickerHeading,
	children,
	id,
	hitId,
}) => (
	<div tabIndex={-1} id={hitId || id}>
		<AccordionItem
			variant="list"
			title={heading}
			kickerHeading={kickerHeading}
			translations={translations}
		>
			{children}
		</AccordionItem>
	</div>
);

export default SearchResultsItem;
