/**
 * Form - styles
 */

import styled from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { H2, P } from 'components/Text';
import { StandardButton } from 'components/Button';

export const SearchHeader = styled.header`
	background-color: ${colors.offBlueLightest};
	height: ${px2rem(224)};
	position: relative;

	${mediaQueries.phone} {
		height: auto;
		padding: ${px2rem(64)} 2rem;
	}
`;

export const InnerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
`;

export const SearchHeading = styled(H2)`
	color: ${colors.greyDark};
	margin-top: 0;
	margin-bottom: 1.5rem;
`;

export const SearchPhrase = styled(P)`
	color: ${colors.greyDark};
	margin-bottom: 1.5rem;
	line-height: ${px2rem(20)};

	${mediaQueries.phone} {
		padding: 0;
		margin-top: 1.5rem;
		margin-bottom: 0;

		font-size: ${px2rem(16)};
		letter-spacing: 0;
		line-height: ${px2rem(20)};
		text-align: center;
	}
`;

export const BoldText = styled.span`
	font-weight: ${typography.fontWeight.bold};
`;

export const LoaderWrapper = styled.div`
	position: absolute;
	bottom: 0.5rem;
`;

export const Filters = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: ${px2rem(50)} auto 0;

	${mediaQueries.phone} {
		margin-top: 2.5rem;
		min-width: 100%;
	}
`;

export const FilterHeading = styled(H2)`
	${mediaQueries.phone} {
		margin-bottom: ${px2rem(25)};
	}
`;

export const FilterWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: ${px2rem(1440)};
	margin: ${px2rem(50)} auto 0;

	> div {
		margin-right: 0.5rem;
		margin-top: 0.5rem;
	}

	@media (max-width: 1024px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	${mediaQueries.phone} {
		flex-direction: column;
		margin: 0;
		padding-left: 0;
		padding-right: 0;
		min-width: 100%;
		justify-content: center;

		> div {
			margin: 0.5rem 0.8rem;
		}
	}
`;

export const SortWrapper = styled.div`
	display: flex;
	align-items: baseline;
`;

export const HiddenSubmitButton = styled(StandardButton)`
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;

	&:focus {
		position: relative;
		clip-path: unset;
		overflow: initial;
		height: auto;
		width: auto;
		margin-top: 1rem;
	}
`;

export const SearchInfoRow = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: ${px2rem(1200)};
	margin: ${px2rem(50)} auto 0;

	@media (max-width: 1024px) {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	${mediaQueries.phone} {
	}
`;

export const Count = styled.span`
	margin-left: auto;
`;
