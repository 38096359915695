/**
 * DesktopMenu Li
 */

import React, { useEffect, useState } from 'react';
import { LinkType, MenuLinkVariant } from 'types/global';
import {
	StyledLink,
	LiItemTopArea,
	WIWLevel1,
	LiLevel1,
	LiLevel2,
	LiLevel3,
	StyledIcon,
	LinkText,
	IconButton,
} from './DesktopMenu.styles';
import { ScreenReaderText } from 'components/Text';
import Ul from './DesktopMenu.Ul';

interface LiProps {
	/** A menu link structured as JSON */
	link: LinkType;

	/** The link level (1, 2, 3) */
	level: number;

	/** Variant of the link */
	variant?: MenuLinkVariant;

	/** Determine if childelement or not for indent */
	isChild?: boolean;

	/** Screenreadertext for mobile (toggle open) */
	screenReaderText: string;

	/** Callback method when the menu closes */
	onClose?: any;
}

/** Returns the correct styled li for each level */
const getLiLevel = (level: number, isCampaignPage: boolean) => {
	switch (level) {
		case 1:
			return isCampaignPage ? WIWLevel1 : LiLevel1;
		case 2:
			return LiLevel2;
		case 3:
		default:
			return LiLevel3;
	}
};

/** A nav item (li) used by DesktopMenu */
const Li: React.FC<LiProps> = ({
	link,
	level,
	variant,
	isChild,
	onClose,
	screenReaderText,
}) => {
	const linkLevel = variant === 'headerLink' ? 2 : level;
	const LiItem = getLiLevel(linkLevel, link.isCampaignPage);
	const [activeChildren, setActiveChildren] = useState(false);
	const [child, setChild] = useState<any>(null);
	const [expanded, setExpanded] = useState(false);

	const setActiveChildrens = (link: LinkType) => {
		const childLinks = link.children || [];
		let links: LinkType[] = [];

		if (level >= 2) {
			links = [...childLinks];
		} else {
			links = childLinks;
		}

		setChild(
			<Ul
				screenReaderText={screenReaderText}
				isChild
				level={level + 1}
				links={links}
				onClose={onClose}
			/>
		);
	};

	const handleSetActiveChildren = (e: MouseEvent, link: LinkType) => {
		e.preventDefault();

		if (!activeChildren) {
			setActiveChildrens(link);
		} else {
			setChild(null);
		}

		setActiveChildren(!activeChildren);
		toggleExpand();
	};

	const toggleExpand = () => {
		setExpanded(!expanded);
	};

	useEffect(() => {
		if (link && link.active) {
			setActiveChildrens(link);
		}
	}, []);

	return (
		<>
			<LiItem hasChildren={!!link.children}>
				<LiItemTopArea
					active={!!child}
					selected={link.selected}
					childLevel={linkLevel}
					isChild={isChild}
				>
					<StyledLink href={link.link} variant={variant} onClick={onClose}>
						<LinkText>{link.text}</LinkText>
					</StyledLink>

					{link.children && link.children.length > 0 && (
						<IconButton
							onClick={(e: any) => handleSetActiveChildren(e, link)}
							aria-expanded={expanded}
						>
							<ScreenReaderText>{`${screenReaderText} ${link.text}`}</ScreenReaderText>
							<StyledIcon
								size={0}
								icon={child ? 'minus' : 'plus'}
								aria-hidden={true}
							/>
						</IconButton>
					)}
				</LiItemTopArea>

				{child && child}
			</LiItem>
		</>
	);
};

export default Li;
