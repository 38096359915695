import React from 'react';

import { checkDependency } from 'components/EpiForms/EpiForms.dependencies';
import { checkFieldActions } from 'components/EpiForms/EpiForms.actions';
import {
	Field,
	TextArea,
	Select,
	Multiselect,
	RadioButton,
	Checkbox,
	Fieldset,
	Button,
	Label,
	ErrorSpan,
	CheckboxLabel,
} from 'components/Form';

import {
	FieldContainer,
	ErrorLabel,
	ParagraphText,
} from 'components/Form/Form.styles';
import { RawFragmentModel } from 'types/fragments';
import { RichText } from 'components/Text/Text.styles';

interface Field {
	id: string;
	label: string;
	required?: boolean;
	[htmlAttributes: string]: any;
}

interface UseFormRenderer {
	(fields: any, state: any, dependencies: any): any[];
}

interface RenderFunction {
	(field: Field): JSX.Element | null;
}

export const useFormRenderer: UseFormRenderer = (
	fields: any,
	state: any,
	dependencies: any
) => {
	const { invalidFields, validationMessages, isLoading, values } = state;
	let fieldActionExists = false;

	const renderFormElement = (fieldName: string) => {
		const field = fields[fieldName];
		const { name, text } = fields[fieldName];
		let { type } = fields[fieldName];

		if (type === null) {
			type = 'text';
		}

		const renderAsFieldset =
			(type === 'radio' || type === 'checkbox') && field.options.length > 1;
		const singleCheckboxOrRadio =
			(type === 'radio' || type === 'checkbox') && field.options.length === 1;

		const fieldHasDependency =
			dependencies &&
			dependencies.filter((field: any) => field.fieldName === name).length > 0;

		if (fieldHasDependency) {
			const fieldShouldBeRendered = checkDependency(name, dependencies, values);

			if (!fieldShouldBeRendered) {
				return;
			}
		}

		if (renderAsFieldset) {
			return <div key={name}>{renderFieldset(field)}</div>;
		} else if (singleCheckboxOrRadio) {
			return (
				<FieldContainer key={name}>
					{renderSingleInput(field)}
					{/* {renderFieldError(field.name)} */}
				</FieldContainer>
			);
		} else if (type.toLowerCase() === 'paragraphtext') {
			return (
				<RichText key={name}>
					{field.paragraphText.fragments &&
						field.paragraphText.fragments.map((content: any, i: string) => (
							<ParagraphText
								key={i}
								dangerouslySetInnerHTML={{
									__html: (content as RawFragmentModel).raw,
								}}
							/>
						))}
				</RichText>
			);
		} else {
			return (
				<FieldContainer
					key={name}
					id={`form${name}`}
					{...(field.type === 'submit' && { align: 'right' })}
				>
					{field.type !== 'multiselect' && renderLabel(field)}
					{renderField(field)}
					{/* {renderFieldError(field.name)} */}
				</FieldContainer>
			);
		}
	};

	const renderLabel: RenderFunction = ({ id, label, required, type }) => {
		if (!label || type === 'submit' || type === 'reset') {
			return null;
		}

		return (
			<Label fieldId={id} htmlFor={id} required={required}>
				{label}
			</Label>
		);
	};

	const renderFieldError = (id: string) => {
		const validationMessage =
			validationMessages[id] && validationMessages[id].message;
		const isInvalid = invalidFields.indexOf(id) !== -1;

		return (
			<ErrorSpan fieldId={id} invalid={isInvalid}>
				{validationMessage}
			</ErrorSpan>
		);
	};

	const renderField: RenderFunction = ({
		name,
		type,
		required,
		pattern,
		placeholder,
		disabled,
		description,
		min,
		max,
		label,
		failedMessage,
		patternMessage,
		options,
		fieldActions,
	}) => {
		let buttonText = label || type;

		switch (type) {
			case 'submit':
			case 'reset':
				if (type === 'submit' && isLoading) {
					buttonText = 'Skickar...';
				}

				return (
					<Button type={type} name={name} disabled={isLoading} id={name}>
						{buttonText}
					</Button>
				);
			case 'textarea':
				return (
					<TextArea
						id={name}
						name={name}
						description={description}
						required={required}
						placeholder={placeholder}
						disabled={disabled}
						label={label}
						validationMessage={failedMessage}
					/>
				);
			case 'select':
				return (
					<Select
						id={name}
						name={name}
						label={label}
						description={description}
						required={required}
						options={options}
						validationMessage={failedMessage}
					/>
				);
			case 'multiselect':
				return (
					<Multiselect
						id={name}
						label={label}
						description={description}
						required={required}
						validationMessage={failedMessage}
						isInvalid={invalidFields.indexOf(name) !== -1}
					>
						{options.map((opt: any, i: number) => (
							<CheckboxLabel htmlFor={`${name}-${i + 1}`} key={i}>
								<Checkbox
									id={name}
									name={name}
									value={opt.value}
									label={opt.text}
									index={i + 1}
								/>
								<span>{opt.text}</span>
							</CheckboxLabel>
						))}
					</Multiselect>
				);
			case 'text':
			default:
				let actions: any = {};

				if (fieldActions) {
					actions = checkFieldActions(fieldActions, values);
					fieldActionExists = true;
				}

				return (
					<Field
						type={type}
						id={name}
						name={name}
						pattern={pattern}
						required={(actions && actions.required) || required}
						placeholder={placeholder}
						description={description}
						label={label}
						min={min}
						max={max}
						disabled={disabled}
						validationMessage={failedMessage}
						patternMessage={patternMessage}
					/>
				);
		}
	};

	const renderFieldset: RenderFunction = ({
		name,
		type,
		options,
		label,
		required,
		description,
		validationMessage,
	}) => {
		const isInvalid = invalidFields.indexOf(name) !== -1;
		const FieldComponent = type === 'radio' ? RadioButton : Checkbox;

		return (
			<Fieldset
				id={name}
				description={description}
				required={required}
				label={label}
				isInvalid={isInvalid}
				validationMessage={validationMessage}
			>
				{options.map((opt: any, index: number) => {
					return (
						<CheckboxLabel
							htmlFor={`${name}-${index}`}
							key={`${name}-${index}`}
						>
							<FieldComponent
								id={name}
								index={index}
								name={name}
								value={opt.value}
								label={opt.text}
							/>
							<span>{opt.text}</span>
						</CheckboxLabel>
					);
				})}
			</Fieldset>
		);
	};

	const renderSingleInput: RenderFunction = ({
		name,
		type,
		options,
		required,
		disabled,
		description,
		validationMessage,
	}) => {
		const fieldLabel = options[0].text;
		const fieldValue = options[0].value;

		const FieldComponent = type === 'radio' ? RadioButton : Checkbox;
		const isInvalid = invalidFields.indexOf(name) !== -1;

		return (
			<CheckboxLabel htmlFor={name} title={description}>
				<FieldComponent
					id={name}
					name={name}
					value={fieldValue}
					required={required}
					disabled={disabled}
					single={true}
					label={fieldLabel}
					validationMessage={validationMessage}
				/>

				{!isInvalid && (
					<div>
						{fieldLabel}
						{required && <abbr title="Obligatoriskt">*</abbr>}
					</div>
				)}
				{isInvalid && (
					<ErrorLabel>
						<span>{fieldLabel}</span>
						<abbr title="Obligatoriskt">*</abbr>
					</ErrorLabel>
				)}
			</CheckboxLabel>
		);
	};

	return [renderFormElement, fieldActionExists];
};
