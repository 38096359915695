import { ContentTypes } from 'types/pages';

declare global {
	interface Window {
		__model: any;
		epi: any;
	}
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace NodeJS {
		interface Global {
			window: any;
			document: any;
			Styled: any;
			React: any;
			ReactDOM: any;
			ReactDOMServer: any;
		}
	}
}

export interface EpiEvent {
	beta?: {
		isEditable: boolean;
		inEditMode: boolean;
		inPreviewMode: boolean;
		ready: boolean;
	};
}

export interface Model {
	framework?: {
		head?: ModelHead;
		meta?: ModelMetadata;
		header?: ModelHeader;
		footer?: ModelFooter;
		translations: ModelTranslations;
		api: ModelApi;
	};
	content?: ContentTypes;
}

interface ModelHeader {
	search?: {
		searchLink: string;
		searchPlaceholder: string;
	};
	logoLink?: {
		link: string;
		alt: string;
	};
	buttonLink?: {
		link: string;
		text: string;
	};
	languages?: {
		link: string;
		text: string;
	};
	menu?: {};
}

interface ModelFooter {
	columns: [
		{
			heading: string;
			text: string;
		}
	];
	socialLinks: [
		{
			link: string;
			text: string;
			modelType:
				| 'YoutubeLink'
				| 'LinkedInLink'
				| 'TwitterLink'
				| 'EmailLink'
				| 'PodcastLink';
		}
	];
	newsletter: {};
	copyright: string;
	organizationNumber: string;
	links?: [
		{
			link: string;
			text: string;
		}
	];
}

export interface ModelTranslations {
	menuText: string;
	menuCloseText: string;
	searchOpenText: string;
	searchCloseText: string;
	closeSearchBoxText: string;
	searchFieldText: string;
	searchButtonText: string;
	followUsText: string;
	subscribeText: string;
	emailText: string;
	emailPlaceholder: string;
	listenText: string;
	navShowAllText: string;
	suggestionButtonText: string;
	backLinkText: string;
	menuLabel: string;
	screenReaderShowAllText: string;
	registerSuccessMessage: string;
}

interface ModelMetadata {
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	twitter?: {
		card?: string | null;
		title?: string | null;
		description?: string | null;
		image?: string | null;
	};
	openGraph?: {
		title?: string | null;
		siteName?: string | null;
		type?: string | null;
		url?: string | null;
		description?: string | null;
		image?: string | null;
	};
}

interface ModelHead {
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	openGraphTitle?: string | null;
	openGraphImageUrl?: string | null;
	openGraphDescription?: string | null;
	twitterTitle?: string | null;
	twitterDescription?: string | null;
	twitterImageUrl?: string | null;
}

export interface ModelApi {
	autocompleteSearchUrl: string;
	clickTrackUrl?: string;
}

export interface ModelReact {
	containerId: string;
	clientOnly: boolean;
	siteBaseUrl: string;
	apiUrl: string;
	apiEditUrl: string;
	renderingState: RenderingStates;
}

export enum RenderingStates {
	ServerSide = 1,
	Hydrate,
	ClientSide,
}
