/**
 * LatestNewsBlock
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { LinkType } from 'types/global';

import {
	Container,
	ColumnCardContainer,
	Heading,
	ButtonContainer,
} from './LatestNewsBlock.styles';
import { Grid } from 'components/Grid';
import Carousel from 'components/Carousel';
import Button from 'components/Button';
import BlockContainer from 'layouts/BlockLayout';

interface Props {
	/** MobileCarousel */
	mobileCarousel?: boolean;

	/** Block heading */
	heading?: string;

	/** Background color */
	backgroundColor?:
		| 'lightBlue'
		| 'blue'
		| 'yellowLighter'
		| 'greyLighter'
		| 'white'
		| 'transparent';

	/** Heading align */
	headingAlignment?: 'left' | 'center';

	/** Button link */
	button?: LinkType;

	/** Child elements */
	children?: any[];

	/** Should news images be shown */
	hideImages?: boolean;
}

/** Latest news bblock. */
const LatestNewsBlock: React.FC<Props> = ({
	heading,
	backgroundColor = 'lightBlue',
	headingAlignment = 'left',
	mobileCarousel = false,
	button,
	children,
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const hasChildren = children && children.length > 0;
	if (!hasChildren) return null;

	const ChildComponent: React.FC<any> = ({ children }) => {
		if (isPhone && mobileCarousel) {
			return (
				<Carousel hidePaginationDesktop displayPagination>
					{children}
				</Carousel>
			);
		}

		return <Grid inner={true}>{children}</Grid>;
	};

	return (
		<BlockContainer backgroundColor={backgroundColor}>
			<Container backgroundColor={backgroundColor}>
				<ColumnCardContainer>
					{heading && (
						<Heading
							backgroundColor={backgroundColor}
							headingAlignment={headingAlignment}
						>
							{heading}
						</Heading>
					)}

					<ChildComponent>{children}</ChildComponent>

					{button?.link && (
						<ButtonContainer backgroundColor={backgroundColor}>
							<Button type="link" variant="outlined" href={button.link}>
								{button.text}
							</Button>
						</ButtonContainer>
					)}
				</ColumnCardContainer>
			</Container>
		</BlockContainer>
	);
};

export default LatestNewsBlock;
