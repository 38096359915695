/**
 * StartBannerBlock
 */

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointsNumber } from 'theme/media-queries';
import { StandardImage, LinkType } from 'types/global';

import {
	Wrapper,
	LeftBlock,
	LeftBlockInner,
	RightBlock,
	LinksContainer,
	ImageContainer,
	Heading,
	Preamble,
	ButtonWrapper,
} from './StartBannerBlock.styles';

import Button from 'components/Button';
import Image from 'components/Image';
import BlockContainer from 'layouts/BlockLayout';

interface Props {
	/** Block heading */
	heading: string;

	/** Block preamble */
	text?: string;

	/** Block buttons (1-2) */
	buttons?: LinkType[];

	/** Image in desktop */
	image: StandardImage;

	/** Image in mobile */
	imageMobile: StandardImage;

	/** Background color of left side of block */
	backgroundColor: 'blue' | 'white';

	/** Link list items */
	children?: any;

	/** Epi on-page editing properties */
	_properties?: any;
}

/** Start banner block with popular links and image (only at the top of a page) */
const StartBannerBlock: React.FC<Props> = ({
	heading,
	text,
	buttons,
	image,
	imageMobile,
	backgroundColor = 'blue',
	children,
	_properties = {},
}) => {
	const isPhone = useMediaQuery({
		maxWidth: breakpointsNumber.tablet - 1,
	});

	const imageToShow = isPhone ? imageMobile : image;

	return (
		<Wrapper bgColor={backgroundColor} {..._properties?.block}>
			<LeftBlock>
				<LeftBlockInner>
					<Heading bgColor={backgroundColor} {..._properties?.heading}>
						{heading}
					</Heading>

					{text && (
						<Preamble bgColor={backgroundColor} {..._properties?.text}>
							{text}
						</Preamble>
					)}

					{buttons && buttons.length > 0 && (
						<ButtonWrapper $hasMoreThanTwoChilds={buttons.length > 2}>
							{buttons.map((btn: any, i: number) => (
								<Button
									href={btn.link}
									variant={backgroundColor === 'blue' ? 'secondary' : 'primary'}
									type="link"
									key={i}
									displayWidth="fixed"
								>
									{btn.text}
								</Button>
							))}
						</ButtonWrapper>
					)}
				</LeftBlockInner>
			</LeftBlock>

			<RightBlock>
				{imageToShow && (
					<ImageContainer image={image} imageMobile={imageMobile}>
						<Image
							src={imageToShow.src}
							alt={imageToShow.alt}
							srcSet={
								isPhone
									? `
						${imageToShow.sizes[0]} 800w,
						${imageToShow.sizes[1]} 400w
					`
									: `
						${imageToShow.sizes[0]} 1200w,
						${imageToShow.sizes[1]} 800w,
						${imageToShow.sizes[2]} 400w
					`
							}
							webpSrcSet={
								isPhone
									? `
						${imageToShow.webpSizes[0]} 800w,
						${imageToShow.webpSizes[1]} 400w
					`
									: `
						${imageToShow.webpSizes[0]} 1200w,
						${imageToShow.webpSizes[1]} 800w,
						${imageToShow.webpSizes[2]} 400w
					`
							}
							sizes="(min-width: 1040px) 60vw, (min-width: 768px) 40vw, (max-width: 767px) 100vw, 800px"
						/>
					</ImageContainer>
				)}
				<LinksContainer>{children}</LinksContainer>
			</RightBlock>
		</Wrapper>
	);
};

export default StartBannerBlock;
