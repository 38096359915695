/**
 * ColumnBlock - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { H2 } from 'components/Text/Text.styles';

export interface ThemeProps {
	backgroundColor?:
		| 'lightBlue'
		| 'blue'
		| 'white'
		| 'yellowLighter'
		| 'greyLighter'
		| 'transparent';
	headingAlignment?: 'left' | 'center';
	isPhone?: boolean;
}

export const Container = styled.div<ThemeProps>`
	display: flex;
	flex-direction: column;
	padding: 3rem 0;
	font-family: ${typography.fontFamily.alt};

	${({ backgroundColor }) => {
		if (backgroundColor === 'lightBlue') {
			return `
                color: ${colors.white};
            `;
		} else if (backgroundColor === 'blue') {
			return `
                color: ${colors.white};
            `;
		} else {
			return `
                color: ${colors.black};
            `;
		}
	}}

	${mediaQueries.phone} {
		padding: 3rem 0;
	}
`;

export const ColumnCardContainer = styled.div`
	${mediaQueries.tabletOrLarger} {
		margin: 0 auto;
	}
`;

export const Heading = styled(H2)<ThemeProps>`
	${({ backgroundColor }) => {
		if (backgroundColor === 'lightBlue') {
			return `
				color: ${colors.greyDark};
            `;
		} else if (backgroundColor === 'blue') {
			return `
                color: ${colors.white};
            `;
		} else {
			return `
				color: ${colors.greyDark};
            `;
		}
	}}

	text-align: ${({ headingAlignment }) =>
		headingAlignment === 'left' ? 'left' : 'center'};
	margin: 0 0 ${px2rem(32)} 0;

	${mediaQueries.phone} {
		margin-bottom: ${px2rem(25)};
	}
`;

export const LinkItemBlockContainer = styled.div`
	background-color: ${colors.greyLightest};
`;

export const ButtonContainer = styled.div<ThemeProps>`
	max-width: ${px2rem(262)};
	margin: ${px2rem(52)} auto ${px2rem(0)};
	text-align: center;

	a {
		background-color: transparent;
	}

	${mediaQueries.phone} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		margin-top: ${px2rem(32)};
	}
`;
