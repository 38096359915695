/**
 * RouteHandler
 */

import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ScreenReaderText } from 'components/Text/Text.styles';
import { PagesMapper } from 'pages';
import ErrorPage from 'pages/ErrorPage';
import { LoaderStatic } from 'components/Loader';
import { selectModel, selectContent, fetchPage } from 'store/modules/model';
import { updateRenderingState, selectReact } from 'store/modules/react';
import { RenderingStates } from 'types/epi';
import BaseLayout from 'layouts/BaseLayout';
import { getScrollPosition, setScrollPosition } from 'routeScrollManager';

/** Loads data and renders the correct page based on the route. */
const RouteHandler: React.FC = () => {
	let { siteRoute } = useParams();
	let location = useLocation();

	siteRoute = siteRoute || '/';
	const ariaLiveRef = useRef<HTMLDivElement>(null);
	const emptyFocusDivRef = useRef<HTMLDivElement>(null);
	const pageRef = useRef<any>(null);
	const { action, listen, block } = useHistory();
	const dispatch = useDispatch();
	const { error, loading } = useSelector(selectModel);
	const pageContent = useSelector(selectContent);
	const { renderingState, apiUrl } = useSelector(selectReact);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const Page = PagesMapper(pageContent);

	// Fix siteRoute so we don't request '//'.
	if (
		apiUrl &&
		siteRoute &&
		apiUrl.charAt(apiUrl.length - 1) === '/' &&
		siteRoute.charAt(0) === '/'
	) {
		siteRoute = siteRoute.substr(1);
	}

	// Load the page
	useEffect(() => {
		// First hydrate rendering we wont be doing any request, becase we already have the data from the SSR.
		if (renderingState !== RenderingStates.ClientSide && pageContent) {
			return;
		}

		dispatch(fetchPage(apiUrl, `${siteRoute}${location.search}`));
		// eslint-disable-next-line
	}, [apiUrl, siteRoute, dispatch]);

	// Set renderingState to clientside after the first (hydration) render.
	useEffect(() => {
		dispatch(updateRenderingState(RenderingStates.ClientSide));
		// eslint-disable-next-line
	}, []);

	// If we have been waiting for the response more than 400ms we display the loader
	useEffect(() => {
		let loaderTimeout: any;
		// eslint-disable-next-line
		//console.log("history.scrollRestoration", history.scrollRestoration);
		//console.log("Effect loading", loading);
		//console.log("Effect loader", action);
		if (loading) {
			loaderTimeout = setTimeout(() => {
				// Tell sighted users
				setLoaderVisible(true);
				if (ariaLiveRef.current) {
					ariaLiveRef.current.innerHTML = 'Sidan laddar';
				}
				setTimeout(() => {
					if (ariaLiveRef.current) {
						ariaLiveRef.current.innerHTML = '';
					}
				}, 500);
			}, 400);
		} else {
			setLoaderVisible(false);
			const previousPosition = getScrollPosition(location.pathname);
			if (previousPosition && action === 'POP') {
				window.scrollTo({ left: 0, top: previousPosition, behavior: 'auto' });
			} else if (action === 'PUSH') {
				window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
			}
		}

		return () => {
			if (loaderTimeout) {
				clearTimeout(loaderTimeout);
			}
		};
		// eslint-disable-next-line
	}, [loading]);

	// Page is loaded
	useEffect(() => {
		//console.log("Effect pageContent", pageContent)
		//console.log("Effect poage is loaded", action);
		if (renderingState !== RenderingStates.ClientSide && pageContent) {
			return;
		}

		if (pageContent) {
			if (ariaLiveRef.current) {
				ariaLiveRef.current.innerHTML = 'Sidan har laddats';
			}
			setTimeout(() => {
				if (ariaLiveRef.current) {
					ariaLiveRef.current.innerHTML = '';
				}
			}, 500);

			/*
						if (emptyFocusDivRef.current) {
							console.log("emptyFocusDivRef")
							let x = window.scrollX;
							let y = window.scrollY;
							emptyFocusDivRef.current.focus();
							window.scrollTo(x, y);
						}*/
			/*setTimeout(() => {
				if (pageRef.current) {
					const firstH1 = pageRef.current.querySelector('h1');
					if (firstH1) {
						console.log("First h1")
						firstH1.setAttribute('tabindex', -1);
						firstH1.style.outline = 'none';
						firstH1.focus();
					} else {
						console.log("Page ref");
						pageRef.current.focus();
					}
				}
			}, 600);*/
		}
	}, [pageContent]);

	useEffect(() => {
		if (action === 'PUSH') {
			return;
		}
		const unblock = block((location) => {
			setScrollPosition();
		});

		return () => {
			unblock();
		};
	}, [location]);

	return (
		<>
			{loading && loaderVisible && <LoaderStatic />}
			{error && error.indexOf('404') !== -1 && <ErrorPage error={error} />}
			{pageContent && !error && (
				<div ref={pageRef} tabIndex={-1} style={{ outline: 'none' }}>
					<BaseLayout>
						<Page {...pageContent} />
					</BaseLayout>
				</div>
			)}
			<ScreenReaderText
				aria-live="assertive"
				ref={ariaLiveRef}
			></ScreenReaderText>
			<div
				tabIndex={-1}
				ref={emptyFocusDivRef}
				style={{ outline: 'none' }}
			></div>
		</>
	);
};
export { RouteHandler };
