/**
 * PageFooter - styles
 */

import styled from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, typography, mediaQueries } from 'theme';
import { lighten, darken } from 'polished';
import { H5 } from 'components/Text';
import Link from 'components/Link';

export const Footer = styled.footer`
	background-color: ${colors.blueDarker};
`;

export const FooterContainer = styled.div`
	max-width: ${px2rem(1920)};
	margin: 0 auto;
	padding: 5.5rem 7.5rem 2.5rem;

	${mediaQueries.phoneOrTablet} {
		padding: 3rem 1rem;
	}

	@media screen and (min-width: 840px) and (max-width: 1280px) {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}
`;

export const TopContainer = styled.div`
	display: flex;
`;

export const TopLinksContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-wrap: wrap;
	${mediaQueries.phoneOrTablet} {
		justify-content: space-between;
	}
	${mediaQueries.desktop} {
		justify-content: space-around;
	}
`;

export const TopLinksColumn = styled.div`
	line-height: 2;
	padding-bottom: 1rem;
`;

export const TopLinksContent = styled.div`
	margin: 0;
	padding: 0;
	color: ${colors.white};
	font-family: ${typography.fontFamily.alt};
	line-height: ${px2rem(26)};

	a {
		font-size: ${px2rem(16)};
		color: ${colors.offBlueLessLight};

		&:focus {
			box-shadow: 0 0 0 ${px2rem(3)} ${colors.offBlueLessLight};
			outline: none;
		}
	}
`;

export const TopLinksHeading = styled(H5)`
	color: ${colors.white};
	font-size: ${px2rem(18)};
	font-weight: ${typography.fontWeight.medium};
`;

export const TopLogo = styled.img`
	margin-top: 2rem;

	${mediaQueries.phoneOrTablet} {
		display: none;
	}
`;

export const MiddleContainer = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	border-top: ${px2rem(1)} solid ${colors.offBlue};
	border-bottom: ${px2rem(1)} solid ${colors.offBlue};
	padding-top: 2rem;
	padding-bottom: 3.5rem;
	margin-bottom: 2rem;

	${mediaQueries.phoneOrTablet} {
		flex-direction: column-reverse;
		align-items: flex-start;
	}
`;

export const FollowHeading = styled.h2`
	color: ${colors.white};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(18)};
	font-weight: ${typography.fontWeight.medium};
	margin: 0;
	padding: 0 1rem 0 0;

	${mediaQueries.phone} {
		border: 0;
		clip: rect(0 0 0 0);
		height: 0.1rem;
		margin: -0.1rem;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 0.1rem;
	}
`;

export const FollowLinksContainer = styled.div`
	align-items: center;
	display: flex;

	${mediaQueries.phoneOrTablet} {
		justify-content: center;
		margin-top: 2rem;
	}

	${mediaQueries.phone} {
		align-self: center;
	}

	@media (max-width: 320px) {
		flex-wrap: wrap;
		margin: 2rem auto 0rem auto;
	}
`;

export const IconBadge = styled.a`
	margin-right: 1rem;
	align-items: center;
	background-color: ${colors.offBlueLessLight};
	border-radius: 1.5rem;
	color: ${colors.blueDarker};
	display: flex;
	height: 3rem;
	width: 3rem;
	justify-content: center;
	transition: all 0.2s;
	min-width: 3rem;

	:last-child {
		margin-right: 0;
	}

	&:hover {
		background-color: ${lighten(0.1, colors.offBlueLessLight)};
	}

	&:focus {
		border: ${px2rem(2)} solid ${colors.white};
	}

	svg {
		margin-left: 0;
		height: ${px2rem(20)};
		width: ${px2rem(20)};
	}

	${mediaQueries.phoneOrTablet} {
		margin-right: 0.55rem;
	}
`;

export const SubscribeContainer = styled.div`
	display: flex;
	${mediaQueries.phoneOrTablet} {
		flex-wrap: wrap;
		width: 100%;
	}
`;

export const SubscribeForm = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const SubscribeHeading = styled.p`
	align-self: center;
	color: ${colors.white};
	font-family: ${typography.fontFamily.alt};
	padding-right: 1rem;
	width: 100%;

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(18)};
	}
	${mediaQueries.desktop} {
		font-size: ${px2rem(14)};
		margin-top: 2.5rem;
		padding-left: 1rem;
	}
`;

export const SubscribeInput = styled.input`
	border: 0;
	border-radius: 1.75rem;
	color: ${colors.black};
	font-size: ${px2rem(16)};
	height: 3.5rem;
	padding: 1rem 4rem 1rem 1.5rem;
	width: ${px2rem(284)};
	font-family: ${typography.fontFamily.alt};

	::placeholder {
		color: ${colors.greyDark};
	}

	&:focus {
		box-shadow: 0 0 0 ${px2rem(3)} ${colors.focusColor};
		outline: none;
	}

	${mediaQueries.tablet} {
		width: 80%;
	}
	${mediaQueries.phone} {
		width: 100%;
	}
`;

export const SubscribeInputContainer = styled.div`
	display: flex;
`;

export const SubscribeLabel = styled.label`
	color: ${colors.white};
	font-size: ${px2rem(14)};
	font-family: ${typography.fontFamily.alt};

	${mediaQueries.desktop} {
		margin-bottom: 0.5rem;
	}
	${mediaQueries.phoneOrTablet} {
		margin-bottom: 1rem;
	}
`;

export const SubscribeSubmit = styled.button.attrs({ type: 'submit' })`
	background-color: ${colors.blue};
	border: 0;
	border-radius: 1.5rem;
	color: ${colors.white};
	cursor: pointer;
	height: 3rem;
	margin-left: -3.25rem;
	margin-top: 0.25rem;
	width: 3rem;
	transition: all 0.2s;

	&:hover {
		background-color: ${darken(0.1, colors.blue)};
	}

	&:focus {
		border: ${px2rem(1)} solid ${colors.white};
	}
`;

export const BottomContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;

	@media (max-width: 1040px) {
		flex-direction: column;
	}
`;

export const BottomList = styled.div`
	display: flex;
	flex-wrap: wrap;

	@media (max-width: 1040px) {
		margin-bottom: 0.5rem;
	}

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(14)};
		align-items: center;
		flex-direction: column;
		text-align: center;
		width: 100%;
	}
`;

export const BottomLinkList = styled.ul`
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(14)};

		li:last-child {
			border: none;
			padding-right: 0;
			margin-right: 0;
		}

		flex-wrap: wrap;
		justify-content: center;
	}
`;

export const BottomListItem = styled.li`
	${mediaQueries.phoneOrTablet} {
		margin-bottom: 0.5rem;
	}

	padding-right: 1rem;
	margin-right: 1rem;
	border-right: ${px2rem(1)} solid ${colors.offBlueLessLight};
`;

export const BottomLink = styled(Link)`
	font-family: ${typography.fontFamily.alt};
	color: ${colors.offBlueLessLight};

	font-size: ${px2rem(16)};

	${mediaQueries.phoneOrTablet} {
		font-size: ${px2rem(14)};
		word-break: break-all;
	}

	&:hover {
		text-decoration: none;
	}

	&:focus {
		box-shadow: 0 0 0 ${px2rem(3)} ${colors.offBlueLessLight};
		outline: none;
	}
`;

export const BottomLogo = styled.img`
	${mediaQueries.desktop} {
		display: none;
	}
	margin-bottom: 2rem;

	${mediaQueries.phone} {
		max-width: ${px2rem(258)};
	}
`;

export const Copyright = styled.p`
	color: ${colors.white};
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	padding-left: 1rem;
	white-space: nowrap;

	${mediaQueries.phoneOrTablet} {
		display: none;
	}
	word-break: break-all;
`;

export const OrganizationNumber = styled.p`
	font-family: ${typography.fontFamily.alt};
	color: ${colors.white};
	margin: 0;
	word-break: break-all;
`;
