/**
 * DownloadButton - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { customMediaQueryValues } from 'theme/media-queries';
import { colors, typography } from 'theme';
import { darken } from 'polished';

import Icon from 'components/Icon';
import Button from 'components/Button';

export const StandardButton = styled(Button)`
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	outline: none;
	border: none;
	border-radius: ${px2rem(50)};
	font-family: ${typography.fontFamily.alt};
	font-weight: ${typography.fontWeight.medium};
	font-size: ${px2rem(15)};
	transition: all 0.2s;
	text-decoration: none;
	height: ${px2rem(54)};
	padding: 0 ${px2rem(7)} 0 ${px2rem(15)};
	background-color: ${colors.blueDark};
	border: none;
	color: ${colors.white};
	width: 100%;

	&:hover {
		background-color: ${darken(0.05, colors.blueDark)};
	}
`;

export const LinkArea = styled.div`
	display: flex;
	align-items: center;

	${customMediaQueryValues(840, 1200)} {
		display: none;
	}
`;

export const LinkText = styled.span`
	margin: 0 ${px2rem(10)};
`;

export const ChildArea = styled.div`
	display: flex;
	align-items: center;
`;

export const IconWrapper = styled.div`
	background: ${colors.white};
	width: ${px2rem(41)};
	height: ${px2rem(41)};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const PdfIcon = styled(Icon)`
	color: ${colors.white};
	height: ${px2rem(23)};
	width: ${px2rem(23)};
`;

export const ButtonIcon = styled(Icon)`
	height: ${px2rem(14)};
	width: ${px2rem(14)};
`;
