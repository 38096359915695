import React from 'react';
import { LabelProps, ErrorSpanProps } from 'types/form';
import {
	ErrorLabel,
	Label as StyledLabel,
	CheckboxLabel as StyledCheckboxLabel,
	CheckboxWrapper,
} from './Form.styles';

export const Label: React.FC<LabelProps> = ({
	fieldId,
	htmlFor,
	required,
	children,
	title,
}) => (
	<StyledLabel
		{...(fieldId && { id: `form${fieldId}__desc` })}
		htmlFor={htmlFor}
		title={title}
	>
		{children}
		{required && <abbr title="Obligatoriskt">*</abbr>}
	</StyledLabel>
);

export const ErrorSpan: React.FC<ErrorSpanProps> = ({
	fieldId,
	invalid,
	children,
	...props
}) => (
	<ErrorLabel
		id={`form${fieldId}__desc`}
		aria-live="polite"
		hidden={!invalid}
		{...props}
	>
		{children}
	</ErrorLabel>
);

export const CheckboxLabel: React.FC<LabelProps> = ({
	children,
	htmlFor,
	title,
}) => (
	<CheckboxWrapper>
		<StyledCheckboxLabel htmlFor={htmlFor} title={title}>
			{children}
		</StyledCheckboxLabel>
	</CheckboxWrapper>
);
