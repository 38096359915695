/**
 * CasePage - styles
 */

import styled from 'styled-components';
import { colors, typography, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';
import { Cell } from 'components/Grid';
import Link from 'components/Link';
import { H6, P, styleForP } from 'components/Text';

export interface ThemeProps {
	isActive?: boolean;
}

export const FragmentsContainer = styled.div`
	font-family: ${typography.fontFamily.alt};
	line-height: ${px2rem(32)};
`;

export const LeftCell = styled(Cell)`
	${mediaQueries.phone} {
		order: 1;
	}
`;

export const RightCell = styled(Cell)`
	width: auto;
	margin: 0 ${px2rem(30)};
	${mediaQueries.phone} {
		margin: 0 0;
		order: 0;
	}
`;

export const InfoWrapper = styled.article`
	display: flex;
	color: black;
	flex-direction: column;
	justify-content: space-between;
	height: 'auto';
	border: ${px2rem(1)} solid ${colors.offGreyLightest};
	&:not(:last-child) {
		margin-bottom: ${px2rem(13.5)};
	}
`;

export const InfoContent = styled.div`
	padding: ${px2rem(30)};
	width: 100%;
	height: 100%;

	${mediaQueries.phone} {
		padding: ${px2rem(16)};
	}
`;

export const InfoLink = styled(Link)`
	color: ${colors.black};
	text-decoration: none;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	&:hover {
		text-decoration: none;
	}
`;

export const TextArea = styled.div`
	&:not(:last-child) {
		margin-bottom: ${px2rem(15)};
	}
	word-break: normal;
`;

export const InfoHeading = styled(H6)`
	font-size: ${px2rem(16)};
	word-break: normal;
`;

export const InfoText = styled(P)`
	margin: 0;
	font-size: ${px2rem(16)};
	line-height: ${px2rem(20)};
	word-break: normal;
`;

export const InfoDownload = styled(Link)`
	${styleForP}
	color: ${colors.blue};
	font-size: ${px2rem(18)};
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}

	span {
		margin-right: 4px;
	}
`;
