import React from 'react';
import { Container } from '../TextBlock/TextBlock.styles';
import styled from 'styled-components';
import { MediaBlockModel } from '../../types/blocks';
import Image from '../Image';
import { px2rem } from '../../theme/utils';
import { mediaQueries } from '../../theme';

const MediaBlockContainer = styled.div<{
	$insideTopContent?: boolean;
}>`
	max-width: ${px2rem(788)};
	width: 100%;
	margin-inline: auto;
	& iframe {
		aspect-ratio: 16 / 9;
	}
	${mediaQueries.tablet} {
		max-width: ${px2rem(645)};
	}
	${mediaQueries.phone} {
		padding-inline: ${(props) => (props.$insideTopContent ? 0 : '1rem')};
	}
`;

const MediaBlockDescription = styled.div`
	font-family: 'Euclid Circular B', sans-serif;
	font-size: 0.9rem;
	line-height: 1.5;
	margin-top: 1rem;
	text-align: left;
`;

const MediaBlockWrapper = styled(Container)``;

const MediaBlock: React.FC<MediaBlockModel> = (props) => {
	const video = props?.mediaflowVideo || props?.epiVideo;

	return (
		<MediaBlockContainer $insideTopContent={props.insideTopContent}>
			<MediaBlockWrapper
				backgroundColor={'transparent'}
				blockAlignment={'center'}
			>
				{props.image && (
					<Image
						alt={props.image?.alt}
						sizes={props.image?.sizes}
						src={props.image?.src}
					/>
				)}
				{video && (
					<iframe
						frameBorder={0}
						allowFullScreen={true}
						allow={'encrypted-media; fullscreen'}
						width={'100%'}
						height={'100%'}
						src={video.src}
					/>
				)}
			</MediaBlockWrapper>
			<MediaBlockWrapper
				blockAlignment={'left'}
				backgroundColor={'transparent'}
			>
				{props.description && (
					<MediaBlockDescription>{props.description}</MediaBlockDescription>
				)}
			</MediaBlockWrapper>
		</MediaBlockContainer>
	);
};

export default MediaBlock;
