/**
 * ResponsiveIframe
 */

import React from 'react';
import {
	ResponsiveIframeWrapperProps,
	ResponsiveIframeWrapper,
	Iframe,
} from './ResponsiveIframe.styles';

interface Props extends ResponsiveIframeWrapperProps {
	/** Iframe src */
	src: string;

	/** Aria-label for the component */
	'aria-label': string;
}

/** Used to render a responsive iframe. */
const ResponsiveIframe: React.FC<Props> = ({
	src,
	'aria-label': ariaLabel,
	aspectRatio = '16:9',
}) => {
	return (
		<ResponsiveIframeWrapper aspectRatio={aspectRatio}>
			<Iframe src={src} aria-label={ariaLabel} />
		</ResponsiveIframeWrapper>
	);
};

export default ResponsiveIframe;
