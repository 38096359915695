import React, { forwardRef } from 'react';

import {
	Message,
	StatusMessage,
	TextContent,
	RichText,
	SummaryHeading,
} from './EpiForms.styles';

type State = {
	successMessage: string;
	invalidFields: string[];
	serverValidationError: string | boolean;
	validationMessages: any;
};

interface FormMessageProps {
	/** Message to display when successfully submitted */
	confirmationMessage: string | null;

	/** Current form state */
	state: State;

	/** FormMessage ref to set */
	ref: any;
}

interface ErrorSummaryProps {
	validationMessages: any;
}

const ErrorSummary: React.FC<ErrorSummaryProps> = ({ validationMessages }) => (
	<>
		<SummaryHeading>
			Formuläret innehåller {Object.keys(validationMessages).length} fel:
		</SummaryHeading>

		<RichText>
			<ul>
				{validationMessages &&
					Object.keys(validationMessages).map((key, i) => (
						<li key={i}>
							<div
								dangerouslySetInnerHTML={{
									__html: validationMessages[key].label,
								}}
							/>
							<span>{validationMessages[key].message}</span>
						</li>
					))}
			</ul>
		</RichText>
	</>
);

const FormMessage: React.FC<FormMessageProps> = forwardRef(
	({ confirmationMessage, state }, ref: any) => {
		const {
			successMessage,
			invalidFields,
			serverValidationError,
			validationMessages,
		} = state;
		const hasMessageToShow = confirmationMessage || successMessage;
		const messageType =
			serverValidationError || invalidFields.length > 0 ? 'error' : 'success';

		return (
			<Message hidden={messageType === 'success' && !hasMessageToShow}>
				<StatusMessage type={messageType} tabIndex={-1} ref={ref}>
					<TextContent>
						{(serverValidationError || invalidFields.length > 0) && (
							<ErrorSummary validationMessages={validationMessages} />
						)}

						{confirmationMessage && <p>{confirmationMessage}</p>}
						{successMessage && (
							<div dangerouslySetInnerHTML={{ __html: successMessage }} />
						)}
					</TextContent>
				</StatusMessage>
			</Message>
		);
	}
);

export default FormMessage;
