/**
 * Card - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, mediaQueries, typography } from 'theme';
import { H5 } from 'components/Text/Text.styles';
import Link from 'components/Link';

export interface ThemeProps {
	backgroundColor?: 'lightBlue' | 'blue' | 'white';
	headingAlignment?: 'left' | 'center';
	isPhone?: boolean;
	hover?: boolean;
	noImage?: boolean;
}

export const baseTextStyles = css`
	font-size: ${px2rem(13)};
	line-height: ${px2rem(16)};
	letter-spacing: ${px2rem(0.3)};
	font-family: ${typography.fontFamily.alt};

	${mediaQueries.phone} {
		font-size: ${px2rem(12)};
		line-height: ${px2rem(15)};
	}
`;

export const ArrowLink = styled.div`
	background-color: ${colors.offBlueLightest};
	color: ${colors.linkColorDark};
	font-family: ${typography.fontFamily.alt};
	padding: ${px2rem(15)};
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: ${typography.fontWeight.medium};
	text-decoration: none;
	margin-top: auto;

	svg {
		margin-left: ${px2rem(10)};
		width: ${px2rem(14)};
		height: ${px2rem(14)};
	}
`;

export const AnchorText = styled.p<ThemeProps>`
	color: ${colors.white};
	margin-top: auto;
	padding-bottom: ${px2rem(30)};

	${({ backgroundColor }) => {
		if (backgroundColor === 'white' || backgroundColor === 'lightBlue') {
			return `
                color: ${colors.linkColorDark};
            `;
		}
	}}

	font-size: ${px2rem(16)};
	line-height: ${px2rem(20)};
	display: inline-flex;
	align-items: center;
	text-decoration: underline;
`;

export const AnchorTextAlt = styled(AnchorText)`
	padding-bottom: ${px2rem(0)};
	margin-bottom: 0;
	color: ${colors.linkColorDark};
	font-family: ${typography.fontFamily.alt};
`;

export const AnchorTextAlt2 = styled(AnchorText)`
	font-family: ${typography.fontFamily.alt};
	margin-bottom: 0;
	padding-bottom: 0;
`;

export const CardWrapper = styled.article<ThemeProps>`
	height: 100%;
	> * {
		color: ${(props) =>
			props.backgroundColor === 'blue' ? colors.white : colors.greyDark};
	}

	&:hover {
		${AnchorText} {
			text-decoration: none;
		}

		${ArrowLink} {
			text-decoration: none;
		}
	}
`;

export const AltCardWrapper = styled(CardWrapper)`
	background-color: ${colors.white};

	${(props) => {
		if (props.backgroundColor === 'white' && props.noImage) {
			return `border: 1px solid ${colors.blueLightest}`;
		}
	}}}

	> * {
		color: ${colors.greyDark};
	}

	&:hover {
		box-shadow: 0 ${px2rem(2)} ${px2rem(42)} 0 rgba(0, 0, 0, 0.2);
	}
`;

export const Container = styled(Link)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-decoration: none;
	height: 100%;
	width: 100%;
`;

export const ImageColumn = styled.div`
	padding: 0;
	width: 100%;

	${mediaQueries.desktop} {
		display: flex;
		justify-content: center;
	}
`;

export const ImageColumnInner = styled.div`
	img {
		max-height: ${px2rem(495)};
	}
`;

export const BottomArea = styled.div`
	padding: ${px2rem(25)};
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	${mediaQueries.phone} {
		padding: ${px2rem(16)};
	}
	width: 100%;
`;

export const BottomAreaAlt = styled(BottomArea)<ThemeProps>`
	width: 100%;
	padding: 0;
	border-left: ${px2rem(1)} solid ${colors.greyLighter};
	border-right: ${px2rem(1)} solid ${colors.greyLighter};
	border-bottom: ${px2rem(1)} solid ${colors.greyLighter};
	border-top: ${(props) =>
		props.noImage ? `${px2rem(1)} solid ${colors.greyLighter}` : '0'};
`;

export const TextContent = styled.div`
	padding: ${px2rem(20)};
`;

export const TextColumn = styled.div`
	margin: ${px2rem(26)} 0;
`;

export const TextColumnAlt = styled(TextColumn)`
	margin: 0;
`;

export const DateArea = styled.p`
	display: flex;
	margin: 0 0 ${px2rem(13)} 0;
`;

export const TextColumnInner = styled.div`
	max-width: ${px2rem(420)};
`;

export const Date = styled.time`
	${baseTextStyles};
	color: ${colors.grey};

	&:after {
		content: '|';
		margin: 0 ${px2rem(10)};
	}
`;

export const Category = styled.span`
	${baseTextStyles};
	color: ${colors.linkColorDark};
`;

export const Heading = styled(H5)<ThemeProps>`
	font-size: ${px2rem(19)};
	line-height: ${px2rem(25)};
	letter-spacing: ${px2rem(0.4)};
	margin: 0 0 ${px2rem(20)} 0;
	font-weight: ${typography.fontWeight.medium};
	color: ${({ backgroundColor }) =>
		backgroundColor === 'blue' ? colors.white : colors.greyDark};

	${mediaQueries.phone} {
		font-size: ${px2rem(18)};
		line-height: ${px2rem(24)};
	}
`;

export const HeadingAlt = styled(Heading)`
	margin-bottom: ${px2rem(20)};
	font-weight: ${typography.fontWeight.semiBold};
`;

export const SubHeading = styled.p`
	color: ${colors.linkColorDark};
	font-size: ${px2rem(12)};
	line-height: ${px2rem(15)};
	font-weight: ${typography.fontWeight.semiBold};
	text-transform: uppercase;
	font-family: ${typography.fontFamily.alt};
`;

export const Preamble = styled.p<ThemeProps>`
	font-family: ${typography.fontFamily.alt};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(24)};
	letter-spacing: normal;
	margin: 0 0 1rem;
	color: ${({ backgroundColor }) =>
		backgroundColor === 'blue' ? colors.white : colors.greyDark};
`;

export const IconBadge = styled.span<ThemeProps>`
	margin-left: ${px2rem(8)};
	svg {
		color: ${colors.white};

		${({ backgroundColor }) => {
			if (backgroundColor === 'white' || backgroundColor === 'lightBlue') {
				return `
						color: ${colors.linkColorDark};
					`;
			}
		}}

		height: ${px2rem(14)};
		width: ${px2rem(14)};
	}
`;

export const IconBadgeAlt = styled(IconBadge)`
	svg {
		color: ${colors.linkColorDark};
	}
`;

export const VideoButton = styled.button`
	display: inline-flex;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: none;
	height: ${px2rem(55)};
	border-radius: ${px2rem(43)};
`;

export const VideoButtonText = styled.span`
	text-transform: uppercase;
	margin: 0 ${px2rem(10)};
`;

export const VideoContainer = styled.div`
	position: relative;
`;

export const VideoIcon = styled.div`
	background-color: ${colors.linkColorDark};
	height: ${px2rem(45)};
	width: ${px2rem(45)};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		color: ${colors.white};
	}
`;

export const Overlay = styled.div`
	background-color: rgba(0, 17, 32, 0.45);
	width: 100%;
	height: 100%;
	position: absolute;
`;
