/**
 * ProcessItemBlock
 */

import React from 'react';
import {
	AccordionContainer,
	Container,
	IconArea,
	Counter,
	FragmentContainer,
	Heading,
	StyledLink,
	IconBadge,
} from './ProcessItemBlock.styles';
import Icon from 'components/Icon';
import { ScreenReaderText } from 'components/Text';
import { LinkType } from 'types/global';
import EpiFragments from 'components/EpiFragments';

interface Props {
	/** Process heading */
	heading: string;

	/** Process preamble */
	preamble?: any;

	/** Process counter */
	count: number;

	/** Read more label */
	readMoreLabel: string;

	/** Screenreader text (hidden) */
	screenReaderText: string;

	/** Process link */
	link?: LinkType;

	/** BackgroundColor */
	backgroundColor?: 'offBlueLightest' | 'white';

	/** Determine wether it's a accordion or not */
	accordion?: boolean;
}

/** ProcessItemBlock. */
const ProcessItemBlock: React.FC<Props> = ({
	heading,
	preamble,
	count,
	accordion,
	backgroundColor = 'offBlueLightest',
	readMoreLabel,
	screenReaderText,
	link,
}) => {
	return accordion ? (
		<AccordionContainer title={heading} $variant={backgroundColor}>
			{preamble && (
				<FragmentContainer accordion={accordion}>
					<EpiFragments fragments={preamble.fragments} />
				</FragmentContainer>
			)}
			{link && (
				<StyledLink href={link.link} target={link.target}>
					{readMoreLabel}
					<ScreenReaderText>{`${screenReaderText} ${heading}`}</ScreenReaderText>

					<IconBadge>
						<Icon icon={'arrowRight'} size={0} aria-hidden="true" />
					</IconBadge>
				</StyledLink>
			)}
		</AccordionContainer>
	) : (
		<Container>
			<IconArea even={count % 2 === 0}>
				<Icon icon={'diamond'} size={0} aria-hidden="true" />
				<Counter>{count}</Counter>
			</IconArea>

			<Heading as="h3" id={`heading-${count}`}>
				{heading}
			</Heading>

			{preamble && (
				<FragmentContainer>
					<EpiFragments fragments={preamble.fragments} />
				</FragmentContainer>
			)}

			{link && (
				<StyledLink href={link.link} target={link.target}>
					{readMoreLabel}
					<ScreenReaderText>{`${screenReaderText} ${heading}`}</ScreenReaderText>

					<IconBadge>
						<Icon icon={'arrowRight'} size={0} aria-hidden="true" />
					</IconBadge>
				</StyledLink>
			)}
		</Container>
	);
};

export default ProcessItemBlock;
