/**
 * ProcessItemBlock - styles
 */

import styled, { css } from 'styled-components';
import { px2rem } from 'theme/utils';
import { colors, typography, mediaQueries } from 'theme';
import { H5 } from 'components/Text/Text.styles';
import Link from 'components/Link';

type StyleProps = {
	even?: boolean;

	accordion?: boolean;

	$variant?: 'white' | 'offBlueLightest';
};

export const StyledLink = styled(Link)`
	margin-top: auto;
	color: ${colors.linkColorDark};
	font-size: ${px2rem(16)};
	line-height: ${px2rem(20)};
	display: inline-flex;
	align-items: center;
`;

export const AccordionContainer = styled.div<StyleProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: ${px2rem(25)} ${px2rem(85)};

	${StyledLink} {
		margin-top: 1rem;
	}

	${({ $variant }) => {
		if ($variant === 'white') {
			return css`
				background-color: ${colors.greyLightest};
			`;
		} else {
			return css`
				background-color: ${colors.white};
			`;
		}
	}}

	${mediaQueries.phone} {
		padding: ${px2rem(25)};
	}
`;

export const Container = styled.div`
	font-family: ${typography.fontFamily.alt};
	max-width: ${px2rem(360)};
	min-height: ${px2rem(460)};
	background-color: ${colors.white};
	padding: ${px2rem(50)} ${px2rem(40)};
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	${mediaQueries.phoneOrTablet} {
		max-width: 100%;
		padding: ${px2rem(34)} ${px2rem(23)};
	}
`;

export const IconArea = styled.div<StyleProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	svg {
		height: ${px2rem(90)};
		width: ${px2rem(90)};
		color: ${(props) => (props.even ? colors.orange : colors.blue)};
	}
`;

export const Counter = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: ${colors.white};
	font-size: ${px2rem(36)};
	font-weight: ${typography.fontWeight.semiBold};
	letter-spacing: 0;
	line-height: ${px2rem(46)};
`;

export const Heading = styled(H5)`
	font-size: ${px2rem(17)};
	line-height: ${px2rem(24)};
	letter-spacing: 0;
	margin: ${px2rem(50)} 0 ${px2rem(21)} 0;
	font-weight: ${typography.fontWeight.medium};
	color: ${colors.greyDark};

	${mediaQueries.phone} {
		font-size: ${px2rem(18)};
		line-height: ${px2rem(24)};
		margin: ${px2rem(30)} 0 ${px2rem(21)} 0;
	}
`;

export const FragmentContainer = styled.div<StyleProps>`
	font-family: ${typography.fontFamily.alt};
	color: ${colors.greyDark};
	line-height: ${px2rem(24)};
	font-size: ${px2rem(18)};
	letter-spacing: ${px2rem(0.35)};

	li {
		margin-bottom: ${px2rem(0)};
	}

	${({ accordion }) => {
		if (accordion) {
			return css`
				margin: 0;
				p,
				a,
				ul,
				ol,
				li {
					line-height: ${px2rem(32)};
					font-size: ${px2rem(18)};
				}
			`;
		} else {
			return css`
				margin: 0 0 ${px2rem(15)} 0;
				p,
				a,
				ul,
				ol,
				li {
					line-height: ${px2rem(24)};
					font-size: ${px2rem(16)};
				}
			`;
		}
	}}

	${mediaQueries.phone} {
		font-size: ${px2rem(14)};
	}
`;

export const IconBadge = styled.span`
	margin-left: ${px2rem(8)};
	svg {
		color: ${colors.linkColorDark};
		height: ${px2rem(14)};
		width: ${px2rem(14)};
	}
`;
