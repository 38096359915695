/**
 * FactItem - styles
 */

import styled, { css } from 'styled-components';
import { colors, mediaQueries } from 'theme';
import { px2rem } from 'theme/utils';

type StyleProps = {
	$variant?:
		| 'text'
		| 'link'
		| 'download'
		| 'text-no-icon'
		| 'link-no-icon'
		| 'download-no-icon';
};

const getContainerStyle = (variant: string) => {
	switch (variant) {
		case 'text':
			return css`
				background-color: ${colors.orangeLighter};
				&:before {
					background-color: ${colors.orangeLight};
				}
			`;
		case 'text-no-icon':
			return css`
				background-color: ${colors.orangeLighter};
				&:before {
					background-color: ${colors.orangeLight};
				}
			`;
		case 'link':
			return css`
				background-color: ${colors.offBlueLightest};
				&:before {
					background-color: ${colors.blue};
				}
			`;
		case 'link-no-icon':
			return css`
				background-color: ${colors.offBlueLightest};
				&:before {
					background-color: ${colors.blue};
				}
			`;
		case 'download':
			return css`
				background-color: ${colors.greyLightest};
				&:before {
					background-color: ${colors.offGreyLightest};
				}
			`;
		case 'download-no-icon':
			return css`
				background-color: ${colors.greyLightest};
				&:before {
					background-color: ${colors.offGreyLightest};
				}
			`;
		default:
			return css`
				background-color: ${colors.orangeLighter};
				&:before {
					background-color: ${colors.orangeLight};
				}
			`;
	}
};

export const Container = styled.div<StyleProps>`
	position: relative;

	&:before {
		content: ' ';
		height: 100%;
		position: absolute;
		left: 0;
		width: ${px2rem(7)};
	}

	${({ $variant }) => getContainerStyle($variant || 'text')};
`;

export const TextContainer = styled.div`
	padding: ${px2rem(60)};
	${mediaQueries.phone} {
		padding: ${px2rem(67)} ${px2rem(34)} ${px2rem(45)} ${px2rem(34)};
	}
`;

export const FactTextContainer = styled.div`
	max-width: 75%;
	font-size: ${px2rem(18)};
	line-height: ${px2rem(32)};

	p {
		letter-spacing: 0;
		margin: 0;
		padding: 0;
	}

	${mediaQueries.phone} {
		max-width: 100%;
		font-size: ${px2rem(15)};
		line-height: ${px2rem(24)};
	}
`;

export const IconArea = styled.div<StyleProps>`
	background-color: ${colors.white};
	height: ${px2rem(64)};
	width: ${px2rem(64)};
	border-radius: 100%;
	position: absolute;
	top: ${px2rem(30)};
	right: ${px2rem(30)};

	${({ $variant }) => {
		if ($variant !== 'link') {
			return css`
				display: flex;
				align-items: center;
				justify-content: center;
			`;
		}
	}}

	svg {
		height: ${px2rem(90)};
		width: ${px2rem(28)};
	}

	${mediaQueries.phone} {
		top: ${px2rem(11)};
		right: ${px2rem(11)};
		height: ${px2rem(42)};
		width: ${px2rem(42)};

		svg {
			height: ${px2rem(46)};
			width: ${px2rem(18)};
		}
	}
`;

export const LinksContainer = styled.div`
	div {
		margin-top: ${px2rem(30)};
		display: inline-flex;
		flex-direction: column;
	}

	a {
		&:not(:last-child) {
			margin-bottom: ${px2rem(16)};
		}
	}

	svg {
		color: ${colors.blueDark};
		margin-left: 0.9375rem;
	}
`;
