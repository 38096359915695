/**
 * GlossaryItem
 */

import React from 'react';
import { Heading } from './GlossaryPage.styles';
import EpiFragments from 'components/EpiFragments';
import { Accordion, AccordionItem } from 'components/Accordion';

type Props = {
	heading: string;
	items?: [
		{
			heading: string;
			text?: any;
		}
	];
	index: number;
};

/** GlossaryItem */
const GlossaryItem: React.FC<Props> = ({ heading, items, index }) => {
	const renderFragments = (fragments: any) => {
		let newData;
		if (fragments) {
			newData = fragments.map((fragment: any) => {
				if (
					fragment.modelType === 'UrlFragment' &&
					fragment.target === '_blank'
				) {
					return Object.assign({}, fragment, { iconType: 'external' });
				}

				return fragment;
			});
		}

		return <EpiFragments fragments={newData} />;
	};

	return (
		<>
			<Heading as="h2" marginTop={index > 0} id={heading}>
				{heading}
			</Heading>

			{items && items.length > 0 && (
				<Accordion>
					{items.map((item: any, l: number) => (
						<AccordionItem variant="list" title={item.heading} key={l}>
							{renderFragments(item.text?.fragments)}
						</AccordionItem>
					))}
				</Accordion>
			)}
		</>
	);
};

export default GlossaryItem;
